import { Popover as MuiPopover, PopoverOrigin } from '@material-ui/core';
import { FC, ReactNode } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';

import { styles } from './Popover.styles';

export interface PopoverProps extends WithStyles<typeof styles> {
  anchorEl: HTMLElement | null;
  children: ReactNode;
  onClick?: (e?: any) => void;
  onClose?: () => void;
  open: boolean;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  elevation?: number;
  className?: string;
}

const PopoverComponent: FC<PopoverProps> = ({
  classes,
  anchorEl,
  children,
  onClick,
  onClose,
  open,
  anchorOrigin = {
    vertical: 'top',
    horizontal: 'center',
  },
  transformOrigin = {
    vertical: 'bottom',
    horizontal: 'center',
  },
  elevation = 0,
  className,
}) => {
  return (
    <MuiPopover
      open={open}
      anchorEl={anchorEl}
      onClick={onClick}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      elevation={elevation}
      classes={{
        paper: className || classes.paper,
      }}
    >
      {children}
    </MuiPopover>
  );
};

export const Popover = withStyles(styles)(PopoverComponent);
