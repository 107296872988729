import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      flexDirection: 'column',
    },
    label: {},
    autocompleteListItem: {},
    closeIconContainer: {
      borderRadius: 6,
      cursor: 'pointer',
      padding: spacing(2),
      '&:hover': {
        backgroundColor: '#F2F4F7', // TODO: Update this when new palette is created
      },
    },
    dragOption: {
      padding: spacing(3, 2),
      borderRadius: 6,
    },
    draggableOption: {
      alignItems: 'center',
      backgroundColor: colors.white.lightest,
      border: '1px solid #D1D6DF',
      borderRadius: 6,
      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.07)',
      display: 'flex',
      width: '100%',
      maxWidth: '100%',

      '&:hover': {
        cursor: 'grab',
        backgroundColor: colors.white.lightest,
        border: '1px solid #9EA6B3',
        boxShadow: '0px 4px 8px -2px rgba(16, 24, 40, 0.05), 0px 2px 4px -1px rgba(16, 24, 40, 0.10)',
      },
    },
    dragIcon: {
      fontSize: 14,
      marginRight: spacing(4),
    },
    draggableList: {
      backgroundColor: '#EAECF0', // TODO: Update this when new palette is created
      borderRadius: 8,
      marginTop: spacing(2),
      padding: spacing(2),

      '& > *': {
        maxWidth: 800,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: spacing(2),
      },
    },
    draggableListItem: {
      width: '100%',
    },
    optionsList: {},
    optionAvatar: {
      width: 24,
      minWidth: 24,
      height: 24,
      marginRight: spacing(1.5),
    },
    optionAvatarInitials: {
      fontSize: '9.6px',
      paddingTop: 2,
    },
    selectedOptionAvatar: {
      width: 32,
      minWidth: 32,
      height: 32,
      marginRight: spacing(2),
    },
    selectedOptionAvatarInitials: {
      fontSize: 14,
    },
    userName: {
      color: colors.grey.darker,
      fontSize: 14,
      fontWeight: 500,
      marginRight: spacing(2),
    },
    removeDraggableOptionIcon: {
      color: colors.grey.main,
      fontSize: 20,

      '&:hover': {
        cursor: 'pointer',
        color: colors.red.main,
      },
    },
    avatarPlaceholder: {
      borderRadius: '50%',
      backgroundColor: colors.grey.lightish,
      minWidth: 24,
      height: 24,
      marginRight: spacing(1.5),
      marginLeft: spacing(-1.5),
    },
    searchInput: {
      gap: spacing(2),
    },
    searchProperty: {
      maxWidth: '100%',
    },
    searchPropertyListMessage: {
      color: colors.black.darker,
      fontSize: 14,
    },
    searchListItem: {
      padding: 0,
      margin: spacing(0.5, 1),

      '&:hover': {
        backgroundColor: colors.white.lightest,
      },
    },
  });
