import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

export const styles = ({ spacing }: Theme) => {
  return createStyles({
    root: {
      height: '100%',
      '& $inputContainer': {
        display: 'flex',
        gap: spacing(2),
        padding: spacing(2),
        outline: 'solid 1px #D1D6DF', // TODO: replace after new palette creating
        borderRadius: spacing(3),
        height: '100%',
        overflow: 'hidden',
        '&:focus-within': {
          outline: `solid 2px ${colors.kato.navy['950']}`,
        },
      },
    },
    inputActive: {
      '& $inputContainer': {
        outline: `solid 2px ${colors.kato.navy['950']}`,
      },
    },
    actionsContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
    error: {
      '& $inputContainer': {
        outline: 'solid 2px #F44336', //TODO replace after new palette creating
      },
    },
    labelRoot: {
      color: '#101828', // TODO: replace after new palette creating
      fontSize: 14,
      fontWeight: 500,
      marginBottom: spacing(2),
    },
    labelError: {
      color: '#C52810 !important', //TODO replace after new palette creating
    },
    helperText: {
      paddingTop: spacing(1),
    },
    inputContainer: {},
    textFieldRoot: {
      height: '100%',
      '& textarea': {
        boxSizing: 'border-box',
        height: '100% !important',
        resize: 'none',
      },
      '& .MuiFormControl-root': {
        height: '100%',
      },
      '& .MuiOutlinedInput-root': {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      },
      '& .MuiInputBase-input': {
        padding: `${spacing(2)}px !important`,
        scrollbarWidth: 'thin',
        color: '#101828', //TODO replace after new palette creating
        lineHeight: '20px',
        '&::placeholder': {
          color: '#7E8899', //TODO replace after new palette creating
        },
      },
      '& $.MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent !important',
      },
    },
    textFieldInput: {
      height: '100%',
      '&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        border: `1px solid transparent !important`,
      },

      '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `1px solid transparent !important`,
        borderWidth: `1px !important`,
        boxShadow: 'none',
        transition: 'none',
      },

      '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
        boxShadow: 'none',
      },
    },
  });
};
