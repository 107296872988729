import { createStyles, Theme } from '@material-ui/core/styles';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      gap: spacing(6),
    },
    calendarInfoWrapper: {
      gap: spacing(6),
    },
    label: {
      color: '#101828', // TODO replace after creating new palette
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '16px',
      marginBottom: spacing(2),
    },
    radioGroupLabel: {
      margin: spacing(0, 0, 2),
    },
    datePickerRoot: {
      flex: '1 1 auto',

      '& .MuiOutlinedInput-adornedStart': {
        width: '100%',
      },
    },
    timeSelectContainer: {},
    timeSelect: {
      width: '100%',
    },
    usersLookup: {},
    usersDraggableOption: {
      padding: spacing(2, 3),
    },
    usersDraggableList: {
      '& > *': {
        maxWidth: '100%',
      },
    },
    usersAvatarPlaceholder: {
      display: 'none',
    },
    usersSelectedOptionAvatar: {
      minWidth: 24,
      width: 24,
      height: 24,
    },
    usersCloseIconContainer: {
      padding: 0,
    },
    usersSelectedOptionAvatarInitials: {
      fontSize: 12,
    },
  });
