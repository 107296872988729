import { CircularProgress } from '@material-ui/core';
import cx from 'classnames';
import { FC, ReactNode, useMemo } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Button, ButtonProps } from '@shared/components/button-new';
import { ButtonColour, ButtonSize } from '@shared/types/common/button';

import { styles } from './IconButton.styles';

export interface IconButtonProps
  extends Omit<ButtonProps, 'classes' | 'text' | 'startIcon' | 'loading'>,
    WithStyles<typeof styles> {
  icon: ReactNode;
  loading?: boolean;
}

const IconButtonComponent: FC<IconButtonProps> = ({
  classes,
  colour = ButtonColour.secondaryColour,
  icon,
  loading,
  disabled,
  size = ButtonSize.medium,
  ...buttonProps
}) => {
  const iconContent = useMemo(() => {
    if (loading) {
      return (
        <CircularProgress
          color={colour === ButtonColour.primary ? 'secondary' : 'primary'}
          size={size === ButtonSize.extraSmall ? 12 : 15}
        />
      );
    }

    return icon;
  }, [icon, loading]);

  return (
    <Button
      classes={{ root: cx(classes.root, { [classes.buttonLoading]: loading }), buttonStartIcon: classes.startIcon }}
      colour={colour}
      disabled={disabled}
      size={size}
      startIcon={iconContent}
      {...buttonProps}
    />
  );
};

export const IconButton = withStyles(styles)(IconButtonComponent);
