import cx from 'classnames';
import { noop } from 'lodash';
import { FC, ReactNode, useMemo } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { BulkTable, BulkTableProps } from '@shared/components/bulk-table';
import { ListingPageStatesKey } from '@shared/skeletons/listing-page-skeleton/components/listing-page-states';

import styles from './TableBody.styles';
import { TableNew, TableNewProps } from '../table-new';

export interface TableBodyProps extends Omit<TableNewProps, 'classes'>, WithStyles<typeof styles> {
  bulkEnabled?: boolean;
  bulkSelected?: BulkTableProps['bulkSelected'];
  stateContent: ReactNode;
  stateKey: ListingPageStatesKey;
  onBulkClear?: () => void;
  setBulkSelected?: (ids: number[]) => void;
}

const TableBodyComponent: FC<TableBodyProps> = ({
  bulkEnabled,
  classes,
  bulkSelected,
  stateContent,
  stateKey,
  setBulkSelected,
  ...coreTableProps
}) => {
  const isError = useMemo(() => stateKey === ListingPageStatesKey.error, [stateKey]);

  const visibleItems: Item[] = useMemo(() => (isError ? [] : coreTableProps.items), [isError, coreTableProps.items]);

  const stateOverlay = useMemo(() => {
    if (stateKey === ListingPageStatesKey.content) {
      return null;
    }

    return (
      <div className={classes.stateOverlay}>
        <div
          className={cx(classes.stateOverlayContent, {
            [classes.stateOverlayContentLoading]: stateKey === ListingPageStatesKey.loading,
            [classes.stateOverlayContentShort]: visibleItems.length > 0 && visibleItems.length <= 2,
          })}
        >
          {stateContent}
        </div>
      </div>
    );
  }, [stateContent, stateKey, visibleItems]);

  return (
    <div className={classes.root}>
      {/* Table Body */}
      <div className={cx(classes.tableBody, { [classes.bodyContentFullHeight]: !visibleItems.length })}>
        {bulkEnabled ? (
          <BulkTable
            {...coreTableProps}
            classes={{
              root: classes.tableContainerRoot,
              bodyCell: classes.tableBodyCell,
              headerCell: classes.tableHeaderCell,
              emptyCell: classes.emptyCell,
              emptyContent: classes.emptyContent,
            }}
            bulkSelected={bulkSelected ?? []}
            onChangeBulkSelectedRows={setBulkSelected ?? noop}
          />
        ) : (
          <TableNew
            {...coreTableProps}
            classes={{
              root: classes.tableContainerRoot,
              bodyCell: classes.tableBodyCell,
              headerCell: classes.tableHeaderCell,
              emptyCell: classes.emptyCell,
              emptyContent: classes.emptyContent,
            }}
          />
        )}
        {stateOverlay}
      </div>
    </div>
  );
};

export const TableBody = withStyles(styles)(TableBodyComponent);
