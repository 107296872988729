import {
  FormControl,
  FormLabel,
  MenuItem,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  TooltipProps,
} from '@material-ui/core';
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@material-ui/icons';
import cx from 'classnames';
import { ChangeEvent, PropsWithChildren, ReactNode, useMemo } from 'react';

import { t } from '@core/i18n';
import { ClassesProp } from '@core/theme/utils/with-styles';
import { Ellipsis } from '@shared/components/ellipsis';
import { FieldHelperText } from '@shared/components/field-helper-text';
import { Tooltip } from '@shared/components/tooltip';
import { ButtonSize, SelectColour } from '@shared/types/common/button';

import { useSelectClasses } from './Select.styles';

// ****************************************
// * !! DO NOT USE COMPONENT DIRECTLY  !! *
// * !! USE SingleSelect / MultiSelect !! *
// ****************************************

export interface Option<T extends Id = Id> {
  label: string;
  icon?: ReactNode;
  id: T;
  disabled?: boolean;
  show?: boolean;
  tooltip?: string;
}

export type OptionHash<TValue extends Id = Id> = Record<TValue, Option<TValue>>;

export type SingleSelectOnChange<TValue extends Id = Id> = (value: TValue) => void;

export type MultiSelectOnChange<TValue extends Id = Id> = (value: TValue[]) => void;

export type SelectOnChange<TValue extends Id = Id> = (value: TValue | TValue[]) => void;

// ****************************************
// * !! DO NOT USE COMPONENT DIRECTLY  !! *
// * !! USE SingleSelect / MultiSelect !! *
// ****************************************

export const generateOptionsHash = <TValue extends Id = Id>(options: Option<TValue>[]): OptionHash<TValue> =>
  options.reduce((result, option) => {
    if (typeof option.id === 'number') {
      result[option.id] = option;
    } else if (typeof option.id === 'string' && option.id) {
      result[option.id] = option;
    }

    return result;
  }, {} as OptionHash<TValue>);

// ****************************************
// * !! DO NOT USE COMPONENT DIRECTLY  !! *
// * !! USE SingleSelect / MultiSelect !! *
// ****************************************

export type SelectProps<TValue extends Id = Id> = PropsWithChildren<{
  action?: ReactNode;
  active?: boolean;
  classes?: ClassesProp<typeof useSelectClasses>;
  className?: MuiSelectProps['className'];
  colour?: SelectColour;
  customValue?: string;
  disabled?: boolean;
  endAdornment?: MuiSelectProps['endAdornment'];
  error?: boolean;
  errorText?: string;
  fullWidth?: boolean;
  hovered?: boolean;
  icon?: ReactNode;
  infoText?: string;
  label?: ReactNode;
  multiple?: boolean;
  options: Option<TValue>[];
  optionsHash: OptionHash<TValue>;
  placeholder?: string;
  required?: MuiSelectProps['required'];
  size?: ButtonSize;
  startAdornment?: MuiSelectProps['startAdornment'];
  tooltip?: string;
  tooltipPlacement?: TooltipProps['placement'];
  value?: TValue | TValue[];
  onChange?: SelectOnChange<TValue>;
  onClose?: (event: ChangeEvent<any>) => void;
  onOpen?: (event: ChangeEvent<any>) => void;
}>;

// ****************************************
// * !! DO NOT USE COMPONENT DIRECTLY  !! *
// * !! USE SingleSelect / MultiSelect !! *
// ****************************************

export const Select = <TValue extends Id = Id>({
  action,
  active = false,
  classes: propClasses,
  className,
  colour = SelectColour.secondaryColour,
  customValue,
  disabled = false,
  endAdornment,
  error = false,
  errorText,
  fullWidth = false,
  hovered = false,
  icon,
  infoText = '',
  label,
  multiple = false,
  options,
  optionsHash,
  placeholder = t('select'),
  required,
  size = ButtonSize.small,
  startAdornment,
  tooltip,
  tooltipPlacement = 'left',
  value,
  onChange,
  onClose,
  onOpen,
}: SelectProps<TValue>) => {
  const classes = useSelectClasses(propClasses);

  const defaultValue = useMemo(() => {
    if (multiple) {
      return Array.isArray(value) ? value : [];
    } else {
      return typeof value !== 'undefined' ? value : '';
    }
  }, []);

  const hasError = useMemo(() => error && !!errorText, [error, errorText]);

  const handleChange = (e: React.ChangeEvent<any>) => {
    if (!onChange) {
      return;
    }

    if (multiple) {
      if (Array.isArray(e.target.value)) {
        onChange(e.target.value as TValue[]);
      } else {
        onChange([]);
      }
    } else {
      if (typeof e.target.value !== 'undefined') {
        onChange(e.target.value as TValue);
      } else {
        onChange('' as TValue);
      }
    }
  };

  const handleClose = (e: React.ChangeEvent<any>) => {
    if (onClose) {
      onClose(e);
    }

    setTimeout(() => {
      // @ts-ignore
      document?.activeElement?.blur();
    }, 0);
  };

  const renderSelectorValue = (data: any) => {
    let selectorValue = <></>;
    let selectorLabel = '';

    // Multi select
    if (multiple && Array.isArray(data)) {
      selectorLabel = data.map((id: TValue) => optionsHash[id]?.label).join(', ');
    }
    // Single select
    if (!multiple && typeof data !== 'undefined') {
      selectorLabel = optionsHash[data as TValue]?.label || '';
    }

    if (selectorLabel) {
      // Render option label
      selectorValue = (
        <span className={classes.selectorValueText} dangerouslySetInnerHTML={{ __html: selectorLabel }} />
      );
    } else {
      // Show placeholder
      selectorValue = (
        <Ellipsis
          withTooltip={false}
          maxWidth="100%"
          text={placeholder}
          classes={{ root: classes.valuePlaceholderEllipsis }}
        />
      );
    }

    // TODO: This needs refactoring / review
    if (customValue) {
      selectorValue = <span className={classes.selectorValueText} dangerouslySetInnerHTML={{ __html: customValue }} />;
    }

    return (
      <div
        className={cx(classes.value, classes[size], {
          [classes.valueDisabled]: disabled,
          [classes.valueMultiple]: multiple,
          [classes.valuePlaceholder]: !selectorLabel,
        })}
      >
        {!!icon && (
          <span className={cx(classes.valueIcon, { [classes.valueIconPlaceholder]: !selectorLabel })}>{icon}</span>
        )}
        {selectorValue}
      </div>
    );
  };

  const renderOption = (option: Option) => {
    const optionContent = (
      <MenuItem
        value={option.id}
        key={option.id}
        classes={{
          root: cx(classes.menuItem, { [classes.menuItemDisabled]: option.disabled }),
          selected: classes.menuItemActive,
        }}
      >
        {!!option?.icon && <span className={classes.menuItemIcon}>{option?.icon}</span>}
        <span className={classes.menuItemText} dangerouslySetInnerHTML={{ __html: option.label }} />
      </MenuItem>
    );

    if (option.disabled) {
      return (
        <Tooltip data-value={option.id} placement="left" key={option.id} title={option.tooltip as string}>
          {optionContent}
        </Tooltip>
      );
    }

    return optionContent;
  };
  let content = (
    <>
      {label && (
        <FormLabel
          classes={{ root: classes.labelRoot, error: classes.labelError }}
          component="legend"
          error={error}
          required={required}
        >
          {label}
          {action}
        </FormLabel>
      )}
      <MuiSelect
        classes={{ root: cx(classes.select, classes[size]) }}
        className={className}
        defaultValue={defaultValue}
        disabled={disabled}
        displayEmpty
        endAdornment={endAdornment}
        error={error}
        IconComponent={KeyboardArrowDownIcon}
        required={required}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
          classes: { paper: classes.menu },
        }}
        multiple={multiple}
        placeholder={placeholder}
        startAdornment={startAdornment}
        value={value ?? defaultValue}
        onChange={handleChange}
        onClose={handleClose}
        renderValue={renderSelectorValue}
        onOpen={onOpen}
      >
        {options
          .filter((option) => typeof option.show === 'undefined' || option.show)
          .map((option) => renderOption(option))}
      </MuiSelect>
      <FieldHelperText
        classes={{ root: classes.helperText }}
        error={hasError}
        errorText={errorText}
        infoText={infoText}
      />
    </>
  );
  if (tooltip) {
    content = (
      <Tooltip placement={tooltipPlacement} title={tooltip}>
        {content}
      </Tooltip>
    );
  }

  return (
    <FormControl
      variant="outlined"
      classes={{
        root: cx(classes.root, classes[colour], classes[size], {
          [classes.selectActive]: active,
          [classes.selectHovered]: hovered,
          [classes.selectDisabled]: disabled,
          [classes.rootFullWidth]: fullWidth,
        }),
      }}
      required={required}
      error={error}
    >
      {content}
    </FormControl>
  );
};
