import cx from 'classnames';
import { FC, useMemo, useState } from 'react';

import { t } from '@core/i18n';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { IconButton } from '@shared/components/icon-button';
import { ReceivedMessageItem } from '@shared/components/interest-schedule/received-message-item';
import { SentMessageItem } from '@shared/components/interest-schedule/sent-message-item';
import { TextFieldWithActions } from '@shared/components/text-field-with-actions';
import { SendIcon } from '@shared/icons/send-2';
import { AgentMessage } from '@shared/types/common/agentMessage';
import { ButtonColour, ButtonSize } from '@shared/types/common/button';

import { styles } from './ReportingThreadsMessages.styles';

export interface ReportingThreadsMessagesProps extends WithStyles<typeof styles> {
  messages: AgentMessage[];
}

const ReportingThreadsMessagesComponent: FC<ReportingThreadsMessagesProps> = ({ classes, messages }) => {
  const defaultAgentMessage: AgentMessage = {
    by_image: 'https://randomuser.me/api/portraits',
    by_name: 'Sam Simon (Lonic)',
    by_theme: { backgroundColour: '#C9F3DE', textColour: '#31D482' },
    can_manage: false,
    created_at: new Date().toISOString(),
    id: Math.random(),
    message:
      'Preference for space that can be acquired on a managed basis. Please send through any options you may have.',
  };

  const [agentMessages, setAgentMessages] = useState<AgentMessage[]>(
    messages.length ? messages : [defaultAgentMessage]
  );
  const [message, setMessage] = useState<string>('');
  const [agentMessagesMockLoader, setAgentMessagesMockLoader] = useState<boolean>(false);

  const handleAddAgentMessage = () => {
    return new Promise<void>((resolve) => {
      // Mock loader
      setAgentMessagesMockLoader(true);

      setTimeout(() => {
        //TODO replace mock to real data after api is ready
        const newMessage: AgentMessage = {
          by_image: 'https://randomuser.me/api/portraits',
          by_name: 'John Doe',
          by_theme: { backgroundColour: '#C9F3DE', textColour: '#31D482' },
          can_manage: agentMessages.length % 2 === 0 ? true : false,
          created_at: '2024-09-30T11:12:14.000000Z',
          id: Math.random(),
          message: message,
        };

        setAgentMessages((_prev) => [..._prev, newMessage]);
        setMessage('');
        setAgentMessagesMockLoader(false);
        resolve();
      }, 1000);
    });
  };

  const agentMessagesContent = useMemo(() => {
    return (
      <div className={cx(classes.list, classes.contentHeight)}>
        {agentMessages.map((message) => {
          if (message.can_manage) {
            return (
              <SentMessageItem
                key={message.id}
                message={message}
                onDelete={(id) => setAgentMessages((prev) => prev.filter((el) => el.id !== id))}
              />
            );
          }

          return <ReceivedMessageItem key={message.id} message={message} />;
        })}
      </div>
    );
  }, [agentMessages, classes.contentHeight, classes.list]);

  return (
    <div className={classes.root}>
      {agentMessagesContent}
      <div className={classes.action}>
        <TextFieldWithActions
          actions={
            <IconButton
              colour={ButtonColour.primary}
              disabled={!message}
              loading={agentMessagesMockLoader}
              icon={<SendIcon />}
              size={ButtonSize.iconOnly}
              onClick={handleAddAgentMessage}
            />
          }
          classes={{ textFieldRoot: classes.actionTextField, inputContainer: classes.inputContainer }}
          fieldProps={{
            multiline: true,
            minRows: 3,
            maxRows: 3,
            placeholder: t('xtextx_ellipsis', {
              text: t('write_a_message_here'),
            }),
            value: message,
            onChange: (e) => setMessage(e.target.value),
          }}
        />
      </div>
    </div>
  );
};

export const ReportingThreadsMessages = withStyles(styles)(ReportingThreadsMessagesComponent);
