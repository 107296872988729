import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

export const styles = (_: Theme) =>
  createStyles({
    root: {},
    spacesOverlayHeader: {
      background: colors.kato.navy[950],
      borderBottom: 'none',
    },
    spacesOverlayHeaderLogo: {
      height: 34,
      width: 29,
    },
    spacesOverlayHeaderTitle: {
      color: colors.white.lightest,
      fontSize: 18,
      fontWeight: 500,
      lineHeight: '28px',
    },
  });
