import { SvgIcon } from '@material-ui/core';
import { FC } from 'react';

import { withStyles, WithStyles } from '@core/theme/utils/with-styles';

import { styles } from './Xlsx.styles';

type XlsxIconProps = WithStyles<typeof styles>;

const XlsxIconComponent: FC<XlsxIconProps> = ({ classes }) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      classes={{ root: classes.root }}
    >
      <g clipPath="url(#clip0_4897_74459)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 2.09524V21.9048C6 23.0619 6.92341 24 8.0625 24H21.9375C23.0766 24 24 23.0619 24 21.9048V5.51345C24 4.95376 23.7796 4.41734 23.3879 4.02377L19.9859 0.60556C19.5997 0.217598 19.0786 0 18.5355 0H8.0625C6.92341 0 6 0.93807 6 2.09524ZM7.3125 21.9048V2.09524C7.3125 1.67445 7.64829 1.33333 8.0625 1.33333H18.2812V5.90476C18.2812 6.69374 18.9108 7.33333 19.6875 7.33333H22.6875V21.9048C22.6875 22.3256 22.3517 22.6667 21.9375 22.6667H8.0625C7.64829 22.6667 7.3125 22.3256 7.3125 21.9048ZM22.6875 6V5.51345C22.6875 5.30993 22.6073 5.11487 22.4649 4.97175L19.5938 2.08691V5.90476C19.5938 5.95736 19.6357 6 19.6875 6H22.6875Z"
          fill="#D1D6DF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.375 9.84375C15.375 9.48131 15.6688 9.1875 16.0312 9.1875H20.3438C20.7062 9.1875 21 9.48131 21 9.84375C21 10.2062 20.7062 10.5 20.3438 10.5H16.0312C15.6688 10.5 15.375 10.2062 15.375 9.84375Z"
          fill="#69C997"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.375 12.8438C15.375 12.4813 15.6688 12.1875 16.0312 12.1875H20.3438C20.7062 12.1875 21 12.4813 21 12.8438C21 13.2062 20.7062 13.5 20.3438 13.5H16.0312C15.6688 13.5 15.375 13.2062 15.375 12.8438Z"
          fill="#54AD7D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.375 15.8438C15.375 15.4813 15.6688 15.1875 16.0312 15.1875H20.3438C20.7062 15.1875 21 15.4813 21 15.8438C21 16.2062 20.7062 16.5 20.3438 16.5H16.0312C15.6688 16.5 15.375 16.2062 15.375 15.8438Z"
          fill="#2B593D"
        />
        <path
          d="M0 6.9375C0 6.31618 0.50368 5.8125 1.125 5.8125H12.375C12.9963 5.8125 13.5 6.31618 13.5 6.9375V18.1875C13.5 18.8088 12.9963 19.3125 12.375 19.3125H1.125C0.503679 19.3125 0 18.8088 0 18.1875V6.9375Z"
          fill="#54AD7D"
        />
        <path
          d="M3.75 16.3286L5.85718 12.4065L3.94482 8.8125H5.40088L6.63647 11.2273L7.84644 8.8125H9.28711L7.37476 12.4629L9.48193 16.3286H7.97974L6.61084 13.7292L5.24194 16.3286H3.75Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4897_74459">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export const XlsxIcon = withStyles(styles)(XlsxIconComponent);
