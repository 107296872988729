import { t } from '@core/i18n';
import { PartialDisposalInterestIndexItem } from '@shared/apis/disposals';
import { IDropdownItem } from '@shared/components/dropdown-new';
import { Option } from '@shared/components/select-new/Select';
import {
  InterestScheduleCustomAction,
  InterestScheduleStatuses,
  ScheduleConfig,
  ScheduleStatuses,
} from '@shared/types/common/match';

export const getVisibleInterestScheduleStatusOptions = (
  scheduleConfig: ScheduleConfig,
  currentStatus?: InterestScheduleStatuses
): Option<ScheduleStatuses>[] => {
  // console.log('getVisibleInterestScheduleStatusOptions. TODO - use currentStatus: ', currentStatus);

  return scheduleConfig.scheduleGroups.reduce((result, group) => {
    const groupStatuses = scheduleConfig.groupConfig[group].statuses;

    if (groupStatuses) {
      groupStatuses.forEach((status) => {
        const statusConfig = scheduleConfig.statusConfig[status];

        // We have a list of status options which include mixed statuses
        // We need to only show the option in this list if the current status is one of the mixed statuses
        if (currentStatus && statusConfig.show_if_status && status !== currentStatus) {
          return;
        }

        // Otherwise add the status
        result.push({ id: status, label: statusConfig.label });
      });
    }

    return result;
  }, [] as Option<ScheduleStatuses>[]);
};

interface DisposalInterestStatusOptionsConfig {
  discountedPrefix?: boolean;
  interest?: PartialDisposalInterestIndexItem;
  withActions?: boolean;
  withDividers?: boolean;
}

export const getInterestScheduleStatusOptions = (
  scheduleConfig: ScheduleConfig,
  suppliedConfig: DisposalInterestStatusOptionsConfig = {}
) => {
  const config: DisposalInterestStatusOptionsConfig = {
    discountedPrefix: false,
    withActions: true,
    withDividers: true,
    ...suppliedConfig,
  };

  const isDiscounted = !!config?.interest?.interest_schedule_discounted_reason;
  const isHiddenFromSchedule = !!config?.interest?.interest_schedule_hidden;

  const statusOptions: IDropdownItem<InterestScheduleStatuses | InterestScheduleCustomAction | ''>[] =
    getVisibleInterestScheduleStatusOptions(scheduleConfig, config?.interest?.interest_schedule_status).map(
      (option) => {
        let displayLabel = option.label;
        if (config.discountedPrefix && isDiscounted) {
          displayLabel = t('hyphen_xstatusx', { status: option.label });
        }

        return {
          active:
            !!config?.interest?.interest_schedule_status && option.id === config?.interest?.interest_schedule_status,
          text: option.label,
          value: option.id as InterestScheduleStatuses,
          displayLabel: displayLabel,
        };
      }
    );

  // Discount
  if (config.withActions) {
    if (!isDiscounted) {
      statusOptions.push({ divider: true, text: '' });
      statusOptions.push({
        value: InterestScheduleCustomAction.Discount,
        text: t('discount'),
      });
    } else if (isDiscounted && !isHiddenFromSchedule) {
      statusOptions.push({ divider: true, text: '' });
      statusOptions.push({
        value: InterestScheduleCustomAction.ClearDiscountedReason,
        text: t('clear_discounted_reason'),
      });
    }
  }

  // Show On Schedule / Hide From Schedule
  if (config.withActions) {
    statusOptions.push({ divider: true, text: '' });

    if (isHiddenFromSchedule) {
      statusOptions.push({
        value: InterestScheduleCustomAction.ShowOnSchedule,
        text: t('un_hyphen_archive_to_schedule'),
      });
    } else {
      statusOptions.push({
        value: InterestScheduleCustomAction.HideFromSchedule,
        text: t('archive_from_schedule'),
      });
    }
  }

  return statusOptions.filter((option) => {
    if (!config.withDividers && option.divider) {
      return false;
    }

    return true;
  });
};
