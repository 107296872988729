import { SvgIcon } from '@material-ui/core';
import { FC } from 'react';

import { withStyles, WithStyles } from '@core/theme/utils/with-styles';

import { styles } from './ChartBreakoutSquare.styles';

export interface ChartBreakoutSquareIconProps extends WithStyles<typeof styles> {
  color?: string;
}

const ChartBreakoutSquareIconComponent: FC<ChartBreakoutSquareIconProps> = ({ classes, color = '7E8899' }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      classes={{ root: classes.root }}
    >
      <path
        d="M9.16667 2.50008H6.5C5.09987 2.50008 4.3998 2.50008 3.86502 2.77257C3.39462 3.01225 3.01217 3.3947 2.77248 3.86511C2.5 4.39988 2.5 5.09995 2.5 6.50008V13.5001C2.5 14.9002 2.5 15.6003 2.77248 16.1351C3.01217 16.6055 3.39462 16.9879 3.86502 17.2276C4.3998 17.5001 5.09987 17.5001 6.5 17.5001H13.5C14.9001 17.5001 15.6002 17.5001 16.135 17.2276C16.6054 16.9879 16.9878 16.6055 17.2275 16.1351C17.5 15.6003 17.5 14.9002 17.5 13.5001V10.8334M10 6.66675H13.3333V10.0001M12.9167 2.91675V1.66675M16.1995 3.80063L17.0833 2.91675M17.0919 7.08341H18.3419M2.5 11.1227C3.04328 11.2066 3.59989 11.2501 4.16667 11.2501C7.82197 11.2501 11.0544 9.43974 13.0164 6.66675"
        stroke={color}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export const ChartBreakoutSquareIcon = withStyles(styles)(ChartBreakoutSquareIconComponent);
