import { createStyles, Theme } from '@material-ui/core/styles';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {},
    iframe: {
      display: 'block',
      height: '100vh',
      position: 'relative',
      width: '100%',
    },
  });
