import { createStyles } from '@material-ui/core/styles';

export const styles = () =>
  createStyles({
    root: {},

    list: {},

    item: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    disabledItem: {
      opacity: 0.5,
    },
  });
