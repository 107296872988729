import { FC, useState } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Dialog } from '@shared/components/dialog';
import { SingleSelect } from '@shared/components/select-new/single-select';
import { TextField } from '@shared/components/text-field';
import { useCoreOptions } from '@shared/contexts/OptionsContext';
import { t } from '@shared/i18n';
import { InfoIcon } from '@shared/icons/info';
import { ButtonSize } from '@shared/types/common/button';
import { DiscountedReason } from '@shared/types/common/match';

import { styles } from './InterestScheduleBulkArchiveModal.styles';

export type InterestScheduleBulkArchiveModalProps = WithStyles<typeof styles> & {
  loading?: boolean;
  onClose: () => void;
  onSave: (archiveReason: Id, comment: string) => void;
};

const InterestScheduleBulkArchiveModalComponent: FC<InterestScheduleBulkArchiveModalProps> = ({
  classes,
  loading,
  onClose,
  onSave,
}) => {
  const { discountReasonOptions } = useCoreOptions();
  const [archiveReason, setArchiveReason] = useState<Id>(DiscountedReason.Unknown);
  const [comment, setComment] = useState<string>('');

  const handleSubmit = async () => {
    onSave(archiveReason, comment);
  };
  return (
    <Dialog
      buttonProps={{
        cancel: {
          disabled: loading,
          onClick: onClose,
        },
        confirm: {
          disabled: !archiveReason,
          loading: loading,
          text: t('archive'),
          onClick: handleSubmit,
        },
      }}
      classes={{ actionButtons: classes.actionButtons }}
      headingProps={{
        heading: t('archive_from_schedule_1'),
        withCloseButton: true,
      }}
      open
      withTransition
      onClose={onClose}
    >
      <SingleSelect
        label={t('archive_reason')}
        options={discountReasonOptions}
        placeholder={t('please_select')}
        required
        size={ButtonSize.medium}
        value={archiveReason}
        onChange={(value) => setArchiveReason(value)}
      />
      <TextField
        fullWidth
        label={t('comment')}
        minRows={3}
        multiline
        placeholder={t('xtextx_ellipsis', { text: t('add_a_comment_you_can_add_others_with_at_to_notify_them') })}
        value={comment}
        onChange={(e) => setComment(e.target.value)}
      />
      <div className={classes.information}>
        <InfoIcon classes={{ root: classes.informationIcon }} color="#515D70" />
        <p className={classes.informationText}>
          {t(
            'archiving_this_match_will_remove_it_from_your_schedule_and_omit_the_record_from_the_matches_sharded_with_your_collaborators'
          )}
        </p>
      </div>
    </Dialog>
  );
};

export const InterestScheduleBulkArchiveModal = withStyles(styles)(InterestScheduleBulkArchiveModalComponent);
