import { createStyles } from '@material-ui/core/styles';

export const styles = () =>
  createStyles({
    root: {},
    startIcon: {
      marginRight: 0,
    },
    buttonLoading: {
      cursor: 'wait',
      pointerEvents: 'none',
    },
  });
