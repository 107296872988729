import { TooltipProps } from '@material-ui/core';
import { InfoOutlined as InfoOutlinedIcon } from '@material-ui/icons';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Tooltip } from '@shared/components/tooltip';
import { normalizedHTMLText } from '@shared/utils/text';

import { styles } from './TooltipIcon.styles';

export interface TooltipIconProps extends WithStyles<typeof styles> {
  tooltip: string;
  placement?: TooltipProps['placement'];
  icon?: React.ReactElement;
}

const TooltipIconComponent: React.FC<TooltipIconProps> = ({ classes, tooltip, icon, placement = 'top' }) => {
  return (
    <Tooltip
      arrow={true}
      placement={placement}
      title={<span dangerouslySetInnerHTML={{ __html: normalizedHTMLText(tooltip) || '' }} />}
      classes={{
        root: classes.root,
        tooltip: classes.tooltip,
        container: classes.tooltipContainer,
      }}
    >
      {icon || <InfoOutlinedIcon classes={{ root: classes.icon }} />}
    </Tooltip>
  );
};

export const TooltipIcon = withStyles(styles)(TooltipIconComponent);
