import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      flexDirection: 'column',
    },
    autocompleteOptionName: {
      color: '#344054', //TODO replace after creating new palette
      fontSize: 14,
      fontWeight: 500,
    },
    autocompleteOptionEmail: {
      color: '#7E8899', //TODO replace after creating new palette
      fontSize: 14,
      fontWeight: 500,
    },
    closeIconContainer: {
      borderRadius: 6,
      cursor: 'pointer',
      padding: spacing(2),
      '&:hover': {
        backgroundColor: '#F2F4F7', // TODO: Update this when new palette is created
      },
    },
    contactContentWrapper: {
      cursor: 'pointer',
      flex: '1 1 auto',
      overflow: 'hidden',
    },
    contactIconContainer: {
      backgroundColor: '#EAECF0', //TODO change after creating new palette
      borderRadius: '50%',
      flex: '0 0 auto',
      height: 40,
      marginRight: spacing(3),
      width: 40,
    },
    contactTextWrapper: {
      overflow: 'hidden',
    },
    label: {},
    searchIcon: {
      fontSize: 13,
      marginRight: spacing(2.5),
    },
    searchInput: {
      gap: spacing(2),
    },
    searchProperty: {
      marginBottom: spacing(4),
      maxWidth: '100%',
    },
    autocompleteListItem: {},
    searchPropertyListMessage: {
      color: colors.black.darker,
      fontSize: 14,
    },
    searchListItem: {
      margin: spacing(0.5, 1),
      padding: 0,

      '&:hover': {
        backgroundColor: colors.white.lightest,
      },
    },
    draggableList: {
      backgroundColor: '#EAECF0', // TODO: Update this when new palette is created
      borderRadius: 8,
      padding: spacing(2),
      width: '100%',

      '& > *': {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: spacing(2),
      },
    },
    draggableListItem: {},
    dragIcon: {
      fontSize: 14,
      marginRight: spacing(3),
    },
    dragOption: {
      alignItems: 'center',
      backgroundColor: colors.white.lightest,
      border: '1px solid #D1D6DF', //TODO replace after creating new palette
      borderRadius: 6,
      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.07)',
      display: 'flex',
      padding: spacing(2.5, 3),
      width: '100%',

      '&:hover': {
        cursor: 'grab',
        backgroundColor: colors.white.lightest,
        border: '1px solid #9EA6B3',
        boxShadow: '0px 4px 8px -2px rgba(16, 24, 40, 0.05), 0px 2px 4px -1px rgba(16, 24, 40, 0.10)',
      },
    },
    optionTitle: {
      color: colors.black.darker,
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '20px',
    },
    optionSubtitle: {
      color: '#7E8899', //TODO change after creating new palette
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '20px',
    },
    draggableItem: {
      width: '100%',
    },
  });
