import { SvgIcon, useTheme } from '@material-ui/core';
import { FC } from 'react';

import { withStyles, WithStyles } from '@core/theme/utils/with-styles';

import { styles } from './KatoLogoShort.styles';

type KatoLogoShortIconProps = WithStyles<typeof styles>;

const KatoLogoShortIconComponent: FC<KatoLogoShortIconProps> = ({ classes }) => {
  const theme = useTheme();

  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="36"
      viewBox="0 0 30 36"
      fill="none"
      classes={{ root: classes.root }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.9747 18.0009L29.1401 0.989502L12.2214 10.8655V1.96619H0V18.001L12.2213 10.8657V25.1344L29.1401 35.0104L22.9747 18.0009ZM12.2214 34.034H0V18.001L12.2214 25.1346V34.034Z"
        fill={theme.palette.primarySpecial.main}
      />
    </SvgIcon>
  );
};

export const KatoLogoShortIcon = withStyles(styles)(KatoLogoShortIconComponent);
