import { HighlightOffRounded as RoundedIcon } from '@material-ui/icons';
import cx from 'classnames';
import { debounce } from 'lodash';
import {
  ChangeEvent,
  CSSProperties,
  forwardRef,
  ForwardRefRenderFunction,
  KeyboardEventHandler,
  MouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import { t } from '@core/i18n';
import { withStyles, WithStyles } from '@core/theme/utils/with-styles';
import { Ellipsis } from '@shared/components/ellipsis';
import { Flex } from '@shared/components/flex';
import { Loading } from '@shared/components/loading';
import { TextField, TextFieldProps } from '@shared/components/text-field';

import { defaultAbsoluteValue, styles } from './Autocomplete.styles';

export interface AutocompleteProps extends WithStyles<typeof styles> {
  action?: React.ReactNode;
  actionRow?: JSX.Element;
  clearAfterSelect?: boolean;
  disabled?: boolean;
  dropUp?: boolean;
  error?: boolean;
  inputProps?: Omit<TextFieldProps, 'classes'>;
  label?: ReactNode | string;
  list: any[];
  loading: boolean;
  loadingText?: string;
  minSearchLength?: number;
  priorityList?: any[];
  searchValue: string;
  withClearIcon?: boolean;
  withListUpdate?: boolean;
  withSearchIcon?: boolean;
  onClearList: (clickToClearIcon?: boolean) => void;
  onGetList: (search: string) => void;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  onSearchValueChange: (search: string) => void;
  onSelect: (item: any) => void;
  renderOption: (option: any) => JSX.Element | string;
}

export interface AutocompleteRef extends HTMLInputElement {
  clear: () => void;
}

const AutocompleteComponent: ForwardRefRenderFunction<AutocompleteRef, AutocompleteProps> = (
  {
    action,
    actionRow,
    classes,
    clearAfterSelect,
    disabled = false,
    dropUp = false,
    error = false,
    inputProps,
    label,
    list,
    loading,
    loadingText,
    minSearchLength = 1,
    priorityList,
    searchValue,
    withClearIcon = false,
    withListUpdate = false,
    withSearchIcon = false,
    onClearList,
    onGetList,
    onKeyDown,
    onSearchValueChange,
    onSelect,
    renderOption,
  },
  ref
) => {
  const textFieldRef = useRef<HTMLInputElement>(null);
  const searchContainerRef = useRef<HTMLDivElement>(null);

  const [prevList, setPrevList] = useState<any[]>(list);
  const [typing, setTyping] = useState(false);
  const [open, setOpen] = useState(false);
  const [searchWasChanged, setSearchWasChanged] = useState<boolean>(false);

  useEffect(() => {
    if (withListUpdate && list !== prevList) {
      setPrevList(list);
      setOpen(true);
    }
  }, [list]);

  const handleSearchChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    onSearchValueChange(value);

    if (value === '') {
      debouncedSearch('');
      return;
    }

    const trimmedValue = value.trim();

    if (trimmedValue.length >= minSearchLength) {
      setSearchWasChanged(true);
      setTyping(true);

      debouncedSearch(trimmedValue);
    }
  };

  const debouncedSearch = useCallback(
    debounce((search) => {
      if (search === '') {
        onClearList();
        return;
      }

      onGetList(search);
      setTyping(false);
    }, 500),
    []
  );

  const handleTextFieldBlur = () => {
    setTimeout(() => {
      setOpen(false);
    }, 200);
  };

  const handleClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      onSelect(e);

      setSearchWasChanged(false);
      setOpen(false);

      if (clearAfterSelect) {
        onClearList(true);
      }
    },
    [clearAfterSelect, onClearList, onSelect]
  );

  const handleClearSearch = () => {
    onSearchValueChange('');

    onClearList(true);
  };

  useImperativeHandle<HTMLElement, AutocompleteRef>(ref, () => ({
    ...(textFieldRef.current as HTMLInputElement),
    focus: () => {
      textFieldRef.current?.focus();
      setOpen(true);
    },
    clear: () => handleClearSearch(),
  }));

  const listAbsoluteStyles: CSSProperties = useMemo(() => {
    const value = searchContainerRef.current?.clientHeight || defaultAbsoluteValue;

    if (dropUp) {
      return {
        bottom: value,
      };
    }

    return {
      top: value,
    };
  }, [dropUp, searchContainerRef.current?.clientHeight]);

  const listOptionsContent = useMemo(() => {
    if (!list.length) {
      return null;
    }

    return list.map((item, key) => {
      return (
        <div onClick={() => handleClick(item)} className={classes.listItem} key={key}>
          {renderOption(item)}
        </div>
      );
    });
  }, [classes.listItem, handleClick, list, listAbsoluteStyles, renderOption]);

  const priorityListOptionsContent = useMemo(() => {
    if (!priorityList?.length) {
      return null;
    }

    return (priorityList || []).map((item, key) => {
      return (
        <div onClick={() => handleClick(item)} className={classes.listItem} key={key}>
          {renderOption(item)}
        </div>
      );
    });
  }, [classes.listItem, handleClick, priorityList, renderOption]);

  const autocompleteContent = useMemo(() => {
    if (searchValue && (typing || loading) && !withClearIcon) {
      return (
        <div className={cx(classes.loading, { [classes.listAbove]: dropUp })} style={listAbsoluteStyles}>
          {loadingText || t('loading')}...
        </div>
      );
    }

    if (
      open &&
      searchWasChanged &&
      searchValue &&
      !typing &&
      !loading &&
      !listOptionsContent &&
      searchValue.length >= minSearchLength
    ) {
      return (
        <div className={cx(classes.notFoundResults, { [classes.listAbove]: dropUp })} style={listAbsoluteStyles}>
          {actionRow && <div className={classes.actionRow}>{actionRow}</div>}
          <Ellipsis
            classes={{ root: classes.notFoundText }}
            text={t('no_results_found_for_quote_xsearchx_quote', { search: searchValue })}
          />
        </div>
      );
    }

    if (open && listOptionsContent) {
      return (
        <Flex
          autoWidth={false}
          direction="column"
          wrap="nowrap"
          className={cx(classes.list, { [classes.listAbove]: dropUp })}
          style={listAbsoluteStyles}
        >
          {actionRow && <div className={classes.actionRow}>{actionRow}</div>}
          <div className={classes.optionsList}>{listOptionsContent}</div>
        </Flex>
      );
    }

    if (priorityListOptionsContent) {
      return (
        <Flex direction="column" wrap="nowrap" className={classes.list}>
          <div className={classes.optionsList}>{priorityListOptionsContent}</div>
        </Flex>
      );
    }

    return null;
  }, [
    classes,
    actionRow,
    handleClick,
    listOptionsContent,
    listAbsoluteStyles,
    loading,
    open,
    priorityListOptionsContent,
    renderOption,
    searchWasChanged,
    typing,
    withClearIcon,
  ]);

  const endAdornment = useMemo(() => {
    if (loading && open && withClearIcon) {
      return <Loading size={20} />;
    }

    if (searchValue && withClearIcon) {
      return <RoundedIcon onClick={handleClearSearch} classes={{ root: classes.clearIcon }} />;
    }

    return null;
  }, [loading, searchValue, open, onClearList, withClearIcon, handleClearSearch]);

  return (
    <div className={classes.root}>
      {/* TODO: Use FormLabel here */}
      <Flex autoWidth={false} justifyContent="space-between">
        {label && <span className={classes.label}>{label}</span>}
        {action}
      </Flex>
      <div className={classes.search} ref={searchContainerRef}>
        <TextField
          ref={textFieldRef}
          disabled={disabled}
          error={error}
          {...inputProps} // TODO: Remove disabled / error props from Autocomplete, in favour of inputProps
          fullWidth
          InputProps={{
            ...inputProps?.InputProps,
            endAdornment: endAdornment,
          }}
          withSearchIcon={withSearchIcon}
          value={searchValue}
          onChange={handleSearchChange}
          onBlur={handleTextFieldBlur}
          onFocus={() => setOpen(true)}
          onKeyDown={onKeyDown}
          classes={{
            root: classes.textField,
            input: classes.textFieldInput,
          }}
        />
        {autocompleteContent}
      </div>
    </div>
  );
};

export const Autocomplete = withStyles(styles)(forwardRef(AutocompleteComponent));
