import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

const messageActionHeight = 110;

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      overflow: 'auto',
      height: '100%',
    },
    list: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      gap: spacing(6),
    },
    contentHeight: {
      height: `calc(100% - ${messageActionHeight + 2}px)`,
      padding: spacing(4),
    },
    noDataContent: {
      height: `calc(100% - ${messageActionHeight + 2}px)`,
      paddingTop: spacing(25),
    },
    noData: {},
    noDataText: {
      fontWeight: 500,
    },
    action: {
      height: messageActionHeight,
      padding: spacing(4),
      width: '100%',
      paddingRight: '10px',
      overflow: 'hidden',
      position: 'sticky',
    },
    actionTextField: {},
    inputContainer: {
      backgroundColor: colors.white.lightest,
    },
  });
