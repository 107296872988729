import { Collapse } from '@material-ui/core';
import cx from 'classnames';
import { FC, useState } from 'react';

import { t } from '@core/i18n';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Button } from '@shared/components/button-new';
import { ColumnPicker } from '@shared/components/column-picker-new';
import { DatePicker, MaterialDate } from '@shared/components/date-picker';
import { DraggableList } from '@shared/components/draggable-list';
import { ColumnRadioType } from '@shared/components/drawer-content-components/export-card-content';
import { Flex } from '@shared/components/flex';
import { INTEREST_SCHEDULE_ORDER_OPTIONS } from '@shared/components/interest-schedule/reporting-interest-table';
import { Radio } from '@shared/components/radio';
import { RadioGroup, RadioGroupOption } from '@shared/components/radio-group';
import { SingleSelect } from '@shared/components/select-new/single-select';
import { Switch, SwitchSize, SwitchType } from '@shared/components/switch';
import { TableColumn } from '@shared/components/table-new';
import { Tooltip } from '@shared/components/tooltip';
import { CheckCircleIcon } from '@shared/icons/check-circle';
import { DocxIcon } from '@shared/icons/docx/Docx';
import { DragIcon } from '@shared/icons/drag';
import { InfoIcon } from '@shared/icons/info';
import { XlsxIcon } from '@shared/icons/xlsx';
import { ButtonColour, ButtonSize, SelectColour } from '@shared/types/common/button';
import { InputSize } from '@shared/types/common/input';
import { GroupdByFilter } from '@shared/utils/interest-schedule';

import { styles } from './DownloadScheduleTab.styles';

interface DownloadScheduleTabProps extends WithStyles<typeof styles> {
  initialState?: AdditionalOptionsSectionMockState; // TODO Pass down actual props and state, remove mock, it should be required
  columns: TableColumn[];
  downloaded: boolean;
  setOutputOptionSelected: (value: boolean) => void;
  selectedColumns?: string | null;
  selectedColumnRadio: number | string;
  setSelectedColumns: (newColumns: string) => void;
  setSelectedColumnRadio: (value: number | string) => void;
}

//TODO Replace with actual state forwarding create a non mock type
//State should be inherited but should mutate on it's own after
type AdditionalOptionsSectionMockState = {
  downloadExcel: boolean;
  downloadWord: boolean;
  groupBy: GroupdByFilter;
  highlightFrom: MaterialDate | null;
  highlightNewMatches: boolean;
  includeDiscountedMatches: boolean;
  includeHiddenMatches: boolean;
  orderBy: string;
};

enum DownloadType {
  Word = 'word',
  Excel = 'excel',
}

const GROUP_BY_OPTIONS = [
  { id: GroupdByFilter.Starred, label: t('starred') },
  { id: GroupdByFilter.Status, label: t('status') },
];

//TODO Remove this later on it's a mock state
const INITIAL_ADDITIONAL_OPTIONS_MOCK_STATE: AdditionalOptionsSectionMockState = {
  downloadExcel: false,
  downloadWord: false,
  groupBy: GroupdByFilter.Status,
  highlightFrom: null,
  highlightNewMatches: false,
  includeDiscountedMatches: false,
  includeHiddenMatches: false,
  orderBy: 'date_of_requirement-desc',
};

interface DraggableColumn {
  id: number;
  name: string;
  checked: boolean;
}

const DownloadScheduleTabComponent: FC<DownloadScheduleTabProps> = ({
  classes,
  initialState = INITIAL_ADDITIONAL_OPTIONS_MOCK_STATE,
  columns,
  downloaded,
  setOutputOptionSelected,
  selectedColumns,
  selectedColumnRadio,
  setSelectedColumnRadio,
  setSelectedColumns,
}) => {
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [additionalOptionsState, setAdditonalOptionsState] = useState<AdditionalOptionsSectionMockState>(initialState);
  // TODO BE query statusGroupsMap
  const [draggableColumns, setDraggableColumns] = useState<DraggableColumn[]>([
    {
      id: 1,
      name: 'Enquiries',
      checked: true,
    },
    {
      id: 2,
      name: 'Viewing',
      checked: true,
    },
    {
      id: 3,
      name: 'Negotiating',
      checked: true,
    },
    {
      id: 4,
      name: 'Under Offer',
      checked: true,
    },
    {
      id: 5,
      name: 'Signed',
      checked: true,
    },
    {
      id: 6,
      name: 'Idle',
      checked: true,
    },
    {
      id: 7,
      name: 'Request Sent',
      checked: false,
    },
  ]);

  const columnRadios: RadioGroupOption<number | string>[] = [
    {
      id: ColumnRadioType.Selected,
      label: t('same_as_the_page_below'),
    },
    {
      id: ColumnRadioType.All,
      label: t('selected_columns_1'),
      content: (
        <Button
          colour={ButtonColour.secondary}
          size={ButtonSize.small}
          text={t('edit')}
          onClick={() => setEditModalOpen(true)}
        />
      ),
    },
  ];

  // TODO Mutate query pre-download
  const saveColumns = (newColumns: string) => {
    setSelectedColumns(newColumns);
    setEditModalOpen(false);
  };

  const toggleChecked = (id: number) => {
    setDraggableColumns((prevColumns) =>
      prevColumns.map((column) => (column.id === id ? { ...column, checked: !column.checked } : column))
    );
  };

  const handleOptionToggle = (option: DownloadType) => {
    setAdditonalOptionsState((prevState) => {
      const newState = {
        ...prevState,
        downloadWord: option === DownloadType.Word ? !prevState.downloadWord : false,
        downloadExcel: option === DownloadType.Excel ? !prevState.downloadExcel : false,
      };
      return newState;
    });
    setOutputOptionSelected(
      option === DownloadType.Word ? !additionalOptionsState.downloadWord : !additionalOptionsState.downloadExcel
    );
  };

  if (downloaded) {
    return (
      <Flex classes={{ root: classes.container }} direction="column">
        <Flex className={classes.downloadSuccessContainer} direction="column">
          <Flex alignItems="center" autoWidth={false} justifyContent="center" className={classes.iconContainer}>
            <CheckCircleIcon color="#1D9C33" classes={{ root: classes.icon }} />
          </Flex>
          <span className={classes.iconHeading}>{t('download_complete')}</span>
          <span
            className={classes.iconText}
            //TODO Manual download link
            dangerouslySetInnerHTML={{
              __html: t(
                'your_export_has_been_successfully_generated_if_it_didnt_download_automatically_you_can_manually_download_here',
                {
                  start_link: '<a style="text-decoration: underline;">',
                  end_link: '</a>',
                }
              ),
            }}
          ></span>
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex classes={{ root: classes.container }} direction="column">
      <Flex direction="column">
        <h2 className={classes.heading}>{t('choose_your_columns_1')}</h2>
        <div className={classes.divider} />
        <RadioGroup
          classes={{
            root: classes.radio,
            formControlLabel: classes.formControlLabel,
            radioWrapper: classes.radioWrapper,
            radiosRow: classes.radiosRow,
          }}
          value={selectedColumnRadio}
          onChange={setSelectedColumnRadio}
          options={columnRadios}
          direction="row"
        />
        <ColumnPicker
          columns={columns}
          selectedColumns={selectedColumns}
          headingProps={{ heading: t('customise_table') }}
          open={editModalOpen}
          withFooter={false}
          onClose={() => setEditModalOpen(false)}
          onSave={(columns) => saveColumns(columns)}
        />
      </Flex>
      <Flex direction="column">
        <h2 className={classes.heading}>{t('requirement_order')}</h2>
        <div className={classes.divider} />
        <DraggableList
          classes={{ root: classes.draggableList }}
          items={draggableColumns}
          onDragEnd={(list: DraggableColumn[]) => setDraggableColumns(list)}
          key={draggableColumns.length}
          renderItem={(item: DraggableColumn) => {
            return (
              <Flex
                alignItems="center"
                autoWidth={false}
                classes={{ root: cx(classes.columnItem, classes.draggableItem) }}
                wrap="nowrap"
              >
                <DragIcon classes={{ root: classes.draggableItemIcon }} />
                <span className={classes.columnLabel}>{item.name}</span>
                <Switch
                  checked={item.checked}
                  color="primary"
                  size={SwitchSize.small}
                  type={SwitchType.round}
                  onChange={() => toggleChecked(item.id)}
                />
              </Flex>
            );
          }}
        />
      </Flex>
      <Flex direction="column">
        <h2 className={classes.heading}>{t('additional_options_1')}</h2>
        <div className={classes.divider} />
        <div className={classes.additionalOptionsContainer}>
          <Flex autoWidth={false} classes={{ root: classes.rowContainer }}>
            <Flex autoWidth={false} direction="column" classes={{ root: classes.labelContainer }}>
              <span className={classes.label}>{t('order_your_matches_by')}</span>
            </Flex>
            <Flex autoWidth={false} className={classes.fieldContainer} justifyContent="flex-end">
              <SingleSelect
                options={INTEREST_SCHEDULE_ORDER_OPTIONS}
                placeholder={t('select')}
                size={ButtonSize.small}
                colour={SelectColour.secondaryGhost}
                value={additionalOptionsState.orderBy}
                onChange={(value) => setAdditonalOptionsState({ ...additionalOptionsState, orderBy: value.toString() })}
              />
            </Flex>
          </Flex>
          <Flex autoWidth={false} classes={{ root: classes.rowContainer }}>
            <Flex autoWidth={false} direction="column" classes={{ root: classes.labelContainer }}>
              <span className={classes.label}>{t('group_your_matches_by')}</span>
            </Flex>
            <Flex autoWidth={false} className={classes.fieldContainer} justifyContent="flex-end">
              <SingleSelect
                options={GROUP_BY_OPTIONS}
                placeholder={t('select')}
                size={ButtonSize.small}
                colour={SelectColour.secondaryGhost}
                value={additionalOptionsState.groupBy}
                onChange={(value) =>
                  setAdditonalOptionsState({ ...additionalOptionsState, groupBy: value as GroupdByFilter })
                }
              />
            </Flex>
          </Flex>
          <Flex autoWidth={false} classes={{ root: classes.rowContainer }}>
            <Flex autoWidth={false} direction="column" classes={{ root: classes.labelContainer }}>
              <span className={classes.label}>{t('include_discounted_matches')}</span>
            </Flex>
            <Flex autoWidth={false} className={classes.fieldContainer} justifyContent="flex-end">
              <Switch
                checked={additionalOptionsState.includeDiscountedMatches}
                color="primary"
                size={SwitchSize.small}
                type={SwitchType.round}
                onChange={(_, checked) => {
                  setAdditonalOptionsState({ ...additionalOptionsState, includeDiscountedMatches: checked });
                }}
              />
            </Flex>
          </Flex>
          <Flex autoWidth={false} classes={{ root: classes.rowContainer }}>
            <Flex autoWidth={false} direction="column" classes={{ root: classes.labelContainer }}>
              <span className={classes.label}>{t('include_hidden_matches')}</span>
            </Flex>
            <Flex autoWidth={false} className={classes.fieldContainer} justifyContent="flex-end">
              <Switch
                checked={additionalOptionsState.includeHiddenMatches}
                color="primary"
                size={SwitchSize.small}
                type={SwitchType.round}
                onChange={(_, checked) => {
                  setAdditonalOptionsState({ ...additionalOptionsState, includeHiddenMatches: checked });
                }}
              />
            </Flex>
          </Flex>
          <Flex autoWidth={false} classes={{ root: classes.rowContainer }}>
            <Flex autoWidth={false} alignItems="center" classes={{ root: classes.labelContainer }}>
              <span className={classes.label}>{t('highlight_new_matches_question')}</span>
              <Tooltip
                placement="top"
                title={t(
                  'set_a_date_you_last_ran_the_report_and_well_highlight_the_new_requirements_within_the_report'
                )}
                classes={{ root: classes.infoIcon }}
              >
                <InfoIcon />
              </Tooltip>
            </Flex>
            <Flex autoWidth={false} className={classes.fieldContainer} justifyContent="flex-end">
              <Switch
                checked={additionalOptionsState.highlightNewMatches}
                color="primary"
                size={SwitchSize.small}
                type={SwitchType.round}
                onChange={(_, checked) => {
                  setAdditonalOptionsState({ ...additionalOptionsState, highlightNewMatches: checked });
                }}
              />
            </Flex>
          </Flex>
          <Collapse
            key="highlight-from"
            className={classes.collapse}
            mountOnEnter
            unmountOnExit
            in={additionalOptionsState.highlightNewMatches}
          >
            <Flex autoWidth={false} classes={{ root: classes.rowContainer }}>
              <Flex autoWidth={false} alignItems="center" classes={{ root: classes.labelContainer }}>
                <span className={classes.label}>{t('highlight_from')}</span>
                <Tooltip
                  classes={{ root: classes.infoIcon }}
                  placement="top"
                  title={t('well_highlight_the_new_requirements_within_the_report')}
                >
                  <InfoIcon />
                </Tooltip>
              </Flex>
              <Flex className={classes.fieldContainer} justifyContent="flex-end">
                <DatePicker
                  classes={{ picker: classes.datePicker }}
                  placeholder="DD/MM/YYYY"
                  size={InputSize.small}
                  value={additionalOptionsState.highlightFrom}
                  onChange={(value) => setAdditonalOptionsState({ ...additionalOptionsState, highlightFrom: value })}
                />
              </Flex>
            </Flex>
          </Collapse>
        </div>
      </Flex>
      <Flex className={classes.outputContainer} direction="column">
        <h2 className={classes.heading}>{t('choose_your_output')}</h2>
        <div className={classes.divider} />
        <Flex direction="row" wrap="nowrap" className={classes.buttonContainer}>
          <Button
            classes={{
              root: cx(classes.button, { [classes.buttonChecked]: additionalOptionsState.downloadWord }),
              buttonLabel: classes.buttonLabel,
            }}
            colour={ButtonColour.secondary}
            endIcon={
              <Radio
                classes={{
                  root: classes.buttonRoot,
                  radio: classes.buttonRadio,
                }}
                checked={additionalOptionsState.downloadWord}
                onClick={(e) => e.preventDefault()}
                color="primary"
                label=""
              />
            }
            startIcon={<DocxIcon classes={{ root: classes.officeIcon }} />}
            text={<span className={classes.buttonText}>{t('download_word')}</span>}
            onClick={() => handleOptionToggle(DownloadType.Word)}
          />
          <Button
            classes={{
              root: cx(classes.button, { [classes.buttonChecked]: additionalOptionsState.downloadExcel }),
              buttonLabel: classes.buttonLabel,
            }}
            colour={ButtonColour.secondary}
            endIcon={
              <Radio
                classes={{
                  root: classes.buttonRoot,
                  radio: classes.buttonRadio,
                }}
                checked={additionalOptionsState.downloadExcel}
                onClick={(e) => e.preventDefault()}
                color="primary"
                label=""
              />
            }
            startIcon={<XlsxIcon classes={{ root: classes.officeIcon }} />}
            text={<span className={classes.buttonText}>{t('download_excel')}</span>}
            onClick={() => handleOptionToggle(DownloadType.Excel)}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export const DownloadScheduleTab = withStyles(styles)(DownloadScheduleTabComponent);
