import { Contact } from '@shared/models/contact/contact';
import { IUser } from '@shared/models/IUser';
import { ListUserItem } from '@shared/models/user';
import { LookupCommonItem, LookupUserItem } from '@shared/types/common/common';

// Contact

export const listContactToLookupContact = (contact: Contact): LookupCommonItem => ({
  id: contact.id,
  name: `${contact.name}${contact.company ? ` (${contact.company.name})` : ''}`,
  subtext: contact.email || contact.mobile_number || contact.phone_number,
});

// User

export const configUserToLookupUser = (user: IUser): LookupUserItem => ({
  id: user.id,
  name: user.name,
  image: user.image.url || '',
  theme: user.theme,
  ...(user.organisation && { organisationName: user.organisation.name }),
});

export const listUserToLookupUser = (user: ListUserItem): LookupUserItem => ({
  id: user.id,
  name: user.name,
  image: user.image.url || '',
  theme: user.theme,
  ...(user.organisation && { organisationName: user.organisation.name }),
});
