import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      backgroundColor: colors.white.lightest,
      display: 'flex',
      flex: '0 0 auto',
      gap: spacing(4),
      overflowX: 'auto',
      overflowY: 'visible',
      padding: spacing(3),
      position: 'relative',
      zIndex: 1,
      borderTopLeftRadius: 12,
      borderTopRightRadius: 12,

      '& > *': {
        alignItems: 'center',
        display: 'flex',
        flex: '1 1 auto',
        justifyContent: 'flex-start',
        position: 'relative',

        '& > *:not(:first-child)': {
          marginLeft: spacing(2),
        },
      },
    },
    tabsLeft: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    tabsRight: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    activeTabRoot: {},
    tabRoot: {},
    tabText: {},
    tabCount: {},
  });
