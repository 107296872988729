import { t } from 'i18next';
import { FC, useMemo, useState } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { EmptyStateContent } from '@shared/components/empty-state-content';
import { SearchField } from '@shared/components/search-field';
import { Tab, TabConfigItem } from '@shared/components/tab';
import { UrlViewState, useUrlState } from '@shared/hooks/viewState';
import { ArchiveIcon } from '@shared/icons/archive';
import { MessageIcon } from '@shared/icons/message';
import { InterestScheduleData } from '@shared/types/common/match';
import { getQueries, getQueryValueString } from '@shared/utils/common';

import { styles } from './ReportingInboundSpace.styles';
import { ReportingThreadsMessages } from '../reporting-threads-messages';

enum InboundTab {
  UnactionedEnquiries = 'unactioned_enquiries',
  Archive = 'archived',
}

// --- URL State ---

export interface InboundUrlState extends UrlViewState {
  inbound_search: string;
  inbound_tab: InboundTab;
}

export const defaultInboundUrlState: InboundUrlState = {
  inbound_search: '',
  inbound_tab: InboundTab.UnactionedEnquiries,
};

export const getInboundUrlState = (queries: Query): InboundUrlState => {
  return {
    ...defaultInboundUrlState,
    inbound_search: getQueryValueString('inbound_search', queries) || defaultInboundUrlState.inbound_search,
    inbound_tab: (getQueryValueString('inbound_tab', queries) as InboundTab) || defaultInboundUrlState.inbound_tab,
  };
};

// --- Page State ---

export const getInitialPageState = () => ({
  // columns: false,
  // filters: false,
  // search_open: false,
});

export interface ReportingInboundSpaceProps extends WithStyles<typeof styles> {
  id: string;
  scheduleData: InterestScheduleData;
}

const unactionedThreads = [
  {
    id: 1,
    from: {
      name: 'Sam Simon',
      company: 'Lonic',
      email: 'simon@lonic.com',
      initials: 'SS',
      picture: 'simonspic@jpeg.com',
      theme: {
        backgroundColour: '#23456',
        textColour: '#FFFFFF',
      },
      user_id: 1,
      contact_id: null,
    },
    source: 'Society',
    archived: false,
    last_message_was_internal: false,
    last_message_was_by: null,
    message: 'Looking for 1,750 t-2,600 sqft soho / mayfair and would love it to be fantastic',
    date_time_unix: 2345678986,
    date_time_string: '13:13 on Thur 19 Jan',
  },
];

const archivedThreads: any[] = [];

const ReportingInboundSpaceComponent: FC<ReportingInboundSpaceProps> = ({ classes }) => {
  const { urlState, updateUrlState } = useUrlState<InboundUrlState>(() => getInboundUrlState(getQueries()));
  const [searchFieldValue, setSearchFieldValue] = useState<string>(urlState.inbound_search);
  const [selectedThread, setSelectedThread] = useState<number | null>(null);

  const tabsContent: TabConfigItem[] = useMemo(
    () => [
      {
        label: t('unactioned_enquiries'),
        value: InboundTab.UnactionedEnquiries,
        count: unactionedThreads.length,
      },
      {
        icon: <ArchiveIcon classes={{ root: classes.archiveIcon }} />,
        label: t('archived'),
        value: InboundTab.Archive,
        count: archivedThreads.length,
      },
    ],
    [classes.archiveIcon]
  );

  const threads = useMemo(() => {
    const currentThreads =
      urlState.inbound_tab === InboundTab.UnactionedEnquiries ? unactionedThreads : archivedThreads;
    if (!currentThreads.length) {
      return (
        <div className={classes.noDataContent}>
          <EmptyStateContent
            classes={{ root: classes.noData }}
            header={t('youre_all_up_to_date')}
            icon={<MessageIcon />}
            text={<span className={classes.noDataText}>{t('any_enquiries_will_automatically_drop_in_here')}</span>}
          />
        </div>
      );
    }
    return (
      <div>
        {currentThreads.map((thread) => (
          <div key={thread.id} onClick={() => setSelectedThread(thread.id)} className={classes.threadItem}>
            <div>{thread.from.name}</div>
            <div>{thread.message}</div>
            <div>{thread.date_time_string}</div>
          </div>
        ))}
      </div>
    );
  }, [urlState.inbound_tab, classes.noData, classes.noDataContent, classes.noDataText, classes.threadItem]);

  const message = useMemo(() => {
    if (selectedThread === null) {
      return null;
    }
    const currentThreads =
      urlState.inbound_tab === InboundTab.UnactionedEnquiries ? unactionedThreads : archivedThreads;
    const thread = currentThreads.find((t) => t.id === selectedThread);
    if (!thread) {
      return null;
    }
    return <ReportingThreadsMessages classes={{ list: classes.contentPaddingNoSubheading }} messages={[]} />;
  }, [selectedThread, urlState.inbound_tab, classes.contentPaddingNoSubheading]);

  return (
    <div className={classes.body}>
      <div className={classes.threadsPanel}>
        <div className={classes.searchFieldWrapper}>
          <SearchField
            placeholder={t('search_inbounds')}
            search={searchFieldValue}
            onChange={(search) => {
              setSearchFieldValue(search);
              updateUrlState({ inbound_search: search });
            }}
            fullWidth
            classes={{
              inputClass: classes.searchField,
            }}
          />
        </div>
        {!!tabsContent.length && (
          <div className={classes.tabs}>
            {tabsContent.map((tab) => (
              <Tab
                active={tab.value === urlState.inbound_tab}
                classes={{ root: classes.tab, count: classes.count, countActive: classes.countActive }}
                icon={tab.icon}
                key={tab.value}
                label={tab.label ?? ''}
                value={tab.value}
                count={tab.count}
                onClick={() => updateUrlState({ inbound_tab: tab.value })}
              />
            ))}
          </div>
        )}
        <div className={classes.threads}>{threads}</div>
      </div>
      <div className={classes.messagePanel}>{message}</div>
    </div>
  );
};

export const ReportingInboundSpace = withStyles(styles)(ReportingInboundSpaceComponent);
