import { FC, useMemo } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Checkbox } from '@shared/components/checkbox';
import { TableColumn, TableNew, TableNewProps } from '@shared/components/table-new/TableNew';

import { styles } from './BulkTable.styles';

export interface BulkTableProps extends Omit<TableNewProps, 'classes'>, WithStyles<typeof styles> {
  bulkSelected: number[];
  onChangeBulkSelectedRows: (bulkSelected: number[]) => void;
}

const BulkTableComponent: FC<BulkTableProps> = ({
  classes,
  columns,
  items,
  bulkSelected,
  onChangeBulkSelectedRows,
  renderBodyCell,
  ...tableProps
}: BulkTableProps) => {
  const { checkboxRootIconOnly, headerCheckboxCell, ...tableClasses } = classes;

  const selectedBulkActionsSet = useMemo(() => new Set(bulkSelected), [bulkSelected]);

  const allCheckboxesSelected = useMemo(
    () => items.length > 0 && selectedBulkActionsSet.size === items.length,
    [selectedBulkActionsSet.size, items.length]
  );

  const checkboxesColumns = useMemo((): TableColumn[] => {
    const checkboxColumn: TableColumn = {
      field: 'checkbox',
      headerCell: (
        <Checkbox
          classes={{ rootIconOnly: classes.checkboxRootIconOnly }}
          checked={allCheckboxesSelected}
          onChange={(checked) => onChangeBulkSelectedRows(checked ? items.map((item) => item.id) : [])}
        />
      ),
      headerCellClassName: headerCheckboxCell,
      name: '',
      width: 35,
    };

    return [checkboxColumn, ...columns];
  }, [allCheckboxesSelected, columns, items]);

  const checkboxesItems = useMemo(() => {
    return items.map((item) => ({
      ...item,
      selected: selectedBulkActionsSet.has(item.id),
    }));
  }, [items, selectedBulkActionsSet]);

  return (
    <TableNew
      {...tableProps}
      classes={tableClasses}
      columns={checkboxesColumns}
      items={checkboxesItems}
      stickyColumn={0}
      renderBodyCell={(field, item) => {
        if (field === 'checkbox') {
          return (
            <Checkbox
              classes={{ rootIconOnly: classes.checkboxRootIconOnly }}
              checked={selectedBulkActionsSet.has(item.id)}
              onChange={(checked, e) => {
                e.stopPropagation();

                if (checked) {
                  onChangeBulkSelectedRows([item.id, ...bulkSelected]);
                } else {
                  onChangeBulkSelectedRows(bulkSelected.filter((bulk) => bulk !== item.id));
                }
              }}
              onClick={(e) => e.stopPropagation()}
            />
          );
        }

        if (renderBodyCell) {
          return renderBodyCell(field, item);
        }

        return item[field] || '-';
      }}
    />
  );
};

export const BulkTable = withStyles(styles)(BulkTableComponent);
