import { FC, useEffect, useState } from 'react';

import { t } from '@core/i18n';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Checkbox } from '@shared/components/checkbox';
import { Dialog } from '@shared/components/dialog';
import { Flex } from '@shared/components/flex';
import { RadioGroup, RadioGroupOption, RadioGroupSerializerType } from '@shared/components/radio-group';
import { GroupdByFilter, InterestTypeFilter } from '@shared/utils/interest-schedule';

import { styles } from './InterestScheduleFilterModal.styles';

export interface InterestScheduleFilterParams {
  groupBy: GroupdByFilter;
  includeArchived: boolean;
  includeDiscounted: boolean;
  interestType: InterestTypeFilter;
}

const groupByRadios = [
  {
    label: t('starred'),
    id: GroupdByFilter.Starred,
  },
  {
    label: t('status'),
    id: GroupdByFilter.Status,
  },
];

export interface InterestScheduleFilterModalProps extends WithStyles<typeof styles> {
  interestTypeOptions: RadioGroupOption<InterestTypeFilter>[];
  open: boolean;
  params: InterestScheduleFilterParams;
  onApplyFilters: (data: InterestScheduleFilterParams) => void;
  onClose: () => void;
}

const InterestScheduleFilterModalComponent: FC<InterestScheduleFilterModalProps> = ({
  classes,
  interestTypeOptions,
  open,
  params,
  onApplyFilters,
  onClose,
}) => {
  const [paramsState, setParamsState] = useState<InterestScheduleFilterParams>(() => ({ ...params }));

  useEffect(() => {
    if (open) {
      setParamsState({ ...params });
    }
  }, [open]);

  return (
    <Dialog
      buttonProps={{
        cancel: {
          onClick: onClose,
        },
        confirm: {
          text: t('apply_filters_1'),
          onClick: () => onApplyFilters({ ...paramsState }),
        },
      }}
      classes={{ root: classes.root, body: classes.content }}
      headingProps={{
        heading: t('interest_schedule_filters'),
        withCloseButton: true,
      }}
      open={open}
      withTransition
      onClose={onClose}
    >
      <>
        <Flex classes={{ root: classes.checkboxes }} direction="column">
          <Checkbox
            checked={paramsState.includeDiscounted}
            classes={{ label: classes.actionLabel }}
            label={t('include_discounted')}
            onChange={(checked) => setParamsState((prev) => ({ ...prev, includeDiscounted: checked }))}
          />
          <Checkbox
            checked={paramsState.includeArchived}
            classes={{ label: classes.actionLabel }}
            label={t('include_archived')}
            onChange={(checked) => setParamsState((prev) => ({ ...prev, includeArchived: checked }))}
          />
        </Flex>
        <div className={classes.radiosContainer}>
          <span className={classes.radiosLabel}>{t('group_by')}</span>
          <Flex classes={{ root: classes.radios }}>
            <RadioGroup
              classes={{ formControlLabel: classes.actionLabel }}
              direction="column"
              options={groupByRadios}
              serializerType={RadioGroupSerializerType.string}
              value={paramsState.groupBy}
              onChange={(id) => setParamsState((prev) => ({ ...prev, groupBy: id as GroupdByFilter }))}
            />
          </Flex>
        </div>
        {!!interestTypeOptions.length && (
          <div className={classes.radiosContainer}>
            <span className={classes.radiosLabel}>{t('show_me')}</span>
            <Flex classes={{ root: classes.radios }}>
              <RadioGroup
                classes={{ formControlLabel: classes.actionLabel }}
                direction="column"
                options={interestTypeOptions}
                serializerType={RadioGroupSerializerType.string}
                value={paramsState.interestType}
                onChange={(id) => setParamsState((prev) => ({ ...prev, interestType: id as InterestTypeFilter }))}
              />
            </Flex>
          </div>
        )}
      </>
    </Dialog>
  );
};

export const InterestScheduleFilterModal = withStyles(styles)(InterestScheduleFilterModalComponent);
