import { createQueryKeys, inferQueryKeys } from '@lukemorales/query-key-factory';

import { AnyActivity } from '@shared/types/common/activity';
import { getQueriesAsSearch } from '@shared/utils/common';
import {
  $httpAgency,
  UpdateCacheCallback,
  updateQueryCache,
  useQuery,
  UseQueryOptions,
} from '@shared/utils/http-client';

import {
  DisposalInterestShowItem,
  DisposalInterestShowPayload,
  DisposalPresentScheduleIndexResponse,
  DisposalScheduleIndexPayload,
  DisposalScheduleIndexResponse,
  RefreshDisposalScheduleIndexPayload,
} from './disposalsApi.types';

export const disposalsQueryKeys = createQueryKeys('disposals', {
  // ---- Disposal, Interest - Queries ----

  interestInboxIndex: (payload: DisposalScheduleIndexPayload) => ({
    queryKey: [payload.id, payload.query],
    queryFn: async ({ signal }) =>
      (
        await $httpAgency.get<DisposalScheduleIndexResponse>(
          `disposals/${payload.id}/interest-schedule/inbox${getQueriesAsSearch(payload.query)}`,
          {
            signal,
          }
        )
      ).data,
  }),
  interestSentIndex: (payload: DisposalScheduleIndexPayload) => ({
    queryKey: [payload.id, payload.query],
    queryFn: async ({ signal }) =>
      (
        await $httpAgency.get<DisposalScheduleIndexResponse>(
          `disposals/${payload.id}/interest-schedule/sent-items${getQueriesAsSearch(payload.query)}`,
          {
            signal,
          }
        )
      ).data,
  }),
  interestActiveIndex: (payload: DisposalScheduleIndexPayload) => ({
    queryKey: [payload.id, payload.query],
    queryFn: async ({ signal }) =>
      (
        await $httpAgency.get<DisposalScheduleIndexResponse>(
          `disposals/${payload.id}/interest/schedule${getQueriesAsSearch(payload.query)}`,
          {
            signal,
          }
        )
      ).data,
  }),
  interestShow: (payload: DisposalInterestShowPayload) => ({
    queryKey: [payload],
    queryFn: async () =>
      (
        await $httpAgency.get<DisposalInterestShowItem>(
          `disposals/${payload.disposalId}/interest/${payload.interestId}`
        )
      ).data,
  }),
  interestActivities: (payload: DisposalInterestShowPayload) => ({
    queryKey: [payload],
    queryFn: async () =>
      (await $httpAgency.get(`disposals/${payload.disposalId}/interest/${payload.interestId}/activities`)).data,
  }),

  // ---- Disposal, Present - Queries ----

  presentInsightsIndex: (id: string) => ({
    queryKey: [id],
    queryFn: async () => (await $httpAgency.get(`/disposals/${id}/present/insights`)).data,
  }),
  presentScheduleIndex: (id: string) => ({
    queryKey: [id],
    queryFn: async () => (await $httpAgency.get(`/disposals/${id}/present/schedule`)).data,
  }),
  presentViewingsIndex: (id: string) => ({
    queryKey: [id],
    queryFn: async () => (await $httpAgency.get(`/disposals/${id}/present/viewings`)).data,
  }),
});

export type DisposalsQueryKeys = inferQueryKeys<typeof disposalsQueryKeys>;

// ---- Disposal, Interest - Queries ----

export const useDisposalInterestActiveIndexQuery = (
  payload: DisposalScheduleIndexPayload,
  options?: UseQueryOptions<DisposalScheduleIndexResponse>
) =>
  useQuery<DisposalsQueryKeys['interestActiveIndex']['queryKey'], DisposalScheduleIndexResponse>({
    ...disposalsQueryKeys.interestActiveIndex(payload),
    keepPreviousData: true,
    ...options,
  });

export const useDisposalInterestShowQuery = (
  payload: DisposalInterestShowPayload,
  options?: UseQueryOptions<DisposalInterestShowItem>
) =>
  useQuery<DisposalsQueryKeys['interestShow']['queryKey'], DisposalInterestShowItem>({
    ...disposalsQueryKeys.interestShow(payload),
    ...options,
    enabled: !!payload.disposalId && !!payload.interestId,
  });

export const useRefreshDisposalScheduleIndexQuery = (
  payload: RefreshDisposalScheduleIndexPayload,
  options?: UseQueryOptions<DisposalScheduleIndexResponse>
) =>
  useDisposalInterestActiveIndexQuery(
    {
      id: payload.disposalId,
      query: { ids: payload.matchIds.join(','), include_hidden: payload.include_hidden },
    },
    { keepPreviousData: false, enabled: !!payload.matchIds.length, ...options }
  );

export const useDisposalInterestInboxIndexQuery = (
  payload: DisposalScheduleIndexPayload,
  options?: UseQueryOptions<DisposalScheduleIndexResponse>
) =>
  useQuery<DisposalsQueryKeys['interestInboxIndex']['queryKey'], DisposalScheduleIndexResponse>({
    ...disposalsQueryKeys.interestInboxIndex(payload),
    keepPreviousData: true,
    ...options,
  });

export const useDisposalInterestSentIndexQuery = (
  payload: DisposalScheduleIndexPayload,
  options?: UseQueryOptions<DisposalScheduleIndexResponse>
) =>
  useQuery<DisposalsQueryKeys['interestSentIndex']['queryKey'], DisposalScheduleIndexResponse>({
    ...disposalsQueryKeys.interestSentIndex(payload),
    keepPreviousData: true,
    ...options,
  });

export const useDisposalInterestActivitiesQuery = (
  payload: DisposalInterestShowPayload,
  options?: UseQueryOptions<AnyActivity[]>
) =>
  useQuery<DisposalsQueryKeys['interestActivities']['queryKey'], AnyActivity[]>({
    ...disposalsQueryKeys.interestActivities(payload),
    enabled: !!(payload.disposalId && payload.interestId),
    ...options,
  });

// ---- Disposal, Present - Queries ----

 export const useDisposalPresentInsightsIndexQuery = (
  id: string,
  options?: UseQueryOptions<DisposalPresentScheduleIndexResponse>
) =>
  useQuery<DisposalsQueryKeys['presentInsightsIndex']['queryKey'], DisposalPresentScheduleIndexResponse>({
    ...disposalsQueryKeys.presentInsightsIndex(id),
    ...options,
  });

export const useDisposalPresentScheduleIndexQuery = (
  id: string,
  options?: UseQueryOptions<DisposalPresentScheduleIndexResponse>
) =>
  useQuery<DisposalsQueryKeys['presentScheduleIndex']['queryKey'], DisposalPresentScheduleIndexResponse>({
    ...disposalsQueryKeys.presentScheduleIndex(id),
    ...options,
  });

export const useDisposalPresentViewingsIndexQuery = (
  id: string,
  options?: UseQueryOptions<DisposalPresentScheduleIndexResponse>
) =>
  useQuery<DisposalsQueryKeys['presentViewingsIndex']['queryKey'], DisposalPresentScheduleIndexResponse>({
    ...disposalsQueryKeys.presentViewingsIndex(id),
    ...options,
  });

// ---- Utils ----

export const updateDisposalMatchActivitiesShowCache = (
  payload: DisposalInterestShowPayload,
  callback: UpdateCacheCallback<AnyActivity[]>
) => {
  updateQueryCache<AnyActivity[]>(disposalsQueryKeys.interestActivities(payload).queryKey, callback);
};
