import { isEqual } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { getQueries, setQueries } from '@shared/utils/common';
import { convertStringifiedQueryParamsToObject } from '@shared/utils/route';

export type PageViewState<T extends Item<unknown> = Item<unknown>> = T;

export const usePageState = <TState extends PageViewState>(initialState: TState | (() => TState)) => {
  const [state, setState] = useState<TState>(initialState);

  return useMemo(
    () => ({
      pageState: state,

      setPageState: (pageState: TState) => setState(pageState),

      updatePageState: (pageState: Partial<TState>) => setState((prev) => ({ ...prev, ...pageState })),
    }),
    [state]
  );
};

export type UrlViewState<T extends Item<string | boolean> = Item<string | boolean>> = T;

export const useUrlState = <TState extends UrlViewState>(initialState: TState | (() => TState)) => {
  const [state, setState] = useState<TState>(initialState);
  const history = useHistory();

  useEffect(() => {
    const newQueries = { ...getQueries() };
    // console.log('useUrlState. queries before: ', { ...newQueries });

    for (const [key, urlValue] of Object.entries(state)) {
      // The url key value (i.e. urlValue) can `false`, so we need to also convert false to a string along with other valid url state query param values
      if (urlValue || typeof urlValue === 'boolean') {
        newQueries[key] = urlValue.toString();
      } else {
        delete newQueries[key];
      }
    }

    // console.log('useUrlState. set queries: ', { ...newQueries });

    const currentQueryParams = convertStringifiedQueryParamsToObject(history.location.search);

    // We only want to update the URL state if the query params have changed, otherwise we will end up with duplicate entries in the history, messing up the back button
    if (!isEqual(newQueries, currentQueryParams)) {
      setQueries(newQueries);
    }
  }, [state]);

  return useMemo(
    () => ({
      urlState: state,

      setUrlState: (urlState: TState) => setState(urlState),

      updateUrlState: (urlState: Partial<TState>) => setState((prev) => ({ ...prev, ...urlState })),
    }),
    [state]
  );
};
