import { createStyles } from '@material-ui/core/styles';

export const styles = () =>
  createStyles({
    root: {},

    filtersIcon: {
      blockSize: '16px',
      fontSize: '16px !important',
    },
    sourceIcon: {
      blockSize: '16px',
      fontSize: '16px  !important',
    },
    teamsIcon: {
      blockSize: '16px',
      fontSize: '16px !important',
    },

    counterRoot: {
      width: 17,
      height: 17,
      minWidth: 17,
    },
    counterCount: {
      fontSize: 11,
    },
  });
