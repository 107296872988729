import { SvgIcon } from '@material-ui/core';
import { FC } from 'react';

import { withStyles, WithStyles } from '@core/theme/utils/with-styles';

import { styles } from './List.styles';

export interface ListIconProps extends WithStyles<typeof styles> {
  color?: string;
}

const ListIconComponent: FC<ListIconProps> = ({ classes, color = '#101828' }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      classes={{ root: classes.root }}
    >
      <path
        d="M14 8L6 8M14 4L6 4M14 12L6 12M3.33333 8C3.33333 8.36819 3.03486 8.66667 2.66667 8.66667C2.29848 8.66667 2 8.36819 2 8C2 7.63181 2.29848 7.33334 2.66667 7.33334C3.03486 7.33334 3.33333 7.63181 3.33333 8ZM3.33333 4C3.33333 4.36819 3.03486 4.66667 2.66667 4.66667C2.29848 4.66667 2 4.36819 2 4C2 3.63181 2.29848 3.33334 2.66667 3.33334C3.03486 3.33334 3.33333 3.63181 3.33333 4ZM3.33333 12C3.33333 12.3682 3.03486 12.6667 2.66667 12.6667C2.29848 12.6667 2 12.3682 2 12C2 11.6318 2.29848 11.3333 2.66667 11.3333C3.03486 11.3333 3.33333 11.6318 3.33333 12Z"
        stroke={color}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export const ListIcon = withStyles(styles)(ListIconComponent);
