import { PropsWithChildren, useMemo } from 'react';

import { ClassesProp } from '@core/theme/utils/with-styles';

import { useSingleSelectClasses } from './SingleSelect.styles';
import { generateOptionsHash, Select, SelectProps, SingleSelectOnChange } from '../Select';

export interface SingleSelectProps<TValue extends Id = Id>
  extends Omit<SelectProps<TValue>, 'classes' | 'defaultValue' | 'multiple' | 'onChange' | 'optionsHash' | 'value'>,
    PropsWithChildren<{
      classes?: ClassesProp<typeof useSingleSelectClasses>;
      defaultValue?: TValue;
      value?: TValue;
      onChange?: SingleSelectOnChange<TValue>;
    }> {}

export const SingleSelect = <TValue extends Id = Id>({
  classes: propClasses,
  onChange,
  ...selectProps
}: SingleSelectProps<TValue>) => {
  const classes = useSingleSelectClasses(propClasses);

  const optionsHash = useMemo(() => generateOptionsHash<TValue>(selectProps.options), [selectProps.options]);

  return (
    <Select<TValue>
      {...selectProps}
      classes={classes}
      multiple={false}
      optionsHash={optionsHash}
      onChange={(value) => {
        if (onChange) {
          onChange(value as TValue);
        }
      }}
    ></Select>
  );
};
