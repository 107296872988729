import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      background: colors.kato.navy[950],
      border: 'none',
      borderRadius: 6,
      boxShadow: 'none',
      padding: spacing(2, 3),
    },
    checkboxIconChecked: {
      color: colors.white.lightest,
    },
    checkboxLabel: {
      color: colors.white.lightest,
    },
    matchLabel: {
      color: colors.white.lightest,
      fontSize: 14,
      fontWeight: 500,
    },
    clearAction: {
      color: colors.white.lightest,
      cursor: 'pointer',
      fontSize: 14,
      fontWeight: 500,
      marginLeft: spacing(2),
      textDecoration: 'underline',
    },
    divider: {
      background: '#7E8899', // TODO replace after creating new palette
      width: 1,
      height: 21,
      margin: spacing(0, 4),
    },
  });
