import { IconButton } from '@material-ui/core';
import cx from 'classnames';
import { FC, useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { updateDisposalShowCache, useDisposalUpdateMutation } from '@agency/apis/disposals';
import {
  addInterestItemsIntoHash,
  supportedGroupedBy,
  supportedMatchesType,
  supportedTabs,
  updateInterestItemInHash,
  useDisposalReporting,
} from '@agency/modules/disposals/pages/disposal-manage/pages/disposal-reporting/DisposalReporting.context';
import ROUTES from '@agency/routes/routes.constant';
import { t } from '@core/i18n';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import {
  DisposalInterestIndexItem,
  useDisposalArchiveMatchFromScheduleMutation,
  useDisposalChangeMatchStatusMutation,
  useDisposalClearMatchDiscountedReasonMutation,
  useDisposalDiscountMatchMutation,
  useDisposalFavouriteMatchMutation,
  useDisposalInterestActiveIndexQuery,
  useDisposalInterestShowQuery,
  useDisposalShareMatchMutation,
  useDisposalShowMatchOnScheduleMutation,
} from '@shared/apis/disposals';
import { Button } from '@shared/components/button-new';
import { FilterButton, FilterButtonTypes } from '@shared/components/buttons/filter-button-new';
import { CollapsingSearch } from '@shared/components/collapsing-search';
import { ColumnPicker } from '@shared/components/column-picker-new';
import { IDropdownItem } from '@shared/components/dropdown-new';
import { Flex } from '@shared/components/flex';
import { InterestScheduleBoard } from '@shared/components/interest-schedule/interest-schedule-board';
import { InterestScheduleBulkArchiveModal } from '@shared/components/interest-schedule/interest-schedule-bulk-archive-modal';
import { InterestScheduleBulkDiscountModal } from '@shared/components/interest-schedule/interest-schedule-bulk-discount-modal';
import {
  getChangeStatusRequestBody,
  InterestScheduleChangeStatusModal,
} from '@shared/components/interest-schedule/interest-schedule-change-status-modal';
import {
  InterestScheduleFilterModal,
  InterestScheduleFilterParams,
} from '@shared/components/interest-schedule/interest-schedule-filter-modal';
import { InterestScheduleShareModal } from '@shared/components/interest-schedule/interest-schedule-share-modal';
import { InterestScheduleStatusDropdown } from '@shared/components/interest-schedule/interest-schedule-status-dropdown';
import { ReportingAddInterestModal } from '@shared/components/interest-schedule/reporting-add-interest-modal';
import {
  INTEREST_SCHEDULE_ORDER_OPTIONS,
  ReportingInterestColumnKeys,
  ReportingInterestSelectableColumnKeys,
  ReportingInterestTable,
} from '@shared/components/interest-schedule/reporting-interest-table';
import {
  ReportingSpaceTableTabs,
  ReportingSpaceTableTabsProps,
} from '@shared/components/interest-schedule/reporting-space-table-tabs';
import { showNotification } from '@shared/components/notification';
import { RadioGroupOption } from '@shared/components/radio-group';
import { SingleSelect } from '@shared/components/select-new/single-select';
import { Switch, SwitchSize, SwitchType } from '@shared/components/switch';
import { TableColumn } from '@shared/components/table-new';
import { ViewTypeSelect } from '@shared/components/view-type-select';
import { useAgencyConstants } from '@shared/contexts/ConfigContext';
import { useInterestScheduleConfig, useInterestScheduleMatches } from '@shared/hooks/interestSchedule.hooks';
import { PageViewState, UrlViewState, usePageState } from '@shared/hooks/viewState';
import { ColumnsIcon } from '@shared/icons/columns';
import { PlayIcon } from '@shared/icons/play';
import { PlusIcon } from '@shared/icons/plus';
import { SearchIcon } from '@shared/icons/search';
import { ShareIcon } from '@shared/icons/share-2';
import { StarIcon } from '@shared/icons/star';
import { SwitchVerticalIcon } from '@shared/icons/switch-vertical';
import { UserLeftIcon } from '@shared/icons/user/UserLeft';
import { SavedViewType } from '@shared/models/saved_view/savedView';
import {
  ListingPageStates,
  useListingPageStatesKey,
} from '@shared/skeletons/listing-page-skeleton/components/listing-page-states';
import { ButtonColour, ButtonSize } from '@shared/types/common/button';
import { InputSize } from '@shared/types/common/input';
import {
  ALL_SCHEDULE_STATUS_GROUP,
  InterestScheduleCustomAction,
  InterestScheduleData,
  InterestScheduleStatuses,
  ScheduleStatusGroups,
  ScheduleStatusGroupsWithAll,
} from '@shared/types/common/match';
import { getDefaultError, getQueryValueString, reduceStringRecordToArray } from '@shared/utils/common';
import {
  filterInterestByTypeFilter,
  getInterestScheduleOrderByConfig,
  getInterestScheduleTableColumn,
  getMatchSearchHash,
  GroupdByFilter,
  InterestTypeFilter,
} from '@shared/utils/interest-schedule';

import { styles } from './ReportingScheduleSpace.styles';

const fixedColumns: TableColumn<any, ReportingInterestColumnKeys>[] = [
  getInterestScheduleTableColumn('fav', ''),
  getInterestScheduleTableColumn('hide', t('hide')),
  getInterestScheduleTableColumn('status', t('status')),
  getInterestScheduleTableColumn('tenant_sector', t('tenant_or_sector')),
];
const fixedColumnKeys = fixedColumns.map((column) => column.field);
const supportedViewTypes = [SavedViewType.table, SavedViewType.board] as const;
const viewTypes = [{ type: SavedViewType.table }, { type: SavedViewType.board }];

// --- URL State ---

export interface ScheduleUrlState extends UrlViewState {
  schedule_group_by: GroupdByFilter;
  schedule_interest_type: InterestTypeFilter;
  schedule_order: string;
  schedule_search: string;
  schedule_show_discounted: boolean;
  schedule_show_hidden: boolean;
  schedule_tab: ScheduleStatusGroups | typeof ALL_SCHEDULE_STATUS_GROUP;
  schedule_view_type: SavedViewType;
}

export const defaultScheduleUrlState: ScheduleUrlState = {
  schedule_group_by: GroupdByFilter.Status,
  schedule_interest_type: InterestTypeFilter.All,
  schedule_order: 'created_at-desc',
  schedule_search: '',
  schedule_show_discounted: true,
  schedule_show_hidden: false,
  schedule_tab: ALL_SCHEDULE_STATUS_GROUP,
  schedule_view_type: SavedViewType.table,
};

export const getScheduleUrlState = (queries: Query) => {
  const result: ScheduleUrlState = {
    ...defaultScheduleUrlState,
    schedule_search: getQueryValueString('schedule_search', queries),
  };

  const groupByValue = getQueryValueString('schedule_group_by', queries) as GroupdByFilter;
  if (groupByValue && supportedGroupedBy.includes(groupByValue)) {
    result.schedule_group_by = groupByValue;
  }

  const interestTypeValue = getQueryValueString('schedule_interest_type', queries) as InterestTypeFilter;
  if (interestTypeValue && supportedMatchesType.includes(interestTypeValue)) {
    result.schedule_interest_type = interestTypeValue;
  }

  const orderValue = getQueryValueString('schedule_order', queries);
  if (orderValue) {
    result.schedule_order = orderValue;
  }

  if ('schedule_show_discounted' in queries) {
    result.schedule_show_discounted = queries.schedule_show_discounted === 'true'; // avoid !! to account for possible valid "false" string value, otherwise "false" will always result to an assignment of true
  }

  if ('schedule_show_hidden' in queries) {
    result.schedule_show_hidden = queries.schedule_show_hidden === 'true'; // avoid !! to account for possible valid "false" string value, otherwise "false" will always result to an assignment of true
  }

  const tabValue = getQueryValueString('schedule_tab', queries) as ScheduleStatusGroups;
  if (tabValue && supportedTabs.includes(tabValue)) {
    result.schedule_tab = tabValue;
  }

  const viewTypeValue = getQueryValueString('schedule_view_type', queries) as typeof supportedViewTypes[number];
  if (viewTypeValue && supportedViewTypes.includes(viewTypeValue)) {
    result.schedule_view_type = viewTypeValue;
  }

  return result;
};

// --- Page State ---

export const getInitialPageState = () => ({
  add_interest: false,
  columns: false,
  filters: false,
  search_open: false,
  share_open: false,
});

type PageState = PageViewState<ReturnType<typeof getInitialPageState>>;

export interface ReportingScheduleSpaceProps extends WithStyles<typeof styles> {
  id: string;
  scheduleData: InterestScheduleData;

  title: string;
}

const ReportingScheduleSpaceComponent: FC<ReportingScheduleSpaceProps> = ({ classes, id, scheduleData, title }) => {
  const { constants } = useAgencyConstants();
  const history = useHistory();

  const {
    activeInterestId,
    interestHash,
    scheduleUrlState,
    setActiveInterestId,
    setInterestHash,
    urlState,
    updateUrlState,
  } = useDisposalReporting();

  const { pageState, updatePageState } = usePageState<PageState>(() => getInitialPageState());

  const [activeTab, setActiveTab] = useState<ScheduleStatusGroupsWithAll>(
    scheduleUrlState.schedule_tab || ALL_SCHEDULE_STATUS_GROUP
  );
  const [changingStatusGroup, setChangingStatusGroup] = useState<InterestScheduleStatuses[]>([]);
  const [interestToArchive, setInterestToArchive] = useState<DisposalInterestIndexItem | undefined>(undefined);
  const [interestToDiscount, setInterestToDiscount] = useState<DisposalInterestIndexItem | undefined>(undefined);

  const { scheduleConfig } = useInterestScheduleConfig(scheduleData.investment === 1);

  // ---- Queries ----

  const disposalInterestActiveIndexPayload = useMemo(
    () => ({
      query: {
        include_hidden: scheduleUrlState.schedule_show_hidden ? 'true' : undefined,
      },
      id: id,
    }),
    [id, scheduleUrlState.schedule_show_hidden]
  );

  const handleResetChangeStatus = () => {
    setActiveInterestId(undefined);
    setChangingStatusGroup([]);
  };

  const disposalInterestActiveIndexQuery = useDisposalInterestActiveIndexQuery(disposalInterestActiveIndexPayload, {
    onSuccess: (data) => {
      setInterestHash((prev) => addInterestItemsIntoHash(data.data, prev));
    },
  });

  const disposalInterestShowQuery = useDisposalInterestShowQuery({
    disposalId: scheduleData.disposal_id.toString(),
    interestId: activeInterestId?.toString() ?? '',
  });

  const allInterestItemIds = useMemo(
    () => (disposalInterestActiveIndexQuery.data?.data ?? []).map((interest) => interest.id),
    [disposalInterestActiveIndexQuery.data]
  );

  // ---- Mutations ----

  // TODO: We need to use a different mutation - agency vs. landlord
  const disposalUpdateMutation = useDisposalUpdateMutation({
    onSuccess: (data) => {
      updateDisposalShowCache(scheduleData.disposal_id.toString(), () => data);
    },
  });

  const disposalChangeMatchStatusMutation = useDisposalChangeMatchStatusMutation({
    onSuccess: () => handleResetChangeStatus(),
  });

  const disposalFavouriteMatchMutation = useDisposalFavouriteMatchMutation<DisposalInterestIndexItem>({
    onMutate: (vars) => {
      setInterestHash((prev) =>
        updateInterestItemInHash(vars.id, prev, (interest) => ({
          ...interest,
          fav: vars.body.fav ?? false,
        }))
      );
      return undefined;
    },
    onError: (error, vars, context) => {
      if (context) {
        setInterestHash((prev) => addInterestItemsIntoHash([context], prev));
      }

      showNotification(getDefaultError(), 'error');
    },
  });

  const disposalShowMatchOnScheduleMutation = useDisposalShowMatchOnScheduleMutation();

  const disposalClearMatchDiscountedReasonMutation = useDisposalClearMatchDiscountedReasonMutation();

  const disposalShareMatchMutation = useDisposalShareMatchMutation<DisposalInterestIndexItem>({
    onMutate: (vars) => {
      setInterestHash((prev) =>
        updateInterestItemInHash(vars.id, prev, (interest) => ({
          ...interest,
          shared_requirement: vars.body.shared_requirement ?? 0,
        }))
      );
      return undefined;
    },
    onError: (error, vars, context) => {
      if (context) {
        setInterestHash((prev) => addInterestItemsIntoHash([context], prev));
      }

      showNotification(getDefaultError(), 'error');
    },
  });

  const disposalArchiveMatchFromScheduleMutation = useDisposalArchiveMatchFromScheduleMutation({
    onSuccess: () => setInterestToArchive(undefined),
  });

  const disposalDiscountMatchMutation = useDisposalDiscountMatchMutation({
    onSuccess: () => setInterestToDiscount(undefined),
  });

  const filterParams = useMemo(() => {
    // console.log('filterParams memo');
    const result: InterestScheduleFilterParams = {
      groupBy: scheduleUrlState.schedule_group_by,
      includeArchived: scheduleUrlState.schedule_show_hidden,
      includeDiscounted: scheduleUrlState.schedule_show_discounted,
      interestType: scheduleUrlState.schedule_interest_type,
    };

    return result;
  }, [
    scheduleUrlState.schedule_group_by,
    scheduleUrlState.schedule_interest_type,
    scheduleUrlState.schedule_show_discounted,
    scheduleUrlState.schedule_show_hidden,
  ]);

  const orderByDefaultConfig = useMemo(
    () => getInterestScheduleOrderByConfig(scheduleUrlState.schedule_order),
    [scheduleUrlState.schedule_order]
  );

  const interestFilter = useCallback(
    (interestId) => {
      const interest = interestHash[interestId];

      return (
        !!interest &&
        // Discounted visible
        (!interest.interest_schedule_discounted_reason || filterParams.includeDiscounted) &&
        // Archived visible
        (!interest.interest_schedule_hidden || filterParams.includeArchived) &&
        // Interest type visible
        filterInterestByTypeFilter(interest, filterParams.interestType, scheduleConfig)
      );
    },
    [filterParams, interestHash, scheduleConfig]
  );

  const trimmedSearch = useMemo(
    () => scheduleUrlState.schedule_search.trim().toLowerCase(),
    [scheduleUrlState.schedule_search]
  );

  const interestSearchFilter = useCallback(
    (interestId) => {
      const interest = interestHash[interestId];

      return !!interest && getMatchSearchHash(interest).includes(trimmedSearch);
    },
    [interestHash, trimmedSearch]
  );

  const { interestGrouped, interestCounts, interestItems } = useInterestScheduleMatches({
    includeDiscounted: filterParams.includeDiscounted,
    interestFilter,
    interestHash,
    interestIds: allInterestItemIds,
    interestSearchFilter,
    matchesGroupBy: filterParams.groupBy,
    orderBy: orderByDefaultConfig,
    scheduleConfig,
    search: trimmedSearch,
    tab: scheduleUrlState.schedule_tab,
  });

  const visibleItems = useMemo(() => {
    if (urlState.present) {
      return interestItems.filter((match) => {
        return match.shared_requirement && !match.is_external_match;
      });
    }

    return interestItems.map((match) => ({ ...match, hidden: match.interest_schedule_hidden }));
  }, [interestItems, urlState.present]);

  const columns: TableColumn<any, ReportingInterestSelectableColumnKeys>[] = useMemo(
    () =>
      reduceStringRecordToArray(constants.interestScheduleAllColumns, (result, value, key) => {
        if (!fixedColumnKeys.includes(key)) {
          result.push(getInterestScheduleTableColumn(key as ReportingInterestSelectableColumnKeys, value));
        }

        return result;
      }),
    [constants.interestScheduleAllColumns]
  );

  const selectedColumnsCsv = useMemo(() => scheduleData.preferred_columns.join(','), [scheduleData.preferred_columns]);

  const matchesTypeOptions = useMemo(() => {
    const options: RadioGroupOption<InterestTypeFilter>[] = [];

    options.push({ label: t('all_requirements'), id: InterestTypeFilter.All });
    options.push({ label: t('our_requirements'), id: InterestTypeFilter.OurRequirements });
    options.push({ label: t('society_requirements'), id: InterestTypeFilter.SocietyRequirements });
    options.push({ label: t('starred'), id: InterestTypeFilter.Starred });

    return options;
  }, []);

  const header = useMemo(() => {
    return (
      <div className={classes.header}>
        <div className={classes.headerControls}>
          <ViewTypeSelect
            supportedViewTypes={viewTypes}
            viewType={scheduleUrlState.schedule_view_type}
            setViewType={(viewType) => {
              if (viewType) {
                // TODO: Figure out why we can't just use viewType. Probably refactor ViewTypeSelect
                updateUrlState({ schedule_view_type: viewType as SavedViewType });
              }
            }}
          />
          {scheduleUrlState.schedule_view_type === SavedViewType.table && (
            <Button
              size={ButtonSize.small}
              startIcon={<ColumnsIcon />}
              text={t('columns')}
              onClick={() => updatePageState({ columns: true })}
            />
          )}
          <CollapsingSearch
            classes={{ searchBtn: classes.searchBtn, searchFieldInput: classes.searchField }}
            inputProps={{ size: InputSize.small }}
            placeholder={t('xtextx_ellipsis', { text: t('search_matches') })}
            search={scheduleUrlState.schedule_search}
            searchOpen={pageState.search_open}
            onSearchChange={(search) => updateUrlState({ schedule_search: search })}
            setSearchOpen={(open) => updatePageState({ search_open: open })}
          />
        </div>
        <div className={classes.headerControls}>
          <FilterButton
            count={0}
            type={FilterButtonTypes.filters}
            onClick={() => {
              updatePageState({ filters: true });
            }}
          />
          <SingleSelect
            icon={<SwitchVerticalIcon />}
            options={INTEREST_SCHEDULE_ORDER_OPTIONS}
            placeholder={t('select')}
            value={urlState.schedule_order}
            onChange={(value) => updateUrlState({ schedule_order: value.toString() })}
          />
          <div className={classes.divider} />
          <Button
            size={ButtonSize.small}
            startIcon={<PlayIcon />}
            text={t('present')}
            onClick={() => {
              history.push(ROUTES.present.presentReport(id, urlState));
            }}
          />
          <Button
            size={ButtonSize.small}
            colour={ButtonColour.primarySpecial}
            startIcon={<ShareIcon />}
            text={t('share')}
            onClick={() => updatePageState({ share_open: true })}
          />
        </div>
      </div>
    );
  }, [
    scheduleUrlState.schedule_view_type,
    scheduleUrlState.schedule_search,
    pageState.search_open,
    updateUrlState,
    updatePageState,
  ]);

  const boardContent = useMemo(() => {
    return (
      <>
        {header}
        <div className={classes.body}>
          <InterestScheduleBoard
            classes={{ root: classes.boardBody }}
            groupedItems={interestGrouped}
            interestCounts={interestCounts}
            scheduleData={scheduleData}
            onAddInterest={() => updatePageState({ add_interest: true })}
            onOpenInterest={(id) => {
              setActiveInterestId(id);
              updateUrlState({ view_interest: id.toString() });
            }}
            setChangingStatusGroup={setChangingStatusGroup}
          />
        </div>
      </>
    );
  }, [interestGrouped, header, interestCounts, scheduleData]);

  const tableTabs = useMemo(() => {
    const tabsLeft: ReportingSpaceTableTabsProps<ScheduleStatusGroupsWithAll>['tabsLeft'] = [];
    const tabsRight: ReportingSpaceTableTabsProps<ScheduleStatusGroupsWithAll>['tabsRight'] = [];

    scheduleConfig.scheduleGroups.forEach((groupKey) => {
      if (scheduleConfig.scheduleGroupsActive.indexOf(groupKey) !== -1) {
        tabsLeft.push({
          count:
            ((groupKey as ScheduleStatusGroupsWithAll) === ALL_SCHEDULE_STATUS_GROUP
              ? interestCounts[ALL_SCHEDULE_STATUS_GROUP]
              : interestCounts[groupKey]) || 0,
          text:
            (groupKey as ScheduleStatusGroupsWithAll) === ALL_SCHEDULE_STATUS_GROUP
              ? t('all')
              : scheduleConfig.groupConfig[groupKey].title,
          value: groupKey,
        });
      } else {
        tabsRight.push({
          count: interestCounts[groupKey] || 0,
          text: scheduleConfig.groupConfig[groupKey].title,
          value: groupKey,
        });
      }
    });

    //TODO REMOVE when new prospect status is added
    // tabsLeft.unshift({
    //   count: interestCounts[ScheduleStatusGroups.Prospect] || 0,
    //   text: t('prospect'),
    //   value: ScheduleStatusGroups.Prospect,
    // });

    tabsLeft.unshift({
      count: interestCounts[ALL_SCHEDULE_STATUS_GROUP] || 0,
      text: t('all'),
      value: ALL_SCHEDULE_STATUS_GROUP,
    });

    return { left: tabsLeft, right: tabsRight };
  }, [interestCounts, scheduleConfig]);

  const listingPageStatesKey = useListingPageStatesKey({
    error: disposalInterestActiveIndexQuery.isError,
    loading: disposalInterestActiveIndexQuery.isFetching,
    noData: !allInterestItemIds.length,
    noResults: !visibleItems.length,
    searchingOnAll: false,
  });

  const listingPageStatesContent = useMemo(
    () => (
      <ListingPageStates
        classes={{ icon: classes.noResultsIcon }}
        errorConfig={{
          header: 'Oh no, it looks like we had an issue loading matches.',
          text: t('were_really_sorry_about_that_we_have_been_notified_about_this_issue'),
          intercomMessage: t(
            'there_is_an_error_on_the_xnamex_page_i_cant_seem_to_load_any_data_do_you_know_how_long_until_the_issue_will_be_resolved_question',
            { name: t('interest_schedule') }
          ),
        }}
        noDataConfig={{
          header: t('here_youll_find_any_interest_in_your_property_added_by_your_agent'),
          text: t('interest_on_your_property_will_appear_here'),
          icon: <SearchIcon classes={{ root: classes.noResultsIcon }} />,
        }}
        noResultsConfig={{
          header: 'No matching interest has been found for this property',
          search: scheduleUrlState.schedule_search || '',
          text: 'Please add a requirement to include it in the schedule.',
        }}
        noResultsAllConfig={{
          header: '',
          text: '',
        }}
        stateKey={listingPageStatesKey}
      />
    ),
    [listingPageStatesKey, scheduleUrlState.schedule_search]
  );

  const onRenderTableBodyCell = useCallback((field: string, interest: DisposalInterestIndexItem) => {
    const columnKey = field as ReportingInterestColumnKeys;

    switch (columnKey) {
      case 'fav': {
        return (
          <Flex alignItems="center" direction="column" style={{ gap: 4 }}>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                disposalFavouriteMatchMutation.mutate({
                  disposalId: scheduleData.disposal_id,
                  id: interest.id,
                  body: { fav: !interest.fav },
                });
              }}
            >
              <StarIcon active={interest.fav} />
            </IconButton>
            {interest.is_external_match && <UserLeftIcon classes={{ root: classes.columnIcon }} />}
          </Flex>
        ); // TODO
      }

      case 'hide':
        return (
          // TODO uncomment when feature_flags are returned in api
          // interest.feature_flags.can_manage_requirement && (
          <div>
            <Switch
              checked={!interest.shared_requirement}
              classes={{ label: classes.hideSwitchLabel }}
              color="primary"
              disabled={disposalShareMatchMutation.isLoading}
              label={t('hide')}
              size={SwitchSize.small}
              tooltip={t(
                !interest.shared_requirement
                  ? 'requirement_is_hidden_from_or_br_your_presentation'
                  : 'requirement_is_showing_on_or_br_your_presentation'
              ).replace('<br/>', '')}
              type={SwitchType.round}
              onChange={() => {
                disposalShareMatchMutation.mutate({
                  disposalId: scheduleData.disposal_id,
                  id: interest.id,
                  body: { shared_requirement: !interest.shared_requirement },
                });
              }}
              onClick={(e) => e.stopPropagation()}
            />
          </div>
          // )
        );

      case 'status':
        return (
          <Flex direction="column" style={{ gap: 4 }}>
            <InterestScheduleStatusDropdown
              interest={interest}
              onChangeStatus={(item: IDropdownItem) => {
                const option = item as IDropdownItem<InterestScheduleStatuses | InterestScheduleCustomAction>;

                switch (option.value) {
                  case InterestScheduleCustomAction.HideFromSchedule:
                    setInterestToArchive(interest);
                    break;
                  case InterestScheduleCustomAction.Discount:
                    setInterestToDiscount(interest);
                    break;
                  case InterestScheduleCustomAction.ShowOnSchedule:
                    disposalShowMatchOnScheduleMutation.mutate({
                      disposalId: scheduleData.disposal_id,
                      id: interest.id,
                    });
                    break;
                  case InterestScheduleCustomAction.ClearDiscountedReason:
                    disposalClearMatchDiscountedReasonMutation.mutate({
                      disposalId: scheduleData.disposal_id,
                      id: interest.id,
                    });
                    break;
                  case InterestScheduleStatuses.Signed:
                    // TODO changing status to signed
                    break;
                  default:
                    setActiveInterestId(interest.id);
                    setChangingStatusGroup([option.value as InterestScheduleStatuses]);
                    break;
                }
              }}
            />
          </Flex>
        );

      default:
        return null;
    }
  }, []);

  const tableContent = useMemo(() => {
    if (!urlState.present && scheduleUrlState.schedule_view_type === SavedViewType.board) {
      return null;
    }

    return (
      <>
        {!urlState.present && header}
        <div className={classes.content}>
          <ReportingSpaceTableTabs
            activeTab={activeTab}
            classes={{ root: classes.tabs }}
            tabsLeft={tableTabs.left}
            tabsRight={tableTabs.right}
            onChangeTab={(tab) => {
              setActiveTab(tab as ScheduleStatusGroupsWithAll);
              updateUrlState({ schedule_tab: tab as ScheduleStatusGroupsWithAll });
            }}
          />
          <div
            className={cx(classes.body, classes.bodyTableView, {
              [classes.bodyTableNoItems as string]: !visibleItems.length,
            })}
          >
            <ReportingInterestTable
              bulkEnabled
              classes={{ root: classes.tablePresent, tabs: classes.tabs, tableBody: classes.tableBody }}
              columns={columns}
              fixedColumns={fixedColumns}
              items={visibleItems}
              listingPageStatesContent={listingPageStatesContent}
              listingPageStatesKey={listingPageStatesKey}
              scheduleData={scheduleData}
              selectedColumns={scheduleData.preferred_columns}
              onOpenInterest={(interestId) => {
                setActiveInterestId(interestId);
                updateUrlState({ view_interest: interestId.toString() });
              }}
              onRenderTableBodyCell={onRenderTableBodyCell}
            />
          </div>
          {!disposalInterestActiveIndexQuery.isFetching && (
            <div className={classes.tableFooter}>
              <Button
                classes={{ root: classes.addInterestBtn }}
                size={ButtonSize.medium}
                text={t('add_interest')}
                startIcon={<PlusIcon />}
                onClick={() => updatePageState({ add_interest: true })}
              />
            </div>
          )}
        </div>
      </>
    );
  }, [
    activeTab,
    classes,
    columns,
    disposalInterestActiveIndexQuery.isFetching,
    disposalShareMatchMutation.isLoading,
    fixedColumns,
    header,
    listingPageStatesContent,
    scheduleData,
    tableTabs,
    urlState.present,
    visibleItems,
  ]);

  return (
    <>
      {scheduleUrlState.schedule_view_type === SavedViewType.table && tableContent}
      {scheduleUrlState.schedule_view_type === SavedViewType.board && boardContent}

      {/* Modals */}
      <InterestScheduleFilterModal
        open={pageState.filters}
        params={filterParams}
        interestTypeOptions={matchesTypeOptions}
        onClose={() => updatePageState({ filters: false })}
        onApplyFilters={(params) => {
          updatePageState({ filters: false });
          updateUrlState({
            schedule_group_by: params.groupBy,
            schedule_interest_type: params.interestType,
            schedule_show_discounted: params.includeDiscounted,
            schedule_show_hidden: params.includeArchived,
          });
        }}
      />
      <InterestScheduleShareModal
        columns={columns}
        selectedColumns={selectedColumnsCsv}
        open={pageState.share_open}
        scheduleData={scheduleData}
        onClose={() => updatePageState({ share_open: false })}
      />
      <ColumnPicker
        columns={columns}
        fixedColumns={fixedColumns}
        headingProps={{ heading: t('customise_table') }}
        open={pageState.columns}
        selectedColumns={selectedColumnsCsv}
        withFooter={false}
        onClose={() => updatePageState({ columns: false })}
        onSave={(columns: string) => {
          disposalUpdateMutation.mutate({
            id: scheduleData.disposal_id.toString(),
            body: { interest_schedule_preferred_columns: columns.split(',') },
          });

          updatePageState({ columns: false });
        }}
      />
      {!!changingStatusGroup.length && (
        <InterestScheduleChangeStatusModal
          initialStatus={
            scheduleUrlState.schedule_view_type === SavedViewType.table ? changingStatusGroup[0] : undefined
          }
          initialStatusGroup={
            scheduleUrlState.schedule_view_type === SavedViewType.board ? changingStatusGroup : undefined
          }
          queryResult={disposalInterestShowQuery}
          saving={disposalChangeMatchStatusMutation.isLoading}
          scheduleConfig={scheduleConfig}
          onClose={() => handleResetChangeStatus()}
          onSave={(state) => {
            if (!disposalInterestShowQuery.data) {
              return;
            }

            disposalChangeMatchStatusMutation.mutate({
              disposalId: scheduleData.disposal_id,
              id: disposalInterestShowQuery.data.id,
              body: getChangeStatusRequestBody(state, disposalInterestShowQuery.data),
            });
          }}
        />
      )}
      {interestToArchive && (
        <InterestScheduleBulkArchiveModal
          loading={disposalArchiveMatchFromScheduleMutation.isLoading}
          onClose={() => setInterestToArchive(undefined)}
          onSave={(reason, comment) => {
            disposalArchiveMatchFromScheduleMutation.mutate({
              disposalId: scheduleData.disposal_id,
              id: interestToArchive.id,
              body: {
                reason: reason.toString(),
                comment,
              },
            });
          }}
        />
      )}
      {interestToDiscount && (
        <InterestScheduleBulkDiscountModal
          loading={disposalDiscountMatchMutation.isLoading}
          onClose={() => setInterestToDiscount(undefined)}
          onSave={(reason, comment) => {
            disposalDiscountMatchMutation.mutate({
              disposalId: scheduleData.disposal_id,
              id: interestToDiscount.id,
              body: {
                reason: reason.toString(),
                comment,
              },
            });
          }}
        />
      )}
      {pageState.add_interest && (
        <ReportingAddInterestModal
          disposalId={scheduleData.disposal_id}
          scheduleConfig={scheduleConfig}
          onClose={() => updatePageState({ add_interest: false })}
          onCreated={(createAnother) => {
            if (!createAnother) updatePageState({ add_interest: false });
            disposalInterestActiveIndexQuery.refetch();
          }}
        />
      )}
    </>
  );
};

export const ReportingScheduleSpace = withStyles(styles)(ReportingScheduleSpaceComponent);
