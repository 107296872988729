import { FC, ReactNode } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Flex } from '@shared/components/flex';
import { AlertIcon } from '@shared/icons/alert';

import { styles } from './ErrorStateContent.styles';

export interface ErrorStateContentProps extends WithStyles<typeof styles> {
  actions?: ReactNode;
  header: ReactNode;
  icon?: ReactNode;
  text: ReactNode;
}

const ErrorStateContentComponent: FC<ErrorStateContentProps> = ({ actions, classes, header, icon, text }) => {
  return (
    <Flex
      autoWidth={false}
      alignItems="center"
      direction="column"
      justifyContent="center"
      classes={{ root: classes.root }}
    >
      <Flex autoWidth={false} alignItems="center" justifyContent="center" classes={{ root: classes.iconContainer }}>
        {icon || <AlertIcon />}
      </Flex>
      <span className={classes.stateHeader}>{header}</span>
      <span className={classes.stateText}>{text}</span>
      {actions && <Flex classes={{ root: classes.actions }}>{actions}</Flex>}
    </Flex>
  );
};

export const ErrorStateContent = withStyles(styles)(ErrorStateContentComponent);
