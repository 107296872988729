import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      width: 510,

      '& $body': {
        gap: 0,
      },

      '& $actionButtons': {
        justifyContent: 'center',
      },

      '& $actions': {
        borderTop: 'none',
        paddingTop: 0,
      },
    },
    actionButtons: {},
    actions: {},
    iconContainer: {
      backgroundColor: '#FFF5EB', //TODO replace after creating new palette
      marginBottom: spacing(4),
      height: 64,
      width: 64,
      borderRadius: 8,
    },
    icon: {
      fontSize: 27,
    },
    body: {
      alignItems: 'center',
    },
    heading: {
      color: colors.black.darkest,
      fontSize: 18,
      fontWeight: 600,
      lineHeight: '28px',
      textAlign: 'center',
    },
    text: {
      color: '#515D70', // TODO: change after creating new palette
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '20px',
      textAlign: 'center',
    },
  });
