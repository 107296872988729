import { IconButton } from '@material-ui/core';
import cx from 'classnames';
import { ChangeEvent, FC, ReactNode, useMemo, useState } from 'react';

import { t } from '@core/i18n';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { ThumbsDownIcon } from '@shared//icons/thumbs-down';
import { ThumbsUpIcon } from '@shared//icons/thumbs-up';
import {
  DisposalInterestIndexColumnValues,
  DisposalInterestIndexItem,
  useDisposalBulkChangeMatchesStatusMutation,
  useDisposalBulkClearMatchesConfidentialMutation,
  useDisposalBulkClearMatchesDiscountedReasonMutation,
  useDisposalBulkDiscountMatchesMutation,
  useDisposalBulkHideMatchesFromScheduleMutation,
  useDisposalBulkMarkMatchesAsConfidentialMutation,
  useDisposalBulkShareMatchesMutation,
  useDisposalBulkUnhideMatchesFromScheduleMutation,
  useDisposalBulkUnshareMatchesMutation,
  useDisposalCreateMatchCommentMutation,
} from '@shared/apis/disposals'; //TODO define common option for both apps
import { AddCommentModal } from '@shared/components/add-comment-modal/AddCommentModal';
import { AlertConfirmationModal } from '@shared/components/alert-confirmation-modal';
import { Badge, BADGE_THEME } from '@shared/components/badge';
import { BulkActionsBar } from '@shared/components/bulk-actions-bar';
import { Button } from '@shared/components/button-new';
import { IDropdownItem } from '@shared/components/dropdown-new';
import { Flex } from '@shared/components/flex';
import { InterestScheduleBulkChangeStatusModal } from '@shared/components/interest-schedule/interest-schedule-bulk-change-status-modal';
import { InterestScheduleBulkDiscountModal } from '@shared/components/interest-schedule/interest-schedule-bulk-discount-modal';
import { Popover } from '@shared/components/popover';
import { TableBody } from '@shared/components/table-body';
import {
  sortTableColumnsWithBasedArray,
  TableColumn,
  TableColumnField,
  TableTheme,
} from '@shared/components/table-new';
import { Tooltip } from '@shared/components/tooltip';
import { AnnouncementIcon } from '@shared/icons/announcement';
import { CheckSquareIcon } from '@shared/icons/check-square';
import { DownloadCloudIcon } from '@shared/icons/download-cloud';
import { FolderIcon } from '@shared/icons/folder';
import { FolderDownload } from '@shared/icons/folder-download';
import { LockIcon } from '@shared/icons/lock';
import { LockIcon as Lock2Icon } from '@shared/icons/lock-2';
import { RefreshIcon } from '@shared/icons/refresh';
import { UploadCloudIcon } from '@shared/icons/upload-cloud';
import { UserCheck1Icon } from '@shared/icons/user';
import { ListingPageStatesKey } from '@shared/skeletons/listing-page-skeleton/components/listing-page-states';
import { ButtonColour, ButtonSize } from '@shared/types/common/button';
import { InterestScheduleData } from '@shared/types/common/match';
import { getPrettyDate } from '@shared/utils/date';
import { normalizedHTMLText } from '@shared/utils/text';

import { styles } from './ReportingInterestTable.styles';
import { InterestScheduleBulkArchiveModal } from '../interest-schedule-bulk-archive-modal';

export const INTEREST_SCHEDULE_ORDER_OPTIONS = [
  // Match updated most recent first
  { label: t('match_updated'), id: 'updated_at-desc' },
  // shows newest requirements first (default option)
  { label: t('date_of_requirement'), id: 'date_of_requirement-desc' },
  // shows newest matches first
  { label: t('date_of_match'), id: 'created_at-desc' },
];

export type ReportingInterestCustomColumnKeys = 'actions' | 'fav' | 'hide';
export type ReportingInterestSelectableColumnKeys = keyof DisposalInterestIndexColumnValues;
export type ReportingInterestColumnKeys = ReportingInterestSelectableColumnKeys | ReportingInterestCustomColumnKeys;

export interface ReportingInterestTableProps extends WithStyles<typeof styles> {
  bulkEnabled?: boolean;
  columns: TableColumn<any, ReportingInterestColumnKeys>[];
  fixedColumns: TableColumn<any, ReportingInterestColumnKeys>[];
  items: DisposalInterestIndexItem[];
  listingPageStatesContent: ReactNode;
  listingPageStatesKey: ListingPageStatesKey;
  scheduleData: InterestScheduleData;
  selectedColumns: string[];
  onOpenInterest: (id: number) => void;
  onRenderTableBodyCell: (field: TableColumnField, item: DisposalInterestIndexItem) => ReactNode;
}

const ReportingInterestTableComponent: FC<ReportingInterestTableProps> = ({
  bulkEnabled,
  classes,
  columns,
  fixedColumns,
  items,
  listingPageStatesContent,
  listingPageStatesKey,
  scheduleData,
  selectedColumns,
  onOpenInterest,
  onRenderTableBodyCell,
}) => {
  // const { buildingTypeOptions } = useCoreOptions();

  const [bulkSelected, setBulkSelected] = useState<number[]>([]);

  const [interestToComment, setInterestToComment] = useState<DisposalInterestIndexItem | undefined>(undefined);
  const [idsToBulkArchive, setIdsToBulkArchive] = useState<number[] | undefined>(undefined);
  const [idsToBulkDiscount, setIdsToBulkDiscount] = useState<number[] | undefined>(undefined);
  const [idsToBulkChangeStatus, setIdsToBulkChangeStatus] = useState<number[] | undefined>(undefined);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  const [overwriteTenantName, setOverwriteTenantName] = useState<boolean>(false);

  // ---- Single mutations ----

  const disposalCreateMatchCommentMutation = useDisposalCreateMatchCommentMutation({
    onSuccess: () => setInterestToComment(undefined),
  });

  // ---- Bulk mutations ----

  const disposalBulkHideMatchesFromScheduleMutation = useDisposalBulkHideMatchesFromScheduleMutation({
    onSuccess: () => {
      setIdsToBulkArchive(undefined);
      setBulkSelected([]);
    },
  });

  const disposalBulkUnhideMatchesFromScheduleMutation = useDisposalBulkUnhideMatchesFromScheduleMutation({
    onSuccess: () => setBulkSelected([]),
  });

  const disposalBulkChangeMatchesStatusMutation = useDisposalBulkChangeMatchesStatusMutation({
    onSuccess: () => {
      setIdsToBulkChangeStatus(undefined);
      setBulkSelected([]);
    },
  });

  const disposalBulkDiscountMatchesMutation = useDisposalBulkDiscountMatchesMutation({
    onSuccess: () => {
      setIdsToBulkDiscount(undefined);
      setBulkSelected([]);
    },
  });

  const disposalBulkClearMatchesDiscountedReasonMutation = useDisposalBulkClearMatchesDiscountedReasonMutation({
    onSuccess: () => setBulkSelected([]),
  });

  const disposalBulkMarkMatchesAsConfidentialMutation = useDisposalBulkMarkMatchesAsConfidentialMutation({
    onSuccess: () => setBulkSelected([]),
  });

  const disposalBulkClearMatchesConfidentialMutation = useDisposalBulkClearMatchesConfidentialMutation({
    onSuccess: () => setBulkSelected([]),
  });

  const disposalBulkShareMatchesMutation = useDisposalBulkShareMatchesMutation({
    onSuccess: () => setBulkSelected([]),
  });

  const disposalBulkUnshareMatchesMutation = useDisposalBulkUnshareMatchesMutation({
    onSuccess: () => setBulkSelected([]),
  });

  const tableColumns: TableColumn[] = useMemo(
    () => [
      ...fixedColumns,
      ...sortTableColumnsWithBasedArray(
        columns.filter((el) => selectedColumns.includes(el.field)),
        selectedColumns.join(',')
      ),
    ],
    [columns, selectedColumns]
  );

  const archivedItemIds = useMemo(() => {
    return items.reduce((acc, item) => {
      if (item.interest_schedule_hidden) {
        acc.push(item.id);
      }
      return acc;
    }, [] as number[]);
  }, [items]);

  const bulkActionsOptions = useMemo(() => {
    const options: IDropdownItem[] = [];

    if (!bulkEnabled) {
      return options;
    }

    if (!scheduleData.investment) {
      options.push({
        icon: <UploadCloudIcon />,
        text: t('set_interest_as_shared'),
        onClick: () =>
          disposalBulkShareMatchesMutation.mutate({
            id: scheduleData.disposal_id,
            body: { match_ids: bulkSelected },
          }),
      });
      options.push({
        icon: <DownloadCloudIcon />,
        text: t('stop_sharing_interest'),
        onClick: () =>
          disposalBulkUnshareMatchesMutation.mutate({
            id: scheduleData.disposal_id,
            body: { match_ids: bulkSelected },
          }),
      });
    }

    options.push({
      icon: <LockIcon />,
      text: t('confidential'),
      onClick: () =>
        disposalBulkMarkMatchesAsConfidentialMutation.mutate({
          id: scheduleData.disposal_id,
          body: { match_ids: bulkSelected },
        }),
    });
    options.push({
      icon: <Lock2Icon locked={false} />,
      text: t('not_confidential'),
      onClick: () =>
        disposalBulkClearMatchesConfidentialMutation.mutate({
          id: scheduleData.disposal_id,
          body: { match_ids: bulkSelected },
        }),
    });
    options.push({
      icon: <AnnouncementIcon />,
      text: t('update_status'),
      onClick: () => setIdsToBulkChangeStatus(bulkSelected),
    });
    options.push({
      icon: <ThumbsDownIcon />,
      text: t('set_as_discounted'),
      onClick: () => setIdsToBulkDiscount(bulkSelected),
    });
    options.push({
      icon: <ThumbsUpIcon />,
      text: t('clear_discounted_reason'),
      onClick: () =>
        disposalBulkClearMatchesDiscountedReasonMutation.mutate({
          id: scheduleData.disposal_id,
          body: { match_ids: bulkSelected },
        }),
    });
    options.push({
      icon: <FolderDownload />,
      text: t('un_hyphen_archive_to_schedule'),
      onClick: () =>
        disposalBulkUnhideMatchesFromScheduleMutation.mutate({
          id: scheduleData.disposal_id,
          body: { match_ids: bulkSelected },
        }),
    });
    options.push({
      icon: <FolderIcon />,
      text: t('archive_from_schedule'),
      onClick: () => setIdsToBulkArchive(bulkSelected),
    });
    // if (this.Permissions.isImpersonating && interestTypeValue === OUR_REQUIREMENTS_VALUE) { //TODO to use permission
    //   { text: t('reassign_matches') },
    // }

    // if (this.FEATURES_ENABLED.requirements_lists) { //TODO to use permission
    //   { icon: <FilePlusIcon/>, text: t('add_to_list') },
    // }

    return options;
  }, [archivedItemIds, bulkEnabled, bulkSelected, scheduleData]);

  const handleLeaveComment = (e: ChangeEvent<any>, interest: DisposalInterestIndexItem) => {
    e.stopPropagation();

    setInterestToComment(interest);
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setPopoverOpen(true);
  };

  const handlePopoverClose = (e?: any) => {
    if (e) {
      e.stopPropagation();
    }
    setAnchorEl(null);
    setPopoverOpen(false);
  };

  const renderTextCell = (text: string | null): ReactNode => {
    return <span className={classes.tableRowFirstLine}>{text || '-'}</span>;
  };

  const renderTooltipCell = (title: string | null): ReactNode => {
    if (!title) {
      return renderTextCell(null);
    }

    return (
      <Tooltip
        classes={{ container: classes.tooltipContainer, tooltip: classes.commentsTooltip }}
        placement="top"
        title={title}
      >
        <span className={classes.tableRowFirstLine}>{title}</span>
      </Tooltip>
    );
  };

  // NOTE: Haven't used useCallback; will require too many dependencies and will cause stale state bugs easily
  const renderTableBodyCell = (field: TableColumnField, item: Item): ReactNode => {
    const interest = item as DisposalInterestIndexItem;

    const cellContent = onRenderTableBodyCell(field, interest);

    // Return the parent's override if content exists
    if (cellContent) {
      return cellContent;
    }

    // ---- Columns ----

    const columnKey = field as ReportingInterestSelectableColumnKeys;

    switch (columnKey) {
      // ---- Custom Cells ----

      case 'agent': {
        // TODO: Hook this up once BE defined
        return '-';
        // return (
        //   <Flex direction="column" style={{ gap: 4 }}>
        //     <span className={classes.tableRowFirstLine}>{interest.interest_column_values.represented_by || '-'}</span>
        //     {interest.interest_column_values.representing_agents && (
        //       <span className={classes.tableRowSecondLine}>{interest.interest_column_values.representing_agents}</span>
        //     )}
        //   </Flex>
        // );
      }

      case 'all_match_comments': {
        const comments = interest.interest_column_values.all_match_comments
          .map((comment: any) => comment.date_prefixed_comment)
          .join('\n');

        return (
          <Flex direction="column" style={{ gap: 4 }}>
            {!!comments.length && (
              <Tooltip
                classes={{ container: classes.tooltipContainer, tooltip: classes.commentsTooltip }}
                placement="top"
                title={<span dangerouslySetInnerHTML={{ __html: normalizedHTMLText(comments) }} />}
              >
                <span
                  className={classes.tableRowFirstLine}
                  dangerouslySetInnerHTML={{ __html: normalizedHTMLText(comments) }}
                />
              </Tooltip>
            )}

            <span className={classes.leaveComment} onClick={(e) => handleLeaveComment(e, interest)}>
              {t('leave_a_comment')}
            </span>
          </Flex>
        );
      }

      case 'completed_date': {
        // TODO: Figure out whether date rendering happens on BE or FE
        // {interest.timestamp_completed_date ? getPrettyDate(interest.timestamp_completed_date).date : '-'}
        return '-';
      }

      case 'date_of_requirement': {
        // TODO: Figure out whether date rendering happens on BE or FE
        // {interest.timestamp_requirement_date ? getPrettyDate(interest.timestamp_requirement_date).date : '-'}
        return '-';
      }

      case 'details': {
        let tenureText = t('na');

        if (interest.for_sale && interest.to_let) {
          tenureText = t('to_rent_or_to_buy');
        } else if (interest.for_sale && !interest.to_let) {
          tenureText = t('to_buy');
        } else if (!interest.for_sale && interest.to_let) {
          tenureText = t('to_rent_1');
        }

        return (
          <Flex direction="column" style={{ gap: 4 }}>
            <span className={classes.tableRowFirstLine}>{interest.interest_column_values.size}</span>
            <span className={classes.tableRowFirstLine}>{tenureText}</span>
          </Flex>
        );
      }

      case 'interest_schedule_updated_at': {
        // TODO: Figure out whether date rendering happens on BE or FE
        // {interest.timestamp_interest_schedule_updated_at ? getPrettyDate(interest.timestamp_interest_schedule_updated_at).date : '-'}
        return '-';
      }

      case 'latest_comment': {
        // TODO: Hook this up once BE defined
        return '-';
        // const latestComment = interest.interest_column_values.latest_comment;
        // if (!latestComment) {
        //   return (
        //     <span className={classes.leaveComment} onClick={(e) => handleLeaveComment(e, interest)}>
        //       {t('leave_a_comment')}
        //     </span>
        //   );
        // }

        // let comment = latestComment.date_prefixed_comment;

        // const canClip = comment.length > 158;

        // if (canClip) {
        //   comment = t('xtextx_ellipsis', { text: comment.substr(0, 155) });
        // }
        // return (
        //   <Flex direction="column" style={{ gap: 4 }}>
        //     <Tooltip
        //       classes={{ container: classes.tooltipContainer, tooltip: classes.commentsTooltip }}
        //       placement="top"
        //       title={<span dangerouslySetInnerHTML={{ __html: normalizedHTMLText(comment) }} />}
        //     >
        //       <>
        //         <Flex wrap="nowrap" style={{ gap: 4 }}>
        //           <Avatar
        //             classes={{ root: classes.userAvatar, noImageInitials: classes.userInitials }}
        //             image={latestComment.user.image.url}
        //             name={latestComment.user.name}
        //             theme={latestComment.user.theme}
        //           />
        //           <span>{latestComment.user.name}</span>
        //         </Flex>
        //         <span className={classes.tableRowFirstLine}>{comment}</span>
        //       </>
        //     </Tooltip>
        //     <span className={classes.leaveComment} onClick={(e) => handleLeaveComment(e, interest)}>
        //       {t('leave_a_comment')}
        //     </span>
        //   </Flex>
        // );
      }

      case 'match_created': {
        // TODO: Figure out whether date rendering happens on BE or FE
        // {interest.timestamp_match_created ? getPrettyDate(interest.timestamp_match_created).date : '-'}
        return '-';
      }

      case 'property_type': {
        // TODO: Figure out whether date rendering happens on BE or FE
        // return getBuildingTypesString(interest.building_types, buildingTypeOptions) || '-';
        return '-';
      }

      case 'requirement_all_comments': {
        const comments = interest.interest_column_values.requirement_all_comments
          .map((comment: any) => comment.date_prefixed_comment)
          .join('\n');

        return (
          <Flex direction="column" style={{ gap: 4 }}>
            {comments.length ? (
              <Tooltip
                classes={{ container: classes.tooltipContainer, tooltip: classes.commentsTooltip }}
                placement="top"
                title={<span dangerouslySetInnerHTML={{ __html: normalizedHTMLText(comments) }} />}
              >
                <span
                  className={classes.tableRowFirstLine}
                  dangerouslySetInnerHTML={{ __html: normalizedHTMLText(comments) }}
                />
              </Tooltip>
            ) : (
              '-'
            )}
          </Flex>
        );
      }

      case 'requirement_created': {
        // TODO: Figure out whether date rendering happens on BE or FE
        // {interest.timestamp_requirement_created ? getPrettyDate(interest.timestamp_requirement_created).date : '-'}
        return '-';
      }

      case 'requirement_latest_comment': {
        // TODO: Hook this up once BE defined
        return '-';
        // if (!interest.requirement_latest_comment) {
        //   return '-';
        // }

        // let comment = interest.requirement_latest_comment.date_prefixed_comment;

        // const canClip = comment.length > 158;

        // if (canClip) {
        //   comment = t('xtextx_ellipsis', { text: comment.substr(0, 155) });
        // }
        // return (
        //   <Flex direction="column" style={{ gap: 4 }}>
        //     <Tooltip
        //       classes={{ container: classes.tooltipContainer, tooltip: classes.commentsTooltip }}
        //       placement="top"
        //       title={<span dangerouslySetInnerHTML={{ __html: normalizedHTMLText(comment) }} />}
        //     >
        //       <>
        //         <Flex wrap="nowrap" style={{ gap: 4 }}>
        //           <Avatar
        //             classes={{ root: classes.userAvatar, noImageInitials: classes.userInitials }}
        //             image={interest.requirement_latest_comment.user.image.url}
        //             name={interest.requirement_latest_comment.user.name}
        //             theme={interest.requirement_latest_comment.user.theme}
        //           />
        //           <span>{interest.requirement_latest_comment.user.name}</span>
        //         </Flex>
        //         <span className={classes.tableRowFirstLine}>{comment}</span>
        //       </>
        //     </Tooltip>
        //   </Flex>
        // );
      }

      case 'requirement_updated': {
        return (
          <span className={classes.tableRowFirstLine}>
            {interest.interest_column_values.requirement_updated
              ? getPrettyDate(interest.interest_column_values.requirement_updated).date
              : '-'}
          </span>
        );
      }

      case 'source': {
        // TODO: We need organisation_name
        return '-';
        // if (!interest.is_external_match) {
        //   return <span className={classes.tableRowFirstLine}>{interest.interest_column_values.source || ''}</span>;
        // }

        // return (
        //   <Flex direction="column" style={{ gap: 4 }}>
        //     <span className={classes.sharedByText}>{t('shared_by')}</span>
        //     <span className={classes.tableRowFirstLine}>{interest.organisation_name}</span>
        //   </Flex>
        // );
      }

      case 'tenant_sector': {
        const tenantName = interest.interest_column_values.tenant_name || '';

        // TODO replace with correct display logic when available
        const isAddedToTenantsReport = tenantName.length > 8 && tenantName.length <= 13;
        const isProspectTenant = !isAddedToTenantsReport && tenantName.length > 13 && tenantName.length <= 21;
        const isOverwriteProspectInterest =
          !isAddedToTenantsReport && !isProspectTenant && tenantName.length > 21 && tenantName.length <= 28;

        return (
          <div className={classes.tenantContainer}>
            <div className={classes.tenantBody}>
              <div className={classes.tableRowFirstLine}>
                {tenantName}
                {isAddedToTenantsReport && (
                  <Tooltip
                    classes={{ container: classes.tenantIcon }}
                    placement="top"
                    title={t('added_to_tenants_availability_schedule')}
                  >
                    <CheckSquareIcon />
                  </Tooltip>
                )}
                {isProspectTenant && (
                  <Tooltip
                    classes={{ container: classes.tenantIcon }}
                    placement="top"
                    title={t('this_tenant_is_a_prospect')}
                  >
                    <UserCheck1Icon />
                  </Tooltip>
                )}
                {isOverwriteProspectInterest && (
                  <>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handlePopoverOpen(e);
                      }}
                    >
                      <RefreshIcon />
                    </IconButton>

                    <Popover
                      anchorEl={anchorEl}
                      open={popoverOpen}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      onClose={handlePopoverClose}
                      className={classes.popover}
                    >
                      <div className={classes.popoverContent}>
                        <h4>
                          {t('your_requirement_says')} <strong>{tenantName}</strong>
                        </h4>
                        <p>{t('do_you_wish_to_overwrite_your_prospect_interest')}</p>
                        <div className={classes.popoverButtonContainer}>
                          <Button
                            size={ButtonSize.small}
                            colour={ButtonColour.secondaryColour}
                            text={t('cancel')}
                            onClick={handlePopoverClose}
                            classes={{ root: classes.popoverButton }}
                          />
                          <Button
                            size={ButtonSize.small}
                            colour={ButtonColour.primary}
                            text={t('overwrite')}
                            onClick={() => setOverwriteTenantName(true)}
                            classes={{ root: classes.popoverButton }}
                          />
                        </div>
                      </div>
                    </Popover>
                  </>
                )}
              </div>
              <span className={classes.tableRowSecondLine}>
                {interest.interest_column_values.sectors || t('unknown_sector')}
              </span>
            </div>
          </div>
        );
      }

      case 'tenure': {
        const badges = [];

        if (interest.is_investment) {
          badges.push({ theme: BADGE_THEME.blue, text: t('investment_1') });
        } else {
          if (interest.for_sale) {
            badges.push({ theme: BADGE_THEME.blue, text: t('buy') });
          }
          if (interest.to_let) {
            badges.push({ theme: BADGE_THEME.purple, text: t('rent_1') });
          }
        }

        if (!badges.length) {
          return '-';
        }

        return (
          <Flex wrap="nowrap" style={{ gap: 4 }}>
            {badges.map((badge) => (
              <Badge key={badge.text} {...badge} />
            ))}
          </Flex>
        );
      }

      case 'viewing_date': {
        return (
          <span className={classes.tableRowFirstLine}>
            {interest.interest_column_values.viewing_date
              ? getPrettyDate(interest.interest_column_values.viewing_date).date
              : '-'}
          </span>
        );
      }

      // ---- Tooltip Cells ----

      case 'agent_contact_email':
      case 'assignee':
      case 'created_by':
      case 'current_tenancy':
      case 'matched_by':
      case 'sectors':
      case 'submarkets':
      case 'tenant_contact_email':
      case 'title':
        return renderTooltipCell(interest.interest_column_values[columnKey]);

      // ---- Text Cells ----

      // Anything else is assumed to render just text
      default:
        return renderTextCell(interest.interest_column_values[columnKey]);
    }
  };

  return (
    <div className={classes.root}>
      <TableBody
        bulkEnabled={bulkEnabled}
        bulkSelected={bulkEnabled ? bulkSelected : undefined}
        classes={{
          root: cx(classes.table, {
            // [classes.tablePresentMode]: presentMode, // TODO remove when chaging styling for present mode
            [classes.tableWithBulkActions]: !!bulkSelected.length,
          }),
          tableContainerRoot: classes.tableContainerRoot,
          tableBody: classes.tableBody,
          tableBodyCell: classes.tableCell,
        }}
        columns={tableColumns}
        items={items}
        noDataText={''}
        stateContent={listingPageStatesContent}
        stateKey={listingPageStatesKey}
        theme={TableTheme.Page}
        onRowClick={(item) => onOpenInterest(item.id)}
        renderBodyCell={renderTableBodyCell}
        setBulkSelected={bulkEnabled ? setBulkSelected : undefined}
      />

      {bulkEnabled && !!bulkSelected.length && (
        <div className={classes.tableFooter}>
          <BulkActionsBar
            actions={bulkActionsOptions}
            actionBtnText={t('update')}
            classes={{ root: classes.bulkActionsBar }}
            selectedCount={bulkSelected.length}
            size={ButtonSize.small}
            onClear={() => setBulkSelected([])}
          />
        </div>
      )}

      {/* Modals */}
      {interestToComment && (
        <AddCommentModal
          loading={disposalCreateMatchCommentMutation.isLoading}
          onClose={() => setInterestToComment(undefined)}
          onSave={(comment) => {
            disposalCreateMatchCommentMutation.mutate({
              body: {
                comments: comment,
              },
              disposalId: scheduleData.disposal_id,
              id: interestToComment.id,
            });
          }}
        />
      )}

      {/* Bulk action modals */}
      {bulkEnabled && idsToBulkArchive && (
        <InterestScheduleBulkArchiveModal
          loading={disposalBulkHideMatchesFromScheduleMutation.isLoading}
          onClose={() => setIdsToBulkArchive(undefined)}
          onSave={(reason, comment) => {
            disposalBulkHideMatchesFromScheduleMutation.mutate({
              id: scheduleData.disposal_id,
              body: {
                match_ids: idsToBulkArchive,
                reason: reason.toString(),
                comment,
              },
            });
          }}
        />
      )}
      {bulkEnabled && idsToBulkChangeStatus && (
        <InterestScheduleBulkChangeStatusModal
          interest={items.find((item) => item.id === idsToBulkChangeStatus[0])!}
          loading={disposalBulkChangeMatchesStatusMutation.isLoading}
          onClose={() => setIdsToBulkChangeStatus(undefined)}
          onSave={(status, comment) => {
            disposalBulkChangeMatchesStatusMutation.mutate({
              id: scheduleData.disposal_id,
              body: {
                match_ids: idsToBulkChangeStatus,
                status: status.toString(),
                comment,
              },
            });
          }}
        />
      )}
      {bulkEnabled && idsToBulkDiscount && (
        <InterestScheduleBulkDiscountModal
          loading={disposalBulkDiscountMatchesMutation.isLoading}
          onClose={() => setIdsToBulkDiscount(undefined)}
          onSave={(reason, comment) => {
            disposalBulkDiscountMatchesMutation.mutate({
              id: scheduleData.disposal_id,
              body: {
                match_ids: idsToBulkDiscount,
                reason: reason.toString(),
                comment,
              },
            });
          }}
        />
      )}
      {overwriteTenantName && (
        <AlertConfirmationModal
          text={t('changing_the_tenant_name_will_be_visible_to_your_collaborators_are_you_sure_you_want_to_proceed')}
          open={overwriteTenantName}
          header={t('overwrite_tenant_name')}
          onClose={() => {
            setOverwriteTenantName(false);
            handlePopoverClose();
          }}
          confirmButtonText={t('proceed')}
          onConfirm={() => {
            // TODO overwrite tenant name mutation
            setOverwriteTenantName(false);
            handlePopoverClose();
          }}
        />
      )}
    </div>
  );
};

export const ReportingInterestTable = withStyles(styles)(ReportingInterestTableComponent);
