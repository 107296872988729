import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

export default ({ spacing }: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    rootIconOnly: {
      paddingRight: 0,
      marginRight: spacing(3),
    },
    rootColumn: {
      display: 'inline-flex',
      flexDirection: 'column',
    },
    label: {
      color: '#1D2433', //TODO replace after new palette creating
      fontSize: 14,
      lineHeight: 'inherit',
      userSelect: 'none',
    },
    labelError: {
      color: '#B42318', //TODO replace after new palette creating
    },
    checkbox: {},
    checkboxChecked: {},
    checkboxPrimary: {},
    icon: {
      height: 18,
      width: 18,
      backgroundColor: colors.white.lightest,
      border: `1px solid ${colors.grey.light}`,
      borderRadius: 4,
    },
    iconChecked: {
      color: colors.kato.navy[950],
      fontSize: 18,
    },
    iconError: {
      borderColor: '#B42318', //TODO replace after new palette creating
    },
  });
