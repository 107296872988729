import { FC, useCallback, useMemo, useState } from 'react';

import { updateDisposalShowCache, useDisposalUpdateMutation } from '@agency/apis/disposals';
import {
  addInterestItemsIntoHash,
  supportedGroupedBy,
  supportedMatchesType,
  useDisposalReporting,
} from '@agency/modules/disposals/pages/disposal-manage/pages/disposal-reporting/DisposalReporting.context';
import { t } from '@core/i18n';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { DisposalInterestIndexItem, useDisposalInterestSentIndexQuery } from '@shared/apis/disposals';
import { Button } from '@shared/components/button-new';
import { FilterButton, FilterButtonTypes } from '@shared/components/buttons/filter-button-new';
import { CollapsingSearch } from '@shared/components/collapsing-search';
import { ColumnPicker } from '@shared/components/column-picker-new';
import {
  InterestScheduleFilterModal,
  InterestScheduleFilterParams,
} from '@shared/components/interest-schedule/interest-schedule-filter-modal';
import {
  INTEREST_SCHEDULE_ORDER_OPTIONS,
  ReportingInterestColumnKeys,
  ReportingInterestSelectableColumnKeys,
  ReportingInterestTable,
} from '@shared/components/interest-schedule/reporting-interest-table';
import { SingleSelect } from '@shared/components/select-new/single-select';
import { TableColumn } from '@shared/components/table-new';
import { useAgencyConstants } from '@shared/contexts/ConfigContext';
import { useInterestScheduleConfig, useInterestScheduleMatches } from '@shared/hooks/interestSchedule.hooks';
import { PageViewState, UrlViewState, usePageState } from '@shared/hooks/viewState';
import { ColumnsIcon } from '@shared/icons/columns';
import { SearchIcon } from '@shared/icons/search';
import { SwitchVerticalIcon } from '@shared/icons/switch-vertical';
import {
  ListingPageStates,
  useListingPageStatesKey,
} from '@shared/skeletons/listing-page-skeleton/components/listing-page-states';
import { ButtonSize } from '@shared/types/common/button';
import { InputSize } from '@shared/types/common/input';
import {
  ALL_SCHEDULE_STATUS_GROUP,
  InterestScheduleData,
  ScheduleStatusGroups,
  ScheduleStatusGroupsWithAll,
} from '@shared/types/common/match';
import { getQueryValueString, reduceStringRecordToArray } from '@shared/utils/common';
import {
  filterInterestByTypeFilter,
  getInterestScheduleOrderByConfig,
  getInterestScheduleTableColumn,
  getMatchSearchHash,
  GroupdByFilter,
  InterestTypeFilter,
} from '@shared/utils/interest-schedule';

import { styles } from './ReportingOutboxSpace.styles';
import { ReportingSpaceTableTabs, ReportingSpaceTableTabsProps } from '../reporting-space-table-tabs';

const fixedColumns: TableColumn<any, ReportingInterestColumnKeys>[] = []; //TODO finalize fixed columns
const fixedColumnKeys = fixedColumns.map((column) => column.field);

// --- URL State ---

export interface OutboxUrlState extends UrlViewState {
  outbox_group_by: GroupdByFilter;
  outbox_interest_type: InterestTypeFilter;
  outbox_order: string;
  outbox_search: string;
  outbox_show_discounted: boolean;
  outbox_show_hidden: boolean;
  outbox_tab: ScheduleStatusGroupsWithAll | typeof ALL_SCHEDULE_STATUS_GROUP;
}

export const defaultOutboxUrlState: OutboxUrlState = {
  outbox_group_by: GroupdByFilter.Status,
  outbox_interest_type: InterestTypeFilter.All,
  outbox_order: 'created_at-desc',
  outbox_search: '',
  outbox_show_discounted: true,
  outbox_show_hidden: false,
  outbox_tab: ALL_SCHEDULE_STATUS_GROUP,
};

export const getOutboxUrlState = (queries: Query) => {
  const result: OutboxUrlState = {
    ...defaultOutboxUrlState,
    outbox_search: getQueryValueString('outbox_search', queries),
  };

  const groupByValue = getQueryValueString('outbox_group_by', queries) as GroupdByFilter;
  if (groupByValue && supportedGroupedBy.includes(groupByValue)) {
    result.outbox_group_by = groupByValue;
  }

  const interestTypeValue = getQueryValueString('outbox_interest_type', queries) as InterestTypeFilter;
  if (interestTypeValue && supportedMatchesType.includes(interestTypeValue)) {
    result.outbox_interest_type = interestTypeValue;
  }

  const orderValue = getQueryValueString('outbox_order', queries);
  if (orderValue) {
    result.outbox_order = orderValue;
  }

  if ('outbox_show_discounted' in queries) {
    result.outbox_show_discounted = !!queries.outbox_show_discounted;
  }

  if ('outbox_show_hidden' in queries) {
    result.outbox_show_hidden = !!queries.outbox_show_hidden;
  }

  return result;
};

// --- Page State ---

export const getInitialPageState = () => ({
  columns: false,
  filters: false,
  search_open: false,
});

type PageState = PageViewState<ReturnType<typeof getInitialPageState>>;

export interface ReportingOutboxSpaceProps extends WithStyles<typeof styles> {
  id: string;
  scheduleData: InterestScheduleData;
}

const ReportingOutboxSpaceComponent: FC<ReportingOutboxSpaceProps> = ({ classes, id, scheduleData }) => {
  const { constants } = useAgencyConstants();
  const { interestHash, outboxUrlState, setActiveInterestId, setInterestHash, updateUrlState } = useDisposalReporting();

  const { pageState, updatePageState } = usePageState<PageState>(() => getInitialPageState());
  const [activeTab, setActiveTab] = useState<ScheduleStatusGroupsWithAll>(
    outboxUrlState.outbox_tab || ALL_SCHEDULE_STATUS_GROUP
  );

  const { scheduleConfig } = useInterestScheduleConfig(scheduleData.investment === 1);

  // ---- Queries ----

  const disposalInterestSentIndexPayload = useMemo(
    () => ({
      query: {
        include_hidden: outboxUrlState.outbox_show_hidden ? 'true' : undefined,
      },
      id: id,
    }),
    [id, outboxUrlState.outbox_show_hidden]
  );

  const disposalInterestSentIndexQuery = useDisposalInterestSentIndexQuery(disposalInterestSentIndexPayload, {
    onSuccess: (data) => {
      setInterestHash((prev) => addInterestItemsIntoHash(data.data, prev));
    },
  });

  const allInterestItemIds = useMemo(
    () => (disposalInterestSentIndexQuery.data?.data ?? []).map((interest) => interest.id),
    [disposalInterestSentIndexQuery.data]
  );

  // ---- Mutations ----

  // TODO: We need to use a different mutation - agency vs. landlord
  const disposalUpdateMutation = useDisposalUpdateMutation({
    onSuccess: (data) => {
      updateDisposalShowCache(scheduleData.disposal_id.toString(), () => data);
    },
  });

  const filterParams = useMemo(() => {
    // console.log('filterParams memo');
    const result: InterestScheduleFilterParams = {
      groupBy: outboxUrlState.outbox_group_by,
      includeArchived: outboxUrlState.outbox_show_hidden,
      includeDiscounted: outboxUrlState.outbox_show_discounted,
      interestType: outboxUrlState.outbox_interest_type,
    };

    return result;
  }, [
    outboxUrlState.outbox_group_by,
    outboxUrlState.outbox_interest_type,
    outboxUrlState.outbox_show_discounted,
    outboxUrlState.outbox_show_hidden,
  ]);

  const orderByDefaultConfig = useMemo(
    () => getInterestScheduleOrderByConfig(outboxUrlState.outbox_order),
    [outboxUrlState.outbox_order]
  );

  const interestFilter = useCallback(
    (interestId) => {
      const interest = interestHash[interestId];

      return (
        !!interest &&
        // Discounted visible
        (!interest.interest_schedule_discounted_reason || filterParams.includeDiscounted) &&
        // Archived visible
        (!interest.interest_schedule_hidden || filterParams.includeArchived) &&
        // Interest type visible
        filterInterestByTypeFilter(interest, filterParams.interestType, scheduleConfig)
      );
    },
    [filterParams, interestHash, scheduleConfig]
  );

  const trimmedSearch = useMemo(
    () => outboxUrlState.outbox_search.trim().toLowerCase(),
    [outboxUrlState.outbox_search]
  );

  const interestSearchFilter = useCallback(
    (interestId) => {
      const interest = interestHash[interestId];

      return !!interest && getMatchSearchHash(interest).includes(trimmedSearch);
    },
    [interestHash, trimmedSearch]
  );

  const { interestItems, interestCounts } = useInterestScheduleMatches({
    includeDiscounted: filterParams.includeDiscounted,
    interestFilter,
    interestHash,
    interestIds: allInterestItemIds,
    interestSearchFilter,
    matchesGroupBy: filterParams.groupBy,
    orderBy: orderByDefaultConfig,
    scheduleConfig,
    search: trimmedSearch,
    tab: outboxUrlState.outbox_tab,
  });

  const visibleItems = useMemo(() => {
    return interestItems.map((match) => ({ ...match, hidden: match.interest_schedule_hidden }));
  }, [interestItems]);

  const columns: TableColumn<any, ReportingInterestSelectableColumnKeys>[] = useMemo(
    () =>
      reduceStringRecordToArray(constants.interestScheduleAllColumns, (result, value, key) => {
        if (!fixedColumnKeys.includes(key)) {
          result.push(getInterestScheduleTableColumn(key as ReportingInterestSelectableColumnKeys, value));
        }

        return result;
      }),
    [constants.interestScheduleAllColumns]
  );

  const selectedColumnsCsv = useMemo(() => scheduleData.preferred_columns.join(','), [scheduleData.preferred_columns]);

  const listingPageStatesKey = useListingPageStatesKey({
    error: disposalInterestSentIndexQuery.isError,
    loading: disposalInterestSentIndexQuery.isFetching,
    noData: !allInterestItemIds.length,
    noResults: !visibleItems.length,
    searchingOnAll: false,
  });

  const tableTabs = useMemo(() => {
    //TODO implement Outbox status group with all
    const tabsLeft: ReportingSpaceTableTabsProps<ScheduleStatusGroupsWithAll>['tabsLeft'] = [];

    scheduleConfig.scheduleGroups.forEach((groupKey) => {
      const count = interestCounts[groupKey] || 0;
      // const text = scheduleConfig.groupConfig[groupKey].title;
      const value = groupKey as ScheduleStatusGroupsWithAll;

      //TODO implement 'accepted'/'discounted' schedule status tab ~ using available/discounted as placeholder
      if (groupKey === 'discounted') {
        tabsLeft.push({ count: 0, text: 'Accepted', value: ScheduleStatusGroups.Available });
        tabsLeft.push({ count, text: 'Declined', value });
      }
    });

    tabsLeft.unshift({
      count: interestCounts[ALL_SCHEDULE_STATUS_GROUP] || 0,
      text: t('all'),
      value: ALL_SCHEDULE_STATUS_GROUP,
    });

    return { left: tabsLeft, right: [] };
  }, [interestCounts, scheduleConfig]);

  const listingPageStatesContent = useMemo(
    () => (
      <ListingPageStates
        errorConfig={{
          header: 'Oh no, it looks like we had an issue loading outbox interests.',
          text: t('were_really_sorry_about_that_we_have_been_notified_about_this_issue'),
          intercomMessage: t(
            'there_is_an_error_on_the_xnamex_page_i_cant_seem_to_load_any_data_do_you_know_how_long_until_the_issue_will_be_resolved_question',
            { name: t('outbox') }
          ),
        }}
        noDataConfig={{
          header: t('your_outbox_is_currently_empty'),
          text: t(
            'search_your_organisations_internal_requirements_or_the_marketplace_to_find_suitable_matching_requirements'
          ),
          icon: <SearchIcon />,
        }}
        noResultsConfig={{
          header: t('your_outbox_is_currently_empty'),
          text: t(
            'search_your_organisations_internal_requirements_or_the_marketplace_to_find_suitable_matching_requirements'
          ),
          search: outboxUrlState.outbox_search || '',
        }}
        noResultsAllConfig={{
          header: '',
          text: '',
        }}
        stateKey={listingPageStatesKey}
      />
    ),
    [listingPageStatesKey, outboxUrlState.outbox_search]
  );

  const onRenderTableBodyCell = useCallback((field: string, interest: DisposalInterestIndexItem) => {
    // console.log('Interest Status Log:', [interest.status]);
    const columnKey = field as ReportingInterestColumnKeys;

    switch (columnKey) {
      //TODO correct status mapping when response_status BE is avaliable
      // case 'response_status': {
      //   let badge;

      //   switch (interest.interest_schedule_status) {
      //     case 1:
      //     case 2:
      //     case 3:
      //       badge = { theme: BadgeTheme.green, text: t('accepted') };
      //       break;
      //     case 4:
      //     case 5:
      //     case 6:
      //       badge = { theme: BadgeTheme.danger, text: t('declined') };
      //       break;
      //     default:
      //       badge = { theme: BadgeTheme.grey, text: t('pending') };
      //       break;
      //   }

      //   return (
      //     <div className={classes.badges}>
      //       <Badge classes={{ root: classes.badge }} key={badge.text} {...badge} content={badge.text} />
      //     </div>
      //   );
      // }

      default:
        return null;
    }
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.headerControls}>
          <Button
            size={ButtonSize.small}
            startIcon={<ColumnsIcon />}
            text={t('columns')}
            onClick={() => updatePageState({ columns: true })}
          />
          <CollapsingSearch
            classes={{ searchBtn: classes.searchBtn, searchFieldInput: classes.searchField }}
            inputProps={{ size: InputSize.small }}
            placeholder={t('xtextx_ellipsis', { text: t('search') })}
            search={outboxUrlState.outbox_search}
            searchOpen={pageState.search_open}
            onSearchChange={(search) => updateUrlState({ outbox_search: search })}
            setSearchOpen={(open) => updatePageState({ search_open: open })}
          />
        </div>
        <div className={classes.headerControls}>
          <FilterButton count={0} type={FilterButtonTypes.filters} onClick={() => updatePageState({ filters: true })} />
          <SingleSelect
            icon={<SwitchVerticalIcon />}
            options={INTEREST_SCHEDULE_ORDER_OPTIONS}
            placeholder={t('select')}
            value={outboxUrlState.outbox_order}
            onChange={(value) => updateUrlState({ outbox_order: value.toString() })}
          />
        </div>
      </div>
      <div className={classes.content}>
        <ReportingSpaceTableTabs
          activeTab={activeTab}
          classes={{ root: classes.tabs }}
          tabsLeft={tableTabs.left}
          tabsRight={tableTabs.right}
          onChangeTab={(tab) => {
            setActiveTab(tab as ScheduleStatusGroupsWithAll);
            updateUrlState({ outbox_tab: tab as ScheduleStatusGroupsWithAll });
          }}
        />
        <div className={classes.body}>
          <ReportingInterestTable
            bulkEnabled
            classes={{ tableBody: classes.tableBody }}
            columns={columns}
            fixedColumns={fixedColumns}
            items={visibleItems}
            listingPageStatesContent={listingPageStatesContent}
            listingPageStatesKey={listingPageStatesKey}
            scheduleData={scheduleData}
            selectedColumns={scheduleData.preferred_columns}
            onOpenInterest={(interestId) => {
              setActiveInterestId(interestId);
              updateUrlState({ view_interest: interestId.toString() });
            }}
            onRenderTableBodyCell={onRenderTableBodyCell}
          />
        </div>
      </div>
      {/* Modals */}
      <InterestScheduleFilterModal
        open={pageState.filters}
        params={filterParams}
        interestTypeOptions={[{ label: t('all_requirements'), id: InterestTypeFilter.All }]}
        onClose={() => updatePageState({ filters: false })}
        onApplyFilters={(params) => {
          updatePageState({ filters: false });
          updateUrlState({
            group_by: params.groupBy,
            matches_type: params.interestType,
            show_discounted: params.includeDiscounted,
            show_hidden: params.includeArchived,
          });
        }}
      />
      <ColumnPicker
        columns={columns}
        fixedColumns={fixedColumns}
        headingProps={{ heading: t('customise_table') }}
        open={pageState.columns}
        selectedColumns={selectedColumnsCsv}
        withFooter={false}
        onClose={() => updatePageState({ columns: false })}
        onSave={(columns: string) => {
          disposalUpdateMutation.mutate({
            id: scheduleData.disposal_id.toString(),
            body: { interest_schedule_preferred_columns: columns.split(',') }, //TODO replace to outbox related column when backend will be ready
          });

          updatePageState({ columns: false });
        }}
      />
    </div>
  );
};

export const ReportingOutboxSpace = withStyles(styles)(ReportingOutboxSpaceComponent);
