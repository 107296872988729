import { createStyles, Theme } from '@material-ui/core/styles';

import { TableTheme } from '@shared/components/table/Table';
import { colors } from '@shared/theme/colours';

const tableBodyPadding = '16px 10px';
const tableHeadingPadding = '8px 10px';

export default function styles({ spacing, palette }: Theme) {
  return createStyles({
    root: {
      boxShadow: '0 2px 3px 0 rgb(0 18 52 / 10%)',
      flexGrow: 1,
      overflowX: 'auto',
      position: 'relative',
      maxHeight: '100%',
    },
    rootFullHeight: {
      height: '100%',
    },
    tableViewWrapper: {},
    tableViewContainer: {},
    headerCell: {},
    headerCellText: {},
    bodyCell: {
      padding: '10px 16px',
    },
    bodyRow: {},
    noDataCell: {},
    loading: {},
    [TableTheme.Page]: {
      '& $root': {
        border: `1px solid ${colors.grey.lightest}`,
        borderRadius: 4,
        maxHeight: '100%',
        height: '100%',
      },
      '& $tableViewContainer': {
        boxShadow: 'none',
        border: `1px solid ${colors.grey.light}`,
      },
      '& $headerCell': {
        padding: tableHeadingPadding,
        backgroundColor: `#F2F4F7 !important`, // TODO change after creating new palette
      },
      '& $headerCellText': {
        fontSize: 14,
        color: colors.black.dark,
      },
      '& $bodyCell': {
        fontSize: 14,
        padding: tableBodyPadding,
      },

      '& $noDataCell': {
        padding: spacing(5, 4),
        fontSize: 14,
      },
    },

    noData: {
      padding: spacing(4, 6),
      minHeight: 300,
      background: colors.white.lightest,
      flexGrow: 1,
      borderRadius: 4,
    },
    noDataIconContainer: {
      width: 64,
      height: 64,
      background: colors.grey.lighter,
      borderRadius: 8,
    },
    noDataIcon: {
      lineHeight: 'initial',
      display: 'inherit',

      '& > svg': {
        fontSize: 26,
        color: colors.blue.dark,
      },
    },
    noDataHeader: {
      display: 'inline-block',
      marginTop: spacing(4),
      fontSize: 16,
      textAlign: 'center',
      fontWeight: 500,
    },
    noDataText: {
      display: 'inline-block',
      color: palette.grey[500],
      marginTop: spacing(1),
      fontSize: 14,
    },
  });
}
