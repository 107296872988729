import { Card } from '@material-ui/core';
import cx from 'classnames';
import { FC, ReactElement } from 'react';

import { t } from '@core/i18n';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Flex } from '@shared/components/flex';
import { RadioGroup, RadioGroupOption } from '@shared/components/radio-group';

import { styles } from './ExportCardContent.styles';

export enum ColumnRadioType {
  Selected,
  All,
}

export const columnRadios: RadioGroupOption<ColumnRadioType>[] = [
  {
    id: ColumnRadioType.Selected,
    label: t('selected_columns'),
  },
  {
    id: ColumnRadioType.All,
    label: t('all_columns'),
  },
];

export interface ExportCardContentProps extends WithStyles<typeof styles> {
  actions?: ReactElement;
  cardImg?: any;
  exportXlsHead: string;
  exportXlsSecondText?: string;
  exportXlsText?: string;
  isChosen: boolean;
  selectedColumnRadio?: ColumnRadioType;
  withRadios?: boolean;
  editColumnsOnClick?: () => void;
  onColumnRadioChange?: (id: ColumnRadioType) => void;
  onSelect?: () => void;
}

const ExportCardContentComponent: FC<ExportCardContentProps> = ({
  actions,
  cardImg,
  classes,
  exportXlsHead,
  exportXlsSecondText,
  exportXlsText,
  isChosen,
  withRadios = false,
  editColumnsOnClick,
  selectedColumnRadio,
  onColumnRadioChange,
  onSelect,
}) => {
  return (
    <Card
      onClick={onSelect}
      classes={{
        root: cx(classes.drawerExport, { [classes.drawerExportCardActive]: isChosen }),
      }}
    >
      <Flex alignItems="center" justifyContent="flex-start" wrap="nowrap" classes={{ root: classes.exportCardContent }}>
        {cardImg}
        <Flex direction="column" className={classes.exportXlsContent}>
          <span className={classes.exportXlsHead}>{exportXlsHead}</span>
          <span className={classes.exportXlsText}>{exportXlsText}</span>

          {withRadios && (
            <RadioGroup
              value={selectedColumnRadio}
              options={columnRadios}
              onChange={(id) => {
                if (onColumnRadioChange) {
                  onColumnRadioChange(id as ColumnRadioType);
                }
              }}
              classes={{
                radios: classes.radios,
                formControl: classes.radiosControl,
              }}
            />
          )}
          {editColumnsOnClick && exportXlsSecondText && (
            <span className={classes.exportXlsBlueText} onClick={editColumnsOnClick}>
              {exportXlsSecondText}
            </span>
          )}
          {actions}
        </Flex>
      </Flex>
    </Card>
  );
};

export const ExportCardContent = withStyles(styles)(ExportCardContentComponent);
