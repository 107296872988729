import { createStyles, Theme } from '@material-ui/core/styles';

import { TableTheme } from '@shared/components/table';
import { colors } from '@shared/theme/colours';

export default function styles({ spacing, transitions }: Theme) {
  return createStyles({
    root: {
      border: '1px solid #EAECF0', //TODO change after creating new palette
      borderRadius: 6,
    },
    rootFullHeight: {
      height: '100%',
    },
    bodyRoot: {
      backgroundColor: colors.white.lightest,
      overflow: 'auto',
    },
    bodyCell: {
      borderBottom: '1px solid #EAECF0', //TODO change after creating new palette
      fontWeight: 400,
      fontSize: 12,
      overflowX: 'hidden',
      padding: spacing(1, 4),
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    bodyCellSticky: {
      left: 0,
      position: 'sticky',
      zIndex: 2,
    },
    bodyCellTextClass: {
      fontSize: 'inherit',
      fontWeight: 'inherit',
    },
    cell: {},
    emptyCell: {},
    emptyContent: {},
    footerRoot: {
      backgroundColor: colors.white.lightest,
      bottom: 0,
      position: 'sticky',
    },
    footerCell: {
      color: colors.black.darker,
      borderTop: '1px solid #EAECF0', // TODO: change after creating new palette
      fontSize: 12,
      padding: spacing(1, 4),
    },
    headerRoot: {},
    headerCell: {
      cursor: 'pointer',
    },
    headerCellDisabled: {
      cursor: 'default',
    },
    headerCellActive: {
      color: colors.black.darkest,
    },
    headerCellSticky: {
      left: 0,
      position: 'sticky',
      zIndex: 3,
    },
    headerCellTextWrapper: {
      fontWeight: 'inherit',
      fontSize: 'inherit',
    },
    headerCellText: {
      fontWeight: 500,
      fontSize: 12,
    },
    loading: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
    menuItem: {
      '&[aria-selected=true]': {
        fontWeight: 500,
      },
    },
    sortActiveIcon: {
      color: colors.black.darkest,
    },
    sortIcon: {
      color: colors.grey.main,
      margin: spacing(-2, 0),
      fontSize: 22,
    },
    sortIcons: {
      display: 'inline-flex',
    },
    row: {
      '&:hover': {
        cursor: 'pointer',
        transition: `all ${transitions.duration.standard}s ${transitions.easing.easeInOut}`,
        backgroundColor: '#F7F8FA !important', // TODO change after creating new palette
      },
    },
    rowHighlighted: {
      background: colors.kato.navy[50],

      '&:hover': {
        background: `${colors.kato.navy[50]} !important`,
      },
    },
    rowHidden: {
      background: '#f6f7f9 !important',
      opacity: 0.65, //quick solution - circle back - steviss
    },
    table: {},
    wrapper: {
      tableLayout: 'fixed',
    },

    [TableTheme.Page]: {
      '& $headerCell': {
        padding: spacing(2),
        backgroundColor: `#F2F4F7`, // TODO change after creating new palette
      },
      '& $headerCellText': {
        fontSize: 14,
        color: '#515D70', // TODO change after creating new palette
        fontWeight: 600,
      },
      '& $bodyRoot': {
        backgroundColor: colors.white.lightest,
      },
      '& $row': {
        backgroundColor: colors.white.lightest,

        '&:hover': {
          backgroundColor: '#F7F8FA !important', // TODO change after creating new palette
        },
      },

      '& $rowHighlighted': {
        background: colors.kato.navy[50],

        '&:hover': {
          background: `${colors.kato.navy[50]} !important`,
        },
      },
      '& $bodyCell': {
        backgroundColor: 'inherit',
        fontSize: 14,
        padding: spacing(2.5, 2),
      },
      '& $footerCell': {
        fontSize: 14,
        fontWeight: 600,
        padding: spacing(2.5, 2),
      },
    },
  });
}
