import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    drawerExport: {
      border: `1px solid ${colors.grey.lightish}`,
      padding: spacing(4, 7),
      marginBottom: spacing(7),
      cursor: 'pointer',
      minHeight: 110,
      display: 'flex',

      '&:hover': {
        border: `1px solid ${colors.blue.dark}`,
      },
    },
    drawerExportCardActive: {
      border: `1px solid ${colors.blue.dark}`,
    },
    exportCardContent: {},
    exportXlsContent: {
      marginLeft: spacing(4),
    },
    exportXlsHead: {
      fontSize: 16,
      fontWeight: 500,
    },
    exportXlsText: {
      color: colors.grey.main,
    },
    exportXlsBlueText: {
      color: colors.blue.dark,

      '&:hover': {
        cursor: 'pointer',
        color: colors.blue.main,
      },
    },
    radios: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
    radiosControl: {
      marginTop: spacing(2),
    },
  });
