import { FC, ReactNode } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';

import { DraggableItem } from './components/draggable-item';
import { reorder } from './Draggable.utils';
import { styles } from './DraggableList.styles';

export interface DraggableListProps extends WithStyles<typeof styles> {
  items: any[];
  disabled?: boolean;
  onDragEnd: (items: any[]) => void;
  renderItem: (item: any, idx: number, itemsCount: number) => JSX.Element;
}

export type DraggableListItemsConfig<T extends string = string> = Record<
  T,
  {
    label: string;
    content: ReactNode;
  }
>;

const DraggableListComponent: FC<DraggableListProps> = ({
  classes,
  disabled = false,
  items,
  onDragEnd,
  renderItem,
}) => {
  const handleDragEnd = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(items, source.index, destination.index);

    onDragEnd(newItems);
  };

  if (disabled) {
    return (
      <div className={classes.root}>
        {items.map((item, index) => (
          <div className={classes.disabledItem} key={index}>
            {renderItem(item, index, items.length)}
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable-list">
          {(provided) => (
            <div ref={provided.innerRef} className={classes.list} {...provided.droppableProps}>
              {items.map((item, index) => (
                <DraggableItem
                  classes={{ item: classes.item }}
                  index={index}
                  item={item}
                  itemCount={items.length}
                  key={item.id}
                  renderItem={renderItem}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export const DraggableList = withStyles(styles)(DraggableListComponent);
