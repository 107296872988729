import { SvgIcon } from '@material-ui/core';
import { FC } from 'react';

import { withStyles, WithStyles } from '@core/theme/utils/with-styles';

import { styles } from './Back.styles';

export type BackIconProps = WithStyles<typeof styles>;

const BackIconComponent: FC<BackIconProps> = ({ classes }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="11"
      viewBox="0 0 14 11"
      fill="none"
      classes={{ root: classes.root }}
    >
      <path
        d="M1 4.00001H10C11.6569 4.00001 13 5.34316 13 7.00001C13 8.65686 11.6569 10 10 10H7M1 4.00001L3.66667 1.33334M1 4.00001L3.66667 6.66668"
        stroke="#828D9E"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export const BackIcon = withStyles(styles)(BackIconComponent);
