import { DisposalInterestIndexItem } from '@shared/apis/disposals';
import { TableColumn } from '@shared/components/table-new';
import { ScheduleConfig } from '@shared/types/common/match';
import {
  ALL_SCHEDULE_STATUS_GROUP,
  ScheduleStatusGroups,
  ScheduleStatusGroupsWithAll,
} from '@shared/types/common/match';

// ---- Types ----

export enum InterestTypeFilter {
  All = 'all',
  JointAgents = 'joint_agents',
  NotShared = 'not_shared',
  OurRequirements = 'our',
  SocietyRequirements = 'society_requirements',
  Shared = 'shared',
  Starred = 'starred',
}

export enum GroupdByFilter {
  Starred = 'starred',
  Status = 'status',
}

export interface OrderByConfig {
  key:
    | 'timestamp_requirement_date'
    | 'timestamp_interest_schedule_updated_at'
    | 'timestamp_match_created'
    | 'viewings_completed';
  reverse: boolean;
}

// ---- Local Utils ----

const isMatchShared = (interest: DisposalInterestIndexItem, scheduleConfig: ScheduleConfig): boolean => {
  const groupKey = scheduleConfig.statusGroupsMap[interest.interest_schedule_status];

  return (
    // Shared requirement
    interest.shared_requirement &&
    // Not hidden
    !interest.interest_schedule_hidden &&
    // Is on schedule
    scheduleConfig.scheduleGroupsActive.indexOf(groupKey) !== -1
  );
};

// ---- Exported Utils ----

export const filterMatchByStatusGroup = (
  interest: DisposalInterestIndexItem,
  statusGroup: ScheduleStatusGroupsWithAll,
  scheduleConfig: ScheduleConfig
): boolean => {
  const groupKey = scheduleConfig.statusGroupsMap[interest.interest_schedule_status];

  // Check whether we can add the match under the current tab
  switch (statusGroup) {
    case ALL_SCHEDULE_STATUS_GROUP:
      // console.log('filterMatch. All', scheduleConfig.scheduleGroupsActive.indexOf(groupKey) !== -1, groupKey);
      return scheduleConfig.scheduleGroupsActive.indexOf(groupKey) !== -1 || typeof groupKey === 'undefined';

    case ScheduleStatusGroups.Discounted:
      // console.log('filterMatch. Discounted', !!match.discounted_reason, groupKey);
      return !!interest.interest_schedule_discounted_reason;

    default:
      // console.log('filterMatch. group', statusGroup === groupKey, statusGroup, groupKey);
      return statusGroup === groupKey;
  }
};

export const filterInterestByTypeFilter = (
  interest: DisposalInterestIndexItem,
  typeFilter: InterestTypeFilter,
  scheduleConfig: ScheduleConfig
): boolean => {
  switch (typeFilter) {
    case InterestTypeFilter.All:
      return true;

    case InterestTypeFilter.JointAgents:
      return interest.is_external_match;

    case InterestTypeFilter.NotShared:
      return !interest.is_external_match && !isMatchShared(interest, scheduleConfig);

    case InterestTypeFilter.OurRequirements:
      return !interest.is_society_match && !interest.is_external_match;

    case InterestTypeFilter.Shared:
      return !interest.is_external_match && isMatchShared(interest, scheduleConfig);

    case InterestTypeFilter.SocietyRequirements:
      return interest.is_society_match;

    case InterestTypeFilter.Starred:
      return interest.fav;
  }
};

export const getInterestScheduleOrderByConfig = (orderBy: string): OrderByConfig => {
  switch (orderBy) {
    case 'date_of_requirement-desc':
      return { key: 'timestamp_requirement_date', reverse: true };

    case 'updated_at-desc':
      return { key: 'timestamp_interest_schedule_updated_at', reverse: true };
    case 'viewings_complete-desc':
      return { key: 'viewings_completed', reverse: true };

    case 'created_at-desc':
    default:
      return { key: 'timestamp_match_created', reverse: true };
  }
};

export const getInterestScheduleTableColumn = <TField extends string = string>(
  field: TField,
  name: string
): TableColumn<any, TField> => {
  const column: TableColumn<any, TField> = { field, name };

  switch (field) {
    case 'fav':
      column.width = 40;
      break;

    case 'match_demise':
    case 'submarkets':
    case 'title':
      column.width = 200;
      break;

    case 'status':
      column.width = 220;
      break;

    case 'all_match_comments':
      column.width = 240;
      break;

    case 'latest_comment':
      column.width = 400;
      break;

    default:
      // TODO: Set default column width
      // column.width = ;
      break;
  }

  return column;
};

export const getMatchSearchHash = (interest: DisposalInterestIndexItem): string => {
  const items: string[] = [
    interest.interest_column_values.agent_company,
    interest.interest_column_values.agent_contact_email,
    interest.interest_column_values.agent_contact_mobile,
    interest.interest_column_values.agent_contact_telephone,
    interest.interest_column_values.agent_contact,
    interest.interest_column_values.assignee,
    interest.interest_column_values.investor,
    interest.interest_column_values.matched_by,
    interest.interest_column_values.sectors,
    interest.interest_column_values.source,
    interest.interest_column_values.status,
    interest.interest_column_values.submarkets,
    interest.interest_column_values.team,
    interest.interest_column_values.tenant_contact_email,
    interest.interest_column_values.tenant_contact_mobile,
    interest.interest_column_values.tenant_contact_telephone,
    interest.interest_column_values.tenant_contact,
    interest.interest_column_values.tenant_name,
  ];

  return items
    .filter((item) => item)
    .join(' ')
    .toLowerCase();
};
