import { Feature, MultiPolygon, Polygon } from '@turf/turf';

import { LettingTransactionsListItem } from '@shared/apis/transactionsLettingsApi.types';
import { SaleTransactionsListItem } from '@shared/apis/transactionsSalesApi.types';
import { ReportDataItem } from '@shared/components/dashboard-report';
import { IComment } from '@shared/models/comment';
import { Company } from '@shared/models/company/company';
import { Contact } from '@shared/models/contact/contact';
import {
  DisposalCompanyKeys,
  DisposalContactKeys,
  DisposalInvestmentMarketStatus,
  DisposalShowFeatureFlags,
  IDisposalListItem,
  IDisposalListTableItem,
  MarketingEntries,
  MarketingEntryData,
  MarketingEntryPayload,
} from '@shared/models/disposal/disposal';
import { IDisposalManageContent, IDisposalManageSocietyAdvert } from '@shared/models/disposal/disposalDetails';
import { IFile } from '@shared/models/file';
import { IImageDetails } from '@shared/models/IImages';
import { InsightsViewingTask } from '@shared/models/insightsViewing/insightsViewingTask';
import { IUser } from '@shared/models/IUser';
import { SavedViewType } from '@shared/models/saved_view/savedView';
import { AvailableSpace } from '@shared/models/space';
import { ITeam } from '@shared/models/team/team';
import { ListUserItem } from '@shared/models/user';
import { MapPosition, StreetView } from '@shared/types/common/map';
import { InterestScheduleData, ScheduleStatusGroups } from '@shared/types/common/match';
import { PropertyItem } from '@shared/types/common/property';
import { Languages } from '@shared/types/common/region';
import { SocietySegments } from '@shared/types/common/segment';
import { IndexMeta, IndexResponse } from '@shared/types/services';

// ---- Disposal, List - Item ----

// TODO

// ---- Disposal, List - Queries ----

export enum DisposalsRequestSegments {
  all = '',
  dev_pipeline = '/dev_pipeline',
  investment = '/investment',
  our_instructions = '/instruction_availability',
  pitching = '/pitching',
  tracked_availability = '/market_availability',
}

export const disposalsListFilterGroupKeys = [
  ['record_owner', 'record_owner_string'],
  ['agent', 'agent_string'],
  ['company', 'company_string'],
  ['contact', 'contact_string'],
  ['created_ago', 'created_ahead', 'created_from', 'created_to'],
  ['development_completion_year_from', 'development_completion_year_to'],
  ['development_floor_size_from', 'development_floor_size_metric', 'development_floor_size_to'],
  ['development_no_of_floors_from', 'development_no_of_floors_to'],
  [
    'development_pre_let_or_committed_from',
    'development_pre_let_or_committed_metric',
    'development_pre_let_or_committed_to',
  ],
  ['development_speculative_from', 'development_speculative_metric', 'development_speculative_to'],
  [
    'development_total_area_proposed_from',
    'development_total_area_proposed_metric',
    'development_total_area_proposed_to',
  ],
  ['lot_size_currency', 'lot_size_from', 'lot_size_to'],
  ['niy_from', 'niy_to'],
  ['price_currency', 'price_from', 'price_to'],
  ['rent_currency', 'rent_from', 'rent_metric', 'rent_to'],
  ['size_from', 'size_metric', 'size_to'],
  ['target_net_yield_from', 'target_net_yield_to'],
  ['updated_ago', 'updated_ahead', 'updated_from', 'updated_to'],
  ['wault_to_expiry_from', 'wault_to_expiry_to'],
] as const;

export const disposalsListNonFilterableParamKeys = [
  'assigned',
  'needs_approval',
  'society_due_to_expire',
  'society_listing_not_circulated',
] as const;

export const disposalsListFilterParamKeys = [
  ...disposalsListFilterGroupKeys.flat(),
  'building_types',
  'development_funding',
  'development_likelihood',
  'development_status',
  'development_type',
  'external_only',
  'fitted_spaces',
  'has_presentation',
  'instructions',
  'investment_grade',
  'investment_market_status',
  'investment_profile',
  'investment_sale',
  'is_investment_sale',
  'kyc_completed',
  'location',
  'new_matches',
  'not_published_to',
  'our_instruction',
  'pending_matches',
  'postcode',
  'published_to',
  'requirement_id',
  'reverse_segment_intersect_logic',
  'search',
  'status',
  'submarkets',
  'tags',
  'teams',
  'team_submarkets',
  'tenancy_detail',
  'tenure',
  'terms_of_engagement',
] as const;

export const disposalsListParamKeys = [...disposalsListFilterParamKeys, 'limit', 'order', 'page'] as const;

export interface DisposalsListBody {
  polygons?: Feature<Polygon | MultiPolygon>;
}

export type DisposalsListParams = PartialQuery<typeof disposalsListParamKeys>;

export interface DisposalsListPayload extends OptionalBodyPayload<DisposalsListBody, DisposalsListParams> {
  requestSegment?: DisposalsRequestSegments;
  viewType?: SavedViewType;
}

export type DisposalsResponse = IndexResponse<IDisposalListItem>;

export type DisposalsTableResponse = IndexResponse<IDisposalListTableItem>;

// ---- Disposal, List - Mutations ----

// TODO

// ---- Disposal - Item ----

export interface DisposalInsights {
  discount_reasons: ReportDataItem[];
  interest_sector: ReportDataItem[];
  interest_source: ReportDataItem[];
  new_matches_weekly: ReportDataItem[];
  viewings_weekly: ReportDataItem[];
  totals: Record<ScheduleStatusGroups, ReportDataItem>;
}

export interface DisposalSummaryResponse {
  comments: IComment[];
  insights: DisposalInsights | null;
  tasks: InsightsViewingTask[];
}

export interface DisposalMarketing {
  availability: {
    columns: string[];
    show_totals: boolean;
  };
  public_website_link: string | null;
}

export interface DisposalShowItem {
  [DisposalCompanyKeys.all]: Company[];
  [DisposalCompanyKeys.architect]: Company[];
  [DisposalCompanyKeys.developer]: Company[];
  [DisposalCompanyKeys.landlord]: Company[];
  [DisposalCompanyKeys.vendor]: Company[];
  [DisposalContactKeys.all]: Contact[];
  [DisposalContactKeys.landlord]: Contact[];
  [DisposalContactKeys.vendor]: Contact[];
  address: AddressManual;
  address_string: string;
  amenities_specifications: KeyLabelValueRecord[];
  api_locked: NumberBoolean;
  approved_at: string;
  assigned_users: IUser[];
  assigned_users_pa_string: string;
  available_spaces: AvailableSpace[];
  breakdown: null;
  brochures: any[];
  building_types: string[];
  building_types_society: string[];
  can_access_fees: boolean;
  collaborator_disposals: any[];
  comments: IComment[];
  competition_schedule_id: string | null;
  content: IDisposalManageContent;
  counts: {
    campaign_emails_not_sent: number;
    campaign_emails_sent: number;
    match_count: number;
    match_new: number;
    pending_proposals: number;
  };
  created_at: string;
  development_pipeline: NumberBoolean;
  development_pipeline_comments: IComment[];
  development_pipeline_data: {
    completion_date_quarter?: string | null;
    completion_date_year?: string | null;
    development_likelihood?: string | null;
    development_status?: string | null;
    development_type?: string | null;
    floor_size_metric?: Metric | null;
    floor_size_value?: string | null;
    funding?: string | null;
    no_floors?: string | null;
    pre_let_size_metric?: Metric | null;
    pre_let_size_value?: string | null;
    speculative_size_metric?: Metric | null;
    speculative_size_value?: string | null;
    target_rent?: string | null;
    total_area_proposed_size_metric?: Metric | null;
    total_area_proposed_size_value?: string | null;
  };
  epcs: any[];
  external_ref_1: string;
  external_refs: any[];
  feature_flags: DisposalShowFeatureFlags;
  floor_plans: any[];
  for_sale: NumberBoolean;
  goad_plans: any[];
  hash: Id;
  id: number;
  images: IImageDetails[];
  interest_schedule: InterestScheduleData;
  investment_market_status: DisposalInvestmentMarketStatus | null;
  investment_market_status_string: string;
  investment_profile_string: string;
  is_investment_sale: NumberBoolean;
  is_portfolio: boolean;
  joint_agent_schedule_candidate: boolean;
  joint_agents: any[];
  key_companies: any[];
  key_contacts: any[];
  letting_transaction_id: Id | null;
  logo: any[];
  marketing: DisposalMarketing;
  marketing_entries: MarketingEntries;
  marketing_languages_enabled: Languages[];
  name: string;
  needs_approval: boolean;
  needs_approval_remaining_days: number;
  net_yield: string;
  on_the_market_adverts: any[];
  property_pal_adverts: any[];
  organisation_id: Id;
  other_files: IImageDetails[];
  our_instruction: StringBoolean | '';
  owner: IUser;
  pa_administrator_user: IUser[];
  parent_disposal_id: number | null;
  parent_disposal_name: string | null;
  particulars: IImageDetails[];
  position: {
    map: MapPosition;
    street_view: StreetView;
  };
  primary_address: string;
  private_files: any[];
  private_images: any[];
  property: PropertyItem;
  publish_options: any;
  quality_score: number;
  rightmove_adverts: any[];
  sale_transaction_id: Id | null;
  secondary_address: string;
  segments: SocietySegments[];
  society_adverts: IDisposalManageSocietyAdvert[];
  society_adverts_archived: any[];
  society_listing_needs_approval: boolean;
  society_listing_remaining_days: number | null;
  society_listing_validated: boolean;
  status: number;
  status_string: string;
  tags: any[];
  tasks: InsightsViewingTask[];
  teams: ITeam[];
  to_let: NumberBoolean;
  transaction_id: Id | null;
  updated_at: string;
  zoopla_rtf_adverts: any[];
}

// ---- Disposal - Queries ----

export interface DisposalTransactionsResponse {
  letting_transactions: LettingTransactionsListItem[];
  sale_transactions: SaleTransactionsListItem[];
}

// ---- Disposal - Mutations ----

export interface UpdateDisposalDTO {
  interest_schedule_preferred_columns: string[];
}

export interface DisposalUpdatePayload extends BodyPayload<Partial<UpdateDisposalDTO>> {
  id: string;
}

// ---- Disposal, Data Room - Item ----

export enum DisposalDataRoomAccessStatus {
  Pending = 0,
  Approved = 1,
  Denied = 2,
  Removed = 3,
}

export interface AuthorisationLog {
  action: string;
  activity: string;
  causer: string;
  id: number;
  message: string;
  timestamp: string;
}

export interface DisposalDataRoomAccessItem {
  id: number;
  name: string;
  company: Company | null;
  contact_id: number | null;
  email: string;
  status: DisposalDataRoomAccessStatus;
  status_string: string;
  requested_at: string;
  authorisation_logs: AuthorisationLog[];
}

export interface DisposalDataRoomInsightAllActivityItem {
  company: string;
  event: string;
  event_time: string;
  id: number;
  name: string;
}

export interface DisposalDataRoomInsightsVisitorsItem {
  company: string;
  downloads: number;
  email: string;
  id: number;
  last_viewed: string;
  name: string;
  views: number;
  requested_at: string;
}

export interface DisposalDataRoomInsightsDownloadsItem {
  id: number;
  ext: string;
  size: string;
  download_count: number;
  unique_download_count: number;
  last_downloaded_at: string | null;
  published_date: string;
}

export interface DisposalDataRoomInsightsShowItem {
  activity_feed: {
    action: string;
    timestamp: string;
  }[];
  company: string;
  contact_id: number | null;
  first_viewed: string;
  id: number;
  last_viewed: string;
  name: string;
  registered_at: string;
  total_downloads: number;
  total_views: number;
  unique_downloads: number;
}

export interface DisposalDataRoomInsightsDocumentsShowItem {
  activities: {
    id: number;
    causer: string;
    timestamp: string;
  }[];
  id: number;
  name: string;
  total_downloads: number;
  unique_downloads: number;
}

export interface DisposalDataRoomFolder {
  assets: IFile[];
  id: number;
  name: string;
  uuid: string;
}

export enum DisposalDataRoomStatus {
  UnPublished = 0,
  Live = 1,
}

export interface DisposalDataRoomShowItem {
  access_level: NumberBoolean | null;
  approvers: ListUserItem[];
  availability_columns: string[];
  available_spaces: AvailableSpace[];
  availability_show_totals: boolean;
  display_business_rate: boolean;
  display_estate_charge: boolean;
  display_parking_charge: boolean;
  display_price: boolean;
  display_rent: boolean;
  display_service_charge: boolean;
  documents_summary:
    | {
        [key in Languages]: string;
      }
    | null;
  domain: string | null;
  folders: DisposalDataRoomFolder[];
  id: number;
  include_documents_page: boolean | null;
  last_updated_by: string;
  marketing_entries:
    | {
        [key in Languages]: MarketingEntryData;
      }
    | null;
  marketing_languages_enabled: Languages[];
  include_marketing_page: boolean | null;
  published_at: string | null;
  published_by: string | null;
  show_availability: boolean;
  status: DisposalDataRoomStatus;
  status_string: string;
  title: string;
  updated_at: string;
  url: string;
}

// ---- Disposal, Data Room - Queries ----

export interface DisposalDataRoomAccessMeta extends IndexMeta {
  status_counts: {
    all: number;
    pending: number;
    approved: number;
    denied: number;
    removed: number;
  };
}

export type DisposalDataRoomAccessResponse = IndexResponse<DisposalDataRoomAccessItem, DisposalDataRoomAccessMeta>;

export type DisposalDataRoomInsightsAllActivityListResponse = IndexResponse<DisposalDataRoomInsightAllActivityItem>;
export type DisposalDataRoomInsightsVisitorsListResponse = IndexResponse<DisposalDataRoomInsightsVisitorsItem>;
export type DisposalDataRoomInsightsDownloadsListResponse = IndexResponse<DisposalDataRoomInsightsDownloadsItem>;

export type DisposalDataRoomAccessListParams = PartialQuery<['limit', 'page', 'search']>;

export interface DisposalDataRoomAccessListPayload extends QueryPayload<DisposalDataRoomAccessListParams> {
  id: string;
}

export type DisposalDataRoomInsightsParams = PartialQuery<['limit', 'page', 'search']>;

export interface DisposalDataRoomInsightsPayload extends QueryPayload<DisposalDataRoomInsightsParams> {
  id: string;
}

export interface DisposalDataRoomInsightsShowPayload extends QueryPayload<{ activityId: number }> {
  id: string;
}

// ---- Disposal, Data Room - Mutations ----

export interface DisposalDataRoomUpdateDTO extends MarketingEntryPayload {
  floor_units: { id: number; data_room_visible: boolean }[];
  availability_columns: string[];
  availability_show_totals: boolean;
  show_availability: boolean;

  domain: string;
  documents_summary_en: string;
  include_documents_page: boolean;
  include_marketing_page: boolean;
  access_level: NumberBoolean;
  display_rent: boolean;
  display_business_rate: boolean;
  display_service_charge: boolean;
  display_estate_charge: boolean;
  display_parking_charge: boolean;
  display_price: boolean;
  approvers: string;
}

export interface DisposalUpdataDataRoomPayload extends BodyPayload<Partial<DisposalDataRoomUpdateDTO>> {
  id: string;
}

export interface DisposalManageDataRoomAccess extends BodyPayload<{ accessId: number }> {
  id: string;
}

export interface DisposalDataRoomDocumentsReorderDTO {
  folder_id: string;
  document_ids: number[];
}

export interface DisposalDataRoomDocumentsReorderPayload extends BodyPayload<DisposalDataRoomDocumentsReorderDTO> {
  id: string;
}

export interface UploadFileProgress {
  file: File;
  folderId: number;
  progress: number;
}

export type DisposalDataRoomUploadFileDTO = FormData;

export interface DisposalDataRoomUploadDocumentPayload extends BodyPayload<DisposalDataRoomUploadFileDTO> {
  folderId: number;
  id: string;
}

export interface DisposalDataRoomDeleteDocumentPayload {
  id: string;
  assetId: string;
}

export interface DisposalDataRoomDeleteFolderPayload {
  id: string;
  folderId: string;
}

export interface DisposalDataRoomCreateFolderPayload extends BodyPayload<{ name: string }> {
  id: string;
}

export interface DisposalDataRoomFoldersReorderDTO {
  folder_ids: number[];
}
export interface DisposalDataRoomFoldersReorderPayload extends BodyPayload<DisposalDataRoomFoldersReorderDTO> {
  id: string;
}

export interface DisposalDataRoomUpdateFolderDTO {
  name: string;
}
export interface DisposalDataRoomUpdateFolderPayload extends BodyPayload<DisposalDataRoomUpdateFolderDTO> {
  id: string;
  folderId: string;
}
