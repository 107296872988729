import { createStyles } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

export const styles = () =>
  createStyles({
    root: {
      fontSize: 20,
      fill: colors.white.lightest,
    },
  });
