import cx from 'classnames';
import { omit } from 'lodash';
import { FC, useEffect, useMemo, useState } from 'react';

import { t } from '@core/i18n';
import { withStyles, WithStyles } from '@core/theme/utils/with-styles';
import { Button } from '@shared/components/button';
import { Checkbox } from '@shared/components/checkbox';
import { Dialog, DialogProps } from '@shared/components/dialog';
import { DraggableList } from '@shared/components/draggable-list';
import { Flex } from '@shared/components/flex';
import { TableColumn, TableColumnField } from '@shared/components/table-new';
import { TextField } from '@shared/components/text-field';
import { CrossRoundIcon } from '@shared/icons/cross-round';
import { DragIcon } from '@shared/icons/drag';
import { LockIcon } from '@shared/icons/lock';

import { styles } from './ColumnPicker.styles';

export interface CheckboxColumn extends TableColumn {
  checked: boolean;
}

export interface NormalisedColumn extends CheckboxColumn {
  id: TableColumnField;
}

export const convertToCheckboxColumns = (columns: TableColumn[], selected?: string | null) => {
  const selectedColumnsArray = typeof selected === 'string' ? selected.split(',') : [];

  return columns.map((item) => {
    return { ...item, checked: selectedColumnsArray.includes(item.field) };
  });
};

export const searchColumns = (search: string, checkboxColumns: CheckboxColumn[]): CheckboxColumn[] => {
  return checkboxColumns.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()));
};

export interface ColumnPickerProps extends WithStyles<typeof styles>, Omit<DialogProps, 'classes'> {
  columns: TableColumn[];
  fixedColumns?: TableColumn[];
  selectedColumns?: string | null;
  onSave: (columns: string) => void;
}

const ColumnPickerComponent: FC<ColumnPickerProps> = ({
  classes,
  columns,
  fixedColumns,
  selectedColumns,
  onSave,
  ...dialogProps
}) => {
  const [search, setSearch] = useState('');
  const [checkboxColumns, setCheckboxColumns] = useState<CheckboxColumn[]>([]);
  const [draggableColumns, setDraggableColumns] = useState<CheckboxColumn[]>([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (dialogProps?.open) {
      setSearch('');

      const _checkboxColumns = convertToCheckboxColumns(columns, selectedColumns);
      setCheckboxColumns(_checkboxColumns);

      const selectedColumnsArray = typeof selectedColumns === 'string' ? selectedColumns.split(',') : [];
      setDraggableColumns(
        _checkboxColumns
          .filter((el) => el.checked)
          .sort((a, b) => (selectedColumnsArray.indexOf(a.field) > selectedColumnsArray.indexOf(b.field) ? 1 : -1))
      );
    }
  }, [dialogProps?.open]);

  const selectedColumnItems = useMemo(() => checkboxColumns.filter((el) => el.checked), [checkboxColumns]);

  const filteredColumns = useMemo(() => searchColumns(search, checkboxColumns), [checkboxColumns, search]);

  const normalisedDraggableColumns: NormalisedColumn[] = useMemo(
    () => draggableColumns.map((column) => ({ ...column, id: column.field })),
    [draggableColumns]
  );

  const handleCheckboxChange = (field: string) => (checked: boolean) => {
    let changedElement = undefined;
    const updatedState = checkboxColumns.map((el) => {
      if (el.field === field) {
        changedElement = { ...el, checked: checked };
        return { ...el, checked: checked };
      }

      return el;
    });

    setError(false);
    setCheckboxColumns(updatedState);

    if (checked && changedElement) {
      setDraggableColumns([...draggableColumns, changedElement]);
    } else {
      setDraggableColumns(draggableColumns.filter((el) => el.field !== field));
    }
  };

  const orderColumnCount = useMemo(
    () => (selectedColumnItems.length || 0) + (fixedColumns?.filter((el) => el.name).length ?? 0),
    [selectedColumnItems, fixedColumns]
  );

  return (
    <Dialog
      {...dialogProps}
      classes={{
        rootContainer: classes.dialogRoot,
        root: classes.dialogPaper,
        body: classes.dialogBody,
        actions: classes.dialogActions,
      }}
      withTransition
    >
      <Flex wrap="nowrap" className={classes.container}>
        <div className={classes.leftContainer}>
          <TextField
            fullWidth
            value={search}
            placeholder={t('xtextx_ellipsis', { text: t('search_for_columns') })}
            onChange={(e) => setSearch(e.target.value)}
            classes={{ root: classes.searchField }}
          />
          <div className={classes.columnsCheckboxes}>
            {filteredColumns.map(({ field, name, checked }) => (
              <Checkbox
                key={field}
                checked={checked}
                label={name}
                onChange={handleCheckboxChange(field)}
                classes={{
                  root: cx(classes.columnItem, classes.columnsCheckboxRoot),
                  label: classes.columnLabel,
                  checkbox: classes.checkbox,
                  icon: classes.checkboxIcon,
                  iconChecked: classes.checkboxIconChecked,
                }}
              />
            ))}
          </div>
        </div>

        <div className={classes.rightContainer}>
          <span className={classes.selectedColumnsTitle}>
            {t('order_selected_columns_bracket_xcountx_bracket', {
              count: orderColumnCount,
            })}
          </span>
          <div className={classes.selectedColumnsList}>
            {fixedColumns?.map((item) => {
              // Don't show fixed columns with no name
              if (!item.name) {
                return undefined;
              }

              return (
                <Flex
                  key={item.field}
                  alignItems="center"
                  autoWidth={false}
                  classes={{ root: classes.fixedColumnItem }}
                  wrap="nowrap"
                >
                  <LockIcon classes={{ root: classes.fixedItemIcon }} />
                  <span className={classes.columnLabel}>{item.name}</span>
                </Flex>
              );
            })}
            <DraggableList
              classes={{ root: classes.draggableList }}
              items={normalisedDraggableColumns}
              onDragEnd={(list: NormalisedColumn[]) => setDraggableColumns(list.map((item) => omit(item, ['id'])))}
              renderItem={(item: NormalisedColumn) => {
                return (
                  <Flex
                    alignItems="center"
                    autoWidth={false}
                    classes={{ root: cx(classes.columnItem, classes.draggableItem) }}
                    wrap="nowrap"
                  >
                    <DragIcon classes={{ root: classes.draggableItemIcon }} />
                    <span className={classes.columnLabel}>{item.name}</span>
                    <CrossRoundIcon
                      classes={{ root: classes.draggableItemIconClose }}
                      onClick={() => handleCheckboxChange(item.field)(false)}
                    />
                  </Flex>
                );
              }}
            />
          </div>
        </div>
      </Flex>

      <div className={classes.footer}>
        {error && <span className={classes.footerError}>{t('you_must_select_at_least_one_column')}</span>}
        <Flex alignItems="center" justifyContent="space-between" classes={{ root: classes.footerActions }}>
          <Button
            classes={{ root: classes.allColumnsButton }}
            text={t(selectedColumnItems.length == columns.length ? 'remove_all_columns' : 'select_all_columns')}
            variant="text"
            onClick={() => {
              if (selectedColumnItems.length == columns.length) {
                // Remove all columns
                setCheckboxColumns(checkboxColumns.map((el) => ({ ...el, checked: false })));
                setDraggableColumns([]);
              } else {
                // Select all columns
                const allCheckedColumns = checkboxColumns.map((el) => ({ ...el, checked: true }));
                setCheckboxColumns(allCheckedColumns);
                setDraggableColumns(allCheckedColumns);
                setError(false);
              }
            }}
          />
          <div>
            <Button
              classes={{ label: classes.actionBtnLabel }}
              color="default"
              text={t('cancel')}
              variant="outlined"
              onClick={() => {
                if (dialogProps?.onClose) {
                  dialogProps.onClose('', 'backdropClick');
                }
              }}
            />
            <Button
              classes={{ root: classes.saveButton, label: classes.actionBtnLabel }}
              text={t('save')}
              onClick={() => {
                if (selectedColumnItems.length === 0) {
                  setError(true);
                  return;
                }

                onSave(draggableColumns.map((item) => item.field).join(','));
              }}
            />
          </div>
        </Flex>
      </div>
    </Dialog>
  );
};

export const ColumnPicker = withStyles(styles)(ColumnPickerComponent);
