import { SvgIcon } from '@material-ui/core';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';

import { styles } from './SwitchVertical.styles';

export interface SwitchVerticalIconProps extends WithStyles<typeof styles> {
  color?: string;
}

const SwitchVerticalIconComponent: React.FC<SwitchVerticalIconProps> = ({ classes, color = '#7E8899' }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      classes={{ root: classes.root }}
    >
      <path
        d="M4.66667 2.6665V13.3332M4.66667 13.3332L2 10.6665M4.66667 13.3332L7.33333 10.6665M11.3333 13.3332V2.6665M11.3333 2.6665L8.66667 5.33317M11.3333 2.6665L14 5.33317"
        stroke={color}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export const SwitchVerticalIcon = withStyles(styles)(SwitchVerticalIconComponent);
