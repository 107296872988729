import { SvgIcon } from '@material-ui/core';
import { FC } from 'react';

import { withStyles, WithStyles } from '@core/theme/utils/with-styles';
import { colors } from '@shared/theme/colours';

import { styles } from './Play.styles';

export interface PlayIconProps extends WithStyles<typeof styles> {
  color?: string;
}

const PlayIconComponent: FC<PlayIconProps> = ({ classes, color = colors.white.lightest }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      classes={{ root: classes.root }}
    >
      <path
        d="M3.33325 3.32642C3.33325 2.67898 3.33325 2.35526 3.46825 2.17681C3.58585 2.02135 3.7656 1.92515 3.96018 1.91353C4.18354 1.9002 4.45289 2.07977 4.9916 2.4389L12.002 7.11248C12.4471 7.40923 12.6697 7.55761 12.7472 7.74462C12.815 7.90813 12.815 8.09188 12.7472 8.25538C12.6697 8.4424 12.4471 8.59077 12.002 8.88752L4.9916 13.5611C4.45289 13.9202 4.18354 14.0998 3.96018 14.0865C3.7656 14.0749 3.58585 13.9787 3.46825 13.8232C3.33325 13.6447 3.33325 13.321 3.33325 12.6736V3.32642Z"
        stroke={color}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export const PlayIcon = withStyles(styles)(PlayIconComponent);
