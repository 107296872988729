import { createQueryKeys, inferQueryKeys } from '@lukemorales/query-key-factory';
import { AxiosResponse } from 'axios';

import { FeeShowItem } from '@shared/apis/feesApi.types';
import { SavedViewType } from '@shared/models/saved_view/savedView';
import { getQueriesAsSearch } from '@shared/utils/common';
import { $httpAgency, useQuery, UseQueryOptions } from '@shared/utils/http-client';

import {
  DisposalDataRoomAccessListPayload,
  DisposalDataRoomAccessResponse,
  DisposalDataRoomInsightsAllActivityListResponse,
  DisposalDataRoomInsightsDocumentsShowItem,
  DisposalDataRoomInsightsDownloadsListResponse,
  DisposalDataRoomInsightsPayload,
  DisposalDataRoomInsightsShowItem,
  DisposalDataRoomInsightsShowPayload,
  DisposalDataRoomInsightsVisitorsListResponse,
  DisposalDataRoomShowItem,
  DisposalShowItem,
  DisposalsListPayload,
  DisposalsRequestSegments,
  DisposalsResponse,
  DisposalsTableResponse,
  DisposalSummaryResponse,
  DisposalTransactionsResponse,
} from './disposalsApi.types';

export const disposalsQueryKeys = createQueryKeys('disposals', {
  // ---- Disposal, List - Queries ----

  list: (payload: DisposalsListPayload) => ({
    queryKey: [payload.viewType || '', payload.query, payload.body],
    queryFn: async ({ signal }) => {
      const segment = payload.requestSegment ?? DisposalsRequestSegments.all;
      const queryString = getQueriesAsSearch(payload.query);

      if (payload.viewType === SavedViewType.map) {
        // console.log('disposalsQueryKeys.list. fetching map: ', queryString, payload.body);
        const mapResponse = await $httpAgency.post<DisposalsListPayload['body'], AxiosResponse<DisposalsResponse>>(
          `/disposals${segment}/map${queryString}`,
          payload.body,
          { signal }
        );
        // console.log('disposalsQueryKeys.list. fetched map: ', mapResponse);
        return mapResponse.data;
      }

      if (payload.viewType === SavedViewType.table) {
        // console.log('disposalsQueryKeys.list. fetching table: ', queryString, payload.body);
        const tableResponse = await $httpAgency.post<
          DisposalsListPayload['body'],
          AxiosResponse<DisposalsTableResponse>
        >(`/disposals${segment}/table${queryString}`, payload.body, { signal });
        // console.log('disposalsQueryKeys.list. fetched table: ', tableResponse);
        return tableResponse.data;
      }

      // console.log('disposalsQueryKeys.list. fetching: ', queryString);
      const response = await $httpAgency.get<DisposalsResponse>(`/disposals${segment}${queryString}`, { signal });
      // console.log('disposalsQueryKeys.list. fetched: ', response);
      return response.data;
    },
  }),

  // ---- Disposal - Queries ----

  fees: (id: string) => ({
    queryKey: [id],
    queryFn: async () => (await $httpAgency.get(`/disposals/${id}/fees`)).data,
  }),
  show: (id: string) => ({
    queryKey: [id],
    queryFn: async () => (await $httpAgency.get(`/disposals/${id}`)).data,
  }),
  summary: (id: string) => ({
    queryKey: [id],
    queryFn: async () => (await $httpAgency.get(`/disposals/${id}/summary`)).data,
  }),
  transactions: (id: string) => ({
    queryKey: [id],
    queryFn: async () => (await $httpAgency.get(`/disposals/${id}/transactions`)).data,
  }),

  // ---- Disposal, Data Room - Queries ----

  dataRoom: (id: string) => ({
    queryKey: [id],
    queryFn: async () => (await $httpAgency.get(`/disposals/${id}/data-room`)).data,
  }),
  dataRoomAccessList: (payload: DisposalDataRoomAccessListPayload) => ({
    queryKey: [payload.id, payload.query],
    queryFn: async () => {
      return (
        await $httpAgency.get(`/disposals/${payload.id}/data-room/access-requests${getQueriesAsSearch(payload.query)}`)
      ).data;
    },
  }),
  dataRoomInsightsAllActivityList: (payload: DisposalDataRoomInsightsPayload) => ({
    queryKey: [payload.id, payload.query],
    queryFn: async () => {
      return (await $httpAgency.get(`/disposals/${payload.id}/data-room/insights${getQueriesAsSearch(payload.query)}`))
        .data;
    },
  }),
  dataRoomInsightsVisitorsList: (payload: DisposalDataRoomInsightsPayload) => ({
    queryKey: [payload.id, payload.query],
    queryFn: async () => {
      return (
        await $httpAgency.get(
          `/disposals/${payload.id}/data-room/insights/visitors${getQueriesAsSearch(payload.query)}`
        )
      ).data;
    },
  }),
  dataRoomInsightsDownloadsList: (payload: DisposalDataRoomInsightsPayload) => ({
    queryKey: [payload.id, payload.query],
    queryFn: async () => {
      return (
        await $httpAgency.get(
          `/disposals/${payload.id}/data-room/insights/documents${getQueriesAsSearch(payload.query)}`
        )
      ).data;
    },
  }),
  dataRoomInsightsShow: (payload: DisposalDataRoomInsightsShowPayload) => ({
    queryKey: [payload.id, payload.query],
    queryFn: async () => {
      return (await $httpAgency.get(`/disposals/${payload.id}/data-room/insights/visitors/${payload.query.activityId}`))
        .data;
    },
  }),
  dataRoomInsightsDocumentsShow: (payload: DisposalDataRoomInsightsShowPayload) => ({
    queryKey: [payload.id],
    queryFn: async () => {
      return (
        await $httpAgency.get(`/disposals/${payload.id}/data-room/insights/documents/${payload.query.activityId}`)
      ).data;
    },
  }),
});

export type DisposalsQueryKeys = inferQueryKeys<typeof disposalsQueryKeys>;

// ---- Disposal, List - Queries ----

export const useDisposalsListQuery = (
  payload: DisposalsListPayload,
  options?: UseQueryOptions<DisposalsResponse | DisposalsTableResponse>
) =>
  useQuery<DisposalsQueryKeys['list']['queryKey'], DisposalsResponse | DisposalsTableResponse>({
    ...disposalsQueryKeys.list(payload),
    keepPreviousData: false, // TODO Had to turn this off to fix work with two data types one for table one for grid
    ...options,
  });

// ---- Disposal - Queries ----

export const useDisposalFeesQuery = (id: string, options?: UseQueryOptions<FeeShowItem>) =>
  useQuery<DisposalsQueryKeys['fees']['queryKey'], FeeShowItem>({
    ...disposalsQueryKeys.fees(id),
    ...options,
  });

export const useDisposalShowQuery = (id: string, options?: UseQueryOptions<DisposalShowItem>) =>
  useQuery<DisposalsQueryKeys['show']['queryKey'], DisposalShowItem>({
    ...disposalsQueryKeys.show(id),
    ...options,
  });

export const useDisposalSummaryQuery = (id: string, options?: UseQueryOptions<DisposalSummaryResponse>) =>
  useQuery<DisposalsQueryKeys['summary']['queryKey'], DisposalSummaryResponse>({
    ...disposalsQueryKeys.summary(id),
    ...options,
  });

export const useDisposalTransactionsQuery = (id: string, options?: UseQueryOptions<DisposalTransactionsResponse>) =>
  useQuery<DisposalsQueryKeys['transactions']['queryKey'], DisposalTransactionsResponse>({
    ...disposalsQueryKeys.transactions(id),
    ...options,
  });

// ---- Disposal, Data Room - Queries ----

export const useDisposalDataRoomAccessListQuery = (
  payload: DisposalDataRoomAccessListPayload,
  options?: UseQueryOptions<DisposalDataRoomAccessResponse>
) =>
  useQuery<DisposalsQueryKeys['dataRoomAccessList']['queryKey'], DisposalDataRoomAccessResponse>({
    ...disposalsQueryKeys.dataRoomAccessList(payload),
    keepPreviousData: true,
    ...options,
  });

export const useDisposalDataRoomInsightsAllActivityListQuery = (
  payload: DisposalDataRoomInsightsPayload,
  options?: UseQueryOptions<DisposalDataRoomInsightsAllActivityListResponse>
) =>
  useQuery<
    DisposalsQueryKeys['dataRoomInsightsAllActivityList']['queryKey'],
    DisposalDataRoomInsightsAllActivityListResponse
  >({
    ...disposalsQueryKeys.dataRoomInsightsAllActivityList(payload),
    keepPreviousData: false,
    ...options,
  });

export const useDisposalDataRoomInsightsVisitorsListQuery = (
  payload: DisposalDataRoomInsightsPayload,
  options?: UseQueryOptions<DisposalDataRoomInsightsVisitorsListResponse>
) =>
  useQuery<
    DisposalsQueryKeys['dataRoomInsightsVisitorsList']['queryKey'],
    DisposalDataRoomInsightsVisitorsListResponse
  >({
    ...disposalsQueryKeys.dataRoomInsightsVisitorsList(payload),
    keepPreviousData: false,
    ...options,
  });

export const useDisposalDataRoomInsightsDownloadsListQuery = (
  payload: DisposalDataRoomInsightsPayload,
  options?: UseQueryOptions<DisposalDataRoomInsightsDownloadsListResponse>
) =>
  useQuery<
    DisposalsQueryKeys['dataRoomInsightsDownloadsList']['queryKey'],
    DisposalDataRoomInsightsDownloadsListResponse
  >({
    ...disposalsQueryKeys.dataRoomInsightsDownloadsList(payload),
    keepPreviousData: false,
    ...options,
  });

export const useDisposalDataRoomInsightsShowQuery = (
  payload: DisposalDataRoomInsightsShowPayload,
  options?: UseQueryOptions<DisposalDataRoomInsightsShowItem>
) =>
  useQuery<DisposalsQueryKeys['dataRoomInsightsShow']['queryKey'], DisposalDataRoomInsightsShowItem>({
    ...disposalsQueryKeys.dataRoomInsightsShow(payload),
    keepPreviousData: false,
    ...options,
  });

export const useDisposalDataRoomInsightsDocumentsShowQuery = (
  payload: DisposalDataRoomInsightsShowPayload,
  options?: UseQueryOptions<DisposalDataRoomInsightsDocumentsShowItem>
) =>
  useQuery<DisposalsQueryKeys['dataRoomInsightsDocumentsShow']['queryKey'], DisposalDataRoomInsightsDocumentsShowItem>({
    ...disposalsQueryKeys.dataRoomInsightsDocumentsShow(payload),
    keepPreviousData: false,
    ...options,
  });

export const useDisposalDataRoomQuery = (id: string, options?: UseQueryOptions<DisposalDataRoomShowItem>) =>
  useQuery<DisposalsQueryKeys['dataRoom']['queryKey'], DisposalDataRoomShowItem>({
    ...disposalsQueryKeys.dataRoom(id),
    ...options,
  });
