import { SvgIcon } from '@material-ui/core';
import { FC } from 'react';

import { withStyles, WithStyles } from '@core/theme/utils/with-styles';

import { styles } from './CrossRound.styles';

interface CrossRoundIconProps extends WithStyles<typeof styles> {
  color?: string;
  onClick?: () => void;
}

const CrossRoundIconComponent: FC<CrossRoundIconProps> = ({ classes, color = '#7E8899', onClick }) => {
  return (
    <SvgIcon
      classes={{ root: classes.root }}
      height="16"
      onClick={onClick}
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99992 5.99992L5.99992 9.99992M5.99992 5.99992L9.99992 9.99992M14.6666 7.99992C14.6666 11.6818 11.6818 14.6666 7.99992 14.6666C4.31802 14.6666 1.33325 11.6818 1.33325 7.99992C1.33325 4.31802 4.31802 1.33325 7.99992 1.33325C11.6818 1.33325 14.6666 4.31802 14.6666 7.99992Z"
        stroke={color}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export const CrossRoundIcon = withStyles(styles)(CrossRoundIconComponent);
