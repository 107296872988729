import { FC, useState } from 'react';
import { DropResult as BeautifulDropResult, DragDropContext, DragStart, DragUpdate } from 'react-beautiful-dnd';

import {
  addInterestItemsIntoHash,
  updateInterestItemInHash,
  useDisposalReporting,
} from '@agency/modules/disposals/pages/disposal-manage/pages/disposal-reporting/DisposalReporting.context';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { DisposalInterestIndexItem, useDisposalFavouriteMatchMutation } from '@shared/apis/disposals';
import { showNotification } from '@shared/components/notification';
import { useInterestScheduleConfig } from '@shared/hooks/interestSchedule.hooks';
import {
  InterestScheduleData,
  InterestScheduleStatuses,
  ScheduleStatusGroups,
  ScheduleStatusGroupsWithAll,
} from '@shared/types/common/match';
import { getDefaultError } from '@shared/utils/common';

import { styles } from './InterestScheduleBoard.styles';
import { InterestScheduleBoardLane } from '../interest-schedule-board-lane/InterestScheduleBoardLane';

export interface InterestScheduleBoardProps extends WithStyles<typeof styles> {
  groupedItems: Record<ScheduleStatusGroups, DisposalInterestIndexItem[]>;
  interestCounts: Record<ScheduleStatusGroupsWithAll, number>;
  scheduleData: InterestScheduleData;
  onOpenInterest: (id: number) => void;
  onAddInterest: () => void;
  setChangingStatusGroup: UseStateSetter<InterestScheduleStatuses[]>;
}

const InterestScheduleBoardComponent: FC<InterestScheduleBoardProps> = ({
  classes,
  groupedItems,
  interestCounts,
  scheduleData,
  onOpenInterest,
  onAddInterest,
  setChangingStatusGroup,
}) => {
  const { setActiveInterestId, setInterestHash } = useDisposalReporting();
  const { scheduleConfig } = useInterestScheduleConfig(scheduleData.investment === 1);

  const [draggingId, setDraggingId] = useState<string | undefined>();

  const handleMoveCard = ({ source, destination, draggableId }: BeautifulDropResult) => {
    // Dropped outside the list
    if (!destination) return;

    setDraggingId(undefined);

    // Change interest status
    if (source.droppableId !== destination.droppableId) {
      if (destination.droppableId === 'signed') {
        // TODO changing status to signed
        return;
      }

      setActiveInterestId(Number(draggableId));
      const scheduleGroup: ScheduleStatusGroups = destination.droppableId as unknown as ScheduleStatusGroups;

      const groupStatuses = scheduleConfig.groupConfig[scheduleGroup].statuses;

      setChangingStatusGroup(groupStatuses as InterestScheduleStatuses[]);
    }
  };

  const disposalFavouriteMatchMutation = useDisposalFavouriteMatchMutation<DisposalInterestIndexItem>({
    onMutate: (vars) => {
      setInterestHash((prev) =>
        updateInterestItemInHash(vars.id, prev, (interest) => ({
          ...interest,
          fav: vars.body.fav ?? false,
        }))
      );
      return undefined;
    },
    onError: (error, vars, context) => {
      if (context) {
        setInterestHash((prev) => addInterestItemsIntoHash([context], prev));
      }

      showNotification(getDefaultError(), 'error');
    },
  });

  return (
    <div className={classes.root}>
      <DragDropContext
        onDragEnd={handleMoveCard}
        onDragStart={(start: DragStart) => {
          setDraggingId(start.source.droppableId);
        }}
        onDragUpdate={(update: DragUpdate) => {
          if (update.destination?.droppableId) {
            setDraggingId(update.destination.droppableId);
          }
        }}
      >
        {scheduleConfig.scheduleGroupsActive.map((lane, idx) => (
          <InterestScheduleBoardLane
            classes={{ root: classes.boardLane }}
            count={interestCounts[lane]}
            id={lane}
            isDragging={draggingId === lane}
            items={groupedItems[lane as ScheduleStatusGroups]}
            key={idx}
            title={scheduleConfig.groupConfig[lane as ScheduleStatusGroups].title}
            tooltip={scheduleConfig.groupConfig[lane as ScheduleStatusGroups].description}
            onAddInterest={onAddInterest}
            onChangeFavourite={(id: number, fav: boolean) => {
              disposalFavouriteMatchMutation.mutate({
                disposalId: scheduleData.disposal_id,
                id: id,
                body: { fav: fav },
              });
            }}
            onOpenItem={onOpenInterest}
          />
        ))}
      </DragDropContext>
    </div>
  );
};

export const InterestScheduleBoard = withStyles(styles)(InterestScheduleBoardComponent);
