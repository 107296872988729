import { createStyles, Theme } from '@material-ui/core/styles';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {},
    actionRow: {},
    label: {},
    list: {},
    listItem: {},
    notFoundText: {},
    optionsList: {},
    textField: {},
  });
