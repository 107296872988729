import { UrlState } from "@landlord/modules/disposals/pages/disposal-manage/pages/disposal-reporting/DisposalReporting.context";
import { stringifyQueryParams } from "@shared/utils/route";

const ROUTES = {
  initial: '/',
  logOut: '/logout',
  defaultRedirect: '/disposals',

  organisation: {
    disposals: {
      root: '/disposals',
      manage: {
        summary: '/summary',
        viewings: '/viewings',
        reporting: '/reporting',
        //viewing_insights: '/viewing-insights', //TODO find out if this page is required
        insights: '/insights',
      },
    },
    viewings: {
      root: '/viewings',
    },
  },
  marketplace: {
    marketplace_members: '/marketplace-members',
    marketplace_events: '/marketplace-events',
  },

  present: {
    disposalReport: '/present/:id/report',
    presentReport: (id: string, queryParams?: UrlState) => {
      const base = `/present/${id}/report`;
      if (!queryParams) return base;
      const stringifiedQueryParams = stringifyQueryParams(queryParams);
      return `${base}?${stringifiedQueryParams}`;
    }
  },
};

export default ROUTES;
