import { createStyles } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';
import { ScheduleStatusTheme } from '@shared/types/common/match';

export const styles = () =>
  createStyles({
    root: {
      width: '100%',
    },

    [ScheduleStatusTheme.Amber]: {
      '&:not($selectDisabled) .MuiInputBase-root': {
        border: `1px solid #FEB872 !important`,

        '& $icon path': {
          stroke: `#FEB872 !important`,
        },
      },
    },
    [ScheduleStatusTheme.Green]: {
      '&:not($selectDisabled) .MuiInputBase-root': {
        border: `1px solid ${colors.kato.green[400]} !important`,
      },

      '& $icon path': {
        stroke: `${colors.kato.green[400]} !important`,
      },
    },
    [ScheduleStatusTheme.Red]: {
      '&:not($selectDisabled) .MuiInputBase-root': {
        border: `1px solid ${colors.kato.salmon[400]} !important`,
      },

      '& $icon path': {
        stroke: `${colors.kato.salmon[400]} !important`,
      },
    },
    [ScheduleStatusTheme.Blue]: {},
    [ScheduleStatusTheme.Danger]: {},
    [ScheduleStatusTheme.Default]: {},
    [ScheduleStatusTheme.Neutral]: {},
    [ScheduleStatusTheme.Primary]: {},
    [ScheduleStatusTheme.Purple]: {},
    [ScheduleStatusTheme.Grey]: {},
    icon: {},
  });
