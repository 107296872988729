import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {},
    container: {
      minHeight: 365,
      padding: spacing(6),
      width: '100%',
      justifyContent: 'space-between',
      gap: 16,
      overflowY: 'auto',
      flexFlow: 'column',
    },
    heading: {
      color: colors.black.darker,
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '20px',
    },
    divider: {
      borderTop: `1px solid #EAECF0`, // TODO: change this when new palette is created
      width: '100%',
      margin: spacing(2, 0, 4, 0),
    },
    radio: {
      '& .MuiFormControlLabel-root': {
        paddingTop: '5px',
      },
    },
    radioWrapper: {
      flexDirection: 'row',
    },
    formControlLabel: {
      paddingTop: 0,
    },
    radiosRow: {
      gap: '80px',
    },
    columnItem: {
      color: '#101828', // TODO replace after creating new palette
      padding: spacing(2, 0),
      userSelect: 'none',
      width: '100%',

      '&:hover': {
        backgroundColor: '#F7F8FA', // TODO replace after palette will be change
      },
    },
    columnLabel: {
      color: 'inherit',
      flex: '1 1 auto',
      fontSize: 14,
      lineHeight: '16px',
      fontWeight: 500,
    },
    draggableList: {
      height: '100%',
    },
    draggableItem: {
      gap: spacing(2),

      '&:hover': {
        cursor: 'grab',
      },
    },
    draggableItemIcon: {
      color: '#7E8899', // TODO replace after creating new palette
      flex: '0 0 auto',
      fontSize: 16,

      '& > circle': {
        fill: 'currentColor',
      },
    },
    additionalOptionsContainer: {
      display: 'flex',
      flexFlow: 'wrap',
      gap: spacing(1),
    },
    outputContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: spacing(1),
    },
    buttonContainer: {
      gap: spacing(4),
    },
    button: {
      height: 'auto',
      borderRadius: 12,
      padding: spacing(3),
      flex: '1 1 50%',
    },
    buttonChecked: {
      borderColor: '#101828', // TODO: change this when new palette is created
      outline: '1px solid #101828', // TODO: change this when new palette is created
      '&:hover': {
        borderColor: '#101828', // TODO: change this when new palette is created
      },
    },
    officeIcon: { width: 24, height: 24, '& path': { stroke: 'none !important' } },
    buttonLabel: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: spacing(2),
      '& path': { stroke: 'none !important' },
    },
    buttonText: {
      marginRight: 'auto',
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '20px',
      color: '#101828', // TODO: change this when new palette is created
    },
    buttonRoot: {
      marginRight: 0,
    },
    buttonRadio: {
      marginRight: 0,
    },
    buttonIconChecked: {
      color: 'currentColor !important',
    },
    rowContainer: {
      flexWrap: 'nowrap',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      minHeight: 32,
      '& legend': {
        marginBottom: 0,
      },
    },
    labelContainer: {
      flex: '1 1 auto',
      gap: spacing(1),
    },
    label: {
      color: '#101828', // TODO: change this when new palette is created
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '20px',
    },
    fieldContainer: {
      flex: '1 1 auto',
      gap: spacing(4),
      maxWidth: `calc(50% - ${spacing(4)}px)`,
    },
    datePicker: {
      '& .MuiOutlinedInput-adornedStart': {
        minWidth: 156,
      },
    },
    collapse: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    infoIcon: {
      width: 16,
      height: 16,
    },
    downloadSuccessContainer: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    iconContainer: {
      backgroundColor: '#ECFDF3', //TODO replace after creating new palette
      marginBottom: spacing(4),
      height: 64,
      width: 64,
      borderRadius: 8,
    },
    icon: {
      fontSize: 27,
    },
    iconHeading: {
      color: colors.black.darkest,
      fontSize: 18,
      fontWeight: 600,
      lineHeight: '28px',
      textAlign: 'center',
    },
    iconText: {
      maxWidth: 500,
      color: '#515D70', // TODO: change after creating new palette
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '20px',
      textAlign: 'center',
      '& a': {
        textDecoration: 'underline',
        color: 'inherit',
        fontWeight: 500,
        fontSize: 'inherit',
      },
    },
  });
