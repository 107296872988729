import { FC, KeyboardEventHandler, useMemo, useState } from 'react';

import { t } from '@core/i18n';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import {
  // useDisposalInterestScehduleMatchActivitiesShowQuery,
  useDisposalInviteAgentsMutation,
  useDisposalInviteLandlordsMutation,
  // useDisposalShowQuery,
  // useGetDisposalsInterestScheduleAssociatedUsersQuery,
  // useGetDisposalsInterestScheduleSuggestedUsersQuery,
} from '@shared/apis/disposals'; // TODO define common option for both apps
import { Checkbox } from '@shared/components/checkbox';
import { Dialog } from '@shared/components/dialog';
import { Flex } from '@shared/components/flex';
import { Tab, TabConfigItem } from '@shared/components/tab';
import { TableColumn } from '@shared/components/table-new';
import { emailRegex } from '@shared/constants/common';
import { ListUserItem } from '@shared/models/user';
import { InterestScheduleData } from '@shared/types/common/match';

import { DownloadScheduleTab } from './components/download-schedule-tab';
import { InviteCollaboratorsTab } from './components/invite-collaborators-tab';
import { styles } from './InterestScheduleShareModal.styles';

export interface InterestScheduleShareModalProps extends WithStyles<typeof styles> {
  columns: TableColumn[];
  selectedColumns?: string | null;
  open: boolean;
  scheduleData: InterestScheduleData;
  onClose: () => void;
}

enum TabType {
  Invite = 'invite',
  Download = 'download',
}

export enum InviteType {
  Landlords = 'landlords',
  Agents = 'agents',
}
const InterestScheduleShareModalComponent: FC<InterestScheduleShareModalProps> = ({
  classes,
  columns,
  selectedColumns: initialSelectedColumns,
  open,
  scheduleData,
  onClose,
}) => {
  const [activeTab, setActiveTab] = useState<TabType>(TabType.Invite);
  const [currentContactList, setCurrentContactList] = useState<ListUserItem[]>([]); // TODO fetch current contacts invited
  const [downloaded, setDownloaded] = useState<boolean>(false);
  const [invitationError, setInvitationErrors] = useState<boolean>(false);
  const [outputOptionSelected, setOutputOptionSelected] = useState<boolean>(false);
  const [scheduleDownloading, setScheduleDownloading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedChips, setSelectedChips] = useState<ListUserItem[]>([]);
  const [selectedColumns, setSelectedColumns] = useState(initialSelectedColumns);
  const [selectedColumnRadio, setSelectedColumnRadio] = useState<number | string>(0);
  const [selectedInviteType, setSelectedInviteType] = useState<InviteType>(InviteType.Landlords);
  const [suggestedContacts, setSuggestedContacts] = useState<ListUserItem[]>(() => [
    {
      id: 1,
      name: 'Anna Blackburn',
      organisation: { name: 'Savills', id: 2 },
      assets: [],
      email: '',
      forename: '',
      image: { url: null },
      linkedin: null,
      mobile: null,
      organisation_id: '',
      position: '',
      pro: false,
      society_regions: [],
      society_user_id: null,
      surname: '',
      tel: '',
      terms: false,
      theme: { backgroundColour: null, textColour: null },
      twitter: null,
    },
  ]); // TODO fetch suggested contacts

  // TODO define common invite mutation for both apps
  const disposalInviteLandlordsMutation = useDisposalInviteLandlordsMutation({});

  // TODO define common invite mutation for both apps
  const disposalInviteAgencyMutation = useDisposalInviteAgentsMutation({});

  // TODO mutate current contacts invited
  // const disposalInviteAssociatedUsers = useDisposalInviteAssociatedUsersMutation({});

  // TODO mutate suggested contacts
  // const disposalInviteSuggestedUsers = useDisposalInviteSuggestedUsersMutation({});

  const handleInviteTypeTabChange = (inviteType: InviteType) => {
    setSelectedInviteType(inviteType);
    setSelectedChips([]);
  };

  const handleSelectListUser = (user: ListUserItem) => {
    setSelectedChips((prevChips) => {
      const isDuplicate = prevChips.some((chip) => chip.email === user.email);
      if (isDuplicate) {
        return selectedChips;
      } else {
        setSearchValue('');
        // TODO handle organisation based on invite type
        user.position = selectedInviteType === InviteType.Landlords ? 'Landlord' : 'Agent';
        return [...prevChips, user];
      }
    });
  };

  // TODO remove mock and handle invite flow
  const handleOnChipKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if ((event.key === ' ' || event.key === 'Enter') && searchValue.trim()) {
      const trimmedValue = searchValue.trim();
      if (emailRegex.test(trimmedValue)) {
        const newChip: ListUserItem = {
          id: Math.random(),
          name: trimmedValue,
          email: trimmedValue,
          assets: [],
          forename: '',
          surname: '',
          organisation: { name: '', id: '' },
          image: { url: null },
          linkedin: null,
          mobile: null,
          organisation_id: '',
          position: '',
          pro: false,
          society_regions: [],
          society_user_id: null,
          tel: '',
          terms: false,
          theme: { backgroundColour: null, textColour: null },
          twitter: null,
        };
        setSelectedChips([...(selectedChips || []), newChip]);
        setSearchValue('');
      }
      event.preventDefault();
    }
    if (event.key === 'Backspace' && searchValue === '' && (selectedChips?.length || 0) > 0) {
      setSelectedChips((selectedChips || []).slice(0, -1));
      event.preventDefault();
    }
  };

  const handleDeleteChip = (chipToDelete: ListUserItem) => {
    setSelectedChips((prevChips) => prevChips.filter((chip) => chip.id !== chipToDelete.id));
  };

  // TODO remove mock and handle invite flow
  const handleInviteChipContacts = () => {
    if (selectedChips && selectedChips.length > 0) {
      const uniqueSelectedChips = selectedChips.filter(
        (chip, index, self) => index === self.findIndex((c) => c.email === chip.email)
      );
      const existingContactIds = new Set(currentContactList.map((contact) => contact.id));
      const newContacts = uniqueSelectedChips
        .filter((chip) => !existingContactIds.has(chip.id))
        .map(
          (chip) =>
            ({
              ...chip,
              company: chip.organisation?.name || '',
              state: 'Pending',
            } as ListUserItem)
        );
      if (newContacts.length > 0) {
        setCurrentContactList((prevList) => [...prevList, ...newContacts]);
        setInvitationErrors(false);
        setSearchValue('');
        setSelectedChips([]);
      } else {
        setInvitationErrors(true);
      }
    }
  };

  // TODO download query
  const handleDownloadSchedule = async () => {
    setScheduleDownloading(true);
    setTimeout(() => {
      setScheduleDownloading(false);
      setDownloaded(true);
    }, 1500);
  };

  const handleClose = () => {
    setActiveTab(TabType.Invite);
    setDownloaded(false);
    setSelectedColumns(initialSelectedColumns);
    setSelectedColumnRadio(0);
    onClose();
  };

  const getInviteTypeProps = (inviteType: InviteType) => {
    switch (inviteType) {
      case InviteType.Landlords:
        return {
          placeholder: t('invite_landlord_by_name_or_email'),
          loading: disposalInviteLandlordsMutation.isLoading,
          onClick: () => {
            // TODO handle multiple emails ~ api
            // disposalInviteLandlordsMutation.mutate({
            //   disposalId: scheduleData.disposal_id,
            //   body: {
            //     emails: 'landlordEmail@kato.app',
            //   },
            // });
          },
        };
      case InviteType.Agents:
        return {
          placeholder: t('invite_agent_by_name_or_email'),
          loading: disposalInviteAgencyMutation.isLoading,
          onClick: () => {
            // TODO handle multiple emails ~ api
            // disposalInviteAgencyMutation.mutate({
            //   disposalId: scheduleData.disposal_id,
            //   body: {
            //     emails: 'agentEmail@kato.app',
            //   },
            // });
          },
        };
    }
  };

  const inviteTypeProps = getInviteTypeProps(selectedInviteType);

  const mainTabsContent = useMemo(() => {
    switch (activeTab) {
      case TabType.Invite:
        return (
          <InviteCollaboratorsTab
            selectedInviteType={selectedInviteType}
            selectedChips={selectedChips}
            searchValue={searchValue}
            invitationError={invitationError}
            currentContactList={currentContactList}
            suggestedContacts={suggestedContacts}
            inviteTypeProps={inviteTypeProps}
            handleInviteTypeTabChange={handleInviteTypeTabChange}
            handleDeleteChip={handleDeleteChip}
            handleOnChipKeyDown={handleOnChipKeyDown}
            handleSelectListUser={handleSelectListUser}
            setSearchValue={setSearchValue}
            handleInviteChipContacts={handleInviteChipContacts}
            setCurrentContactList={setCurrentContactList}
            setSuggestedContacts={setSuggestedContacts}
          />
        );
      case TabType.Download:
        return (
          <DownloadScheduleTab
            columns={columns}
            downloaded={downloaded}
            selectedColumns={selectedColumns}
            selectedColumnRadio={selectedColumnRadio}
            setSelectedColumns={setSelectedColumns}
            setOutputOptionSelected={setOutputOptionSelected}
            setSelectedColumnRadio={setSelectedColumnRadio}
          />
        );
      default:
        return null;
    }
  }, [
    activeTab,
    columns,
    handleInviteChipContacts,
    handleSelectListUser,
    invitationError,
    inviteTypeProps.loading,
    inviteTypeProps.placeholder,
    selectedColumns,
    selectedInviteType,
    scheduleDownloading,
    downloaded,
    selectedColumnRadio,
  ]);

  const tabsConfig: TabConfigItem[] = useMemo(
    () => [
      { label: t('invite_collaborators') || '', value: TabType.Invite },
      { label: t('download_schedule') || '', value: TabType.Download },
    ],
    []
  );

  const content = useMemo(() => {
    return (
      <>
        <Flex wrap="nowrap" className={classes.heading}>
          <div className={classes.headingTabs}>
            {tabsConfig.map((tab: TabConfigItem) => (
              <Tab
                classes={{ root: classes.headingTab }}
                key={tab.value}
                active={tab.value === activeTab}
                label={tab.label ?? ''}
                value={tab.value}
                onClick={() => setActiveTab(tab.value as TabType)}
              />
            ))}
          </div>
        </Flex>
        {mainTabsContent}
      </>
    );
  }, [activeTab, classes.heading, classes.headingTab, classes.headingTabs, mainTabsContent, tabsConfig]);

  return (
    <Dialog
      classes={{ root: classes.root, body: classes.dialogBody, actionButtons: classes.actionButtons }}
      headingProps={{
        heading: t('share_interest_schedule'),
        withCloseButton: true,
      }}
      open={open}
      withFooter={TabType.Download === activeTab && !downloaded}
      withTransition
      onClose={handleClose}
      actionNode={<Checkbox label={t('get_a_copy_via_email')} />}
      buttonProps={{
        cancel: {
          text: t('cancel'),
          onClick: handleClose,
        },
        confirm: {
          text: t('download'),
          onClick: handleDownloadSchedule,
          loading: scheduleDownloading,
          disabled: !outputOptionSelected,
        },
      }}
    >
      {content}
    </Dialog>
  );
};

export const InterestScheduleShareModal = withStyles(styles)(InterestScheduleShareModalComponent);
