import { ConfigCoreConstants } from '@shared/models/config';
import { AllFieldCastValues, FieldCasts, FieldCastValues, SerialisedFieldValues } from '@shared/types/common/match';
import { reduceStringRecordToObject } from '@shared/utils/common';
import { queryClient, updateShowQueryItem, updateShowQueryItemAndGetPrevious } from '@shared/utils/http-client';

import { disposalsQueryKeys } from './disposalsApi.queries';
import {
  DisposalInterestShowItem,
  DisposalScheduleIndexPayload,
  DisposalScheduleIndexResponse,
} from './disposalsApi.types';

// Use this when we want to revert an update to a show item (onError)
export const updateDisposalInterestShowItem = (
  disposalId: number,
  matchId: number,
  updateCb: (item: DisposalInterestShowItem) => DisposalInterestShowItem
) =>
  updateShowQueryItem<DisposalInterestShowItem>(
    disposalsQueryKeys.interestShow({ disposalId: disposalId.toString(), interestId: matchId.toString() }),
    matchId,
    updateCb
  );

// Use this when we want to optimistically update a show item (onMutate)
export const updateDisposalMatchShowItemAndGetPrevious = (
  disposalId: number,
  matchId: number,
  updateCb: (item: DisposalInterestShowItem) => DisposalInterestShowItem
) =>
  updateShowQueryItemAndGetPrevious<DisposalInterestShowItem>(
    disposalsQueryKeys.interestShow({ disposalId: disposalId.toString(), interestId: matchId.toString() }),
    matchId,
    updateCb
  );

export const filterDisposalScheduleIndexCache = (payload: DisposalScheduleIndexPayload) => (filterIds: number[]) => {
  queryClient.setQueryData<DisposalScheduleIndexResponse>(
    disposalsQueryKeys.interestActiveIndex(payload).queryKey,
    (response) => {
      if (!response) {
        return response;
      }
      const data = response.data.filter((match) => !filterIds.includes(match.id));
      return { ...response, data };
    }
  );
};

export const isInterestValueWhole = (castType: FieldCasts, value: AllFieldCastValues | null): boolean => {
  switch (castType) {
    case FieldCasts.Boolean: {
      const fieldValue = value as FieldCastValues[FieldCasts.Boolean];

      return fieldValue !== null;
    }

    case FieldCasts.DateTime: {
      const fieldValue = value as FieldCastValues[FieldCasts.DateTime];

      // TODO: Add logic to determine wholeness of this cast
      return !!fieldValue;
    }

    case FieldCasts.Float: {
      const fieldValue = value as FieldCastValues[FieldCasts.Float];

      return fieldValue !== null;
    }

    case FieldCasts.FloatRange: {
      const fieldValue = value as FieldCastValues[FieldCasts.FloatRange];

      return fieldValue !== null;
    }

    case FieldCasts.InvestorProfile: {
      const fieldValue = value as FieldCastValues[FieldCasts.InvestorProfile];

      return fieldValue !== null;
    }

    case FieldCasts.MonthAndYear: {
      const fieldValue = value as FieldCastValues[FieldCasts.MonthAndYear];

      // TODO: Add logic to determine wholeness of this cast
      return fieldValue !== null;
    }

    case FieldCasts.MultiSelectInt: {
      const fieldValue = value as FieldCastValues[FieldCasts.MultiSelectInt];

      return fieldValue !== null && fieldValue.length > 0;
    }

    case FieldCasts.MultiSelectString: {
      const fieldValue = value as FieldCastValues[FieldCasts.MultiSelectString];

      return fieldValue !== null && fieldValue.length > 0;
    }

    case FieldCasts.Price: {
      const fieldValue = value as FieldCastValues[FieldCasts.Price];

      return fieldValue !== null;
    }

    case FieldCasts.PriceRange: {
      const fieldValue = value as FieldCastValues[FieldCasts.PriceRange];

      // TODO: Add logic to determine wholeness of this cast
      return !!fieldValue;
    }

    case FieldCasts.RentRange: {
      const fieldValue = value as FieldCastValues[FieldCasts.RentRange];

      // TODO: Add logic to determine wholeness of this cast
      return !!fieldValue;
    }

    case FieldCasts.SingleSelectInt: {
      const fieldValue = value as FieldCastValues[FieldCasts.SingleSelectInt];

      return fieldValue !== null;
    }

    case FieldCasts.SingleSelectString: {
      const fieldValue = value as FieldCastValues[FieldCasts.SingleSelectString];

      return fieldValue !== null && fieldValue !== '';
    }

    case FieldCasts.SizeRange: {
      const fieldValue = value as FieldCastValues[FieldCasts.SizeRange];

      // TODO: Add logic to determine wholeness of this cast
      return !!fieldValue;
    }

    case FieldCasts.String: {
      const fieldValue = value as FieldCastValues[FieldCasts.String];

      return fieldValue !== null && fieldValue !== '';
    }

    // TODO: ---- These do not exist in BE ----

    case FieldCasts.ChargeCast: {
      const fieldValue = value as FieldCastValues[FieldCasts.ChargeCast];

      // TODO: Add logic to determine wholeness of this cast
      return !!fieldValue;
    }

    case FieldCasts.Int: {
      const fieldValue = value as FieldCastValues[FieldCasts.Int];

      return fieldValue !== null;
    }

    case FieldCasts.Json: {
      const fieldValue = value as FieldCastValues[FieldCasts.Json];

      // TODO: Add logic to determine wholeness of this cast
      return !!fieldValue;
    }
  }
};

export const serialisePartialInterestValues = (
  constants: ConfigCoreConstants,
  suppliedValues: Partial<SerialisedFieldValues>
): Partial<SerialisedFieldValues> => {
  // Casting to a string record, so that we aren't looping over a object with optional keys
  const values = suppliedValues as Record<string, AllFieldCastValues>;

  return reduceStringRecordToObject(values, (result, value, key) => {
    const castType = constants.scheduleMatchFields[key]?.input;

    if (castType && isInterestValueWhole(castType, value)) {
      result[key] = value;
    } else {
      result[key] = null;
    }

    return result;
  });
};
