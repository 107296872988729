import { createStyles, Theme } from '@material-ui/core/styles';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {},
    body: {},
    actionButtons: {},
    information: {
      display: 'flex',
      padding: spacing(3),
      gap: spacing(2),
      borderRadius: 8,
      border: `1px solid #EAECF0`, // TODO replace after creating new palette
    },
    informationIcon: {
      width: 20,
      height: 20,
    },
    informationText: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '20px',
      color: '#515D70', // TODO replace after creating new palette
    },
  });
