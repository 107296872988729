import { SvgIcon } from '@material-ui/core';
import { FC } from 'react';

import { withStyles, WithStyles } from '@core/theme/utils/with-styles';

import { styles } from './SearchNoData.styles';

export interface SearchNoDataIconProps extends WithStyles<typeof styles> {
  color?: string;
  strokeWidth?: number;
}

const SearchNoDataIconComponent: FC<SearchNoDataIconProps> = ({ classes, color = '#324DFC', strokeWidth = 2 }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="transparent"
      classes={{ root: classes.root }}
    >
      <path
        d="M27.1663 14.0001V9.06675C27.1663 6.82654 27.1663 5.70643 26.7304 4.85079C26.3469 4.09814 25.735 3.48622 24.9823 3.10272C24.1267 2.66675 23.0066 2.66675 20.7663 2.66675H12.233C9.9928 2.66675 8.87269 2.66675 8.01705 3.10272C7.2644 3.48622 6.65248 4.09814 6.26898 4.85079C5.83301 5.70643 5.83301 6.82654 5.83301 9.06675V22.9334C5.83301 25.1736 5.83301 26.2937 6.26898 27.1494C6.65248 27.902 7.2644 28.5139 8.01705 28.8974C8.87269 29.3334 9.9928 29.3334 12.233 29.3334H15.833M29.833 29.3334L27.833 27.3334M29.1663 24.0001C29.1663 26.5774 27.077 28.6667 24.4997 28.6667C21.9223 28.6667 19.833 26.5774 19.833 24.0001C19.833 21.4228 21.9223 19.3334 24.4997 19.3334C27.077 19.3334 29.1663 21.4228 29.1663 24.0001Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export const SearchNoDataIcon = withStyles(styles)(SearchNoDataIconComponent);
