import { MenuItem, Select, SelectProps } from '@material-ui/core';
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@material-ui/icons';
import cx from 'classnames';
import { FC, HTMLAttributeAnchorTarget, ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Link } from '@shared/components/link';
import { Tooltip, TooltipProps } from '@shared/components/tooltip';
import { ButtonSize, SelectColour } from '@shared/types/common/button';

import { styles } from './Dropdown.styles';
import { createMenuPlacementObject, MenuPlacement } from './Dropdown.utils';

type Styles = WithStyles<typeof styles>;

export interface IDropdownItem<T extends Id = Id> {
  active?: boolean;
  disabled?: boolean;
  displayedText?: string;
  divider?: boolean;
  endIcon?: ReactNode;
  hovered?: boolean;
  href?: string;
  hrefTarget?: HTMLAttributeAnchorTarget;
  icon?: ReactNode;
  text: string;
  tooltip?: string | number;
  value?: T;
  onClick?: () => void;
}

const renderItem = (classes: Styles['classes'], option: IDropdownItem, index: number) => {
  if (option.divider) {
    return <div key={`divider:${index}`} className={classes.divider} />;
  }

  const uniqueKey = `${option.text}:${index}`;

  const itemComponent = option.href ? (
    <MenuItem
      key={uniqueKey}
      classes={{
        root: cx(classes.menuItem, { [classes.menuItemDisabled]: option.disabled }),
      }}
    >
      <Link target={option.hrefTarget} to={option.href}>
        {!!option?.icon && <span className={classes.menuItemIcon}>{option?.icon}</span>}
        <span className={classes.menuItemText} dangerouslySetInnerHTML={{ __html: option.text }} />
      </Link>
    </MenuItem>
  ) : (
    <MenuItem
      key={uniqueKey}
      classes={{
        root: cx(classes.menuItem, { [classes.menuItemDisabled]: option.disabled }),
        selected: cx({ [classes.menuItemActive]: option.active }),
      }}
      onClick={option.onClick}
    >
      {!!option?.icon && <span className={classes.menuItemIcon}>{option?.icon}</span>}
      <span className={classes.menuItemText} dangerouslySetInnerHTML={{ __html: option.text }} />
    </MenuItem>
  );

  if (option.disabled) {
    return (
      <Tooltip data-value={uniqueKey} key={uniqueKey} placement="left" title={option.tooltip as string}>
        {itemComponent}
      </Tooltip>
    );
  }

  return itemComponent;
};
export interface DropdownComponentProps extends Omit<SelectProps, 'classes' | 'colour' | 'size'>, Styles {
  active?: boolean;
  autoMenuHeight?: boolean;
  colour?: SelectColour;
  disabled?: boolean;
  hovered?: boolean;
  icon?: ReactNode;
  items: IDropdownItem[];
  mainBtnText: string;
  menuPlacement?: MenuPlacement;
  size?: ButtonSize;
  tooltip?: string;
  tooltipPlacement?: TooltipProps['placement'];
  onClose?: () => void;
}

const DropdownComponent: FC<DropdownComponentProps> = ({
  active = false,
  autoMenuHeight = false,
  classes,
  colour = SelectColour.secondaryColour,
  disabled = false,
  hovered = false,
  icon,
  items,
  mainBtnText,
  menuPlacement = 'left',
  size = ButtonSize.small,
  tooltip,
  tooltipPlacement = 'left',
  onClose,
  ...selectProps
}) => {
  const componentRef = useRef<HTMLDivElement | null>(null);

  const [menuOpen, setMenuOpen] = useState(false);
  const [tooltipDisabled, setTooltipDisabled] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  useEffect(() => {
    if (!tooltip) {
      return;
    }

    if (menuOpen) {
      setTooltipOpen(false);
      setTooltipDisabled(true);
    } else {
      setTooltipOpen(false);

      const timeout = setTimeout(() => setTooltipDisabled(false), 200);
      return () => clearTimeout(timeout);
    }
  }, [menuOpen]);

  const className = useMemo(
    () =>
      cx(classes.root, classes[colour], classes[size], {
        [classes.selectActive]: active,
        [classes.selectHovered]: hovered,
        [classes.selectDisabled]: disabled,
      }),
    [active, classes, colour, disabled, hovered, size]
  );

  const selectContent = useMemo(() => {
    return (
      <Select
        {...selectProps}
        classes={{ root: cx(classes.select, classes[size]) }}
        disabled={disabled}
        displayEmpty
        IconComponent={KeyboardArrowDownIcon}
        MenuProps={{
          ...createMenuPlacementObject(menuPlacement),
          ...selectProps.MenuProps,
          getContentAnchorEl: null,
          classes: { paper: cx(classes.menu, { [classes.autoMenuHeight]: autoMenuHeight }) },
        }}
        variant="outlined"
        onClose={(e) => {
          e.stopPropagation();

          setMenuOpen(false);

          if (onClose) {
            onClose();
          } else {
            componentRef.current?.blur();
          }
        }}
        onOpen={() => setMenuOpen(true)}
        renderValue={() => (
          <div className={cx(classes.label, classes[size])}>
            {!!icon && <span className={cx(classes.labelIcon)}>{icon}</span>}
            {mainBtnText}
          </div>
        )}
      >
        {items.map((item, index) => renderItem(classes, item, index))}
      </Select>
    );
  }, [autoMenuHeight, classes, disabled, icon, items, mainBtnText, menuPlacement, onClose, selectProps, size]);

  if (tooltip) {
    return (
      <Tooltip
        classes={{ root: className }}
        open={tooltipOpen}
        placement={tooltipPlacement}
        ref={componentRef}
        title={tooltip}
        onClose={tooltipDisabled ? undefined : () => setTooltipOpen(false)}
        onOpen={tooltipDisabled ? undefined : () => setTooltipOpen(true)}
      >
        {selectContent}
      </Tooltip>
    );
  }

  return (
    <div className={className} ref={componentRef}>
      {selectContent}
    </div>
  );
};

export const Dropdown = withStyles(styles)(DropdownComponent);
