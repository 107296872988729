import { omitBy } from 'lodash';
import { FC, useMemo, useState } from 'react';

import { t } from '@core/i18n';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import {
  DisposalStoreNakedInterestDTO,
  DisposalStoreNakedInterestRequiredDTO,
  DisposalStoreNakedOptionalDTO,
  useDisposalStoreNakedInterestMutation,
} from '@shared/apis/disposals';
import { Checkbox } from '@shared/components/checkbox';
// import { CompanySingleLookup } from '@shared/components/company-lookup/company-single-lookup';
import { Dialog } from '@shared/components/dialog';
import { MultiSelect } from '@shared/components/select-new/multi-select';
import { Option } from '@shared/components/select-new/Select';
import { SingleSelect } from '@shared/components/select-new/single-select';
import { TextField } from '@shared/components/text-field';
import { useCoreOptions, useEmptyOption } from '@shared/contexts/OptionsContext';
import { useChangesRecordState } from '@shared/hooks/changes-state';
// import { MixedCompany } from '@shared/models/company/company';
import { ButtonSize } from '@shared/types/common/button';
import { InputSize } from '@shared/types/common/input';
import { InterestScheduleStatuses, ScheduleConfig } from '@shared/types/common/match';
import { getInterestScheduleStatusOptions } from '@shared/utils/match';

import { styles } from './ReportingAddInterestModal.styles';

type StatusesWithNull = InterestScheduleStatuses | '';

interface FormState {
  sectors: string[];
  status: StatusesWithNull;
  tenantName: string;
}

interface FormErrors {
  tenantName: string;
  status: string;
}

const defaultFormErrors: FormErrors = {
  tenantName: '',
  status: '',
};

const validateForm = (state: FormState): FormErrors => {
  const result: FormErrors = { ...defaultFormErrors };

  if (!state.tenantName) {
    result.tenantName = t('xtextx_is_required', { text: t('tenant') });
  }

  if (!state.status) {
    result.status = t('xtextx_is_required', { text: t('status') });
  }

  return result;
};

export interface ReportingAddInterestModalProps extends WithStyles<typeof styles> {
  disposalId: number;
  scheduleConfig: ScheduleConfig;
  onClose: () => void;
  onCreated: (createAnother: boolean) => void;
}

const ReportingAddInterestModalComponent: FC<ReportingAddInterestModalProps> = ({
  classes,
  disposalId,
  scheduleConfig,
  onClose,
  onCreated,
}) => {
  const { sectorOptions } = useCoreOptions();

  const [formErrors, setFormErrors] = useState<FormErrors>({ ...defaultFormErrors });
  const [submitAttempted, setSubmitAttempted] = useState<boolean>(false);

  const disposalStoreNakedInterestMutation = useDisposalStoreNakedInterestMutation();

  const initialFormState = useMemo<FormState>(() => {
    const result: FormState = {
      sectors: [],
      status: '',
      tenantName: '',
    };

    return result;
  }, []);

  const { state, updateState } = useChangesRecordState<FormState>(initialFormState);
  const [addMoreInterest, setAddMoreInterest] = useState(false);
  // const [company, setCompany] = useState<MixedCompany | undefined>(undefined);

  const statusOptions: Option<StatusesWithNull>[] = useMemo(
    () =>
      getInterestScheduleStatusOptions(scheduleConfig, { withActions: false, withDividers: false }).map((item) => {
        const option: Option<InterestScheduleStatuses> = {
          id: item.value as InterestScheduleStatuses,
          label: item.text,
        };
        return option;
      }),
    [scheduleConfig]
  );

  const statusOptionsWithNull = useEmptyOption(statusOptions, {
    id: '',
    label: t('please_select'),
  });

  const disposalStoreInterestPayload: DisposalStoreNakedInterestDTO = useMemo(() => {
    const required: DisposalStoreNakedInterestRequiredDTO = {
      tenant_name: '',
    };
    const optional: DisposalStoreNakedOptionalDTO = {
      interest_schedule_status: null,
      sectors: null,
    };

    if (state.sectors.length) {
      optional.sectors = state.sectors.length ? state.sectors : null;
    }

    if (state.status) {
      optional.interest_schedule_status = state.status;
    }

    if (state.tenantName) {
      required.tenant_name = state.tenantName;
    }

    return { ...required, ...omitBy(optional, (value) => value === null) };
  }, [state]);

  return (
    <Dialog
      actionNode={<Checkbox checked={addMoreInterest} label={t('add_more_interest')} onChange={setAddMoreInterest} />}
      buttonProps={{
        cancel: {
          disabled: disposalStoreNakedInterestMutation.isLoading,
          onClick: onClose,
        },
        confirm: {
          loading: disposalStoreNakedInterestMutation.isLoading,
          text: t('add_interest'),
          onClick: () => {
            setSubmitAttempted(true);
            const errors = validateForm(state);

            setFormErrors(errors);

            if (Object.values(errors).some((error) => !!error)) {
              return;
            }

            disposalStoreNakedInterestMutation.mutate(
              { disposalId: disposalId, body: disposalStoreInterestPayload },
              {
                onSuccess: () => {
                  onCreated(addMoreInterest);

                  if (addMoreInterest) {
                    updateState(initialFormState);
                    setFormErrors(defaultFormErrors);
                  }
                },
              }
            );
          },
        },
      }}
      classes={{ root: classes.root, body: classes.body, actionButtons: classes.actionsButtons }}
      headingProps={{
        heading: t('add_interest'),
        withCloseButton: true,
      }}
      open
      withTransition
      onClose={onClose}
    >
      <div className={classes.content}>
        <TextField
          fullWidth
          error={submitAttempted && !!formErrors.tenantName}
          errorText={formErrors.tenantName}
          label={t('xtextx_asterisk_1', { text: t('who_is_the_tenant') })}
          size={InputSize.medium}
          value={state.tenantName}
          onChange={(event) => {
            updateState({ tenantName: event.target.value });
            if (submitAttempted) {
              setFormErrors(validateForm({ ...state, tenantName: event.target.value }));
            }
          }}
        />
        {/* <CompanySingleLookup
          classes={{
            labelRoot: classes.label,
            //   labelError: classes.labelError,
            //   autocompleteRoot: classes.autocompleteRoot,
          }}
          inputSize={InputSize.medium}
          label={t('xtextx_asterisk_1', { text: t('who_is_the_tenant') })}
          placeholder={t('Search for tenant')}
          value={company}
          onCreateCompany={(name) => setCompany({ name: name })}
          onRemoveCompany={() => setCompany(undefined)}
          onSelectCompany={(company) => setCompany(company)}
        /> */}
        <MultiSelect
          classes={{ root: classes.multiSelect }}
          label={t('sectors')}
          options={sectorOptions}
          size={ButtonSize.medium}
          value={state.sectors}
          onChange={(sectors) => updateState({ sectors: sectors })}
        />
        <SingleSelect
          error={submitAttempted && !!formErrors.status}
          errorText={formErrors.status}
          label={t('xtextx_asterisk_1', { text: t('status') })}
          options={statusOptionsWithNull}
          size={ButtonSize.medium}
          value={state.status}
          onChange={(status) => {
            updateState({ status: status });

            if (submitAttempted) {
              setFormErrors(validateForm({ ...state, status: status }));
            }
          }}
        />
      </div>
    </Dialog>
  );
};

export const ReportingAddInterestModal = withStyles(styles)(ReportingAddInterestModalComponent);
