import cx from 'classnames';
import { FC, useCallback } from 'react';

import { usersQueryKeys } from '@apis/usersApi';
import { t } from '@core/i18n';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { AutocompleteWithQuery } from '@shared/components/autocomplete-with-query/AutocompleteWithQuery';
import { Avatar } from '@shared/components/avatar';
import { DraggableList } from '@shared/components/draggable-list';
import { Flex } from '@shared/components/flex';
import { TextFieldProps } from '@shared/components/text-field';
import { CloseIcon } from '@shared/icons/close';
import { DragIcon } from '@shared/icons/drag';
import { ListUserItem } from '@shared/models/user';
import { LookupUserItem } from '@shared/types/common/common';
import { listUserToLookupUser } from '@shared/utils/lookup';

import { styles } from './UsersLookupWithDraggableList.styles';

export interface UsersLookupWithDraggableListProps extends WithStyles<typeof styles> {
  actionRow?: JSX.Element;
  disabled?: boolean;
  dropUp?: boolean;
  error?: boolean;
  inputProps?: Omit<TextFieldProps, 'classes'>;
  items: LookupUserItem[];
  label?: string;
  searchValue: string;
  onDragEnd: (list: LookupUserItem[]) => void;
  onMatchedDelete: (id: number) => void;
  onSearchValueChange: (value: string) => void;
  onSelect: (option: LookupUserItem) => void;
}

const UsersLookupWithDraggableListComponent: FC<UsersLookupWithDraggableListProps> = ({
  actionRow,
  classes,
  disabled = false,
  dropUp = false,
  error = false,
  inputProps,
  items,
  label = '',
  searchValue,
  onDragEnd,
  onMatchedDelete,
  onSearchValueChange,
  onSelect,
}) => {
  const renderAutocompleOption = useCallback((option: ListUserItem) => {
    return (
      <Flex alignItems="center">
        <Avatar
          classes={{ root: classes.optionAvatar, noImageInitials: classes.optionAvatarInitials }}
          image={option.image.url}
          name={option.name}
          theme={option.theme}
        />
        {`${option.name}${option?.organisation?.name ? ` (${option.organisation.name})` : ''}`}
      </Flex>
    );
  }, []);

  const renderDraggableOption = useCallback(
    (option: LookupUserItem, action?: boolean) => {
      return (
        <div className={cx(classes.dragOption, { [classes.draggableOption]: !disabled && action })}>
          <Flex wrap="nowrap" alignItems="center" autoWidth={!action} justifyContent="space-between">
            <Flex autoWidth={false} alignItems="center" wrap="nowrap">
              {!disabled && action && <DragIcon classes={{ root: classes.dragIcon }} />}
              <Avatar
                classes={{ root: classes.selectedOptionAvatar, noImageInitials: classes.selectedOptionAvatarInitials }}
                image={option.image}
                name={option.name}
                theme={option.theme}
              />
              <Flex wrap="nowrap">
                <span className={classes.userName}>{`${option.name}${
                  option?.organisationName ? ` (${option.organisationName})` : ''
                }`}</span>
              </Flex>
            </Flex>
            {!disabled && action && (
              <Flex className={classes.closeIconContainer} onClick={() => onMatchedDelete(option.id)}>
                <CloseIcon />
              </Flex>
            )}
          </Flex>
        </div>
      );
    },
    [classes, disabled]
  );

  return (
    <Flex autoWidth={false} classes={{ root: classes.root }}>
      <AutocompleteWithQuery
        actionRow={actionRow}
        classes={{
          root: classes.searchProperty,
          label: classes.label,
          listItem: classes.autocompleteListItem,
          optionsList: classes.optionsList,
        }}
        dropUp={dropUp}
        error={error}
        inputProps={{
          InputProps: {
            startAdornment: <div className={classes.avatarPlaceholder} />,
            disabled: disabled,
          },
          placeholder: t('xtextx_ellipsis', { text: t('search_users') }),
          ...inputProps,
        }}
        label={label}
        queryFactory={usersQueryKeys.list}
        searchValue={searchValue}
        withClearIcon
        onSearchValueChange={onSearchValueChange}
        onSelect={(user) => onSelect(listUserToLookupUser(user))}
        renderOption={renderAutocompleOption}
      />
      {items.length > 0 && (
        <DraggableList
          classes={{ root: classes.draggableList, item: classes.draggableListItem }}
          disabled={disabled}
          items={items}
          onDragEnd={onDragEnd}
          renderItem={(item: LookupUserItem) => renderDraggableOption(item, true)}
        />
      )}
    </Flex>
  );
};
export const UsersLookupWithDraggableList = withStyles(styles)(UsersLookupWithDraggableListComponent);
