import { t } from 'i18next';
import { FC, useMemo, useState } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { SearchField } from '@shared/components/search-field';
import { Tab, TabConfigItem } from '@shared/components/tab';
import { UrlViewState, useUrlState } from '@shared/hooks/viewState';
import { ArchiveIcon } from '@shared/icons/archive';
import { InterestScheduleData } from '@shared/types/common/match';
import { getQueries, getQueryValueString } from '@shared/utils/common';

import { styles } from './ReportingInboundSpace.styles';

enum InboundTab {
  UnactionedEnquiries = 'unactioned_enquiries',
  Archive = 'archive',
}

// --- URL State ---

export interface InboundUrlState extends UrlViewState {
  inbound_search: string;
  inbound_tab: InboundTab;
}

export const defaultInboundUrlState: InboundUrlState = {
  inbound_search: '',
  inbound_tab: InboundTab.UnactionedEnquiries,
};

export const getInboundUrlState = (queries: Query) => {
  const result: InboundUrlState = {
    ...defaultInboundUrlState,
    inbound_search: getQueryValueString('inbound_search', queries),
    inbound_tab: getQueryValueString('inbound_tab', queries) as InboundTab,
  };

  return result;
};

// --- Page State ---

export const getInitialPageState = () => ({
  // columns: false,
  // filters: false,
  // search_open: false,
});

export interface ReportingInboundSpaceProps extends WithStyles<typeof styles> {
  id: string;
  scheduleData: InterestScheduleData;
}

const ReportingInboundSpaceComponent: FC<ReportingInboundSpaceProps> = ({ classes }) => {
  const { urlState, updateUrlState } = useUrlState<InboundUrlState>(() => getInboundUrlState(getQueries()));
  const [searchFieldValue, setSearchFieldValue] = useState<string>('');

  const tabsContent: TabConfigItem[] = useMemo(
    () => [
      {
        label: t('unactioned_enquiries'),
        value: InboundTab.UnactionedEnquiries,
        count: 0,
      },
      {
        icon: <ArchiveIcon />,
        label: t('archive'),
        value: InboundTab.Archive,
      },
    ],
    []
  );

  const content = useMemo(() => {
    switch (urlState.inbound_tab) {
      case InboundTab.UnactionedEnquiries:
        return <div>Unactioned</div>;
      case InboundTab.Archive:
        return <div>Archived</div>;
      default:
        return null;
    }
  }, [urlState.inbound_tab]);

  return (
    <div className={classes.body}>
      <div className={classes.leftPanel}>
        <div className={classes.searchFieldWrapper}>
          <SearchField
            placeholder="Search inbounds"
            search={searchFieldValue}
            onChange={(search) => setSearchFieldValue(search)}
            className={classes.searchField}
          />
        </div>
        {!!tabsContent?.length && (
          <div className={classes.contentTabs}>
            <div className={classes.tabs}>
              {tabsContent.map((tab) => (
                <Tab
                  active={tab.value === urlState.inbound_tab}
                  classes={{ root: classes.tab }}
                  icon={tab.icon}
                  key={tab.value}
                  label={tab.label ?? ''}
                  value={tab.value}
                  count={tab.count}
                  onClick={(tab) => updateUrlState({ inbound_tab: tab })}
                />
              ))}
            </div>
          </div>
        )}
        <div className={classes.leftPanelContent}>{content}</div>
      </div>
      <div className={classes.rightPanel}>Messaging Panel</div>
    </div>
  );
};

export const ReportingInboundSpace = withStyles(styles)(ReportingInboundSpaceComponent);
