import { createStyles, Theme } from '@material-ui/core/styles';

import { TableTheme } from '../table-new';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    checkboxRootIconOnly: {
      marginRight: 0,
      padding: spacing(2.5, 2),
    },
    headerCheckboxCell: {
      padding: '0 !important',
    },

    // TableNew classes
    root: {},
    rootFullHeight: {},
    bodyRoot: {},
    bodyCell: {},
    bodyCellTextClass: {},
    cell: {},
    emptyCell: {},
    emptyContent: {},
    footerRoot: {},
    footerCell: {},
    headerRoot: {},
    headerCell: {},
    headerCellDisabled: {},
    headerCellActive: {},
    headerCellTextWrapper: {},
    headerCellText: {},
    loading: {},
    menuItem: {},
    sortActiveIcon: {},
    sortIcon: {},
    sortIcons: {},
    row: {
      '&> *:first-child': {
        padding: 0,
      },
    },
    rowHighlighted: {},
    table: {},
    wrapper: {},
    [TableTheme.Page]: {},
  });
