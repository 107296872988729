import { createStyles } from '@material-ui/core/styles';

export const styles = () =>
  createStyles({
    root: {
      fill: 'transparent',
      fontSize: 15,
      color: '#7E8899', //TODO replace after creating new palette
    },
  });
