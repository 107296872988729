import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

import { BadgeSize, BadgeTheme } from './Badge';

export const styles = ({ spacing, shape }: Theme) =>
  createStyles({
    root: {},
    badge: {
      alignItems: 'center',
      borderRadius: 6,
      display: 'inline-flex',
      fontWeight: 600,
      letterSpacing: 0.5,
      lineHeight: '1',
    },
    counterRoot: {
      border: `1px solid transparent`,
      lineHeight: 'normal',
    },
    counterRootZero: {
      border: `1px solid transparent`,
    },
    counterCount: {},
    counterCountZero: {},

    [BadgeSize.small]: {
      fontSize: 12,
      gap: spacing(1),
      height: 20,
      padding: spacing(0.5, 1),

      '& $counterRoot': {
        height: 17,
        minWidth: 17,
        width: 17,
      },
      '& $counterCount': {
        fontSize: 12,
      },
    },
    [BadgeSize.large]: {
      fontSize: 14,
      gap: spacing(2),
      height: 24,
      padding: spacing(1, 2),

      '& $counterRoot': {
        height: 20,
        minWidth: 20,
        width: 20,
      },
      '& $counterCount': {
        fontSize: 14,
      },
    },

    [BadgeTheme.grey]: {
      border: `1px solid ${colors.grey.lighter}`,
      backgroundColor: colors.grey.lighter,
      color: colors.grey.main,

      '& $counterRoot': {
        backgroundColor: colors.grey.main,
      },
      '& $counterRootZero': {
        backgroundColor: colors.white.lightest,
        borderColor: colors.grey.main,
      },
      '& $counterCount': {
        color: colors.grey.lighter,
      },
      '& $counterCountZero': {
        color: colors.grey.main,
      },
    },
    [BadgeTheme.green]: {
      border: `1px solid ${colors.green.lightest}`,
      backgroundColor: colors.green.lightest,
      color: '#027A48', // TODO: Change after palette updated

      '& $counterRoot': {
        backgroundColor: '#027A48', // TODO: Change after palette updated
      },
      '& $counterRootZero': {
        backgroundColor: colors.white.lightest,
        borderColor: '#027A48', // TODO: Change after palette updated
      },
      '& $counterCount': {
        color: colors.green.lightest,
      },
      '& $counterCountZero': {
        color: '#027A48', // TODO: Change after palette updated
      },
    },
    [BadgeTheme.warning]: {
      border: `1px solid ${colors.orange.main}`,
      backgroundColor: colors.orange.main,
      color: colors.white.lightest,

      '& $counterRoot': {
        backgroundColor: colors.white.lightest,
      },
      '& $counterRootZero': {
        backgroundColor: colors.white.lightest,
        borderColor: colors.white.lightest,
      },
      '& $counterCount': {
        color: colors.orange.main,
      },
      '& $counterCountZero': {
        color: colors.orange.main,
      },
    },
    [BadgeTheme.danger]: {
      border: `1px solid ${colors.red.main}`,
      backgroundColor: colors.red.main,
      color: colors.white.lightest,

      '& $counterRoot': {
        backgroundColor: colors.white.lightest,
      },
      '& $counterRootZero': {
        backgroundColor: colors.white.lightest,
        borderColor: colors.white.lightest,
      },
      '& $counterCount': {
        color: colors.red.main,
      },
      '& $counterCountZero': {
        color: colors.red.main,
      },
    },
    [BadgeTheme.purple]: {
      border: `1px solid ${colors.purple.light}`,
      backgroundColor: colors.purple.light,
      color: colors.purple.main,

      '& $counterRoot': {
        backgroundColor: colors.purple.main,
      },
      '& $counterRootZero': {
        backgroundColor: colors.white.lightest,
        borderColor: colors.purple.main,
      },
      '& $counterCount': {
        color: colors.purple.light,
      },
      '& $counterCountZero': {
        color: colors.purple.main,
      },
    },
    [BadgeTheme.blue]: {
      border: `1px solid ${colors.blue.light}`,
      backgroundColor: colors.blue.light,
      color: colors.blue.darkish,

      '& $counterRoot': {
        backgroundColor: colors.blue.darkish,
      },
      '& $counterRootZero': {
        backgroundColor: colors.white.lightest,
        borderColor: colors.blue.darkish,
      },
      '& $counterCount': {
        color: colors.blue.light,
      },
      '& $counterCountZero': {
        color: colors.blue.darkish,
      },
    },
    [BadgeTheme.empty]: {
      border: `1px solid transparent`,
      backgroundColor: 'transparent',
      color: colors.grey.main,

      '& $counterRoot': {
        backgroundColor: colors.grey.main,
      },
      '& $counterRootZero': {
        backgroundColor: colors.white.lightest,
        borderColor: colors.grey.main,
      },
      '& $counterCount': {
        color: colors.white.lightest,
      },
      '& $counterCountZero': {
        color: colors.grey.main,
      },
    },
    [BadgeTheme.deepBlue]: {
      border: `1px solid transparent`,
      backgroundColor: '#EBEEFF', // TODO: Change after palette updated
      color: '#263CD1', // TODO: Change after palette updated

      '& $counterRoot': {
        backgroundColor: '#263CD1', // TODO: Change after palette updated
      },
      '& $counterRootZero': {
        backgroundColor: colors.white.lightest,
        borderColor: '#263CD1', // TODO: Change after palette updated
      },
      '& $counterCount': {
        color: '#EBEEFF', // TODO: Change after palette updated
      },
      '& $counterCountZero': {
        color: '#263CD1', // TODO: Change after palette updated
      },
    },
    [BadgeTheme.orange]: {
      border: `1px solid transparent`,
      backgroundColor: '#FFF5EB', // TODO: Change after palette updated
      color: '#B54708', // TODO: Change after palette updated

      '& $counterRoot': {
        backgroundColor: '#B54708', // TODO: Change after palette updated
      },
      '& $counterRootZero': {
        backgroundColor: colors.white.lightest,
        borderColor: '#B54708', // TODO: Change after palette updated
      },
      '& $counterCount': {
        color: '#FFF5EB', // TODO: Change after palette updated
      },
      '& $counterCountZero': {
        color: '#B54708', // TODO: Change after palette updated
      },
    },
    // New
    [BadgeTheme.navy]: {
      border: `1px solid ${colors.kato.navy[100]}`,
      backgroundColor: colors.kato.navy[100],
      color: colors.kato.navy[800],

      '& $counterRoot': {
        backgroundColor: colors.kato.navy[800],
      },
      '& $counterRootZero': {
        backgroundColor: colors.white.lightest,
        borderColor: colors.kato.navy[800],
      },
      '& $counterCount': {
        color: colors.kato.navy[100],
      },
      '& $counterCountZero': {
        color: colors.kato.navy[800],
      },
    },
    [BadgeTheme.teal]: {
      border: `1px solid ${colors.kato.teal[100]}`,
      backgroundColor: colors.kato.teal[100],
      color: colors.kato.teal[800],

      '& $counterRoot': {
        backgroundColor: colors.kato.teal[800],
      },
      '& $counterRootZero': {
        backgroundColor: colors.white.lightest,
        borderColor: colors.kato.teal[800],
      },
      '& $counterCount': {
        color: colors.kato.teal[100],
      },
      '& $counterCountZero': {
        color: colors.kato.teal[800],
      },
    },
    [BadgeTheme.white]: {
      border: `1px solid #EAECF0`,
      backgroundColor: colors.white.lightest,
      color: colors.black.main,

      '& $counterRoot': {
        backgroundColor: colors.grey.main,
      },
      '& $counterRootZero': {
        backgroundColor: colors.white.lightest,
        borderColor: colors.grey.main,
      },
      '& $counterCount': {
        color: colors.white.lightest,
      },
      '& $counterCountZero': {
        color: colors.grey.main,
      },
    },
  });
