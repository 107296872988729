import { FC } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';

import { styles } from './ContentOrIframe.styles';

export interface ContentOrIframeProps extends WithStyles<typeof styles> {
  showContent: boolean;
  iframeUrl?: string;
}

const ContentOrIframeComponent: FC<ContentOrIframeProps> = ({ children, classes, showContent, iframeUrl }) => {
  if (showContent) {
    return <>{children}</>;
  }

  return <iframe src={iframeUrl} className={classes.iframe} />;
};

export const ContentOrIframe = withStyles(styles)(ContentOrIframeComponent);
