import { FC } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { ContentOrIframe } from '@shared/components/content-or-iframe';

import { styles } from './DisposalReportingPresentInsightsSpace.styles';

export type DisposalReportingPresentInsightsSpaceProps = WithStyles<typeof styles> & {
  iframeUrl: string;
};

const DisposalReportingPresentInsightsSpaceComponent: FC<DisposalReportingPresentInsightsSpaceProps> = ({
  classes,
  iframeUrl,
}) => {
  return <ContentOrIframe classes={{ iframe: classes.iframe }} showContent={false} iframeUrl={iframeUrl} />;
};

export const DisposalReportingPresentInsightsSpace = withStyles(styles)(DisposalReportingPresentInsightsSpaceComponent);
