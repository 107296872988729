import { createStyles, Theme } from '@material-ui/core/styles';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      display: 'inline-flex',
      flexDirection: 'row',
      height: '100%',
      gap: spacing(3),
      overflowX: 'auto',
      overflowY: 'hidden',
      width: '100%',
    },
    boardLane: {
      width: 296,
      minWidth: 296,
    },
  });
