import { FC } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Dialog } from '@shared/components/dialog';
import { Flex } from '@shared/components/flex';
import { AlertIcon } from '@shared/icons/alert';
import { ButtonSize } from '@shared/types/common/button';

import { styles } from './AlertConfirmationModal.styles';

// TODO: Figure out whether this can be blended with Dialog in Confirmation.tsx

export interface AlertConfirmationModalProps extends WithStyles<typeof styles> {
  open: boolean;
  header: string;
  text: string;
  confirmButtonText: string;
  onClose: () => void;
  onConfirm: () => void;
}

const AlertConfirmationModalComponent: FC<AlertConfirmationModalProps> = ({
  classes,
  confirmButtonText,
  header,
  text,
  open,
  onClose,
  onConfirm,
}) => {
  return (
    <Dialog
      classes={{
        root: classes.root,
        body: classes.body,
        actionButtons: classes.actionButtons,
        actions: classes.actions,
      }}
      buttonProps={{
        cancel: {
          size: ButtonSize.medium,
          onClick: onClose,
        },
        confirm: {
          size: ButtonSize.medium,
          text: confirmButtonText,
          onClick: onConfirm,
        },
      }}
      open={open}
      withTransition
      onClose={onClose}
    >
      <>
        <Flex alignItems="center" autoWidth={false} justifyContent="center" className={classes.iconContainer}>
          <AlertIcon color="#DC6803" classes={{ root: classes.icon }} />
        </Flex>
        <span className={classes.heading}>{header}</span>
        <span className={classes.text}>{text}</span>
      </>
    </Dialog>
  );
};

export const AlertConfirmationModal = withStyles(styles)(AlertConfirmationModalComponent);
