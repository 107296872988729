import mimeDb from 'mime-db/db.json';

export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

type MimeWithExtensions = {
  [K in keyof typeof mimeDb]: typeof mimeDb[K] extends { extensions: string[] } ? K : never;
}[keyof typeof mimeDb];

export type MimeToExtensions = {
  [K in MimeWithExtensions]?: string[];
};

// Create a hash based on key to list of mime types
export const mimeGroups: {
  cad: MimeWithExtensions[];
  document: MimeWithExtensions[];
  images: MimeWithExtensions[];
  pdf: MimeWithExtensions[];
  presentation: MimeWithExtensions[];
  spreadsheet: MimeWithExtensions[];
  zip: MimeWithExtensions[];
} = {
  cad: ['image/vnd.dwg'],
  document: [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.oasis.opendocument.text',
    'application/rtf',
    'application/vnd.ms-works',
    'application/vnd.ms-xpsdocument',
  ],
  images: ['image/jpeg', 'image/png'],
  pdf: ['application/pdf'],
  presentation: [
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  ],
  spreadsheet: [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/csv',
    'application/vnd.oasis.opendocument.spreadsheet',
    'application/vnd.ms-excel.addin.macroenabled.12',
    'application/vnd.ms-excel.sheet.binary.macroenabled.12',
    'application/vnd.ms-excel.sheet.macroenabled.12',
  ],
  zip: ['application/zip'],
};
