import { Dialog, Fade } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { forwardRef, ReactElement, ReactNode, Ref, useMemo } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { IconButton, IconButtonProps } from '@shared/components/icon-button';
import { ButtonColour, ButtonSize } from '@shared/types/common/button';
import { SpaceItem } from '@shared/types/common/common';

import { styles } from './SpacesOverlay.styles';

const SpacesOverlayTransition = forwardRef(
  (props: TransitionProps & { children?: ReactElement<any, any> }, ref: Ref<unknown>) => <Fade ref={ref} {...props} />
);

export interface SpacesOverlayProps extends WithStyles<typeof styles> {
  activeSpace: string;
  headerActions?: ReactNode;
  headerTitle: ReactNode;
  open: boolean;
  spaces: SpaceItem[];
  onClose: () => void;
  onSelectSpace: (id: string) => void;
}

const SpacesOverlayComponent: React.FC<SpacesOverlayProps> = ({
  activeSpace,
  children,
  classes,
  headerActions,
  headerTitle,
  open,
  spaces,
  onClose,
  onSelectSpace,
}) => {
  const spaceButtons: Omit<IconButtonProps, 'classes'>[] = useMemo(
    () =>
      spaces.map((space) => ({
        active: space.id === activeSpace,
        icon: space.icon,
        tooltip: space.name,
        onClick: () => onSelectSpace(space.id),
      })),
    [activeSpace, onSelectSpace, spaces]
  );

  return (
    <Dialog
      classes={{ root: classes.dialogRoot, paper: classes.root }}
      open={open}
      TransitionComponent={SpacesOverlayTransition}
      onClose={onClose}
    >
      <div className={classes.header}>
        <div className={classes.headerTitle}>{headerTitle}</div>
        <div className={classes.headerActions}>{headerActions}</div>
      </div>

      <div className={classes.content}>
        <div className={classes.sidebar}>
          {spaceButtons.map((buttonProps, idx) => (
            <IconButton
              key={idx}
              {...buttonProps}
              tooltipPlacement="right"
              classes={{ root: classes.spaceButton }}
              colour={ButtonColour.secondaryGhost}
              size={ButtonSize.medium}
            />
          ))}
        </div>

        <div className={classes.body}>{children}</div>
      </div>
    </Dialog>
  );
};

export const SpacesOverlay = withStyles(styles)(SpacesOverlayComponent);
