import { createStyles } from '@material-ui/core/styles';

export default function styles() {
  return createStyles({
    root: {
      fill: 'transparent',
      fontSize: 16,
    },
  });
}
