import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

export default function styles({ breakpoints, spacing }: Theme) {
  return createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      position: 'relative',
      height: '100%',
    },
    tableContainerRoot: {
      overflow: 'initial',
    },
    tableHeaderCell: {},
    tableBody: {
      overflow: 'auto',
      zIndex: 1,
    },
    tableBodyCell: {},
    tableBodyLoading: {
      overflowY: 'initial',
    },
    bodyContentFullHeight: {
      overflow: 'auto',
      flex: '1 1 auto',
    },
    emptyCell: {},
    emptyContent: {
      height: 300,
    },
    stateOverlay: {
      alignItems: 'flex-start',
      borderRadius: 6,
      bottom: 0,
      display: 'flex',
      justifyContent: 'center',
      position: 'absolute',
      right: 0,
      top: 0,
      width: '100%',
      zIndex: 2,
    },
    stateOverlayContent: {
      marginTop: spacing(3),
      zIndex: 3,

      '&:not($stateOverlayContentShort)': {
        marginTop: spacing(15),

        '&$stateOverlayContentLoading': {
          marginTop: spacing(20),
        },
      },

      '&:not($stateOverlayContentLoading)': {
        backgroundColor: colors.white.lightest,
        border: `1px solid ${colors.grey.fainter}`,
        borderRadius: 4,
        boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.1)',
        maxWidth: 600,
        padding: spacing(12, 6),
        width: '100%',

        [breakpoints.down('sm')]: {
          zIndex: 0,
          padding: spacing(3, 1.5),
        },
      },
    },

    // Utility classes - DO NOT STYLE
    stateOverlayContentLoading: {},
    stateOverlayContentShort: {},
  });
}
