import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {},
    iconContainer: {
      width: 64,
      height: 64,
      background: colors.grey.lighter,
      borderRadius: 8,

      '& > svg': {
        fontSize: '32px !important',
      },
    },
    stateHeader: {
      fontSize: 16,
      fontWeight: 600,
      marginBottom: 0,
      marginTop: spacing(4),
      textAlign: 'center',
    },
    stateText: {
      color: '#7E8899', //TODO change after creating new palette
      fontSize: 14,
      marginTop: spacing(1),
      textAlign: 'center',
    },
    actions: {
      marginTop: spacing(2),
      textAlign: 'center',

      '& > *:not(:first-child)': {
        marginLeft: spacing(2),
      },
    },
  });
