import { SvgIcon } from '@material-ui/core';
import { FC } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';

import styles from './Lock.styles';

export interface LockIconProps extends WithStyles<typeof styles> {
  locked: boolean;
}

const LockIconComponent: FC<LockIconProps> = ({ classes, locked }) => {
  return locked ? (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      classes={{ root: classes.root }}
    >
      <path
        d="M3 10.5333C3 9.41323 3 8.85318 3.21799 8.42535C3.40973 8.04903 3.71569 7.74307 4.09202 7.55132C4.51984 7.33333 5.0799 7.33333 6.2 7.33333H10.4667C11.5868 7.33333 12.1468 7.33333 12.5746 7.55132C12.951 7.74307 13.2569 8.04903 13.4487 8.42535C13.6667 8.85318 13.6667 9.41323 13.6667 10.5333V10.8C13.6667 11.9201 13.6667 12.4802 13.4487 12.908C13.2569 13.2843 12.951 13.5903 12.5746 13.782C12.1468 14 11.5868 14 10.4667 14H6.2C5.0799 14 4.51984 14 4.09202 13.782C3.71569 13.5903 3.40973 13.2843 3.21799 12.908C3 12.4802 3 11.9201 3 10.8V10.5333Z"
        fill="#201E42"
      />
      <path
        d="M11.6667 7.33333V5.33333C11.6667 3.49238 10.1743 2 8.33333 2C6.49238 2 5 3.49238 5 5.33333V7.33333M6.2 14H10.4667C11.5868 14 12.1468 14 12.5746 13.782C12.951 13.5903 13.2569 13.2843 13.4487 12.908C13.6667 12.4802 13.6667 11.9201 13.6667 10.8V10.5333C13.6667 9.41323 13.6667 8.85318 13.4487 8.42535C13.2569 8.04903 12.951 7.74307 12.5746 7.55132C12.1468 7.33333 11.5868 7.33333 10.4667 7.33333H6.2C5.0799 7.33333 4.51984 7.33333 4.09202 7.55132C3.71569 7.74307 3.40973 8.04903 3.21799 8.42535C3 8.85318 3 9.41323 3 10.5333V10.8C3 11.9201 3 12.4802 3.21799 12.908C3.40973 13.2843 3.71569 13.5903 4.09202 13.782C4.51984 14 5.0799 14 6.2 14Z"
        stroke="#201E42"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  ) : (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      classes={{ root: classes.root }}
    >
      <path
        d="M9.63388 2.42497C9.15373 2.15155 8.59356 1.99817 8.00004 1.99817C6.15946 1.99817 4.66566 3.49197 4.66566 5.33254V7.33317M5.86604 14.0019H10.134C11.2544 14.0019 11.8146 14.0019 12.2414 13.7819C12.6148 13.5885 12.9216 13.2817 13.115 12.9082C13.335 12.4814 13.335 11.9213 13.335 10.8009V10.5342C13.335 9.41382 13.335 8.85364 13.115 8.42684C12.9216 8.05339 12.6148 7.74663 12.2414 7.55324C11.8146 7.33317 11.2544 7.33317 10.134 7.33317H5.86604C4.74569 7.33317 4.18551 7.33317 3.75871 7.55324C3.38526 7.74663 3.0785 8.05339 2.88511 8.42684C2.66504 8.85364 2.66504 9.41382 2.66504 10.5342V10.8009C2.66504 11.9213 2.66504 12.4814 2.88511 12.9082C3.0785 13.2817 3.38526 13.5885 3.75871 13.7819C4.18551 14.0019 4.74569 14.0019 5.86604 14.0019Z"
        stroke="#9EA6B3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export const LockIcon = withStyles(styles)(LockIconComponent);
