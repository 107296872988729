import { FC } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Avatar } from '@shared/components/avatar';
import { Flex } from '@shared/components/flex';
import { AgentMessage } from '@shared/types/common/agentMessage';
import { formatDate } from '@shared/utils/date';
import { normalizedHTMLText } from '@shared/utils/text';

import { styles } from './ReceivedMessageItem.styles';

export interface ReceivedMessageItemProps extends WithStyles<typeof styles> {
  message: AgentMessage;
}

const ReceivedMessageItemComponent: FC<ReceivedMessageItemProps> = ({ classes, message }) => {
  return (
    <Flex alignItems="flex-start" autoWidth={false} classes={{ root: classes.root }} wrap="nowrap">
      <Avatar
        classes={{ root: classes.userAvatar, noImageInitials: classes.userAvatarNoInitials }}
        image={message.by_image}
        name={message.by_name}
        theme={message.by_theme}
      />
      <div className={classes.container}>
        <Flex direction="column" classes={{ root: classes.messageContainer }}>
          <span className={classes.userName}>{message.by_name}</span>
          <span className={classes.message} dangerouslySetInnerHTML={{ __html: normalizedHTMLText(message.message) }} />
        </Flex>
        <div className={classes.messageDate}>{formatDate(message.created_at, 'MMM D YYYY, H:mm a')} </div>
      </div>
      <div className={classes.emptySpace} />
    </Flex>
  );
};

export const ReceivedMessageItem = withStyles(styles)(ReceivedMessageItemComponent);
