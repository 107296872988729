import { FC, useMemo, useState } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { DisposalInterestIndexItem } from '@shared/apis/disposals';
import { Dialog } from '@shared/components/dialog';
import { Option } from '@shared/components/select-new/Select';
import { SingleSelect } from '@shared/components/select-new/single-select';
import { TextField } from '@shared/components/text-field';
import { useEmptyOption } from '@shared/contexts/OptionsContext';
import { useInterestScheduleConfig } from '@shared/hooks/interestSchedule.hooks';
import { t } from '@shared/i18n';
import { ButtonSize } from '@shared/types/common/button';
import { InterestScheduleStatuses } from '@shared/types/common/match';
import { getInterestScheduleStatusOptions } from '@shared/utils/match';

import { styles } from './InterestScheduleBulkChangeStatusModal.styles';

type StatusesWithNull = InterestScheduleStatuses | '';

export type InterestScheduleBulkChangeStatusModalProps = WithStyles<typeof styles> & {
  interest: DisposalInterestIndexItem;
  loading?: boolean;
  onClose: () => void;
  onSave: (status: StatusesWithNull, comment: string) => void;
};

const InterestScheduleBulkChangeStatusModalComponent: FC<InterestScheduleBulkChangeStatusModalProps> = ({
  classes,
  interest,
  loading,
  onClose,
  onSave,
}) => {
  const { scheduleConfig } = useInterestScheduleConfig(interest.is_investment);
  const [status, setStatus] = useState<StatusesWithNull>('');
  const [comment, setComment] = useState<string>('');

  const statusOptions = useMemo(
    () =>
      getInterestScheduleStatusOptions(scheduleConfig, { interest, withActions: false, withDividers: false })
        .map((item) => {
          const option: Option<InterestScheduleStatuses> = {
            id: item.value as InterestScheduleStatuses,
            label: item.text,
          };
          return option;
        })
        .filter((option) => option.id !== interest.interest_schedule_status),
    [interest, scheduleConfig]
  );

  const statusOptionsWithNull = useEmptyOption<Option<StatusesWithNull>>(statusOptions, {
    id: '',
    label: t('please_select'),
  });

  const handleSubmit = () => {
    onSave(status, comment);
  };

  return (
    <Dialog
      buttonProps={{
        cancel: {
          disabled: loading,
          onClick: onClose,
        },
        confirm: {
          disabled: !status || loading,
          text: t('update_match_status'),
          onClick: handleSubmit,
        },
      }}
      classes={{ actionButtons: classes.actionButtons }}
      headingProps={{
        heading: t('bulk_update_match_status'),
        withCloseButton: true,
      }}
      open
      withTransition
      onClose={onClose}
    >
      <SingleSelect<StatusesWithNull>
        label={t('status')}
        options={statusOptionsWithNull}
        size={ButtonSize.medium}
        value={status}
        onChange={(value) => setStatus(value)}
      />
      <TextField
        fullWidth
        label={t('comment')}
        minRows={3}
        multiline
        placeholder={t('xtextx_ellipsis', { text: t('add_a_comment_you_can_add_others_with_at_to_notify_them') })}
        value={comment}
        onChange={(e) => setComment(e.target.value)}
      />
    </Dialog>
  );
};

export const InterestScheduleBulkChangeStatusModal = withStyles(styles)(InterestScheduleBulkChangeStatusModalComponent);
