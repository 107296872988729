import { SvgIcon } from '@material-ui/core';
import { FC } from 'react';

import { withStyles, WithStyles } from '@core/theme/utils/with-styles';

import styles from './Refresh.styles';

interface RefreshIconProps extends WithStyles<typeof styles> {
  color?: string;
}

const RefreshIconComponent: FC<RefreshIconProps> = ({ classes, color = '#F59638' }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      classes={{ root: classes.root }}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M1 7C1 7 1.06066 7.42462 2.81802 9.18198C4.57538 10.9393 7.42462 10.9393 9.18198 9.18198C9.80462 8.55934 10.2067 7.79964 10.3881 7M1 7V10M1 7H4M11 5C11 5 10.9393 4.57538 9.18198 2.81802C7.42462 1.06066 4.57538 1.06066 2.81802 2.81802C2.19538 3.44066 1.79335 4.20036 1.61191 5M11 5V2M11 5H8"
          stroke={color}
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export const RefreshIcon = withStyles(styles)(RefreshIconComponent);
