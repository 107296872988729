import { ConfigCoreConstants } from '@shared/models/config';
import {
  ScheduleMatchFieldAllValues,
  ScheduleMatchFieldCasts,
  ScheduleMatchFieldCastValues,
  ScheduleMatchFieldsSerialised,
} from '@shared/types/common/match';
import { reduceStringRecordToObject } from '@shared/utils/common';
import { queryClient, updateShowQueryItem, updateShowQueryItemAndGetPrevious } from '@shared/utils/http-client';

import { disposalsQueryKeys } from './disposalsApi.queries';
import {
  DisposalInterestShowItem,
  DisposalScheduleIndexPayload,
  DisposalScheduleIndexResponse,
} from './disposalsApi.types';

// Use this when we want to revert an update to a show item (onError)
export const updateDisposalInterestShowItem = (
  disposalId: number,
  matchId: number,
  updateCb: (item: DisposalInterestShowItem) => DisposalInterestShowItem
) =>
  updateShowQueryItem<DisposalInterestShowItem>(
    disposalsQueryKeys.interestShow({ disposalId: disposalId.toString(), interestId: matchId.toString() }),
    matchId,
    updateCb
  );

// Use this when we want to optimistically update a show item (onMutate)
export const updateDisposalMatchShowItemAndGetPrevious = (
  disposalId: number,
  matchId: number,
  updateCb: (item: DisposalInterestShowItem) => DisposalInterestShowItem
) =>
  updateShowQueryItemAndGetPrevious<DisposalInterestShowItem>(
    disposalsQueryKeys.interestShow({ disposalId: disposalId.toString(), interestId: matchId.toString() }),
    matchId,
    updateCb
  );

export const filterDisposalScheduleIndexCache = (payload: DisposalScheduleIndexPayload) => (filterIds: number[]) => {
  queryClient.setQueryData<DisposalScheduleIndexResponse>(
    disposalsQueryKeys.interestActiveIndex(payload).queryKey,
    (response) => {
      if (!response) {
        return response;
      }
      const data = response.data.filter((match) => !filterIds.includes(match.id));
      return { ...response, data };
    }
  );
};

export const isInterestValueWhole = (
  castType: ScheduleMatchFieldCasts,
  value: ScheduleMatchFieldAllValues | null
): boolean => {
  switch (castType) {
    case ScheduleMatchFieldCasts.Boolean: {
      const fieldValue = value as ScheduleMatchFieldCastValues[ScheduleMatchFieldCasts.Boolean];

      return fieldValue !== null;
    }

    case ScheduleMatchFieldCasts.DateTime: {
      const fieldValue = value as ScheduleMatchFieldCastValues[ScheduleMatchFieldCasts.DateTime];

      // TODO: Add logic to determine wholeness of this cast
      return !!fieldValue;
    }

    case ScheduleMatchFieldCasts.Float: {
      const fieldValue = value as ScheduleMatchFieldCastValues[ScheduleMatchFieldCasts.Float];

      return fieldValue !== null;
    }

    case ScheduleMatchFieldCasts.FloatRange: {
      const fieldValue = value as ScheduleMatchFieldCastValues[ScheduleMatchFieldCasts.FloatRange];

      return fieldValue !== null;
    }

    case ScheduleMatchFieldCasts.InvestorProfile: {
      const fieldValue = value as ScheduleMatchFieldCastValues[ScheduleMatchFieldCasts.InvestorProfile];

      return fieldValue !== null;
    }

    case ScheduleMatchFieldCasts.MonthAndYear: {
      const fieldValue = value as ScheduleMatchFieldCastValues[ScheduleMatchFieldCasts.MonthAndYear];

      // TODO: Add logic to determine wholeness of this cast
      return fieldValue !== null;
    }

    case ScheduleMatchFieldCasts.MultiSelect: {
      const fieldValue = value as ScheduleMatchFieldCastValues[ScheduleMatchFieldCasts.MultiSelect];

      // TODO: Add logic to determine wholeness of this cast
      return !!fieldValue;
    }

    case ScheduleMatchFieldCasts.Price: {
      const fieldValue = value as ScheduleMatchFieldCastValues[ScheduleMatchFieldCasts.Price];

      return fieldValue !== null;
    }

    case ScheduleMatchFieldCasts.PriceRange: {
      const fieldValue = value as ScheduleMatchFieldCastValues[ScheduleMatchFieldCasts.PriceRange];

      // TODO: Add logic to determine wholeness of this cast
      return !!fieldValue;
    }

    case ScheduleMatchFieldCasts.RentRange: {
      const fieldValue = value as ScheduleMatchFieldCastValues[ScheduleMatchFieldCasts.RentRange];

      // TODO: Add logic to determine wholeness of this cast
      return !!fieldValue;
    }

    case ScheduleMatchFieldCasts.SingleSelect: {
      const fieldValue = value as ScheduleMatchFieldCastValues[ScheduleMatchFieldCasts.SingleSelect];

      return fieldValue !== null;
    }

    case ScheduleMatchFieldCasts.SizeRange: {
      const fieldValue = value as ScheduleMatchFieldCastValues[ScheduleMatchFieldCasts.SizeRange];

      // TODO: Add logic to determine wholeness of this cast
      return !!fieldValue;
    }

    case ScheduleMatchFieldCasts.String: {
      const fieldValue = value as ScheduleMatchFieldCastValues[ScheduleMatchFieldCasts.String];

      return fieldValue !== null && fieldValue !== '';
    }

    // TODO: ---- These do not exist in BE ----

    case ScheduleMatchFieldCasts.ChargeCast: {
      const fieldValue = value as ScheduleMatchFieldCastValues[ScheduleMatchFieldCasts.ChargeCast];

      // TODO: Add logic to determine wholeness of this cast
      return !!fieldValue;
    }

    case ScheduleMatchFieldCasts.Int: {
      const fieldValue = value as ScheduleMatchFieldCastValues[ScheduleMatchFieldCasts.Int];

      return fieldValue !== null;
    }

    case ScheduleMatchFieldCasts.Json: {
      const fieldValue = value as ScheduleMatchFieldCastValues[ScheduleMatchFieldCasts.Json];

      // TODO: Add logic to determine wholeness of this cast
      return !!fieldValue;
    }

    case ScheduleMatchFieldCasts.MultiSelectInt: {
      const fieldValue = value as ScheduleMatchFieldCastValues[ScheduleMatchFieldCasts.MultiSelectInt];

      return fieldValue !== null && fieldValue.length > 0;
    }

    case ScheduleMatchFieldCasts.MultiSelectString: {
      const fieldValue = value as ScheduleMatchFieldCastValues[ScheduleMatchFieldCasts.MultiSelectString];

      return fieldValue !== null && fieldValue.length > 0;
    }

    case ScheduleMatchFieldCasts.SingleSelectInt: {
      const fieldValue = value as ScheduleMatchFieldCastValues[ScheduleMatchFieldCasts.SingleSelectInt];

      return fieldValue !== null;
    }

    case ScheduleMatchFieldCasts.SingleSelectString: {
      const fieldValue = value as ScheduleMatchFieldCastValues[ScheduleMatchFieldCasts.SingleSelectString];

      return fieldValue !== null && fieldValue !== '';
    }
  }
};

export const serialisePartialInterestValues = (
  constants: ConfigCoreConstants,
  suppliedValues: Partial<ScheduleMatchFieldsSerialised>
): Partial<ScheduleMatchFieldsSerialised> => {
  // Casting to a string record, so that we aren't looping over a object with optional keys
  const values = suppliedValues as Record<string, ScheduleMatchFieldAllValues>;

  return reduceStringRecordToObject(values, (result, value, key) => {
    const castType = constants.scheduleMatchFields[key]?.input;

    if (castType && isInterestValueWhole(castType, value)) {
      result[key] = value;
    } else {
      result[key] = null;
    }

    return result;
  });
};
