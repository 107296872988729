import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

export const styles = ({ spacing }: Theme) => {
  return createStyles({
    error: {},
    loading: {},
    noData: {},
    noResults: {},
    noResultsAll: {},
    iconContainer: {
      background: colors.grey.lighter,
    },
    icon: {
      stroke: '#324DFC', //TODO change after creating new palette
    },
    openFilterIcon: {
      fontSize: '13px !important',

      '& path': {
        stroke: colors.white.lightest,
      },
    },
  });
};
