import { AxiosResponse } from 'axios';

import { $httpAgency, useMutation, UseMutationOptions } from '@core/http-client';
import { BasicViewing } from '@shared/types/common/viewing';

import {
  DisposalAddToRequirementListsPayload,
  DisposalArchiveMatchFromSchedulePayload,
  DisposalBulkChangeMatchesStatusPayload,
  DisposalBulkClearMatchesConfidentialPayload,
  DisposalBulkClearMatchesDiscountedReasonPayload,
  DisposalBulkDiscountMatchesPayload,
  DisposalBulkHideMatchesFromSchedulePayload,
  DisposalBulkMarkMatchesAsConfidentialPayload,
  DisposalBulkShareMatchesPayload,
  DisposalBulkUnhideMatchesFromSchedulePayload,
  DisposalBulkUnshareMatchesPayload,
  DisposalChangeMatchStatusPayload,
  DisposalClearMatchDiscountedReasonPayload,
  DisposalCreateMatchCommentPayload,
  DisposalCreateViewingPayload,
  DisposalDiscountMatchPayload,
  DisposalFavouriteMatchPayload,
  DisposalInterestIndexItem,
  DisposalInterestShowItem,
  DisposalInterestUpdatePayload,
  DisposalMarkMatchAsConfidentialPayload,
  DisposalMatchAddCommentPayload,
  DisposalMatchUpdatePayload,
  DisposalScheduleInvitePayload,
  DisposalShareMatchPayload,
  DisposalShowMatchOnSchedulePayload,
  DisposalStoreNakedInterestPayload,
} from './disposalsApi.types';

// ---- Disposal, Interest - Mutations ----

export const useDisposalStoreNakedInterestMutation = (
  opts?: UseMutationOptions<DisposalStoreNakedInterestPayload, undefined>
) =>
  useMutation({
    ...opts,
    mutationFn: async (data) =>
      (
        await $httpAgency.post<DisposalStoreNakedInterestPayload['body'], AxiosResponse<undefined>>(
          `/disposals/${data.disposalId}/interest`,
          data.body
        )
      ).data,
  });

export const useDisposalInviteAgentsMutation = (opts?: UseMutationOptions<DisposalScheduleInvitePayload, undefined>) =>
  useMutation({
    ...opts,
    mutationFn: async (data) =>
      (
        await $httpAgency.post<DisposalScheduleInvitePayload['body'], AxiosResponse<undefined>>(
          `/disposals/${data.disposalId}/interest/invite-agents`,
          data.body
        )
      ).data,
  });

export const useDisposalInviteLandlordsMutation = (
  opts?: UseMutationOptions<DisposalScheduleInvitePayload, undefined>
) =>
  useMutation({
    ...opts,
    mutationFn: async (data) =>
      (
        await $httpAgency.post<DisposalScheduleInvitePayload['body'], AxiosResponse<undefined>>(
          `/disposals/${data.disposalId}/interest/invite-landlords`,
          data.body
        )
      ).data,
  });

export const useDisposalMatchUpdateMutation = <T = DisposalInterestIndexItem | DisposalInterestShowItem>(
  opts?: UseMutationOptions<DisposalMatchUpdatePayload, DisposalInterestShowItem, null, T>
) =>
  useMutation({
    ...opts,
    mutationFn: async (data) =>
      (
        await $httpAgency.put<DisposalMatchUpdatePayload['body'], AxiosResponse<DisposalInterestShowItem>>(
          `/disposals/${data.disposalId}/interest/${data.matchId}`,
          data.body
        )
      ).data,
  });

// ---- Disposal, Interest - Single Action Mutations ----

export const useDisposalArchiveMatchFromScheduleMutation = (
  opts?: UseMutationOptions<DisposalArchiveMatchFromSchedulePayload, undefined>
) =>
  useMutation({
    ...opts,
    mutationFn: async (data) =>
      (
        await $httpAgency.put<DisposalArchiveMatchFromSchedulePayload['body'], AxiosResponse<undefined>>(
          `/disposals/${data.disposalId}/interest/${data.id}/archive-from-schedule`,
          data.body
        )
      ).data,
  });

export const useDisposalChangeMatchStatusMutation = (
  opts?: UseMutationOptions<DisposalChangeMatchStatusPayload, undefined>
) =>
  useMutation({
    ...opts,
    mutationFn: async (data) =>
      (
        await $httpAgency.put<DisposalChangeMatchStatusPayload['body'], AxiosResponse<undefined>>(
          `/disposals/${data.disposalId}/interest/${data.id}/change-status`,
          data.body
        )
      ).data,
  });

export const useDisposalClearMatchDiscountedReasonMutation = (
  opts?: UseMutationOptions<DisposalClearMatchDiscountedReasonPayload, undefined>
) =>
  useMutation({
    ...opts,
    mutationFn: async (data) =>
      (
        await $httpAgency.put<DisposalClearMatchDiscountedReasonPayload, AxiosResponse<undefined>>(
          `/disposals/${data.disposalId}/interest/${data.id}/clear-discounted-reason`
        )
      ).data,
  });

// TODO: Looks like dupe of useDisposalCreateMatchCommentMutation
export const useDisposalMatchAddCommentMutation = (opts?: UseMutationOptions<DisposalMatchAddCommentPayload, any>) =>
  useMutation({
    ...opts,
    mutationFn: async (data) => {
      return (await $httpAgency.put(`/disposals/${data.disposalId}/interest/${data.matchId}/comment`, data.body)).data;
    },
  });

// TODO: Looks like dupe of useDisposalMatchAddCommentMutation
export const useDisposalCreateMatchCommentMutation = (
  opts?: UseMutationOptions<DisposalCreateMatchCommentPayload, undefined>
) =>
  useMutation({
    ...opts,
    mutationFn: async (data) =>
      (
        await $httpAgency.put<DisposalCreateMatchCommentPayload['body'], AxiosResponse<undefined>>(
          `/disposals/${data.disposalId}/interest/${data.id}/comment`,
          data.body
        )
      ).data,
  });

export const useDisposalDiscountMatchMutation = (opts?: UseMutationOptions<DisposalDiscountMatchPayload, undefined>) =>
  useMutation({
    ...opts,
    mutationFn: async (data) =>
      (
        await $httpAgency.put<DisposalDiscountMatchPayload['body'], AxiosResponse<undefined>>(
          `/disposals/${data.disposalId}/interest/${data.id}/discount`,
          data.body
        )
      ).data,
  });

export const useDisposalFavouriteMatchMutation = <T = DisposalInterestIndexItem | DisposalInterestShowItem>(
  opts?: UseMutationOptions<DisposalFavouriteMatchPayload, undefined, null, T>
) =>
  useMutation({
    ...opts,
    mutationFn: async (data) =>
      (
        await $httpAgency.put<DisposalFavouriteMatchPayload['body'], AxiosResponse<undefined>>(
          `/disposals/${data.disposalId}/interest/${data.id}/favourite`,
          data.body
        )
      ).data,
  });

export const useDisposalMarkMatchAsConfidentialMutation = <T = DisposalInterestIndexItem | DisposalInterestShowItem>(
  opts?: UseMutationOptions<DisposalMarkMatchAsConfidentialPayload, undefined, null, T>
) =>
  useMutation({
    ...opts,
    mutationFn: async (data) =>
      (
        await $httpAgency.put<DisposalMarkMatchAsConfidentialPayload['body'], AxiosResponse<undefined>>(
          `/disposals/${data.disposalId}/interest/${data.id}/mark-as-confidential`,
          data.body
        )
      ).data,
  });

export const useDisposalShareMatchMutation = <T = DisposalInterestIndexItem | DisposalInterestShowItem>(
  opts?: UseMutationOptions<DisposalShareMatchPayload, undefined, null, T>
) =>
  useMutation({
    ...opts,
    mutationFn: async (data) =>
      (
        await $httpAgency.put<DisposalShareMatchPayload['body'], AxiosResponse<undefined>>(
          `/disposals/${data.disposalId}/interest/${data.id}/share-requirement`,
          data.body
        )
      ).data,
  });

export const useDisposalShowMatchOnScheduleMutation = (
  opts?: UseMutationOptions<DisposalShowMatchOnSchedulePayload, undefined>
) =>
  useMutation({
    ...opts,
    mutationFn: async (data) =>
      (
        await $httpAgency.put<DisposalShowMatchOnSchedulePayload, AxiosResponse<undefined>>(
          `/disposals/${data.disposalId}/interest/${data.id}/show-on-schedule`
        )
      ).data,
  });

export const useDisposalCreateViewingMutation = (
  opts?: UseMutationOptions<DisposalCreateViewingPayload, BasicViewing>
) =>
  useMutation({
    ...opts,
    mutationFn: async (data) =>
      (
        await $httpAgency.post<DisposalCreateViewingPayload['body'], AxiosResponse<BasicViewing>>(
          `/disposals/${data.disposalId}/interest/${data.matchId}/viewings`,
          data.body
        )
      ).data,
  });

// ---- Disposal, Interest - Bulk Action Mutations ----

export const useDisposalAddToRequirementListsMutation = (
  opts?: UseMutationOptions<DisposalAddToRequirementListsPayload, undefined>
) =>
  useMutation({
    ...opts,
    mutationFn: async (data) =>
      (
        await $httpAgency.put<DisposalAddToRequirementListsPayload['body'], AxiosResponse<undefined>>(
          `/disposals/${data.id}/interest/add-to-requirement-lists`,
          data.body
        )
      ).data,
  });

export const useDisposalBulkChangeMatchesStatusMutation = (
  opts?: UseMutationOptions<DisposalBulkChangeMatchesStatusPayload, undefined>
) =>
  useMutation({
    ...opts,
    mutationFn: async (data) =>
      (
        await $httpAgency.put<DisposalBulkChangeMatchesStatusPayload['body'], AxiosResponse<undefined>>(
          `/disposals/${data.id}/interest/bulk-change-status`,
          data.body
        )
      ).data,
  });

export const useDisposalBulkClearMatchesConfidentialMutation = (
  opts?: UseMutationOptions<DisposalBulkClearMatchesConfidentialPayload, undefined>
) =>
  useMutation({
    ...opts,
    mutationFn: async (data) =>
      (
        await $httpAgency.put<DisposalBulkClearMatchesConfidentialPayload['body'], AxiosResponse<undefined>>(
          `/disposals/${data.id}/interest/bulk-mark-as-not-confidential`,
          data.body
        )
      ).data,
  });

export const useDisposalBulkClearMatchesDiscountedReasonMutation = (
  opts?: UseMutationOptions<DisposalBulkClearMatchesDiscountedReasonPayload, undefined>
) =>
  useMutation({
    ...opts,
    mutationFn: async (data) =>
      (
        await $httpAgency.put<DisposalBulkClearMatchesDiscountedReasonPayload['body'], AxiosResponse<undefined>>(
          `/disposals/${data.id}/interest/bulk-clear-discounted-reason`,
          data.body
        )
      ).data,
  });

export const useDisposalBulkDiscountMatchesMutation = (
  opts?: UseMutationOptions<DisposalBulkDiscountMatchesPayload, undefined>
) =>
  useMutation({
    ...opts,
    mutationFn: async (data) =>
      (
        await $httpAgency.put<DisposalBulkDiscountMatchesPayload['body'], AxiosResponse<undefined>>(
          `/disposals/${data.id}/interest/bulk-discount`,
          data.body
        )
      ).data,
  });

export const useDisposalBulkHideMatchesFromScheduleMutation = (
  opts?: UseMutationOptions<DisposalBulkHideMatchesFromSchedulePayload, undefined>
) =>
  useMutation({
    ...opts,
    mutationFn: async (data) =>
      (
        await $httpAgency.put<DisposalBulkHideMatchesFromSchedulePayload['body'], AxiosResponse<undefined>>(
          `/disposals/${data.id}/interest/bulk-hide-from-schedule`,
          data.body
        )
      ).data,
  });

export const useDisposalBulkMarkMatchesAsConfidentialMutation = (
  opts?: UseMutationOptions<DisposalBulkMarkMatchesAsConfidentialPayload, undefined>
) =>
  useMutation({
    ...opts,
    mutationFn: async (data) =>
      (
        await $httpAgency.put<DisposalBulkMarkMatchesAsConfidentialPayload['body'], AxiosResponse<undefined>>(
          `/disposals/${data.id}/interest/bulk-mark-as-confidential`,
          data.body
        )
      ).data,
  });

export const useDisposalBulkShareMatchesMutation = (
  opts?: UseMutationOptions<DisposalBulkShareMatchesPayload, undefined>
) =>
  useMutation({
    ...opts,
    mutationFn: async (data) =>
      (
        await $httpAgency.put<DisposalBulkShareMatchesPayload['body'], AxiosResponse<undefined>>(
          `/disposals/${data.id}/interest/bulk-share-requirement`,
          data.body
        )
      ).data,
  });

export const useDisposalBulkUnhideMatchesFromScheduleMutation = (
  opts?: UseMutationOptions<DisposalBulkUnhideMatchesFromSchedulePayload, undefined>
) =>
  useMutation({
    ...opts,
    mutationFn: async (data) =>
      (
        await $httpAgency.put<DisposalBulkUnhideMatchesFromSchedulePayload['body'], AxiosResponse<undefined>>(
          `/disposals/${data.id}/interest/bulk-unhide-from-schedule`,
          data.body
        )
      ).data,
  });

export const useDisposalBulkUnshareMatchesMutation = (
  opts?: UseMutationOptions<DisposalBulkUnshareMatchesPayload, undefined>
) =>
  useMutation({
    ...opts,
    mutationFn: async (data) =>
      (
        await $httpAgency.put<DisposalBulkUnshareMatchesPayload['body'], AxiosResponse<undefined>>(
          `/disposals/${data.id}/interest/bulk-unshare-requirement`,
          data.body
        )
      ).data,
  });

export const useDisposalInterestUpdateMutation = (
  opts?: UseMutationOptions<DisposalInterestUpdatePayload, undefined>
) =>
  useMutation({
    ...opts,
    mutationFn: async (data) =>
      (
        await $httpAgency.put<DisposalInterestUpdatePayload['body'], AxiosResponse<undefined>>(
          `/disposals/${data.disposalId}/interest/${data.interestId}`,
          data.body
        )
      ).data,
  });
