import { AxiosResponse } from 'axios';
import { useState } from 'react';

import { $httpAgency, useMutation, UseMutationOptions } from '@core/http-client';
import { FeeShowItem, FeeUpdatePayload } from '@shared/apis/feesApi.types';

import {
  DisposalDataRoomAccessResponse,
  DisposalDataRoomCreateFolderPayload,
  DisposalDataRoomDeleteDocumentPayload,
  DisposalDataRoomDeleteFolderPayload,
  DisposalDataRoomDocumentsReorderPayload,
  DisposalDataRoomFolder,
  DisposalDataRoomFoldersReorderPayload,
  DisposalDataRoomShowItem,
  DisposalDataRoomUpdateFolderPayload,
  DisposalDataRoomUploadDocumentPayload,
  DisposalManageDataRoomAccess,
  DisposalShowItem,
  DisposalUpdataDataRoomPayload,
  DisposalUpdatePayload,
  UploadFileProgress,
} from './disposalsApi.types';

// ---- Disposal, List - Mutations ----

// TODO

// ---- Disposal - Mutations ----

export const useDisposalFeesUpdateMutation = (opts?: UseMutationOptions<FeeUpdatePayload, FeeShowItem>) =>
  useMutation({
    ...opts,
    mutationFn: async (data) =>
      (
        await $httpAgency.put<FeeUpdatePayload['body'], AxiosResponse<FeeShowItem>>(
          `disposals/${data.id}/fees`,
          data.body
        )
      ).data,
  });

export const useDisposalUpdateMutation = (opts?: UseMutationOptions<DisposalUpdatePayload, DisposalShowItem>) =>
  useMutation({
    ...opts,
    mutationFn: async (data) =>
      (
        await $httpAgency.put<DisposalUpdatePayload['body'], AxiosResponse<DisposalShowItem>>(
          `/disposals/${data.id}`,
          data.body
        )
      ).data,
  });

// ---- Disposal, Data Room - Mutations ----

export const useDisposalUpdateDataRoomMutation = (
  opts?: UseMutationOptions<DisposalUpdataDataRoomPayload, DisposalDataRoomShowItem>
) =>
  useMutation({
    ...opts,
    mutationFn: async (data) =>
      (
        await $httpAgency.put<DisposalUpdataDataRoomPayload['body'], AxiosResponse<DisposalDataRoomShowItem>>(
          `/disposals/${data.id}/data-room`,
          data.body
        )
      ).data,
  });

export const useDisposalDataRoomPublishingMutation = (opts?: UseMutationOptions<string, DisposalDataRoomShowItem>) =>
  useMutation({
    ...opts,
    mutationFn: async (id) =>
      (await $httpAgency.put<string, AxiosResponse<DisposalDataRoomShowItem>>(`/disposals/${id}/publish-data-room`))
        .data,
  });

export const useDisposalUpdateDataRoomUnPublishingMutation = (
  opts?: UseMutationOptions<string, DisposalDataRoomShowItem>
) =>
  useMutation({
    ...opts,
    mutationFn: async (id) =>
      (
        await $httpAgency.put<undefined, AxiosResponse<DisposalDataRoomShowItem>>(
          `/disposals/${id}/unpublish-data-room`
        )
      ).data,
  });

export const useDisposalDataRoomDocumentsReorderMutation = (
  opts?: UseMutationOptions<DisposalDataRoomDocumentsReorderPayload, DisposalDataRoomFolder>
) =>
  useMutation({
    ...opts,
    mutationFn: async (data) =>
      (
        await $httpAgency.put<DisposalDataRoomDocumentsReorderPayload['body'], AxiosResponse<DisposalDataRoomFolder>>(
          `/disposals/${data.id}/data-room/documents/reorder`,
          data.body
        )
      ).data,
  });

export const useDisposalDataRoomUploadDocument = (
  opts?: UseMutationOptions<DisposalDataRoomUploadDocumentPayload, DisposalDataRoomFolder>
) => {
  const [filesUploading, setFilesUploading] = useState<{ [key: string]: UploadFileProgress }>({});

  const mutation = useMutation({
    ...opts,
    mutationFn: async (data) =>
      (
        await $httpAgency.post<DisposalDataRoomUploadDocumentPayload['body'], AxiosResponse<DisposalDataRoomFolder>>(
          `/disposals/${data.id}/data-room/documents`,
          data.body,
          {
            onUploadProgress: (progressEvent) => {
              const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);

              setFilesUploading((prev) => ({
                ...prev,
                //@ts-ignore
                [data.body.get('file').name]: {
                  file: data.body.get('file'),
                  folderId: data.folderId,
                  progress,
                },
              }));
            },
          }
        )
      ).data,
  });

  return {
    disposalDataRoomUploadDocument: mutation,
    filesUploading,
    setFilesUploading,
  };
};

export const useDisposalDataRoomDeleteDocumentMutation = (
  opts?: UseMutationOptions<DisposalDataRoomDeleteDocumentPayload, DisposalDataRoomFolder>
) =>
  useMutation({
    ...opts,
    mutationFn: async (data) =>
      (
        await $httpAgency.delete<null, AxiosResponse<DisposalDataRoomFolder>>(
          `/disposals/${data.id}/data-room/documents/${data.assetId}`
        )
      ).data,
  });

export const useDisposalDataRoomCreateFolderMutation = (
  opts?: UseMutationOptions<DisposalDataRoomCreateFolderPayload, DisposalDataRoomFolder[]>
) =>
  useMutation({
    ...opts,
    mutationFn: async (data) =>
      (
        await $httpAgency.post<DisposalDataRoomCreateFolderPayload['body'], AxiosResponse<DisposalDataRoomFolder[]>>(
          `/disposals/${data.id}/data-room/folders`,
          data.body
        )
      ).data,
  });

export const useDisposalDataRoomDeleteFolderMutation = (
  opts?: UseMutationOptions<DisposalDataRoomDeleteFolderPayload, DisposalDataRoomFolder[]>
) =>
  useMutation({
    ...opts,
    mutationFn: async (data) =>
      (
        await $httpAgency.delete<DisposalDataRoomDeleteFolderPayload, AxiosResponse<DisposalDataRoomFolder[]>>(
          `/disposals/${data.id}/data-room/folders/${data.folderId}`
        )
      ).data,
  });

export const useDisposalDataRoomFoldersReorderMutation = (
  opts?: UseMutationOptions<DisposalDataRoomFoldersReorderPayload, DisposalDataRoomFolder[]>
) =>
  useMutation({
    ...opts,
    mutationFn: async (data) =>
      (
        await $httpAgency.put<DisposalDataRoomFoldersReorderPayload['body'], AxiosResponse<DisposalDataRoomFolder[]>>(
          `/disposals/${data.id}/data-room/folders/reorder`,
          data.body
        )
      ).data,
  });

export const useDisposalDataRoomUpdateFolderMutation = (
  opts?: UseMutationOptions<DisposalDataRoomUpdateFolderPayload, DisposalDataRoomFolder>
) =>
  useMutation({
    ...opts,
    mutationFn: async (data) =>
      (
        await $httpAgency.put<DisposalDataRoomUpdateFolderPayload['body'], AxiosResponse<DisposalDataRoomFolder>>(
          `/disposals/${data.id}/data-room/folders/${data.folderId}`,
          data.body
        )
      ).data,
  });

// Data room access
export const useDisposaDataRoomApproveAccessMutation = (
  opts?: UseMutationOptions<DisposalManageDataRoomAccess, DisposalDataRoomAccessResponse>
) =>
  useMutation({
    ...opts,
    mutationFn: async (data) =>
      (
        await $httpAgency.post<DisposalManageDataRoomAccess, AxiosResponse<DisposalDataRoomAccessResponse>>(
          `/disposals/${data.id}/data-room/approve-access/${data.body.accessId}`
        )
      ).data,
  });

export const useDisposaDataRoomReinstateAccessMutation = (
  opts?: UseMutationOptions<DisposalManageDataRoomAccess, DisposalDataRoomAccessResponse>
) =>
  useMutation({
    ...opts,
    mutationFn: async (data) =>
      (
        await $httpAgency.post<DisposalManageDataRoomAccess, AxiosResponse<DisposalDataRoomAccessResponse>>(
          `/disposals/${data.id}/data-room/reinstate-access/${data.body.accessId}`
        )
      ).data,
  });

export const useDisposaDataRoomDenyAccessMutation = (
  opts?: UseMutationOptions<DisposalManageDataRoomAccess, DisposalDataRoomAccessResponse>
) =>
  useMutation({
    ...opts,
    mutationFn: async (data) =>
      (
        await $httpAgency.post<DisposalManageDataRoomAccess, AxiosResponse<DisposalDataRoomAccessResponse>>(
          `/disposals/${data.id}/data-room/deny-access/${data.body.accessId}`
        )
      ).data,
  });

export const useDisposaDataRoomRemoveAccessMutation = (
  opts?: UseMutationOptions<DisposalManageDataRoomAccess, DisposalDataRoomAccessResponse>
) =>
  useMutation({
    ...opts,
    mutationFn: async (data) =>
      (
        await $httpAgency.post<DisposalManageDataRoomAccess, AxiosResponse<DisposalDataRoomAccessResponse>>(
          `/disposals/${data.id}/data-room/remove-access/${data.body.accessId}`
        )
      ).data,
  });
