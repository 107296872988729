import { createStyles, Theme } from '@material-ui/core/styles';

const leftPanelWidth = 480;
const rightPanelWidth = 700;
const subHeadingHeight = 48; //TODO

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {},
    body: {
      display: 'flex',
      flexGrow: 1,
      overflow: 'auto',
      width: '100%',
      border: '1px solid #EAECF0', //TODO change after creating new palette
      borderRadius: 12,
    },
    leftPanel: {
      overflow: 'auto',
      width: leftPanelWidth,
    },
    searchFieldWrapper: {
      padding: spacing(4),
    },
    searchField: {},
    contentTabs: {
      borderBottom: '1px solid #EAECF0', //TODO change after creating new palette
      height: subHeadingHeight,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'nowrap',
      width: '100%',
      padding: spacing(0, 4, 0, 4),
    },
    tabs: {
      height: '100%',

      '& > *:not(:first-child)': {
        marginLeft: spacing(6),
      },
    },
    tab: {
      height: '100%',
    },
    leftPanelContent: {
      padding: spacing(4),
    },
    rightPanel: {
      padding: spacing(4),
      borderLeft: '1px solid #EAECF0', //TODO change after creating new palette
      display: 'flex',
      flexDirection: 'column',
      width: rightPanelWidth,
    },
  });
