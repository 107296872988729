import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

import { StatusBadgeTheme } from './StatusBadge';
import { BadgeSize } from '../badge-new';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {},
    badge: {
      alignItems: 'center',
      borderRadius: 6,
      display: 'inline-flex',
      fontWeight: 600,
      lineHeight: '1',
    },
    counterRoot: {
      border: `1px solid transparent`,
      lineHeight: 'normal',
    },
    counterRootZero: {
      border: `1px solid transparent`,
    },
    counterCount: {},
    counterCountZero: {},

    [BadgeSize.small]: {
      fontSize: 12,
      gap: spacing(1),
      height: 20,
      padding: spacing(0.5, 1),

      '& $counterRoot': {
        height: 17,
        minWidth: 17,
        width: 17,
      },
      '& $counterCount': {
        fontSize: 12,
      },
    },
    [BadgeSize.large]: {
      fontSize: 12,
      gap: spacing(2),
      height: 24,
      padding: spacing(1, 2),

      '& $counterRoot': {
        height: 20,
        minWidth: 20,
        width: 20,
      },
      '& $counterCount': {
        fontSize: 14,
      },
    },

    [StatusBadgeTheme.grey]: {
      border: `1px solid #EAECF0`, // TODO: Change after palette updated
      backgroundColor: '#F7F8FA', // TODO: Change after palette updated
      color: '#344154', // TODO: Change after palette updated

      '& $counterRoot': {
        backgroundColor: '#344154', // TODO: Change after palette updated
      },
      '& $counterRootZero': {
        backgroundColor: colors.white.lightest,
        borderColor: '#344154', // TODO: Change after palette updated
      },
      '& $counterCount': {
        color: colors.grey.lighter,
      },
      '& $counterCountZero': {
        color: '#344154',
      },
    },
    [StatusBadgeTheme.green]: {
      border: `1px solid #BEF4C6`, // TODO: Change after palette updated
      backgroundColor: '#ECFDF3', // TODO: Change after palette updated
      color: '#027A48', // TODO: Change after palette updated

      '& $counterRoot': {
        backgroundColor: '#027A48', // TODO: Change after palette updated
      },
      '& $counterRootZero': {
        backgroundColor: colors.white.lightest,
        borderColor: '#027A48', // TODO: Change after palette updated
      },
      '& $counterCount': {
        color: colors.green.lightest,
      },
      '& $counterCountZero': {
        color: '#027A48', // TODO: Change after palette updated
      },
    },
    [StatusBadgeTheme.warning]: {
      border: `1px solid ${colors.orange.main}`,
      backgroundColor: colors.orange.main,
      color: colors.white.lightest,

      '& $counterRoot': {
        backgroundColor: colors.white.lightest,
      },
      '& $counterRootZero': {
        backgroundColor: colors.white.lightest,
        borderColor: colors.white.lightest,
      },
      '& $counterCount': {
        color: colors.orange.main,
      },
      '& $counterCountZero': {
        color: colors.orange.main,
      },
    },
    [StatusBadgeTheme.danger]: {
      border: `1px solid #FFCDC6`, // TODO: Change after palette updated
      backgroundColor: '#FFF3F1', // TODO: Change after palette updated
      color: '#C52810', // TODO: Change after palette updated

      '& $counterRoot': {
        backgroundColor: colors.white.lightest,
      },
      '& $counterRootZero': {
        backgroundColor: colors.white.lightest,
        borderColor: colors.white.lightest,
      },
      '& $counterCount': {
        color: colors.red.main,
      },
      '& $counterCountZero': {
        color: colors.red.main,
      },
    },
    [StatusBadgeTheme.orange]: {
      border: `1px solid #FCDEC0`,
      backgroundColor: '#FFF5EB', // TODO: Change after palette updated
      color: '#B54708', // TODO: Change after palette updated

      '& $counterRoot': {
        backgroundColor: '#B54708', // TODO: Change after palette updated
      },
      '& $counterRootZero': {
        backgroundColor: colors.white.lightest,
        borderColor: '#B54708', // TODO: Change after palette updated
      },
      '& $counterCount': {
        color: '#FFF5EB', // TODO: Change after palette updated
      },
      '& $counterCountZero': {
        color: '#B54708', // TODO: Change after palette updated
      },
    },
  });
