import { createStyles, Theme } from '@material-ui/core/styles';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {},
    selectAdornmentIcon: {
      fontSize: 15,
    },
    hoursSelectRoot: {
      width: 100,

      '& .MuiOutlinedInput-root': {
        borderBottomRightRadius: 0,
        borderTopRightRadius: 0,
      },
    },
    minutesSelectRoot: {
      width: 90,

      '& .MuiOutlinedInput-root': {
        borderBottomLeftRadius: 0,
        borderTopLeftRadius: 0,
        borderLeftWidth: 0,
      },
    },
  });
