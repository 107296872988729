import { Checkbox, TableBody, TableCell, TableRow } from '@material-ui/core';
import cx from 'classnames';
import lodashGet from 'lodash/get';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Loading } from '@shared/components/loading';

import styles from './Body.styles';
import { SelectedItem } from '../../../../Table';
import { TableColumn, TableConfig } from '../../../../Table.types';

const get = (object: { [key: string]: any }, path: string, defaultValue: string) => {
  const value = lodashGet(object, path);

  if (value || value === 0) {
    return value;
  }

  return defaultValue;
};

export interface BodyProps extends WithStyles<typeof styles> {
  data: Array<Item>;
  columns: Array<TableColumn>;
  loading: boolean;
  hover?: boolean;
  config?: TableConfig;
  selectedItem?: SelectedItem;
  withBulkActions?: boolean;
  selectedItems?: Id[];
  onSelectedItemsChange?: (id: Id, checked: boolean) => any;
  onRowClick?: (item: Item) => void;
}

class Body extends React.Component<BodyProps> {
  render() {
    const { classes, columns, data, loading, selectedItem, selectedItems, withBulkActions, hover = true } = this.props;

    if (loading) {
      return (
        <TableBody classes={{ root: classes.root }}>
          <TableRow>
            <TableCell classes={{ root: classes.noDataCell }} colSpan={columns.length}>
              <Loading classes={{ root: classes.loading }} size={25} />
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }

    return (
      <TableBody classes={{ root: classes.root }}>
        {data.map((item, i) => {
          return (
            <TableRow
              hover={hover}
              role="checkbox"
              tabIndex={-1}
              key={item.id || i}
              onClick={() => this.handleRowClick(item)}
              classes={{
                root: cx(
                  classes.bodyRow,
                  { [classes.row]: this.props.onRowClick },
                  { [classes.highlightedRow]: selectedItem && item.id == selectedItem }
                ),
              }}
            >
              {withBulkActions && (
                <TableCell style={{ width: 70, minWidth: 50 }} className={classes.bodyCell}>
                  <Checkbox
                    inputProps={
                      {
                        'data-id': item.id,
                      } as any
                    }
                    classes={{
                      root: classes.bulkCheckbox,
                    }}
                    checked={selectedItems?.includes(item.id)}
                    onChange={this.handleSelectedItemsChange}
                  />
                </TableCell>
              )}
              {columns.map((column, idx) => {
                const {
                  align,
                  bodyCellClassName,
                  bodyCellFontStyle = 'normal',
                  bodyCellFontWeight,
                  className,
                  key: field,
                  maxWidth,
                  minWidth,
                  renderBodyCell,
                  width,
                } = column;
                const value = renderBodyCell ? renderBodyCell(item) : get(item, field, '-');
                // : <Ellipsis withTooltip={Boolean(get(item, field, ""))} classes={{ root: classes.bodyCellTextClass }}
                //             text={get(item, field, '-')} maxWidth='100%' />;

                return (
                  <TableCell
                    key={`${item.id}${field}${idx}`}
                    align={align}
                    style={{ width, maxWidth, minWidth, fontStyle: bodyCellFontStyle, fontWeight: bodyCellFontWeight }}
                    classes={{ root: cx(classes.bodyCell, className, bodyCellClassName) }}
                  >
                    {value}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    );
  }

  private handleSelectedItemsChange = (e: any, checked: boolean) => {
    const { onSelectedItemsChange } = this.props;

    if (onSelectedItemsChange) {
      onSelectedItemsChange(e.currentTarget.dataset.id, checked);
    }
  };

  private handleRowClick = (item: Item) => {
    const { onRowClick } = this.props;

    if (onRowClick) {
      onRowClick(item);
    }
  };
}

export default withStyles(styles)(Body);
