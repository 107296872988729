import { forwardRef, ForwardRefRenderFunction, useState } from 'react';

import { clearIndexQuery, QueryFactory, useQuery } from '@core/http-client';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { IndexResponse } from '@shared/types/services';

import { styles } from './AutocompleteWithQuery.styles';
import { Autocomplete, AutocompleteProps, AutocompleteRef } from '../autocomplete';

export interface AutocompleteWithQueryParams extends Query {
  search?: string;
}

export interface AutocompleteWithQueryProps
  extends Omit<AutocompleteProps, 'classes' | 'list' | 'loading' | 'onClearList' | 'onGetList'>,
    WithStyles<typeof styles> {
  queryFactory: QueryFactory<IndexResponse<any>>;
  queryParams?: Query;
}

const AutocompleteWithQueryComponent: ForwardRefRenderFunction<AutocompleteRef, AutocompleteWithQueryProps> = (
  { queryFactory, queryParams, ...props },
  ref
) => {
  const [search, setSearch] = useState<string | undefined>(undefined);

  const queryData = useQuery({
    ...queryFactory({ query: { ...queryParams, search } }),
    enabled: !!search,
  });
  return (
    <Autocomplete
      ref={ref}
      clearAfterSelect
      list={queryData.data?.data ?? []}
      loading={queryData.isFetching}
      onGetList={(s: string) => setSearch(s)}
      onClearList={() => {
        setSearch(undefined);
        clearIndexQuery(queryFactory, { ...queryParams, search });
      }}
      {...props}
    />
  );
};

export const AutocompleteWithQuery = withStyles(styles)(forwardRef(AutocompleteWithQueryComponent));
