import { Chip } from '@material-ui/core';
import { Close as RoundedIcon } from '@material-ui/icons';
import { FC, KeyboardEventHandler } from 'react';

import { usersQueryKeys } from '@apis/usersApi';
import { t } from '@core/i18n';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { AutocompleteWithQuery } from '@shared/components/autocomplete-with-query/AutocompleteWithQuery';
import { Avatar } from '@shared/components/avatar';
import { Button } from '@shared/components/button-new';
import { Dropdown } from '@shared/components/dropdown-new';
import { Flex } from '@shared/components/flex';
import { SendIcon } from '@shared/icons/send-2';
import { TrashIcon } from '@shared/icons/trash';
import { ListUserItem } from '@shared/models/user';
import { ButtonColour, ButtonSize } from '@shared/types/common/button';
import { InputSize } from '@shared/types/common/input';

import { styles } from './InviteCollaboratorsTab.styles';
import { InviteType } from '../..';

interface InviteCollaboratorsTabProps extends WithStyles<typeof styles> {
  selectedInviteType: InviteType;
  selectedChips: ListUserItem[];
  searchValue: string;
  invitationError: boolean;
  currentContactList: ListUserItem[];
  suggestedContacts: ListUserItem[];
  inviteTypeProps: any;
  handleInviteTypeTabChange: (type: InviteType) => void;
  handleDeleteChip: (chip: ListUserItem) => void;
  handleOnChipKeyDown: KeyboardEventHandler<HTMLInputElement>;
  handleSelectListUser: (user: ListUserItem) => void;
  setSearchValue: (value: string) => void;
  handleInviteChipContacts: () => void;
  setCurrentContactList: (list: ListUserItem[]) => void;
  setSuggestedContacts: (list: ListUserItem[]) => void;
}

const InviteCollaboratorsTabComponent: FC<InviteCollaboratorsTabProps> = ({
  classes,
  selectedInviteType,
  selectedChips,
  searchValue,
  invitationError,
  currentContactList,
  suggestedContacts,
  inviteTypeProps,
  handleInviteTypeTabChange,
  handleDeleteChip,
  handleOnChipKeyDown,
  handleSelectListUser,
  setSearchValue,
  handleInviteChipContacts,
  setCurrentContactList,
  setSuggestedContacts,
}) => {
  return (
    <Flex classes={{ root: classes.inviteContainer }}>
      <span
        className={classes.collaborate_instructions}
        dangerouslySetInnerHTML={{
          __html: t('collaborate_with_landlords_and_agents_by_inviting_them_below', {
            start_link:
              '<a href="https://help.agents-society.com/en/articles/5769387-training-4-share-interest-schedules-with-landlords" target="_blank" style="text-decoration: underline;">',
            end_link: '</a>',
          }),
        }}
      />
      <Flex className={classes.inviteSearchContainer} autoWidth={false} direction="column">
        <Flex className={classes.inviteTypeTabs} direction="row" wrap="nowrap">
          <Button
            active={selectedInviteType === InviteType.Landlords}
            classes={{ root: classes.inviteTypeTab }}
            colour={ButtonColour.secondaryGhost}
            size={ButtonSize.small}
            text={t('landlords')}
            onClick={() => handleInviteTypeTabChange(InviteType.Landlords)}
          />
          <Button
            active={selectedInviteType === InviteType.Agents}
            classes={{ root: classes.inviteTypeTab }}
            colour={ButtonColour.secondaryGhost}
            size={ButtonSize.small}
            text={t('agents')}
            onClick={() => handleInviteTypeTabChange(InviteType.Agents)}
          />
        </Flex>
        <Flex autoWidth={false} className={classes.inviteSearchContainer} direction="row" wrap="nowrap">
          <AutocompleteWithQuery
            classes={{
              root: classes.inviteInput,
              textField: classes.textField,
              actionRow: classes.autocompleteActionRow,
              label: classes.autocompleteLabel,
              list: classes.autocompleteList,
              listItem: classes.autocompleteListItem,
              notFoundText: classes.autocompleteNotFoundText,
              optionsList: classes.autocompleteOptions,
            }}
            inputProps={{
              InputProps: {
                startAdornment: selectedChips.map((chip: ListUserItem) => (
                  <Flex className={classes.chipsContainer} key={chip.id}>
                    <Chip
                      key={chip.id}
                      label={`${chip.name}${chip.organisation?.name ? ` (${chip.organisation.name})` : ''}`}
                      deleteIcon={<RoundedIcon />}
                      onDelete={() => handleDeleteChip(chip)}
                      className={classes.chip}
                    />
                  </Flex>
                )),
              },
              placeholder: selectedChips.length > 0 ? '' : inviteTypeProps.placeholder,
              size: InputSize.medium,
            }}
            onKeyDown={handleOnChipKeyDown}
            error={!!invitationError}
            withClearIcon={false}
            onSelect={handleSelectListUser}
            searchValue={searchValue}
            onSearchValueChange={setSearchValue}
            renderOption={(option: ListUserItem) => (
              <Flex className={classes.searchQueryContainer} direction="row" alignItems="center">
                <Avatar
                  classes={{ root: classes.searchQueryAvatar, noImageInitials: classes.searchQueryAvatar }}
                  name={option.name}
                  image={option.image.url}
                  theme={option.theme}
                />
                <Flex classes={{ root: classes.searchQueryDetails }} direction="column">
                  <span className={classes.searchQueryName}>{`${option.name} (${option.organisation.name})`}</span>
                  <span className={classes.searchQueryEmail}>{option.email}</span>
                </Flex>
              </Flex>
            )}
            queryFactory={usersQueryKeys.list}
          />
          <Button
            className={classes.inviteButton}
            disabled={selectedChips.length === 0}
            colour={ButtonColour.primary}
            size={ButtonSize.medium}
            loading={inviteTypeProps.loading}
            text={selectedInviteType === InviteType.Landlords ? t('invite_landlord') : t('invite_agent')}
            onClick={handleInviteChipContacts}
          />
        </Flex>
      </Flex>

      <Flex className={classes.currentAndPotentialCollaboratorsContainer} autoWidth={false} direction="column">
        <Flex className={classes.currentAccessContainer} direction="column" wrap="nowrap">
          <h4 className={classes.smallHeading}>{t('current_access')}</h4>
          {currentContactList && currentContactList.length === 0 ? (
            <p className={classes.currentAccessContact}>
              {t('you_havent_invited_anyone_to_collaborate_on_this_schedule_yet')}
            </p>
          ) : (
            currentContactList.map((currentAccessUser) => (
              <Flex
                key={currentAccessUser.id}
                direction="row"
                justifyContent="space-between"
                wrap="nowrap"
                classes={{ root: classes.contactContentWrapper }}
              >
                <Flex
                  alignItems="center"
                  autoWidth={false}
                  className={classes.contactIconContainer}
                  justifyContent="center"
                >
                  <Avatar
                    classes={{ root: classes.contactAvatar, noImageInitials: classes.contactAvatar }}
                    name={currentAccessUser.name}
                    image={currentAccessUser.image?.url || ''}
                  />
                </Flex>
                <Flex autoWidth={false} direction="column" wrap="nowrap" className={classes.contactTextWrapper}>
                  <div className={classes.contactTitle}>
                    {`${currentAccessUser.name}${
                      currentAccessUser.organisation?.name ? ` (${currentAccessUser.organisation.name})` : ''
                    }`}
                  </div>
                  <div className={classes.contactSubtitle}>{currentAccessUser.position}</div>
                </Flex>
                <Dropdown
                  size={ButtonSize.small}
                  mainBtnText={t('pending')}
                  items={[
                    {
                      icon: <SendIcon />,
                      text: t('resend'),
                      onClick: () => console.log('TODO ~ Invite Flow'),
                    },
                    {
                      icon: <TrashIcon />,
                      text: t('remove'),
                      onClick: () => {
                        setCurrentContactList(currentContactList.filter((user) => user.id !== currentAccessUser.id));
                      },
                    },
                  ]}
                />
              </Flex>
            ))
          )}
        </Flex>

        {!!suggestedContacts.length && <div className={classes.divider} />}

        {!!suggestedContacts.length && (
          <Flex className={classes.suggestedInviteContainer} direction="column" wrap="nowrap">
            <h4 className={classes.smallHeading}>{t('suggested_contacts_to_invite')}</h4>
            {suggestedContacts.map((suggestedContact) => (
              <Flex
                key={suggestedContact.id}
                direction="row"
                wrap="nowrap"
                classes={{ root: classes.contactContentWrapper }}
              >
                <Flex
                  direction="row"
                  wrap="nowrap"
                  classes={{ root: classes.contactContentWrapper }}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Flex
                    alignItems="center"
                    autoWidth={false}
                    className={classes.contactIconContainer}
                    justifyContent="center"
                  >
                    <Avatar
                      classes={{ root: classes.contactAvatar, noImageInitials: classes.contactAvatar }}
                      name={suggestedContact.name}
                      image={suggestedContact.image.url}
                    />
                  </Flex>
                  <Flex autoWidth={false} direction="column" wrap="nowrap" className={classes.contactTextWrapper}>
                    <div className={classes.contactTitle}>{suggestedContact.name}</div>
                    <div className={classes.contactSubtitle}>Agent</div>
                  </Flex>
                  <Button
                    text={t('invite')}
                    size={ButtonSize.small}
                    colour={ButtonColour.secondary}
                    onClick={() => {
                      console.log('TODO ~ Invite Flow');
                      setCurrentContactList([...currentContactList, suggestedContact]);
                      setSuggestedContacts(suggestedContacts.filter((contact) => contact.id !== suggestedContact.id));
                    }}
                  />
                </Flex>
              </Flex>
            ))}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export const InviteCollaboratorsTab = withStyles(styles)(InviteCollaboratorsTabComponent);
