import { SvgIcon } from '@material-ui/core';
import { FC } from 'react';

import { withStyles, WithStyles } from '@core/theme/utils/with-styles';

import { styles } from './ThumbsDown.styles';

export interface ThumbsDownIconProps extends WithStyles<typeof styles> {
  color?: string;
}

const ThumbsDownIconComponent: FC<ThumbsDownIconProps> = ({ classes, color = '#344154' }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      classes={{ root: classes.root }}
    >
      <g clipPath="url(#clip0_3225_4933)">
        <path
          d="M11.3333 1.33301V8.66634M14.6666 6.53301V3.46634C14.6666 2.7196 14.6666 2.34624 14.5213 2.06102C14.3935 1.81014 14.1895 1.60616 13.9386 1.47833C13.6534 1.33301 13.28 1.33301 12.5333 1.33301H5.41196C4.43764 1.33301 3.95048 1.33301 3.55701 1.5113C3.21022 1.66843 2.91549 1.92129 2.70745 2.24015C2.4714 2.60194 2.39732 3.08343 2.24917 4.04642L1.90045 6.31309C1.70505 7.58322 1.60735 8.21828 1.79582 8.71242C1.96125 9.14614 2.27239 9.5088 2.6759 9.73825C3.13564 9.99968 3.77818 9.99967 5.06324 9.99967H5.59995C5.97332 9.99967 6.16001 9.99967 6.30261 10.0723C6.42806 10.1363 6.53004 10.2382 6.59396 10.3637C6.66662 10.5063 6.66662 10.693 6.66662 11.0663V13.0224C6.66662 13.9303 7.40262 14.6663 8.31051 14.6663C8.52706 14.6663 8.7233 14.5388 8.81125 14.3409L11.0518 9.29979C11.1537 9.07051 11.2046 8.95586 11.2852 8.8718C11.3563 8.79749 11.4438 8.74068 11.5406 8.7058C11.6501 8.66634 11.7756 8.66634 12.0265 8.66634H12.5333C13.28 8.66634 13.6534 8.66634 13.9386 8.52102C14.1895 8.39319 14.3935 8.18921 14.5213 7.93833C14.6666 7.65311 14.6666 7.27974 14.6666 6.53301Z"
          stroke={color}
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3225_4933">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export const ThumbsDownIcon = withStyles(styles)(ThumbsDownIconComponent);
