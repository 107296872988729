import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

export const defaultAbsoluteValue = 42;

export const styles = ({ shape, spacing, palette, zIndex }: Theme) => {
  const commonAbsoluteStyles = {
    backgroundColor: colors.white.lightest,
    borderRadius: shape.borderRadius,
    boxShadow: '0 6px 12px rgba(0,0,0,.175)',
    minWidth: '100%',
    position: 'absolute' as const,
    top: defaultAbsoluteValue,
    zIndex: zIndex.modal + 1,
  };

  return createStyles({
    root: {},
    label: {
      // TODO: Make this consistent with TextField styling, when time permits. -Ben
      display: 'inline-block',
      color: colors.grey.darker,
      fontSize: 14,
      marginBottom: spacing(3),
    },
    search: {
      position: 'relative',
    },
    loading: {
      color: colors.grey.main,
      maxWidth: '100%',
      padding: spacing(2.5, 4),
      ...commonAbsoluteStyles,
    },
    notFoundResults: {
      color: colors.grey.main,
      maxWidth: '100%',
      overflow: 'hidden',
      ...commonAbsoluteStyles,
    },
    notFoundText: {
      display: 'inline-block',
      padding: spacing(2.5, 4),
      width: '100%',
    },
    actionRow: {
      borderBottom: `1px solid ${colors.grey.lightest}`,
      color: colors.black.darker,
      display: 'flex',
      padding: spacing(2.5, 4),
    },
    textField: {
      width: '100%',
    },
    textFieldInput: {},
    list: {
      ...commonAbsoluteStyles,
    },
    optionsList: {
      maxHeight: 215,
      overflowX: 'hidden',
      padding: spacing(2, 0),
    },
    listAbove: {
      top: 'auto',
      bottom: defaultAbsoluteValue,
    },
    listItem: {
      padding: spacing(2, 4),

      '&:hover': {
        backgroundColor: colors.grey.body,
        color: palette.primary.main,
        cursor: 'pointer',
      },
    },
    clearIcon: {
      cursor: 'pointer',
      color: '#7E8899', //TODO replace after creating new palette
      zIndex: zIndex.drawer + 1,
      fontSize: 20,
      padding: 2,

      '&:hover': {
        color: '#D1D6DF', //TODO replace after creating new palette
      },
    },
  });
};
