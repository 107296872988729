import { createStyles } from '@material-ui/core/styles';

export const styles = () =>
  createStyles({
    root: {},
    dragging: {},
    item: {
      padding: 0,
    },
  });
