import { ListItem } from '@material-ui/core';
import cx from 'classnames';
import { Draggable } from 'react-beautiful-dnd';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';

import { styles } from './DraggableItem.styles';

export interface DraggableItemProps extends WithStyles<typeof styles> {
  index: number;
  item: any;
  itemCount: number;
  renderItem: (item: any, idx: number, itemsCount: number) => JSX.Element;
}

const DraggableListItemComponent: React.FC<DraggableItemProps> = ({ classes, item, itemCount, renderItem, index }) => {
  return (
    <Draggable draggableId={String(item.id)} index={index}>
      {(provided, snapshot) => (
        <ListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          classes={{
            root: cx({ [classes.dragging]: snapshot.isDragging }),
            gutters: classes.item,
          }}
        >
          {renderItem(item, index, itemCount)}
        </ListItem>
      )}
    </Draggable>
  );
};

export const DraggableItem = withStyles(styles)(DraggableListItemComponent);
