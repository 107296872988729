import { SvgIcon } from '@material-ui/core';
import { FC } from 'react';

import { withStyles, WithStyles } from '@core/theme/utils/with-styles';

import { styles } from './Plus.styles';

interface PlusIconProps extends WithStyles<typeof styles> {
  color?: string;
}

const PlusIconComponent: FC<PlusIconProps> = ({ classes, color = '#7E8899' }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      classes={{ root: classes.root }}
    >
      <path
        d="M6.00065 1.33334V10.6667M1.33398 6.00001H10.6673"
        stroke={color}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export const PlusIcon = withStyles(styles)(PlusIconComponent);
