import { createStyles, Theme } from '@material-ui/core/styles';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    content: {},
    modalRoot: {
      minHeight: 100,
    },
    label: {
      color: '#101828', // TODO replace after creating new palette
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '16px',
      marginBottom: spacing(2),
    },
    calendarBtnWrapper: {
      gap: 24,
    },
    calendarBtnDivider: {
      flex: 1,
      height: 1,
      background: '#EAECF0', //TODO change after creating new palette
    },
  });
