import { SvgIcon, useTheme } from '@material-ui/core';
import { FC } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';

import { styles } from './Message.styles';

export type MessageIconProps = WithStyles<typeof styles>;

const MessageIconComponent: FC<MessageIconProps> = ({ classes }) => {
  const theme = useTheme();

  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      classes={{ root: classes.root }}
    >
      <g clipPath="url(#clip0_3518_83513)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.5 23.3335H14.3883L5.5 30.0002V23.3335H0.5V-0.00146484H30.5V23.3335Z"
          fill={theme.palette.primarySpecial.main}
        />
        <path
          d="M33.8334 13.332V16.6654V26.667H13.8334V33.3337H26.6117L35.5001 40.0004V33.3337H40.5001V13.332H33.8334Z"
          fill="#201E42"
        />
        <circle cx="8.71428" cy="12.1429" r="2.14286" fill="white" />
        <circle cx="15.8571" cy="12.1429" r="2.14286" fill="white" />
        <circle cx="23" cy="12.1429" r="2.14286" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_3518_83513">
          <rect width="40" height="40" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export const MessageIcon = withStyles(styles)(MessageIconComponent);
