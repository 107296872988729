import { createStyles, Theme } from '@material-ui/core/styles';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      gap: spacing(4),

      '&:hover': {
        '& $deleteIconContainer': {
          display: 'block',
        },
      },
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      flex: 1,
      gap: spacing(1),
    },
    messageContainer: {
      background: '#E3E9FC', //TODO replace after palette will be change
      borderRadius: 12,
      gap: spacing(1),
      position: 'relative',
      padding: spacing(3),
    },

    message: {
      color: '#101828', //TODO replace after palette will be change
      fontSize: 14,
    },
    messageDate: {
      color: '#7E8899', //TODO replace after palette will be change
      fontSize: 12,
      fontWeight: 500,
      userSelect: 'none',
    },
    deleteIconContainer: {
      display: 'none',
      height: 32,
      width: 32,

      position: 'absolute',
      left: -spacing(10),
      top: 0,
    },
    deleteIcon: {
      fontSize: 16,
    },
    userAvatar: {
      height: 32,
      width: 32,
    },
    userAvatarNoInitials: {
      fontSize: 12,
    },
    emptySpace: {
      width: 32,
    },
  });
