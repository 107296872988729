import { ConfigCoreConstants } from '@shared/models/config';

import { Range } from './range';

export enum InterestScheduleStatuses {
  SentItem = 0,
  Monitoring = 1,
  Enquiries = 2,
  Viewing = 3,
  Negotiating = 4,
  Removed = 5,
  ViewingCompleted = 6,
  OfferReceived = 7,
  TermsIssued = 8,
  UnderOffer = 9,
  Exchanged = 10,
  Signed = 11,
  Inbox = 12,
  Prospect = 13,
}

export enum AvailabilityScheduleStatuses {
  Inbox = 0,
  Longlist = 1,
  Shortlisted = 2,
  Viewing = 3,
  Negotiating = 4,
  Removed = 5,
  ViewingCompleted = 6,
  OfferSubmitted = 7,
  TermsIssued = 8,
  UnderOffer = 9,
  Exchanged = 10,
  Signed = 11,
  Confidential = 1337,
}

export enum InterestScheduleCustomAction {
  ClearDiscountedReason = 'clearDiscountedReason',
  Discount = 'discount',
  HideFromSchedule = 'hideFromSchedule',
  ShowOnSchedule = 'ShowOnSchedule',
}

export type ScheduleStatuses = InterestScheduleStatuses | AvailabilityScheduleStatuses;

export enum ScheduleGroups {
  Active = 'active',
  Discounted = 'discounted',
  NotMatched = 'not_matched',
  Pending = 'pending',
}

export enum ScheduleStatusActions {
  Complete = 'complete',
  CreateViewing = 'create_viewing',
}

export enum ScheduleStatusTheme {
  Amber = 'amber',
  Blue = 'blue',
  Danger = 'danger',
  Default = 'default',
  Green = 'green',
  Grey = 'grey',
  Neutral = 'neutral',
  Primary = 'primary',
  Purple = 'purple',
  Red = 'red',
}

export enum ScheduleStatusGroups {
  Available = 'available',
  BidPlaced = 'bid_placed',
  Completed = 'completed',
  Confidential = 'confidential',
  DetailsShared = 'details_shared',
  Discounted = 'discounted',
  Enquiries = 'enquiries',
  Idle = 'idle',
  Longlist = 'longlist',
  MatchRequests = 'match_requests',
  Negotiating = 'negotiating',
  NotSent = 'not_sent',
  Progressing = 'progressing',
  ReceivedTeaser = 'received_teaser',
  RequestSent = 'request_sent',
  Shortlist = 'shortlist',
  Signed = 'signed',
  TeaserSent = 'teaser_sent',
  TermsAgreed = 'terms_agreed',
  UnderOffer = 'under_offer',
  Viewing = 'viewing',
  Prospect = 'prospect',
}
// Custom groups
export const ALL_SCHEDULE_STATUS_GROUP = 'all' as const;

export type ScheduleStatusGroupsWithAll = ScheduleStatusGroups | typeof ALL_SCHEDULE_STATUS_GROUP;

export interface ScheduleGroup {
  active: boolean;
  description: string;
  export: boolean;
  insights_description: string;
  manage: boolean;
  order_by?: string;
  statuses: ScheduleStatuses[] | null;
  title: string;
}

export interface ScheduleStatusConfig {
  action: ScheduleStatusActions | null;
  label: string;
  match_group: ScheduleGroups | null;
  show_if_status?: boolean;
  theme: ScheduleStatusTheme;
}

export interface ScheduleConfig {
  // Maps
  actionStatusesMap: Record<ScheduleStatusActions, ScheduleStatuses[]>;
  statusActionsMap: Record<ScheduleStatuses, ScheduleStatusActions>;
  statusLabelsMap: Record<ScheduleStatuses, string>;
  statusGroupsMap: Record<ScheduleStatuses, ScheduleStatusGroups>;
  statusThemesMap: Record<ScheduleStatuses, ScheduleStatusTheme>;

  // Keys
  statuses: ScheduleStatuses[];
  exportGroups: ScheduleStatusGroups[];
  insightGroups: ScheduleStatusGroups[];
  presentGroups: ScheduleStatusGroups[];
  presentGroupsActive: ScheduleStatusGroups[];
  scheduleGroups: ScheduleStatusGroups[];
  scheduleGroupsActive: ScheduleStatusGroups[];

  // Data
  groupConfig: Record<ScheduleStatusGroups, ScheduleGroup>;
  statusConfig: Record<ScheduleStatuses, ScheduleStatusConfig>;
}

export enum ScheduleMatchFieldCasts {
  Boolean = 'boolean',
  DateTime = 'DateTime',
  Float = 'float',
  FloatRange = 'FloatRange',
  InvestorProfile = 'InvestorProfile',
  MonthAndYear = 'MonthAndYear',
  MultiSelect = 'MultiSelect',
  Price = 'Price',
  PriceRange = 'PriceRange',
  RentRange = 'RentRange',
  SingleSelect = 'SingleSelect',
  SizeRange = 'SizeRange',
  String = 'string',

  // TODO: ---- These do not exist in BE ----

  ChargeCast = 'App\\Casts\\ChargeCast',
  Int = 'int',
  Json = 'json',
  MultiSelectInt = 'multi_select_int',
  MultiSelectString = 'MultiSelectString',
  SingleSelectInt = 'single_select_int',
  SingleSelectString = 'single_select_string',
}

export interface ScheduleMatchFieldConfig {
  input: ScheduleMatchFieldCasts;
  key: string;
  label: string;
  tooltip: string;
}

export type ChargeCastValue = Record<string, unknown>; // TODO
export interface DateTimeCastValue {
  date: string;
  timezone: string;
  timezone_type: number;
}

export type JsonValue = Record<string, unknown>; // TODO
export interface MonthYearIntValue {
  month: number | null;
  year: number | null;
}
export interface PriceRangeCastValue {
  currency: string | null;
  from: number | null;
  to: number | null;
}
export interface RentRangeCastValue {
  currency: string | null;
  from: number | null;
  metric: Metric | null;
  rates_inclusive: boolean;
  to: number | null;
}
export interface SizeRangeCastValue {
  from: number | null;
  metric: Metric | null;
  to: number | null;
}

export interface FloatRangeValue {
  from: number | null;
  to: number | null;
}

export const getDefaultPriceRangeCastValue = (constants: ConfigCoreConstants): PriceRangeCastValue => ({
  currency: constants.localisation.currencyDefaultIso,
  from: null,
  to: null,
});

export const getDefaultRentRangeCastValue = (constants: ConfigCoreConstants): RentRangeCastValue => ({
  currency: constants.localisation.currencyDefaultIso,
  from: null,
  metric: constants.defaultRentMetric,
  rates_inclusive: false,
  to: null,
});

export const getDefaultSizeRangeCastValue = (constants: ConfigCoreConstants): SizeRangeCastValue => ({
  from: null,
  metric: constants.defaultRentMetric,
  to: null,
});

export const getDefaultFloatRangeValue = (): FloatRangeValue => ({
  from: null,
  to: null,
});

export const getDefaultMonthYearValue = (): MonthYearIntValue => ({
  month: null,
  year: null,
});

export type ScheduleMatchFieldCastValues = {
  [ScheduleMatchFieldCasts.Boolean]: boolean | null;
  [ScheduleMatchFieldCasts.DateTime]: DateTimeCastValue | null; // TODO
  [ScheduleMatchFieldCasts.Float]: Range | null;
  [ScheduleMatchFieldCasts.FloatRange]: FloatRangeValue | null;
  [ScheduleMatchFieldCasts.InvestorProfile]: Record<string, unknown> | null; // TODO
  [ScheduleMatchFieldCasts.MonthAndYear]: MonthYearIntValue | null;
  [ScheduleMatchFieldCasts.MultiSelect]: Id[] | null;
  [ScheduleMatchFieldCasts.Price]: Record<string, unknown> | null; // TODO
  [ScheduleMatchFieldCasts.PriceRange]: PriceRangeCastValue | null;
  [ScheduleMatchFieldCasts.RentRange]: RentRangeCastValue | null;
  [ScheduleMatchFieldCasts.SingleSelect]: Id | null;
  [ScheduleMatchFieldCasts.SizeRange]: SizeRangeCastValue | null;
  [ScheduleMatchFieldCasts.String]: string | null;

  // TODO: ---- These do not exist in BE ----
  [ScheduleMatchFieldCasts.ChargeCast]: ChargeCastValue | null;
  [ScheduleMatchFieldCasts.Int]: number | null;
  [ScheduleMatchFieldCasts.Json]: JsonValue | null;
  [ScheduleMatchFieldCasts.MultiSelectInt]: number[] | null;
  [ScheduleMatchFieldCasts.MultiSelectString]: string[] | null;
  [ScheduleMatchFieldCasts.SingleSelectInt]: number | null;
  [ScheduleMatchFieldCasts.SingleSelectString]: string | null;
};

export type ScheduleMatchFieldAllValues = ValueOf<ScheduleMatchFieldCastValues>;

export interface ScheduleMatchFieldValueWithString<
  TValue extends ScheduleMatchFieldAllValues = ScheduleMatchFieldAllValues
> {
  value: TValue;
  value_string: string;
}

export type ScheduleMatchFieldsSerialised = Record<string, ScheduleMatchFieldAllValues>;
export type ScheduleMatchFieldsDeserialised = Record<string, ScheduleMatchFieldValueWithString>;

export interface InterestScheduleData {
  can_add_requirement: boolean;
  disposal_id: number;
  investment: NumberBoolean;
  locked: NumberBoolean;
  preferred_columns: string[];
}

export enum DiscountedReason {
  Location = 1,
  Budget = 2,
  SizeTooSmall = 3,
  SizeTooBig = 4,
  Space = 5,
  Specification = 6,
  Terms = 7,
  Staying = 8,
  RequirementsChanged = 9,
  SignedElsewhere = 10,
  OnHold = 11,
  Cancelled = 12,
  NoResponse = 14,
  Unknown = 15,
  // TODO - Requirement closed for some reason
  TakenOffMarket = 16, // TODO - Disposal closed for some reason
  ReasonChoseJointListing = 17,
  ReasonJunk = 18,
  ReasonTimingNotRight = 19,
  ReasonMarketResearchCompSchedule = 20,
  ReasonShortlistedElsewhere = 21,
}

export interface AvailabilityScheduleIndexFeatureFlags {
  agent_messages_visible: boolean;
  taken_off_marketplace: boolean;
}

export interface AvailabilityScheduleShowFeatureFlags {
  agent_messages_visible: boolean;
  can_set_financials_team_intel: boolean;
  match_pending: boolean;
  taken_off_marketplace: boolean;
}

export interface InterestScheduleIndexFeatureFlags {
  can_manage_requirement: boolean;
  can_set_confidentiality: boolean;
  can_set_tenant_intel: boolean;
  can_show_hide_from_presentation: boolean;
  taken_off_marketplace: boolean;
}

export interface InterestScheduleShowFeatureFlags {
  agent_messages_visible: boolean;
  can_manage_requirement: boolean;
  can_set_confidentiality: boolean;
  can_set_tenant_name: boolean;
  can_show_hide_from_presentation: boolean;
  taken_off_marketplace: boolean;
  tenancy_details_visible: boolean;
}
