import { SvgIcon } from '@material-ui/core';
import { FC } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';

import { styles } from './User.styles';

export interface IconProps extends WithStyles<typeof styles> {
  color?: string;
}

const UserLeftIconComponent: FC<IconProps> = ({ classes, color = '#9EA6B3' }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      classes={{ root: classes.root }}
    >
      <rect width="20" height="20" transform="translate(0 0.981934)" fill="none" />
      <path
        d="M15.8334 8.48193L13.3334 5.98193M13.3334 5.98193L15.8334 3.48193M13.3334 5.98193L18.3334 5.98193M13.3334 18.4819V17.4819C13.3334 16.0818 13.3334 15.3817 13.0609 14.847C12.8212 14.3766 12.4388 13.9941 11.9684 13.7544C11.4336 13.4819 10.7335 13.4819 9.33341 13.4819H5.66675C4.26662 13.4819 3.56655 13.4819 3.03177 13.7544C2.56137 13.9941 2.17892 14.3766 1.93923 14.847C1.66675 15.3817 1.66675 16.0818 1.66675 17.4819V18.4819M10.4167 7.23193C10.4167 8.84276 9.11091 10.1486 7.50008 10.1486C5.88925 10.1486 4.58341 8.84276 4.58341 7.23193C4.58341 5.6211 5.88925 4.31527 7.50008 4.31527C9.11091 4.31527 10.4167 5.6211 10.4167 7.23193Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export const UserLeftIcon = withStyles(styles)(UserLeftIconComponent);
