import { SvgIcon } from '@material-ui/core';
import { FC } from 'react';

import { withStyles, WithStyles } from '@core/theme/utils/with-styles';

import { styles } from './Folder.styles';

export interface FolderIconProps extends WithStyles<typeof styles> {
  color?: string;
}

const FolderIconComponent: FC<FolderIconProps> = ({ classes, color = '#7E8899' }) => {
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      classes={{ root: classes.root }}
    >
      <path
        d="M8.66732 4.66667L7.92362 3.17928C7.70959 2.7512 7.60256 2.53715 7.4429 2.38078C7.3017 2.24249 7.13153 2.13732 6.9447 2.07287C6.73343 2 6.49412 2 6.01551 2H3.46732C2.72058 2 2.34721 2 2.062 2.14532C1.81111 2.27316 1.60714 2.47713 1.47931 2.72801C1.33398 3.01323 1.33398 3.3866 1.33398 4.13333V4.66667M1.33398 4.66667H11.4673C12.5874 4.66667 13.1475 4.66667 13.5753 4.88465C13.9516 5.0764 14.2576 5.38236 14.4493 5.75869C14.6673 6.18651 14.6673 6.74656 14.6673 7.86667V10.8C14.6673 11.9201 14.6673 12.4802 14.4493 12.908C14.2576 13.2843 13.9516 13.5903 13.5753 13.782C13.1475 14 12.5874 14 11.4673 14H4.53398C3.41388 14 2.85383 14 2.426 13.782C2.04968 13.5903 1.74372 13.2843 1.55197 12.908C1.33398 12.4802 1.33398 11.9201 1.33398 10.8V4.66667Z"
        stroke={color}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export const FolderIcon = withStyles(styles)(FolderIconComponent);
