import {
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableCellProps as MuiTableCellProps,
  TableContainer as MuiTableContainer,
  TableFooter as MuiTableFooter,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
} from '@material-ui/core';
import { ArrowDropDown as ArrowDropDownIcon, ArrowDropUp as ArrowDropUpIcon } from '@material-ui/icons';
import cx from 'classnames';
import lodashGet from 'lodash/get';
import { FC, ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Ellipsis } from '@shared/components/ellipsis';
import { Flex } from '@shared/components/flex';
import { Sorting, SortingType } from '@shared/types/services';

import styles from './TableNew.styles';

const get = (object: { [key: string]: any }, path: string, defaultValue: string) => {
  const value = lodashGet(object, path);
  return value || value === 0 ? value : defaultValue;
};

export const sortTableColumnsWithBasedArray = (array: Array<TableColumn<any>>, baseColumns: string | null) => {
  const baseArray = baseColumns ? baseColumns.split(',') : [];
  return array.sort((a, b) => (baseArray.indexOf(a.field) > baseArray.indexOf(b.field) ? 1 : -1));
};

export type TableColumnField = string;

export interface TableColumn<TData = any, TField extends TableColumnField = TableColumnField> {
  align?: MuiTableCellProps['align'];
  cellClassName?: string;
  className?: string;
  field: TField;
  headerCell?: ReactNode;
  headerCellClassName?: string;
  maxWidth?: number;
  minWidth?: number;
  name: string;
  sortable?: boolean;
  width?: number;
}

export enum TableTheme {
  Page = 'page',
}

export interface TableNewProps extends WithStyles<typeof styles> {
  columns: TableColumn[];
  defaultOrderField?: TableColumnField;
  defaultOrderType?: SortingType;
  footerRowItem?: Item;
  hover?: boolean;
  items: Item[];
  noDataText?: string;
  stickyHeader?: boolean;
  stickyColumn?: number;
  theme?: TableTheme;
  onColumnClick?: (sort: Sorting) => void;
  onRowClick?: (item: Item) => void;
  renderBodyCell?: (field: TableColumnField, item: Item) => ReactNode;
  sortData?: (items: Item[], field: TableColumnField, sorting: SortingType) => Item[];
}

const TableNewComponent: FC<TableNewProps> = ({
  classes,
  columns,
  defaultOrderField,
  defaultOrderType,
  footerRowItem,
  hover = true,
  items,
  noDataText,
  stickyColumn,
  stickyHeader = true,
  theme,
  onColumnClick,
  onRowClick,
  renderBodyCell,
  sortData,
}) => {
  const tableContainerRef = useRef<HTMLDivElement | null>(null);
  const tableRef = useRef<HTMLTableElement | null>(null);

  const defaultSortBy: Sorting = useMemo(
    () => ({
      field: defaultOrderField || '',
      type: defaultOrderType || SortingType.asc,
    }),
    []
  );

  const [sortBy, setSortBy] = useState<Sorting>(defaultSortBy);
  const [verticalScroll, setVerticalScroll] = useState(false);

  const sortedItems = useMemo(() => {
    if (sortData && sortBy.field) {
      return sortData(items, sortBy.field, sortBy.type);
    }

    return items;
  }, [items, sortData, sortBy]);

  const hasData = useMemo(() => !!items.length, [items]);

  const columnsConfig = useMemo(() => {
    let minTableWidth = 0;

    const processedColumns = columns.slice().map((item) => {
      const column = { ...item };
      column.maxWidth = column.maxWidth || column.width;
      column.minWidth = column.minWidth || column.width;

      minTableWidth += column.minWidth || 0;

      return column;
    });

    return { columns: processedColumns, minTableWidth };
  }, [columns]);

  const handleColumnClick = (field: TableColumnField) => {
    const _sortBy: Sorting = { ...sortBy, field };

    if (sortBy.field == field) {
      // Toggle type
      _sortBy.type = _sortBy.type === SortingType.asc ? SortingType.desc : SortingType.asc;
    } else {
      // Sort by asc, as it is a new sort
      _sortBy.type = SortingType.asc;
    }

    setSortBy(_sortBy);

    if (onColumnClick) {
      onColumnClick(_sortBy);
    }
  };

  const headContent = useMemo(() => {
    if (!columnsConfig.columns.length) {
      return null;
    }

    return (
      <MuiTableHead classes={{ root: classes.headerRoot }}>
        <MuiTableRow>
          {columnsConfig.columns.map((column, i) => {
            const isCurrentOrderField = column.field === sortBy.field;
            const canSort = hasData && column.sortable;

            return (
              <MuiTableCell
                key={i}
                align={column.align}
                style={{ width: column.width, maxWidth: column.maxWidth, minWidth: column.minWidth }}
                sortDirection="desc"
                className={cx(classes.cell, classes.headerCell, column.className, column.headerCellClassName, {
                  [classes.headerCellActive]: isCurrentOrderField,
                  [classes.headerCellDisabled]: !canSort,
                  [classes.headerCellSticky]: stickyColumn === i,
                })}
                onClick={canSort ? () => handleColumnClick(column.field) : undefined}
              >
                <Flex wrap="nowrap" alignItems="center" className={classes.headerCellTextWrapper}>
                  {!!column.headerCell && column.headerCell}
                  {!!column.name && !column.headerCell && (
                    <Ellipsis
                      withTooltip={false}
                      text={column.name}
                      tooltipPlacement="top"
                      classes={{ root: classes.headerCellText }}
                    />
                  )}
                  {canSort && (
                    <Flex container direction="column" className={classes.sortIcons}>
                      <ArrowDropUpIcon
                        className={cx(classes.sortIcon, {
                          [classes.sortActiveIcon]: isCurrentOrderField && sortBy.type === SortingType.asc,
                        })}
                      />
                      <ArrowDropDownIcon
                        className={cx(classes.sortIcon, {
                          [classes.sortActiveIcon]: isCurrentOrderField && sortBy.type === SortingType.desc,
                        })}
                      />
                    </Flex>
                  )}
                </Flex>
              </MuiTableCell>
            );
          })}
        </MuiTableRow>
      </MuiTableHead>
    );
  }, [classes, columnsConfig.columns, hasData, sortBy.field, stickyColumn]);

  const bodyContent = useMemo(() => {
    if (!sortedItems.length && noDataText) {
      return (
        <MuiTableBody classes={{ root: classes.bodyRoot }}>
          <MuiTableRow hover={hover} role="checkbox" tabIndex={-1}>
            <MuiTableCell classes={{ root: cx(classes.cell, classes.bodyCell) }} colSpan={columnsConfig.columns.length}>
              {noDataText}
            </MuiTableCell>
          </MuiTableRow>
        </MuiTableBody>
      );
    }

    return (
      <MuiTableBody classes={{ root: classes.bodyRoot }}>
        {sortedItems.map((item, itemIndex) => (
          <MuiTableRow
            key={`${itemIndex}:${item.id || 0}`}
            hover={hover}
            role="checkbox"
            tabIndex={-1}
            onClick={onRowClick ? () => onRowClick(item) : undefined}
            classes={{
              root: cx(
                { [classes.row]: !!onRowClick },
                { [classes.rowHighlighted]: !!item?.selected },
                { [classes.rowHidden]: !!item?.hidden }
              ),
            }}
          >
            {columnsConfig.columns.map((column, columnIndex) => (
              <MuiTableCell
                key={`${itemIndex}:${item.id || 0}_${columnIndex}:${column.field}`}
                style={{ width: column.width, maxWidth: column.maxWidth, minWidth: column.minWidth }}
                classes={{
                  root: cx(classes.cell, classes.bodyCell, column.className, column.cellClassName, {
                    [classes.bodyCellSticky]: stickyColumn === columnIndex,
                  }),
                }}
              >
                {renderBodyCell ? renderBodyCell(column.field, item) : get(item, column.field, '-')}
              </MuiTableCell>
            ))}
          </MuiTableRow>
        ))}
      </MuiTableBody>
    );
  }, [classes, columnsConfig.columns, hover, renderBodyCell, onRowClick, sortedItems, stickyColumn]);

  const footerContent = useMemo(() => {
    if (!footerRowItem || !hasData) {
      return;
    }

    return (
      <MuiTableFooter classes={{ root: classes.footerRoot }}>
        <MuiTableRow>
          {columnsConfig.columns.map((column, columnIndex) => (
            <MuiTableCell
              key={`${footerRowItem?.id || 0}_${columnIndex}:${column.field}`}
              style={{ width: column.width, maxWidth: column.maxWidth, minWidth: column.minWidth }}
              classes={{ root: cx(classes.cell, classes.footerCell, column.className, column.cellClassName) }}
            >
              {get(footerRowItem, column.field, '')}
            </MuiTableCell>
          ))}
        </MuiTableRow>
      </MuiTableFooter>
    );
  }, [classes, columnsConfig.columns, footerRowItem, hasData]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVerticalScroll(
        Number(tableContainerRef?.current?.offsetHeight) <= Number(tableRef?.current?.offsetHeight) + 2
      ); // means +1px border top and +1 border bottom);
    }, 100);

    return () => clearTimeout(timer);
  }, [tableContainerRef?.current, tableRef?.current, bodyContent]);

  return (
    <MuiTableContainer
      className={cx(classes.root, theme ? classes[theme] : '', { [classes.rootFullHeight]: verticalScroll })}
      ref={tableContainerRef}
    >
      <MuiTable
        stickyHeader={stickyHeader}
        style={{ minWidth: `${columnsConfig.minTableWidth}px` }}
        className={classes.table}
        ref={tableRef}
      >
        {headContent}
        {bodyContent}
        {footerContent}
      </MuiTable>
    </MuiTableContainer>
  );
};

export const TableNew = withStyles(styles)(TableNewComponent);
