import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

const transitionDuration = 0.15;
export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {},
    inputClass: {
      maxHeight: 32,
      backgroundColor: '#F2F4F7',
      padding: spacing(0, 2.25),
      color: colors.black.darker,
      fontSize: 14,
      fontWeight: 400,
      width: 280,

      '&.Mui-disabled': {
        boxShadow: 'none',
        cursor: 'not-allowed',
        opacity: 0.5,
        pointerEvents: 'none',
        userSelect: 'none',
      },

      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },

      '&:hover': {
        backgroundColor: colors.white.lightest,

        '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
          borderWidth: `1px !important`,
          borderColor: colors.kato.navy[950],
          transitionDuration: `${transitionDuration}s`,
          transitionTimingFunction: 'ease-in-out',
          transitionDelay: '0s',
          transitionProperty: 'background-color, border-color, box-shadow',
        },
      },

      '&.Mui-focused': {
        backgroundColor: colors.white.lightest,

        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: colors.kato.navy[950],
          borderWidth: `1px !important`,
          boxShadow: '0px 0px 0px 4px rgba(50, 77, 252, 0.12)',
          transitionDuration: `${transitionDuration}s`,
          transitionTimingFunction: 'ease-in-out',
          transitionDelay: '0s',
          transitionProperty: 'background-color, border-color, box-shadow',
        },
      },
    },
    searchIcon: {
      fontSize: 13,
      marginRight: spacing(2),
    },
    clearIcon: {
      cursor: 'pointer',
      color: colors.grey.main,
      fontSize: 18,
    },
  });
