import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

export const styles = ({ spacing }: Theme) => {
  return createStyles({
    root: {},
    tooltip: {},
    tooltipContainer: {},
    icon: {
      color: colors.black.darkest,
      fontSize: 12,
      cursor: 'pointer',
      marginLeft: spacing(2),
    },
  });
};
