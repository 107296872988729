import { SvgIcon } from '@material-ui/core';
import { FC } from 'react';

import { withStyles, WithStyles } from '@core/theme/utils/with-styles';

import { styles } from './Send.styles';

export interface SendIconProps extends WithStyles<typeof styles> {
  color?: string;
}

const SendIconComponent: FC<SendIconProps> = ({ classes, color = '#9EA6B3' }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      classes={{ root: classes.root }}
    >
      <path
        d="M7.00028 7.99986H3.33362M3.27718 8.19419L1.72057 12.844C1.59828 13.2093 1.53713 13.3919 1.58101 13.5044C1.61912 13.6021 1.70096 13.6761 1.80195 13.7043C1.91824 13.7368 2.09388 13.6577 2.44517 13.4997L13.5862 8.48622C13.929 8.33192 14.1005 8.25477 14.1535 8.1476C14.1995 8.05449 14.1995 7.94524 14.1535 7.85212C14.1005 7.74495 13.929 7.6678 13.5862 7.5135L2.44129 2.49834C2.09106 2.34074 1.91595 2.26194 1.79977 2.29426C1.69888 2.32234 1.61704 2.39619 1.57881 2.49369C1.53478 2.60595 1.59527 2.7882 1.71625 3.15271L3.27761 7.85688C3.29839 7.91948 3.30878 7.95079 3.31288 7.9828C3.31652 8.01121 3.31649 8.03997 3.31277 8.06837C3.30859 8.10037 3.29812 8.13164 3.27718 8.19419Z"
        stroke={color}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export const SendIcon = withStyles(styles)(SendIconComponent);
