import { createStyles, Theme } from '@material-ui/core/styles';

import { colors, colours } from '@shared/theme/colours';

export const styles = ({ spacing, breakpoints }: Theme) =>
  createStyles({
    dialogRoot: {
      overflowY: 'auto',

      '& .MuiDialog-container': {
        height: 'auto',
        margin: spacing(7, 0, 7, 0),
      },
    },
    dialogPaper: {
      maxHeight: 560,
      minHeight: 560,
      maxWidth: 900,
      overflowY: 'hidden',
      position: 'relative',
      top: 0,
      width: '100%',

      [breakpoints.down('sm')]: {
        minWidth: '100%',
        minHeight: 'auto',
      },
    },
    dialogBody: {
      flex: '1 1 100%',
      gap: `0 !important`,
      overflowY: 'hidden',
      padding: `0 !important`,
    },
    dialogActions: {
      padding: `0 !important`,
    },

    // Body framing
    container: {
      background: colours.solid.white,
      flex: '1 1 auto',
      overflowY: 'hidden',
    },
    footer: {
      background: colours.solid.white,
      borderTop: '1px solid #EAECF0', //TODO change after creating new palette
      flex: '0 0 auto',
      padding: spacing(4, 6),
    },

    // Shared column styling
    columnItem: {
      color: '#101828', // TODO replace after creating new palette
      padding: spacing(2),
      userSelect: 'none',
      width: '100%',

      '&:hover': {
        backgroundColor: '#F7F8FA', // TODO replace after palette will be change
      },
    },
    columnLabel: {
      color: 'inherit',
      flex: '1 1 auto',
      fontSize: 14,
      lineHeight: '16px',
      fontWeight: 500,
    },

    // Left container
    leftContainer: {
      borderRight: `1px solid #EAECF0`, // TODO replace after palette will be change
      display: 'flex',
      flexDirection: 'column',
      padding: spacing(4, 6),
      width: '50%',
    },
    searchField: {
      flex: '0 0 auto',
      marginBottom: spacing(5),
    },
    columnsCheckboxes: {
      flex: '1 1 auto',
      height: '100%',
      overflowX: 'hidden',
      overflowY: 'auto',
    },
    columnsCheckboxRoot: {
      gap: spacing(2),
      marginBottom: 0,
      marginRight: 0,
    },
    checkbox: {
      padding: 3,
      paddingLeft: spacing(3),
    },
    checkboxIcon: {
      flex: '0 0 auto',
      height: 16,
      width: 16,
    },
    checkboxIconChecked: {
      fontSize: 16,
    },

    // Right container
    rightContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: spacing(4, 6),
      width: '50%',
      overflow: 'hidden',
    },
    fixedColumnItem: {
      color: '#9EA6B3', // TODO replace after creating new palette
      flex: '0 0 auto',
      gap: spacing(2),
      padding: spacing(2),
      userSelect: 'none',
    },
    fixedItemIcon: {
      blockSize: '17px',
      flex: '0 0 auto',
      fontSize: 14,

      '& > path': {
        stroke: 'currentColor',
      },
    },
    selectedColumnsTitle: {
      color: 'inherit',
      display: 'inline-block',
      flex: '0 0 auto',
      fontSize: 14,
      fontWeight: 500,
      marginBottom: spacing(3),
    },
    selectedColumnsList: {
      display: 'flex',
      flexDirection: 'column',
      flex: '1 1 auto',
      height: '100%',
      overflow: 'auto',
      width: '100%',
    },
    draggableList: {
      height: '100%',
    },
    draggableItem: {
      gap: spacing(2),

      '&:hover': {
        cursor: 'grab',
      },
    },
    draggableItemIcon: {
      color: '#7E8899', // TODO replace after creating new palette
      flex: '0 0 auto',
      fontSize: 16,

      '& > circle': {
        fill: 'currentColor',
      },
    },
    draggableItemIconClose: {
      color: '#9EA6B3', // TODO replace after creating new palette
      cursor: 'pointer',
      flex: '0 0 auto',
      fontSize: 16,
      marginLeft: spacing(2),

      '& > path': {
        stroke: 'currentColor',
      },
    },

    // Footer
    footerError: {
      fontSize: 15,
      fontWeight: 500,
      display: 'inline-block',
      color: colors.red.darkish,
      marginBottom: spacing(2),
    },
    footerActions: {},
    actionBtnLabel: {},
    allColumnsButton: {},
    saveButton: {
      marginLeft: spacing(3),
    },
  });
