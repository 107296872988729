import { SvgIcon } from '@material-ui/core';
import { FC } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';

import { styles } from './PieChart.styles';

export interface PieChartIconProps extends WithStyles<typeof styles> {
  color?: string;
}

const PieChartIconComponent: FC<PieChartIconProps> = ({ classes, color = '#515D70' }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      classes={{ root: classes.root }}
    >
      <path
        d="M20.2104 14.8896C19.5742 16.3941 18.5792 17.7198 17.3123 18.7509C16.0454 19.782 14.5452 20.487 12.9428 20.8044C11.3405 21.1217 9.68483 21.0417 8.12055 20.5714C6.55627 20.101 5.13103 19.2547 3.96942 18.1062C2.80782 16.9578 1.94522 15.5423 1.45704 13.9835C0.968859 12.4247 0.869965 10.7701 1.169 9.16422C1.46804 7.55836 2.1559 6.05021 3.17245 4.77161C4.189 3.49301 5.50329 2.4829 7.0004 1.82958M20.2392 7.17275C20.6395 8.13921 20.8851 9.16095 20.9684 10.2004C20.989 10.4572 20.9993 10.5856 20.9483 10.7013C20.9057 10.7979 20.8213 10.8893 20.7284 10.9395C20.6172 10.9996 20.4783 10.9996 20.2004 10.9996H11.8004C11.5204 10.9996 11.3804 10.9996 11.2734 10.9451C11.1793 10.8971 11.1028 10.8207 11.0549 10.7266C11.0004 10.6196 11.0004 10.4796 11.0004 10.1996V1.79958C11.0004 1.52171 11.0004 1.38278 11.0605 1.27156C11.1107 1.17866 11.2021 1.09427 11.2987 1.05167C11.4144 1.00068 11.5428 1.01098 11.7996 1.03156C12.839 1.1149 13.8608 1.36046 14.8272 1.76079C16.0405 2.26333 17.1429 2.99993 18.0715 3.92851C19.0001 4.8571 19.7367 5.95949 20.2392 7.17275Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export const PieChartIcon = withStyles(styles)(PieChartIconComponent);
