import { SvgIcon } from '@material-ui/core';
import { FC } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';

import { styles } from './CalendarPlus.styles';

export interface CalendarPlusIconProps extends WithStyles<typeof styles> {
  color?: string;
}

const CalendarPlusIconComponent: FC<CalendarPlusIconProps> = ({ classes, color = '#515D70' }) => {
  return (
    <SvgIcon
      classes={{ root: classes.root }}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M14 7.66666V5.86666C14 4.74656 14 4.1865 13.782 3.75868C13.5903 3.38236 13.2843 3.0764 12.908 2.88465C12.4802 2.66666 11.9201 2.66666 10.8 2.66666H5.2C4.0799 2.66666 3.51984 2.66666 3.09202 2.88465C2.71569 3.0764 2.40973 3.38236 2.21799 3.75868C2 4.1865 2 4.74656 2 5.86666V11.4667C2 12.5868 2 13.1468 2.21799 13.5746C2.40973 13.951 2.71569 14.2569 3.09202 14.4487C3.51984 14.6667 4.0799 14.6667 5.2 14.6667H8.33333M14 6.66666H2M10.6667 1.33333V3.99999M5.33333 1.33333V3.99999M12 14V10M10 12H14"
        stroke={color}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export const CalendarPlusIcon = withStyles(styles)(CalendarPlusIconComponent);
