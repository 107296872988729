import { createQueryKeys, inferQueryKeys } from '@lukemorales/query-key-factory';
import { AxiosResponse } from 'axios';

import { getHTTPClient, useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@core/http-client';
import { ContactShowItem } from '@redux/types/crm/contacts/contactsTypes';
import { getQueriesAsSearch } from '@shared/utils/common';

import { ContactsCreateCommentPayload, ContactsListPayload, ContactsResponse } from './contactsApi.types';

const $http = getHTTPClient();

export const contactsQueryKeys = createQueryKeys('contacts', {
  all: null,
  list: (payload: ContactsListPayload) => ({
    queryKey: [payload.query],
    queryFn: async ({ signal }) =>
      (await $http.get<ContactsResponse>(`/contacts${getQueriesAsSearch(payload.query)}`, { signal })).data,
  }),
});

export const useContactsCreateCommentMutation = (
  opts?: UseMutationOptions<ContactsCreateCommentPayload, ContactShowItem>
) =>
  useMutation({
    ...opts,
    mutationFn: async (data) =>
      (
        await $http.post<ContactsCreateCommentPayload['body'], AxiosResponse<ContactShowItem>>(
          `/contacts/${data.id}/comment`,
          data.body
        )
      ).data,
  });

export type contactsQueryKeys = inferQueryKeys<typeof contactsQueryKeys>;

export const useContactListQuery = (payload: ContactsListPayload, options?: UseQueryOptions<ContactsResponse>) =>
  useQuery<contactsQueryKeys['list']['queryKey'], ContactsResponse>({
    ...contactsQueryKeys.list(payload),
    keepPreviousData: true,
    ...options,
  });
