import { cloneDeep, isEqual } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import { getObjectDiff } from '@shared/utils/common';

export interface UseChangesStateResult<TState> {
  hasChanges: boolean;
  resetState: (state: TState) => void;
  setState: UseStateSetter<TState>;
  state: TState;
}

export const useChangesState = <TState = any>(defaultState: TState): UseChangesStateResult<TState> => {
  const [initialState, setInitialState] = useState<TState>(defaultState);
  const [state, setState] = useState<TState>(defaultState);
  const [hasChanges, setHasChanges] = useState<boolean>(false);

  useEffect(() => setHasChanges(!isEqual(initialState, state)), [state, initialState]);

  return useMemo(
    () => ({
      hasChanges,
      state,
      resetState: (newState: TState) => {
        setInitialState(newState);
        setState(newState);
      },
      setState,
    }),
    [hasChanges, setState, state]
  );
};

export interface UseChangesRecordStateResult<TState> {
  hasChanges: boolean;
  resetState: (state: TState) => void;
  setState: UseStateSetter<TState>;
  state: TState;
  stateChanges: Partial<TState>;
  updateState: (state: Partial<TState>) => void;
}

export const useChangesRecordState = <TState extends Record<keyof TState, ValueOf<TState>>>(
  defaultState: TState,
  blacklistKeys: (keyof TState)[] = [],
  onChange?: (hasChanges: boolean) => void
): UseChangesRecordStateResult<TState> => {
  const [initialState, setInitialState] = useState<TState>(() => cloneDeep(defaultState));
  const [state, setState] = useState<TState>(() => cloneDeep(defaultState));

  const stateChanges = useMemo(
    () => getObjectDiff<TState>(initialState, state, blacklistKeys),
    [blacklistKeys, initialState, state]
  );

  const changesCount = useMemo(() => Object.keys(stateChanges).length, [stateChanges]);

  // Call onChange when state changes
  useEffect(() => {
    if (onChange) {
      onChange(changesCount > 0);
    }
  }, [changesCount]);

  // Sync initial state and state when default state changes
  useEffect(() => {
    setInitialState(cloneDeep(defaultState));
    setState(cloneDeep(defaultState));
  }, [defaultState]);

  return useMemo(() => {
    const result: UseChangesRecordStateResult<TState> = {
      hasChanges: changesCount > 0,
      state,
      stateChanges,
      resetState: (newState: InitialStateValue<TState>) => {
        setInitialState(cloneDeep(newState));
        setState(cloneDeep(newState));
      },
      setState,
      updateState: (state: Partial<TState>) => setState((prev) => ({ ...prev, ...state })),
    };

    return result;
  }, [changesCount, setState, state, stateChanges]);
};
