import { createContext, FC, useContext, useMemo, useState } from 'react';

import { DisposalInterestIndexItem } from '@shared/apis/disposals';
import {
  defaultInboundUrlState,
  getInboundUrlState,
  InboundUrlState,
} from '@shared/components/interest-schedule/reporting-inbound-space';
import {
  defaultOutboxUrlState,
  getOutboxUrlState,
  OutboxUrlState,
} from '@shared/components/interest-schedule/reporting-outbox-space';
import {
  defaultScheduleUrlState,
  getScheduleUrlState,
  ScheduleUrlState,
} from '@shared/components/interest-schedule/reporting-schedule-space';
import { UrlViewState, useUrlState } from '@shared/hooks/viewState';
import { ALL_SCHEDULE_STATUS_GROUP, ScheduleStatusGroups } from '@shared/types/common/match';
import { getQueries, getQueryValueString } from '@shared/utils/common';
import { GroupdByFilter, InterestTypeFilter } from '@shared/utils/interest-schedule';

export enum SpaceView {
  Activity = 'activity',
  // Comps = 'comps',
  Find = 'find',
  // Insights = 'insights',
  Inbound = 'inbound',
  Outbox = 'outbox',
  Schedule = 'schedule',
  Viewings = 'viewings',
}

export enum PresentSpaceView {
  Insights = 'insights',
  Schedule = 'schedule',
  Viewings = 'viewings',
}

// Supported values
export const supportedGroupedBy = [...Object.values(GroupdByFilter)];
export const supportedMatchesType = [...Object.values(InterestTypeFilter)];
export const supportedPresentSpaces = [...Object.values(PresentSpaceView)];
export const supportedSpaces = [...Object.values(SpaceView)];
export const supportedTabs = [...Object.values(ScheduleStatusGroups), ALL_SCHEDULE_STATUS_GROUP];

export interface UrlState
  extends WithEmptyString<InboundUrlState>,
    WithEmptyString<OutboxUrlState>,
    WithEmptyString<ScheduleUrlState> {
  // present: boolean;
  present_space: PresentSpaceView;
  space: SpaceView;
  view_interest: string;
}

export const getInitialUrlState = (queries: Query): UrlState => {
  let presentSpaceValue = getQueryValueString('present_space', queries) as PresentSpaceView;
  if (!presentSpaceValue || !supportedPresentSpaces.includes(presentSpaceValue)) {
    presentSpaceValue = PresentSpaceView.Schedule;
  }

  let spaceValue = getQueryValueString('space', queries) as SpaceView;
  if (!spaceValue || !supportedSpaces.includes(spaceValue)) {
    spaceValue = SpaceView.Schedule;
  }

  const result: UrlState = {
    // Top-level
    // present: !!queries.present,
    present_space: presentSpaceValue,
    space: spaceValue,
    view_interest: getQueryValueString('view_interest', queries),

    // Inbound space - defaults
    inbound_search: '',
    inbound_tab: '',

    // Inbound space
    ...(spaceValue === SpaceView.Inbound && getInboundUrlState(queries)),

    // Schedule space - defaults
    schedule_group_by: '',
    schedule_interest_type: '',
    schedule_order: '',
    schedule_search: '',
    schedule_show_discounted: '',
    schedule_show_hidden: '',
    schedule_tab: '',
    schedule_view_type: '',
    // Schedule space
    ...(spaceValue === SpaceView.Schedule && getScheduleUrlState(queries)),

    // Outbox space - defaults
    outbox_group_by: '',
    outbox_interest_type: '',
    outbox_order: '',
    outbox_search: '',
    outbox_show_discounted: '',
    outbox_show_hidden: '',
    outbox_tab: '',
    // Outbox space
    ...(spaceValue === SpaceView.Outbox && getOutboxUrlState(queries)),
  };

  return result;
};

const getUrlStateWithDefaults = <TS extends UrlViewState = UrlViewState>(
  defaultState: TS,
  state: WithEmptyString<TS>
): TS => {
  const modifyState: TS = { ...defaultState };

  for (const key in defaultState) {
    const stateValue = state[key];
    if (stateValue !== '') {
      modifyState[key] = stateValue as TS[typeof key];
    }
  }

  return modifyState;
};

export type InterestHash = Record<number, DisposalInterestIndexItem>;

export const addInterestItemsIntoHash = (items: DisposalInterestIndexItem[], interestHash: InterestHash) => {
  return items.reduce(
    (result, interest) => {
      result[interest.id] = interest;

      return result;
    },
    { ...interestHash }
  );
};

export const updateInterestItemInHash = (
  interestId: number,
  interestHash: InterestHash,
  updateCb: (item: DisposalInterestIndexItem) => DisposalInterestIndexItem
) => {
  const interest = interestHash[interestId];

  if (!interest) {
    return interestHash;
  }

  return { ...interestHash, [interestId]: updateCb(interest) };
};

export const removeInterestItemsFromHash = (ids: number[], interestHash: InterestHash) => {
  const result = { ...interestHash };

  ids.forEach((id) => {
    delete result[id];
  });

  return result;
};

interface UseDisposalReportingResult {
  activeInterestId: number | undefined;
  inboundUrlState: InboundUrlState;
  interestHash: InterestHash;
  outboxUrlState: OutboxUrlState;
  scheduleUrlState: ScheduleUrlState;
  urlState: UrlState;
  setInterestHash: UseStateSetter<InterestHash>;
  setActiveInterestId: UseStateSetter<number | undefined>;
  setUrlState: (urlState: UrlState) => void;
  updateUrlState: (urlState: Partial<UrlState>) => void;
}

const DisposalReportingContext = createContext<UseDisposalReportingResult>({} as UseDisposalReportingResult);

export const useDisposalReporting = () => {
  return useContext(DisposalReportingContext);
};

export const DisposalReportingProvider: FC = ({ children }) => {
  const { setUrlState, urlState, updateUrlState } = useUrlState<UrlState>(() => getInitialUrlState(getQueries()));

  const [activeInterestId, setActiveInterestId] = useState<number | undefined>(() =>
    urlState.view_interest ? parseInt(urlState.view_interest) : undefined
  );
  const [interestHash, setInterestHash] = useState<InterestHash>({});

  const contextValue = useMemo(() => {
    const result: UseDisposalReportingResult = {
      activeInterestId,
      inboundUrlState: getUrlStateWithDefaults(defaultInboundUrlState, urlState),
      interestHash: interestHash,
      outboxUrlState: getUrlStateWithDefaults(defaultOutboxUrlState, urlState),
      scheduleUrlState: getUrlStateWithDefaults(defaultScheduleUrlState, urlState),
      urlState,
      setActiveInterestId,
      setInterestHash,
      setUrlState,
      updateUrlState,
    };

    return result;
  }, [activeInterestId, interestHash, urlState]);

  return <DisposalReportingContext.Provider value={contextValue}>{children}</DisposalReportingContext.Provider>;
};
