import { IconButton } from '@material-ui/core';
import { FC } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Avatar } from '@shared/components/avatar';
import { showConfirmation } from '@shared/components/confirmation';
import { Flex } from '@shared/components/flex';
import { t } from '@shared/i18n';
import { TrashIcon } from '@shared/icons/trash';
import { AgentMessage } from '@shared/types/common/agentMessage';
import { formatDate } from '@shared/utils/date';
import { normalizedHTMLText } from '@shared/utils/text';

import { styles } from './SentMessageItem.styles';

export interface SentMessageItemProps extends WithStyles<typeof styles> {
  message: AgentMessage;
  onDelete: (id: number) => void;
}

const SentMessageItemComponent: FC<SentMessageItemProps> = ({ classes, message, onDelete }) => {
  return (
    <Flex alignItems="flex-start" autoWidth={false} classes={{ root: classes.root }} wrap="nowrap">
      <div className={classes.emptySpace} />
      <div className={classes.container}>
        <Flex direction="column" classes={{ root: classes.messageContainer }}>
          <IconButton
            classes={{ root: classes.deleteIconContainer }}
            onClick={() => {
              showConfirmation(
                {
                  title: t('delete_message'),
                  question: t('are_you_sure_you_want_to_delete_this_message_question'),
                  buttonProps: {
                    confirm: {
                      text: t('delete'),
                    },
                    cancel: {
                      text: t('cancel'),
                    },
                  },
                },
                (confirmed) => {
                  if (confirmed) {
                    onDelete(message.id);
                  }
                }
              );
            }}
          >
            <TrashIcon classes={{ root: classes.deleteIcon }} />
          </IconButton>
          <span className={classes.message} dangerouslySetInnerHTML={{ __html: normalizedHTMLText(message.message) }} />
        </Flex>
        <div className={classes.messageDate}>{formatDate(message.created_at, 'MMM D YYYY, H:mm a')} </div>
      </div>
      <Avatar
        classes={{ root: classes.userAvatar, noImageInitials: classes.userAvatarNoInitials }}
        image={message.by_image}
        name={message.by_name}
        theme={message.by_theme}
      />
    </Flex>
  );
};

export const SentMessageItem = withStyles(styles)(SentMessageItemComponent);
