import { alpha, createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

export default function styles({ transitions }: Theme) {
  return createStyles({
    root: {
      backgroundColor: colors.white.lightest,
    },
    bodyRow: {},
    row: {
      '&:hover': {
        cursor: 'pointer',
        transition: `all ${transitions.duration.standard}s ${transitions.easing.easeInOut}`,
      },

      '&$highlightedRow:hover': {
        backgroundColor: alpha(colors.blue.dark, 0.04),
      },
    },
    highlightedRow: {
      backgroundColor: alpha(colors.blue.dark, 0.04),
    },
    bodyCell: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflowX: 'hidden',
      fontSize: 12,
      fontWeight: 400,
      padding: `4px 16px;`,
    },
    bulkCheckbox: {
      padding: 0,
    },
    noDataCell: {
      fontSize: 12,
    },
    bodyCellTextClass: {
      fontSize: 'inherit',
      fontWeight: 'inherit',
    },
    loading: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
  });
}
