import { IconButton as MuiIconButton } from '@material-ui/core';
import { noop } from 'lodash';
import { FC, useMemo } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { DisposalInterestIndexItem } from '@shared/apis/disposals';
import { Badge, BadgeSize, BadgeTheme } from '@shared/components/badge-new';
import { Flex } from '@shared/components/flex';
import { StatusBadge, StatusBadgeTheme } from '@shared/components/status-badge';
import { Tooltip } from '@shared/components/tooltip';
import { useInterestScheduleConfig } from '@shared/hooks/interestSchedule.hooks';
import { t } from '@shared/i18n';
import { AlertCircleIcon } from '@shared/icons/alert-circle';
import { CloseIcon } from '@shared/icons/close';
import { FolderIcon } from '@shared/icons/folder';
import { MinusCircleIcon } from '@shared/icons/minus-circle';
import { StarIcon } from '@shared/icons/star';
import { Users2Icon } from '@shared/icons/users-2';
import { colors } from '@shared/theme/colours';
import { getInterestScheduleStatusOptions } from '@shared/utils/match';

import { styles } from './InterestScheduleBoardCard.styles';

export type InterestScheduleBoardCardProps = WithStyles<typeof styles> & {
  id: number;
  index: number;
  interest: DisposalInterestIndexItem;
  onClick: (id: number) => void;
  onChangeFavourite: (id: number, fav: boolean) => void;
};

const InterestScheduleBoardCardComponent: FC<InterestScheduleBoardCardProps> = ({
  classes,
  id,
  index,
  interest,
  onClick,
  onChangeFavourite,
}) => {
  const { scheduleConfig } = useInterestScheduleConfig(interest.is_investment);

  const statusOptions = useMemo(
    () =>
      getInterestScheduleStatusOptions(scheduleConfig, { interest }).map((option) => ({
        ...option,
        onClick: () => noop,
      })),
    [interest]
  );

  const statusBadge = useMemo(() => {
    let label = t('unknown');
    let icon = undefined;
    let tooltip = '';

    const isDiscounted = !!interest.interest_schedule_discounted_reason;
    const isNoLongerActive = !!interest.requirement_no_longer_active;

    statusOptions.forEach((option) => {
      if (option.value == interest.interest_schedule_status) {
        label = option.displayedText || option.text;
      }
    });

    if (isNoLongerActive) {
      icon = (
        <AlertCircleIcon
          color={isDiscounted ? colors.kato.red[500] : '#7E8899'}
          classes={{ root: classes.noLongerActiveIcon }}
        />
      );
      tooltip = t('requirement_no_longer_active');
    } else if (isDiscounted) {
      icon = <CloseIcon color={colors.kato.red[500]} />;
      tooltip = t('match_discounted_due_to_quote_xreasonx_quote', {
        reason: interest.interest_column_values.discounted_reason_string,
      });
    }

    return {
      label,
      icon,
      tooltip,
    };
  }, [interest, statusOptions]);

  return (
    <Draggable key={id} draggableId={id.toString()} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={classes.root}
          onClick={() => onClick(interest.id)}
        >
          <div className={classes.header}>
            <Tooltip
              classes={{ container: classes.tenantTooltip }}
              title={interest.interest_column_values.tenant_name}
              placement="top"
            >
              <h3 className={classes.tenantName}>{interest.interest_column_values.tenant_name}</h3>
            </Tooltip>
            <MuiIconButton
              classes={{ root: classes.iconButton }}
              onClick={(e) => {
                e.stopPropagation();
                onChangeFavourite(interest.id, !interest.fav);
              }}
            >
              <StarIcon active={interest.fav} />
            </MuiIconButton>
          </div>
          <div className={classes.body}>
            {/* TODO: Hook this up once BE defined */}
            <span style={{ color: 'red' }}>TODO - BE blocked</span>
            {/* {interest.representing_agents && (
              <span className={classes.representedAgents}>
                {t('co_xnamex', { name: interest.representing_agents })}
              </span>
            )} */}
            <Badge
              classes={{ root: classes.badge }}
              content={
                <Flex alignItems="center" className={classes.statusBadgeText}>
                  {statusBadge.icon}
                  {statusBadge.icon ? ' - ' : ''}
                  {statusBadge.label}
                </Flex>
              }
              size={BadgeSize.large}
              tooltip={statusBadge.tooltip}
              theme={BadgeTheme.white}
            />
            <span className={classes.secondaryLine}>
              {interest.interest_column_values.sectors || t('unknown_sector')}
            </span>
            <span className={classes.secondaryLine}>{interest.interest_column_values.size}</span>
            <Flex alignItems="center" wrap="nowrap" classes={{ root: classes.badges }}>
              {!interest.shared_requirement && (
                <StatusBadge
                  classes={{ root: classes.badge }}
                  content={
                    <Flex alignItems="center" classes={{ root: classes.badgeContent }} wrap="nowrap">
                      <MinusCircleIcon classes={{ root: classes.badgeIcon }} />
                      {t('private')}
                    </Flex>
                  }
                  size={BadgeSize.small}
                  theme={StatusBadgeTheme.danger}
                  tooltip={t('is_not_shared_to_interest_schedule')}
                />
              )}
              {interest.interest_schedule_hidden == 1 && (
                <StatusBadge
                  classes={{ root: classes.badge }}
                  content={
                    <Flex alignItems="center" classes={{ root: classes.badgeContent }} wrap="nowrap">
                      <FolderIcon classes={{ root: classes.badgeIcon }} />
                      {t('archived')}
                    </Flex>
                  }
                  size={BadgeSize.small}
                  theme={StatusBadgeTheme.grey}
                />
              )}
              {interest.is_external_match && (
                <StatusBadge
                  classes={{ root: classes.badge }}
                  content={
                    <Flex alignItems="center" classes={{ root: classes.badgeContent }} wrap="nowrap">
                      <Users2Icon color="#B54708" classes={{ root: classes.badgeIcon }} />
                      {t('joint_agent')}
                    </Flex>
                  }
                  size={BadgeSize.small}
                  theme={StatusBadgeTheme.orange}
                />
              )}
            </Flex>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export const InterestScheduleBoardCard = withStyles(styles)(InterestScheduleBoardCardComponent);
