import { createStyles, Theme } from '@material-ui/core';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    paper: {
      borderRadius: spacing(2),
      boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.08)',
      padding: spacing(4),
    },
  });
