import { FC, ReactNode } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Flex } from '@shared/components/flex';

import { styles } from './EmptyStateContent.styles';

export interface EmptyStateContentProps extends WithStyles<typeof styles> {
  header: ReactNode;
  icon: ReactNode;
  text: ReactNode;
  actions?: ReactNode;
}

const EmptyStateContentComponent: FC<EmptyStateContentProps> = ({ actions, classes, icon, header, text }) => {
  return (
    <Flex
      autoWidth={false}
      alignItems="center"
      direction="column"
      justifyContent="center"
      classes={{ root: classes.root }}
    >
      <Flex autoWidth={false} alignItems="center" justifyContent="center" classes={{ root: classes.iconContainer }}>
        {icon}
      </Flex>
      <span className={classes.stateHeader}>{header}</span>
      <span className={classes.stateText}>{text}</span>
      {actions && <Flex classes={{ root: classes.actions }}>{actions}</Flex>}
    </Flex>
  );
};

export const EmptyStateContent = withStyles(styles)(EmptyStateContentComponent);
