import { SvgIcon } from '@material-ui/core';

import { withStyles, WithStyles } from '@core/theme/utils/with-styles';

import { styles } from './Calendar.styles';

export type CalendarIconProps = WithStyles<typeof styles>;

const CalendarIconComponent: React.FC<CalendarIconProps> = ({ classes }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      classes={{ root: classes.root }}
    >
      <path
        d="M13 6.66667H1M9.66667 1.33333V4M4.33333 1.33333V4M4.2 14.6667H9.8C10.9201 14.6667 11.4802 14.6667 11.908 14.4487C12.2843 14.2569 12.5903 13.951 12.782 13.5746C13 13.1468 13 12.5868 13 11.4667V5.86667C13 4.74656 13 4.18651 12.782 3.75869C12.5903 3.38236 12.2843 3.0764 11.908 2.88465C11.4802 2.66667 10.9201 2.66667 9.8 2.66667H4.2C3.0799 2.66667 2.51984 2.66667 2.09202 2.88465C1.71569 3.0764 1.40973 3.38236 1.21799 3.75869C1 4.18651 1 4.74656 1 5.86667V11.4667C1 12.5868 1 13.1468 1.21799 13.5746C1.40973 13.951 1.71569 14.2569 2.09202 14.4487C2.51984 14.6667 3.0799 14.6667 4.2 14.6667Z"
        stroke="#9EA6B3"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export const CalendarIcon = withStyles(styles)(CalendarIconComponent);
