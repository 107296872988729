import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

export default function styles({ spacing }: Theme) {
  return createStyles({
    root: {},
    headerCell: {
      cursor: 'pointer',
    },
    headerCellDisabled: {
      cursor: 'default',
    },
    headerCellActive: {
      color: colors.black.darkest,
    },
    headerCellTextWrapper: {
      fontWeight: 'inherit',
      fontSize: 'inherit',
    },
    headerCellText: {
      fontWeight: 500,
      fontSize: 12,
    },
    sortIcons: {
      display: 'inline-flex',
    },
    sortIcon: {
      color: colors.grey.main,
      margin: spacing(-2, 0),
      fontSize: 22,
    },
    sortActiveIcon: {
      color: colors.black.darkest,
    },
    bulkCheckbox: {
      padding: 0,
      marginRight: spacing(),
    },
    deleteIcon: {},
  });
}
