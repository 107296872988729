import cx from 'classnames';
import { FC } from 'react';
import { Droppable } from 'react-beautiful-dnd';

import { t } from '@core/i18n';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { DisposalInterestIndexItem } from '@shared/apis/disposals';
import { Button } from '@shared/components/button-new';
import { Flex } from '@shared/components/flex';
import { TooltipIcon } from '@shared/components/tooltip-icon';
import { PlusIcon } from '@shared/icons/plus';
import { ButtonColour } from '@shared/types/common/button';
import { ScheduleStatusGroups } from '@shared/types/common/match';

import { styles } from './InterestScheduleBoardLane.styles';
import { InterestScheduleBoardCard } from '../interest-schedule-board-card';

export interface InterestScheduleBoardLaneProps extends WithStyles<typeof styles> {
  count: number;
  id: ScheduleStatusGroups;
  isDragging: boolean;
  items: DisposalInterestIndexItem[];
  title: string;
  tooltip?: string;
  onAddInterest: () => void;
  onChangeFavourite: (id: number, fav: boolean) => void;
  onOpenItem: (id: number) => void;
}

const InterestScheduleBoardLaneComponent: FC<InterestScheduleBoardLaneProps> = ({
  classes,
  count,
  id,
  isDragging,
  items,
  title,
  tooltip,
  onOpenItem,
  onAddInterest,
  onChangeFavourite,
}) => {
  return (
    <div className={cx(classes.root, { [classes.rootDragging]: isDragging })}>
      <Flex
        direction="row"
        wrap="nowrap"
        justifyContent="space-between"
        classes={{ root: cx(classes.heading, { [classes.headingNoCards]: !items.length }) }}
      >
        <Flex alignItems="center" classes={{ root: classes.headingTitleContainer }}>
          <span className={classes.headingTitleText}>{title}</span>
          {tooltip && <TooltipIcon classes={{ icon: classes.headingTitleIcon }} placement="top" tooltip={tooltip} />}
        </Flex>
        {count > 0 && <span className={classes.headingTitleText}>{count}</span>}
      </Flex>
      <Droppable droppableId={id}>
        {(provided, snapshot) => {
          return (
            <div ref={provided.innerRef} className={classes.cardsContainer}>
              <div className={cx(classes.cards)}>
                <div className={cx(classes.cardsList, { [classes.cardsNoData]: !items.length })}>
                  {items.map((item, index) => (
                    <InterestScheduleBoardCard
                      classes={{ root: classes.card }}
                      id={item.id}
                      index={index}
                      interest={item}
                      key={index}
                      onClick={onOpenItem}
                      onChangeFavourite={onChangeFavourite}
                    />
                  ))}
                  <div className={cx({ [classes.addInterest]: !items.length })}>
                    <Button
                      colour={ButtonColour.secondary}
                      classes={{ root: classes.addInterestButton }}
                      startIcon={<PlusIcon />}
                      onClick={onAddInterest}
                    >
                      {t('add_interest')}
                    </Button>
                  </div>
                </div>
                {provided.placeholder}
              </div>
            </div>
          );
        }}
      </Droppable>
    </div>
  );
};

export const InterestScheduleBoardLane = withStyles(styles)(InterestScheduleBoardLaneComponent);
