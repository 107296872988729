import { useMemo } from 'react';

import { ListingPageStatesKey } from './ListingPageStates';

export interface UseListingPageStatesKey {
  error?: boolean;
  loading?: boolean;
  noData?: boolean;
  noResults?: boolean;
  searchingOnAll?: boolean;
}

export const useListingPageStatesKey = ({
  error,
  loading,
  noData,
  noResults,
  searchingOnAll,
}: UseListingPageStatesKey = {}): ListingPageStatesKey => {
  return useMemo(() => {
    if (loading) {
      return ListingPageStatesKey.loading;
    }
    if (error) {
      return ListingPageStatesKey.error;
    }
    if (noData) {
      return ListingPageStatesKey.noData;
    }
    if (noResults && !searchingOnAll) {
      return ListingPageStatesKey.noResults;
    }
    if (noResults && searchingOnAll) {
      return ListingPageStatesKey.noResultsAll;
    }

    return ListingPageStatesKey.content;
  }, [error, loading, noData, noResults, searchingOnAll]);
};
