import { SvgIcon } from '@material-ui/core';
import { FC } from 'react';

import { withStyles, WithStyles } from '@core/theme/utils/with-styles';

import { styles } from './ThumbsUp.styles';

export interface ThumbsUpIconProps extends WithStyles<typeof styles> {
  color?: string;
}

const ThumbsUpIconComponent: FC<ThumbsUpIconProps> = ({ classes, color = '#344154' }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      classes={{ root: classes.root }}
    >
      <path
        d="M4.66659 14.6663V7.33301M1.33325 8.66634V13.333C1.33325 14.0694 1.93021 14.6663 2.66659 14.6663H11.6174C12.6046 14.6663 13.4441 13.9461 13.5942 12.9705L14.3121 8.30379C14.4985 7.09227 13.5611 5.99967 12.3354 5.99967H9.99992C9.63173 5.99967 9.33325 5.7012 9.33325 5.33301V2.9769C9.33325 2.069 8.59726 1.33301 7.68936 1.33301C7.47281 1.33301 7.27657 1.46054 7.18862 1.65842L4.84254 6.9371C4.73554 7.17785 4.4968 7.33301 4.23334 7.33301H2.66659C1.93021 7.33301 1.33325 7.92996 1.33325 8.66634Z"
        stroke={color}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export const ThumbsUpIcon = withStyles(styles)(ThumbsUpIconComponent);
