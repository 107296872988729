import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    collaborate_instructions: {
      textDecoration: 'none',
      fontSize: 14,
      fontWeight: 500,
      color: '#515D70', //TODO change after creating new palette
      '& a': {
        textDecoration: 'underline',
        color: 'inherit',
        fontWeight: 'inherit',
        fontSize: 'inherit',
      },
    },
    inviteContainer: {
      padding: spacing(6),
      width: '100%',
      justifyContent: 'space-between',
      gap: 16,
      overflowY: 'auto',
    },
    inviteTypeTabs: {
      gap: spacing(2),
    },
    inviteTypeTab: {
      border: '1px solid transparent',
    },
    inviteSearchContainer: {
      gap: spacing(2),
    },
    inviteInput: {
      flex: '1',
      maxWidth: '429px',
    },
    textField: {
      display: 'flex',
      gap: spacing(2),
      '& .MuiInputBase-root': {
        overflowX: 'hidden',
        overflowY: 'hidden',
        display: 'inline-flex',
        flexWrap: 'wrap',
        padding: '5px',
        height: 'auto',
        justifyContent: 'flex-start',
      },
      '& .MuiOutlinedInput-input': {
        padding: '7px',
        maxWidth: '215px',
      },
    },
    chipsContainer: {
      display: 'inline-flex',
      flexWrap: 'nowrap',
      gap: spacing(1),
      maxWidth: '100%',
    },
    chip: {
      borderRadius: 6,
      border: '1px solid #D1D6DF', //TODO replace after creating new palette
      backgroundColor: colors.white.lightest,
      color: '#515D70', //TODO replace after creating new palette
      fontSize: 14,
      fontWeight: 500,
      height: 25,
      margin: '3px',
    },
    autocompleteActionRow: {
      fontWeight: 500,
    },
    autocompleteLabel: {},
    autocompleteOptions: {
      padding: spacing(0),
    },
    autocompleteList: {
      padding: spacing(0),
    },
    autocompleteListItem: {
      padding: spacing(2.5, 4),
    },
    autocompleteNotFoundText: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    searchQueryContainer: {
      width: '100%',
      gap: spacing(2),
    },
    searchQueryAvatar: {
      width: 24,
      height: 24,
      fontSize: 10,
    },
    searchQueryDetails: {},
    searchQueryName: {
      fontSize: 14,
      fontWeight: 500,
      color: colors.black.darker,
    },
    searchQueryEmail: {
      fontSize: 12,
      color: '#7E8899', //TODO change after creating new palette
    },
    inviteButton: {},
    currentAndPotentialCollaboratorsContainer: {
      width: '100%',
      gap: spacing(2),
    },
    currentAccessContainer: {
      paddingBottom: 8,
      gap: spacing(2),
    },
    divider: {
      borderTop: `1px solid #EAECF0`, // TODO: change this when new palette is created
      width: '100%',
    },
    suggestedInviteContainer: {
      paddingTop: 8,
      gap: spacing(2),
    },
    smallHeading: { fontSize: 14, fontWeight: 600, width: '100%' },
    currentAccessContact: {
      color: '#7E8899', //TODO change after creating new palette
      fontSize: 14,
      fontweight: 400,
    },
    contactContentWrapper: {
      width: '100%',
      cursor: 'pointer',
      flex: '1 1 auto',
      overflow: 'hidden',
      alignItems: 'center',
    },
    contactIconContainer: {
      backgroundColor: '#EAECF0', //TODO change after creating new palette
      borderRadius: '50%',
      flex: '0 0 auto',
      height: 32,
      marginRight: spacing(3),
      width: 32,
    },
    contactAvatar: {
      height: 32,
      width: 32,
      fontSize: 10,
    },
    contactTextWrapper: {
      overflow: 'hidden',
      justifyContent: 'center',
    },
    contactTitle: {
      width: '200px',
      color: colors.black.darker,
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '20px',
    },
    contactSubtitle: {
      color: '#515D70', //TODO change after creating new palette
      fontSize: 12,
      fontWeight: 500,
      lineHeight: '20px',
    },
  });
