import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      border: '1px solid #EAECF0',
      background: colors.white.lightest,
      borderRadius: 8,
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: spacing(2),
      gap: spacing(1),
    },
    favTooltip: {
      display: 'inline-flex',
    },
    tenantTooltip: {
      display: 'inline-flex',
      flex: 1,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    tenantName: {
      color: '#101828', //TODO change after creating new palette,
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '20px',
      flex: 1,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    representedAgents: {
      color: '#101828', //TODO change after creating new palette,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '20px',
    },
    secondaryLine: {
      color: '#515D70', //TODO change after creating new palette,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '20px',
    },
    iconButton: {
      borderRadius: 4,
      width: 24,
      height: 24,
    },

    body: {
      display: 'flex',
      flexDirection: 'column',
      padding: spacing(0, 3, 3),
      gap: spacing(2),
      width: '100%',
    },
    lockIconTooltip: {
      display: 'inline-flex',
    },
    badge: {
      borderRadius: 4,
      width: 'fit-content',
    },
    statusBadgeText: {
      color: '#515D70', //TODO change after creating new palette,
      fontSize: 13,
      fontWeight: 500,
      lineHeight: '12px',
      userSelect: 'none',
    },
    badges: {
      gap: spacing(1),
    },
    badgeContent: {
      gap: 4,
      whiteSpace: 'nowrap',
    },
    badgeIcon: {
      fontSize: 12,
    },
    noLongerActiveIcon: {
      fontSize: 14,
      marginRight: spacing(1),
    },
    emptyItem: {
      width: 20,
    },
  });
