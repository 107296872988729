import { InputAdornment } from '@material-ui/core';
import { FC, useMemo } from 'react';

import { t } from '@core/i18n';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Flex } from '@shared/components/flex';
import { Option } from '@shared/components/select-new/Select';
import { SingleSelect } from '@shared/components/select-new/single-select';
import { ClockIcon } from '@shared/icons/clock';
import { ButtonSize } from '@shared/types/common/button';

import { styles } from './TimeSelect.styles';

export interface TimeSelectProps extends WithStyles<typeof styles> {
  disabled?: boolean;
  size?: ButtonSize;
  time: string;
  onTimeChange: (time: string) => void;
}

const TimeSelectComponent: FC<TimeSelectProps> = ({
  classes,
  disabled = false,
  size = ButtonSize.mediumLegacy,
  time,
  onTimeChange,
}) => {
  const parsedTime = useMemo(() => {
    const [hours, minutes] = time.split(':');

    return {
      hours: hours || '',
      minutes: minutes || '',
    };
  }, [time]);

  const hourOptions = useMemo(() => {
    const options = Array(24)
      .fill(0)
      .map((_, index) => {
        const hour = index.toString().padStart(2, '0'); // Format the hour (00, 01, ..., 23)
        return { id: hour, label: hour } as Option;
      });
    return options;
  }, []);

  const minutesOptions = useMemo(() => {
    const options = Array(12)
      .fill(0)
      .map((_, index) => {
        const minute = (index * 5).toString().padStart(2, '0'); // Format the minute (00, 05, ..., 55)
        return { id: minute, label: minute } as Option;
      });
    return options;
  }, []);

  return (
    <Flex classes={{ root: classes.root }} wrap="nowrap">
      <SingleSelect
        classes={{ root: classes.hoursSelectRoot }}
        disabled={disabled}
        options={hourOptions}
        size={size}
        value={parsedTime.hours}
        onChange={(value) => {
          onTimeChange(value ? `${value}:${parsedTime.minutes}` : '');
        }}
        placeholder={t('hh')}
        startAdornment={
          <InputAdornment position="start">
            <ClockIcon classes={{ root: classes.selectAdornmentIcon }} />
          </InputAdornment>
        }
      />
      <SingleSelect
        classes={{ root: classes.minutesSelectRoot }}
        disabled={disabled}
        options={minutesOptions}
        size={size}
        placeholder={t('mm')}
        value={parsedTime.minutes}
        onChange={(value) => {
          onTimeChange(value ? `${parsedTime.hours}:${value}` : '');
        }}
      />
    </Flex>
  );
};

export const TimeSelect = withStyles(styles)(TimeSelectComponent);
