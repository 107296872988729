import { withStyles } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';

import { useDisposalShowQuery } from '@agency/apis/disposals';
import { useDisposalPresentScheduleIndexQuery } from '@shared/apis/disposals';
import { DisposalReportingPresentOverlay } from '@shared/overlays/disposal-reporting-present-overlay';
import { InterestScheduleData } from '@shared/types/common/match';

import { styles } from './DisposalReportingPresent.styles';
import { DisposalReportingProvider } from '../../../agency/modules/disposals/pages/disposal-manage/pages/disposal-reporting/DisposalReporting.context';

interface RouteParams {
  id: string;
}

const DisposalReportingPresent: React.FC = () => {
  const { id } = useParams<RouteParams>();
  const disposalShowQuery = useDisposalShowQuery(id, { keepPreviousData: true });
  const disposalPresentScheduleIndexQuery = useDisposalPresentScheduleIndexQuery(id, { keepPreviousData: true });
  const scheduleData = disposalShowQuery.data?.interest_schedule as InterestScheduleData;

  if (disposalPresentScheduleIndexQuery.data?.meta?.config?.columns) {
    scheduleData.preferred_columns = disposalPresentScheduleIndexQuery.data.meta.config.columns;
  }

  const history = useHistory();

  return (
    <DisposalReportingProvider>
      <DisposalReportingPresentOverlay
        id={id}
        scheduleData={scheduleData}
        title={disposalShowQuery.data?.name || ''}
        onClose={() => history.goBack()}
      />
    </DisposalReportingProvider>
  );
};

export default withStyles(styles)(DisposalReportingPresent);
