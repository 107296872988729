import { SvgIcon } from '@material-ui/core';
import { FC } from 'react';

import { withStyles, WithStyles } from '@core/theme/utils/with-styles';

import { styles } from './Trash.styles';

interface TrashIconProps extends WithStyles<typeof styles> {
  color?: string;
}

const TrashIconComponent: FC<TrashIconProps> = ({ classes, color = '#515D70' }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      classes={{ root: classes.root }}
    >
      <path
        d="M9.66667 4.00004V3.46671C9.66667 2.71997 9.66667 2.3466 9.52134 2.06139C9.39351 1.8105 9.18954 1.60653 8.93865 1.4787C8.65344 1.33337 8.28007 1.33337 7.53333 1.33337H6.46667C5.71993 1.33337 5.34656 1.33337 5.06135 1.4787C4.81046 1.60653 4.60649 1.8105 4.47866 2.06139C4.33333 2.3466 4.33333 2.71997 4.33333 3.46671V4.00004M5.66667 7.66671V11M8.33333 7.66671V11M1 4.00004H13M11.6667 4.00004V11.4667C11.6667 12.5868 11.6667 13.1469 11.4487 13.5747C11.2569 13.951 10.951 14.257 10.5746 14.4487C10.1468 14.6667 9.58677 14.6667 8.46667 14.6667H5.53333C4.41323 14.6667 3.85318 14.6667 3.42535 14.4487C3.04903 14.257 2.74307 13.951 2.55132 13.5747C2.33333 13.1469 2.33333 12.5868 2.33333 11.4667V4.00004"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export const TrashIcon = withStyles(styles)(TrashIconComponent);
