import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { t } from '@core/i18n';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import {
  DisposalChangeMatchStatusDTO,
  DisposalInterestIndexItem,
  DisposalInterestShowItem,
  useDisposalInterestShowQuery,
} from '@shared/apis/disposals';
import { Button } from '@shared/components/button-new';
import {
  CreateUpdateViewingForm,
  CreateUpdateViewingFormErrors,
  CreateUpdateViewingFormState,
  getViewingFormErrors,
  getViewingFormStateFromInterest,
} from '@shared/components/create-update-viewing-form';
import { Dialog } from '@shared/components/dialog';
import { Flex } from '@shared/components/flex';
import { Option } from '@shared/components/select-new/Select';
import { SingleSelect } from '@shared/components/select-new/single-select';
import { TextField } from '@shared/components/text-field';
import { useEmptyOption } from '@shared/contexts/OptionsContext';
import { CalendarMinusIcon } from '@shared/icons/calendar-minus';
import { CalendarPlusIcon } from '@shared/icons/calendar-plus';
import { ViewingOutcome } from '@shared/models/viewing/viewings';
import { ButtonSize } from '@shared/types/common/button';
import { InterestScheduleStatuses, ScheduleConfig } from '@shared/types/common/match';
import { formatDate } from '@shared/utils/date';
import { getInterestScheduleStatusOptions } from '@shared/utils/match';

import { styles } from './InterestScheduleChangeStatusModal.styles';

type StatusesWithNull = InterestScheduleStatuses | '';

interface InterestScheduleChangeStatusModalResult {
  comment: string;
  status: StatusesWithNull;
  viewing?: CreateUpdateViewingFormState;
}

export const getChangeStatusRequestBody = (
  state: InterestScheduleChangeStatusModalResult,
  interest: DisposalInterestShowItem
): DisposalChangeMatchStatusDTO => {
  return {
    comment: state.comment,
    status: state.status.toString(),
    ...(!!state.viewing && {
      viewing: {
        ...(interest.interest.companies.length && {
          companies: state.viewing.companies.map((a) => a.id).join(','),
        }),
        contacts: state.viewing.contacts.length ? state.viewing.contacts.map((a) => a.id).join(',') : 'all',
        ...(state.viewing.feedback === ViewingOutcome.Negative &&
          state.viewing.discountReason && { discounted_reason: Number(state.viewing.discountReason) }),
        ...(state.viewing.feedback && { outcome: state.viewing.feedback }),
        users: state.viewing.users.map((a) => a.id).join(','),
        when: `${formatDate(state.viewing.date, 'YYYY-MM-DD')} ${state.viewing.time}:00`,
      },
    }),
  };
};

export interface InterestScheduleChangeStatusModalProps extends WithStyles<typeof styles> {
  initialStatus?: InterestScheduleStatuses;
  initialStatusGroup?: InterestScheduleStatuses[];
  queryResult: ReturnType<typeof useDisposalInterestShowQuery>;
  saving?: boolean;
  scheduleConfig: ScheduleConfig;
  onClose: () => void;
  onSave: (result: InterestScheduleChangeStatusModalResult) => void;
}

const InterestScheduleChangeStatusModalComponent: FC<InterestScheduleChangeStatusModalProps> = ({
  classes,
  initialStatus,
  initialStatusGroup = [],
  queryResult,
  saving = false,
  scheduleConfig,
  onClose,
  onSave,
}) => {
  const [comment, setComment] = useState<string>('');
  const [status, setStatus] = useState<StatusesWithNull>(
    initialStatusGroup.length === 1 ? (initialStatusGroup[0] as StatusesWithNull) : initialStatus || ''
  );
  const [submitAttempted, setSubmitAttempted] = useState<boolean>(false);
  const [viewingErrors, setViewingErrors] = useState<CreateUpdateViewingFormErrors>({});
  const [viewingState, setViewingState] = useState<CreateUpdateViewingFormState>(() =>
    getViewingFormStateFromInterest()
  );
  const [viewingVisible, setViewingVisible] = useState<boolean>(
    () =>
      initialStatus === InterestScheduleStatuses.ViewingCompleted || initialStatus === InterestScheduleStatuses.Viewing
  );

  const viewingRequired = useMemo(
    () => status === InterestScheduleStatuses.ViewingCompleted || status === InterestScheduleStatuses.Viewing,
    [status]
  );

  const showCreateUpdateViewingForm = useMemo(
    () => viewingVisible || viewingRequired,
    [viewingVisible, viewingRequired]
  );

  const handleChangeStatus = useCallback((status: StatusesWithNull) => {
    setStatus(status);
    setViewingVisible(
      status === InterestScheduleStatuses.ViewingCompleted || status === InterestScheduleStatuses.Viewing
    );
  }, []);

  // Ensure the viewing form state is updated when the match query data is available
  useEffect(() => setViewingState(getViewingFormStateFromInterest(queryResult.data)), [queryResult.data]);

  // Validate viewing form
  useEffect(
    () => setViewingErrors(viewingRequired || viewingVisible ? getViewingFormErrors(viewingState) : {}),
    [viewingVisible, viewingState]
  );

  const statusOptions: Option<StatusesWithNull>[] = useMemo(() => {
    if (!queryResult.data) {
      return [];
    }

    // TODO: Fix cast
    const interest = queryResult.data as unknown as DisposalInterestIndexItem;
    return getInterestScheduleStatusOptions(scheduleConfig, { interest, withActions: false, withDividers: false })
      .map((item) => {
        const option: Option<InterestScheduleStatuses> = {
          id: item.value as InterestScheduleStatuses,
          label: item.text,
        };
        return option;
      })
      .filter((option) => {
        if (initialStatusGroup.length && option.id) {
          return initialStatusGroup.includes(option.id);
        }

        return option.id !== queryResult.data.interest.status;
      });
  }, [initialStatusGroup, queryResult.data, scheduleConfig]);

  const statusOptionsWithNull = useEmptyOption(statusOptions, {
    id: '',
    label: t('please_select'),
  });

  return (
    <Dialog
      buttonProps={{
        cancel: {
          disabled: saving,
          size: ButtonSize.medium,
          onClick: onClose,
        },
        confirm: {
          disabled: !status,
          loading: saving,
          text: t('save'),
          size: ButtonSize.medium,
          onClick: () => {
            setSubmitAttempted(true);

            if (Object.keys(viewingErrors).length) {
              return;
            }

            onSave({ comment, status, viewing: viewingVisible ? viewingState : undefined });
          },
        },
      }}
      classes={{ root: classes.modalRoot, body: classes.content }}
      headingProps={{
        heading: t('change_status'),
        withCloseButton: true,
      }}
      loading={queryResult.isLoading}
      open
      withTransition
      onClose={onClose}
    >
      <SingleSelect<StatusesWithNull>
        classes={{ labelRoot: classes.label }}
        disabled={initialStatusGroup.length === 1}
        label={t('xtextx_asterisk_1', { text: t('status') })}
        options={statusOptionsWithNull}
        placeholder={t('xtextx_ellipsis', { text: t('please_select') })}
        size={ButtonSize.medium}
        value={status}
        onChange={handleChangeStatus}
      />
      <TextField
        classes={{ label: classes.label }}
        fullWidth
        label={t('comments_for_interest_schedule')}
        minRows={3}
        multiline
        placeholder={t('xtextx_ellipsis', { text: t('these_comments_will_appear_on_the_interest_schedule') })}
        value={comment}
        onChange={(e) => setComment(e.target.value)}
      />
      <Flex autoWidth={false} alignItems="center" wrap="nowrap" classes={{ root: classes.calendarBtnWrapper }}>
        <Button
          disabled={viewingRequired}
          size={ButtonSize.small}
          startIcon={viewingVisible ? <CalendarMinusIcon /> : <CalendarPlusIcon />}
          text={t(viewingVisible ? 'remove_viewing' : 'add_a_viewing')}
          onClick={() => setViewingVisible(!viewingVisible)}
        />
        <div className={classes.calendarBtnDivider} />
      </Flex>
      {showCreateUpdateViewingForm && (
        <CreateUpdateViewingForm
          errors={viewingErrors}
          required={viewingRequired}
          submitAttempted={submitAttempted}
          state={viewingState}
          onChangeState={(key, value) => setViewingState((prev) => ({ ...prev, [key]: value }))}
        />
      )}
    </Dialog>
  );
};

export const InterestScheduleChangeStatusModal = withStyles(styles)(InterestScheduleChangeStatusModalComponent);
