import { SvgIcon } from '@material-ui/core';
import { FC } from 'react';

import { withStyles, WithStyles } from '@core/theme/utils/with-styles';

import { styles } from './Users2Icon.styles';

export interface Users2IconProps extends WithStyles<typeof styles> {
  color?: string;
}

const Users2IconComponent: FC<Users2IconProps> = ({ classes, color = '#9EA6B3' }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 14"
      fill="none"
      classes={{ root: classes.root }}
    >
      <path
        d="M14.6667 13V11.6667C14.6667 10.4241 13.8169 9.38004 12.6667 9.08401M10.3334 1.19384C11.3107 1.58943 12 2.54754 12 3.66667C12 4.78579 11.3107 5.7439 10.3334 6.13949M11.3334 13C11.3334 11.7575 11.3334 11.1362 11.1304 10.6462C10.8597 9.99277 10.3406 9.47364 9.6872 9.20299C9.19714 9 8.57589 9 7.33337 9H5.33337C4.09086 9 3.46961 9 2.97955 9.20299C2.32614 9.47364 1.80701 9.99277 1.53636 10.6462C1.33337 11.1362 1.33337 11.7575 1.33337 13M9.00004 3.66667C9.00004 5.13943 7.80613 6.33333 6.33337 6.33333C4.86061 6.33333 3.66671 5.13943 3.66671 3.66667C3.66671 2.19391 4.86061 1 6.33337 1C7.80613 1 9.00004 2.19391 9.00004 3.66667Z"
        stroke={color}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export const Users2Icon = withStyles(styles)(Users2IconComponent);
