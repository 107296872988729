import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

export const headingHeight = 40;

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      borderRadius: 8,
      height: '100%',
      transition: 'background-color .2s ease',
      width: '100%',
    },
    rootDragging: {
      background: colors.kato.navy[100],

      '& $heading': {
        background: colors.kato.navy[100],
      },
      '& $cardsList': {
        background: colors.kato.navy[100],
      },
    },
    heading: {
      background: '#F2F4F7', //TODO replace after creating new palette
      padding: spacing(3, 3, 2),
      height: headingHeight,
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
    },
    headingNoCards: {
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
    },
    headingTitleContainer: {
      gap: spacing(1),
    },
    headingTitleText: {
      color: '#515D70', //TODO replace after creating new palette
      fontSize: 14,
      fontWeight: 600,
    },
    headingTitleIcon: {
      fontSize: 12,
      marginLeft: 0,
    },
    cardsContainer: {
      height: `calc(100% - ${headingHeight}px)`,
    },
    cards: {
      overflowY: 'auto',
      minHeight: 1,
      height: '100%',
    },
    cardsList: {
      background: '#F2F4F7',
      padding: spacing(2),
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
    },
    cardsNoData: {
      padding: 0,
    },
    card: {
      marginBottom: spacing(2),

      '&:last-of-type': {
        marginBottom: 0,
      },
    },
    addInterest: { background: '#F2F4F7', padding: spacing(2), borderBottomLeftRadius: 8, borderBottomRightRadius: 8 },
    addInterestButton: {
      width: '100%',
      background: '#F2F4F7',
      border: 'none',
      boxShadow: 'none',
      padding: spacing(2),
      placeContent: 'flex-start',
    },
  });
