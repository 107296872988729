import { SvgIcon } from '@material-ui/core';

import { withStyles, WithStyles } from '@core/theme/utils/with-styles';

import { styles } from './CheckSquare.styles';

export type CheckSquareIconProps = WithStyles<typeof styles>;

const CheckSquareIconComponent: React.FC<CheckSquareIconProps> = ({ classes }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      classes={{ root: classes.root }}
    >
      <g clipPath="url(#clip0_2316_5209)">
        <path
          d="M3.75 6L5.25 7.5L8.25 4.5M3.9 10.5H8.1C8.94008 10.5 9.36012 10.5 9.68099 10.3365C9.96323 10.1927 10.1927 9.96323 10.3365 9.68099C10.5 9.36012 10.5 8.94008 10.5 8.1V3.9C10.5 3.05992 10.5 2.63988 10.3365 2.31901C10.1927 2.03677 9.96323 1.8073 9.68099 1.66349C9.36012 1.5 8.94008 1.5 8.1 1.5H3.9C3.05992 1.5 2.63988 1.5 2.31901 1.66349C2.03677 1.8073 1.8073 2.03677 1.66349 2.31901C1.5 2.63988 1.5 3.05992 1.5 3.9V8.1C1.5 8.94008 1.5 9.36012 1.66349 9.68099C1.8073 9.96323 2.03677 10.1927 2.31901 10.3365C2.63988 10.5 3.05992 10.5 3.9 10.5Z"
          stroke="#2ABD43"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2316_5209">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export const CheckSquareIcon = withStyles(styles)(CheckSquareIconComponent);
