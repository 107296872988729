import { Table, TableContainer } from '@material-ui/core';
import cx from 'classnames';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { SortingType } from '@shared/types/services';

import Body from './components/Body';
import { Head } from './components/Head';
import styles from './TableView.styles';
import { SelectedItem } from '../../Table';
import { COLUMN_WIDTH } from '../../Table.constants';
import { TableColumn, TableConfig } from '../../Table.types';

export enum SelectedItemsIdType {
  number,
  string,
}

export interface TableViewProps extends WithStyles<typeof styles> {
  additionalHeader?: React.ReactNode;
  columns: TableColumn[];
  config?: TableConfig;
  currentOrderField?: TableConfig['defaultOrderField'];
  currentOrderSortingType?: SortingType;
  data: Item[];
  hover?: boolean;
  loading: boolean;
  selectedItem?: SelectedItem;
  selectedItemsIdType?: SelectedItemsIdType;
  stickyHeader?: boolean;
  withBulkActions?: boolean;
  onColumnClick: (field: string) => void;
  onRowClick?: (item: Item) => any;
  onSelectedItemsChange?: (selectedItems: Id[]) => any;
}

const normalizeId = (id: Id, type = SelectedItemsIdType.string) => {
  const normalizers = {
    [SelectedItemsIdType.number]: Number,
    [SelectedItemsIdType.string]: String,
  };
  const normalize = normalizers[type];

  return normalize(id);
};

class TableView extends React.Component<TableViewProps> {
  static defaultProps = {
    selectedItemsIdType: SelectedItemsIdType.string,
  };

  state = {
    selectedItems: [],
  };

  private handleItemSelected = () => {
    // const { selectedItemsIdType } = this.props;
    // const ids = checked
    //   ? [...this.state.selectedItems, id]
    //   : this.state.selectedItems.filter((selectedItemId) => selectedItemId != id);
    // this.state.selectedItems = ids.map((selectedItemId) => normalizeId(selectedItemId, selectedItemsIdType));
    // this.onSelectedItemsChange();
  };

  private handleSelectedItems = (checked: boolean) => {
    const { data, selectedItemsIdType } = this.props;

    this.setState({
      selectedItems: checked ? data.map(({ id }) => normalizeId(id, selectedItemsIdType)) : [],
    });

    this.onSelectedItemsChange();
  };

  private onSelectedItemsChange = () => {
    const { onSelectedItemsChange } = this.props;

    if (onSelectedItemsChange) {
      onSelectedItemsChange(this.state.selectedItems);
    }
  };

  render() {
    const {
      classes,
      columns,
      currentOrderField,
      currentOrderSortingType,
      config,
      hover,
      data,
      loading,
      selectedItem,
      withBulkActions,
      stickyHeader = true,
      additionalHeader,
      onColumnClick,
      onRowClick,
    } = this.props;

    let minWidth = 0;
    const processedColumns = columns.map((column) => {
      column.width = column.width || column.minWidth || column.maxWidth || COLUMN_WIDTH;
      column.maxWidth = column.maxWidth || column.width;
      column.minWidth = column.minWidth || column.width;

      const minWidthFloat = parseFloat(column.minWidth ? column.minWidth.toString() : '0');
      if (!isNaN(minWidthFloat)) {
        minWidth += minWidthFloat;
      } else {
        minWidth += COLUMN_WIDTH;
      }

      return column;
    });

    return (
      <TableContainer className={classes.container}>
        <Table stickyHeader={stickyHeader} style={{ minWidth: `${minWidth}px` }} className={classes.wrapper}>
          <Head
            classes={{
              headerCell: cx(classes.cell, classes.headerCell),
              headerCellText: classes.headerCellText,
            }}
            columns={processedColumns}
            config={config}
            additionalHeader={additionalHeader}
            currentOrderField={currentOrderField}
            currentOrderSortingType={currentOrderSortingType}
            withBulkActions={withBulkActions}
            onColumnClick={onColumnClick}
            onSelectedItemsChange={this.handleSelectedItems}
          />
          <Body
            classes={{
              bodyCell: cx(classes.cell, classes.bodyCell),
              bodyRow: classes.bodyRow,
              noDataCell: classes.noDataCell,
            }}
            columns={processedColumns}
            config={config}
            data={data}
            hover={hover}
            loading={loading}
            withBulkActions={withBulkActions}
            selectedItem={selectedItem}
            selectedItems={this.state.selectedItems}
            onRowClick={onRowClick}
            onSelectedItemsChange={this.handleItemSelected}
          />
        </Table>
      </TableContainer>
    );
  }
}

export default withStyles(styles)(TableView);
