import { createStyles, Theme } from '@material-ui/core/styles';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      minHeight: 300,
      width: 600,
      overflow: 'hidden',
    },
    dialogBody: {
      gap: '0 !important',
      padding: '0 !important',
      overflow: 'hidden',
    },
    heading: {
      borderBottom: '1px solid #EAECF0', //TODO change after creating new palette
      height: 40,
    },
    headingTab: {
      height: '100%',
    },
    headingTabs: {
      height: '100%',
      gap: 24,
      padding: spacing(0, 6),
      '& > *:not(:first-child)': {
        marginLeft: spacing(6),
      },
    },
    actionButtons: {
      justifyContent: 'space-between !important',
    },
  });
