import { Theme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

import { getStyles, getUseClasses, makeStyles, mockTheme } from '@core/theme/utils/with-styles';
import { getCoreButtonStyling } from '@shared/components/button-new/button.styles';
import { colors } from '@shared/theme/colours';
import { ButtonColour, ButtonSize, SelectColour } from '@shared/types/common/button';

// TODO: Change all hexcodes when palette is updated

type MenuItemStyling = {
  default: CSSProperties;
  hovered: CSSProperties;
  active: CSSProperties;
  disabled: CSSProperties;
};

const currentColorStyling = {
  '& i': { color: 'currentColor' },
  '& svg': { color: 'currentColor' },
  '& svg path': { stroke: 'currentColor' },
  '& svg.MuiSelect-icon': { color: 'currentColor' },
  '& svg.MuiSelect-icon path': { stroke: 'currentColor' },
};

const menuItemStyling: MenuItemStyling = {
  default: {
    backgroundColor: colors.white.lightest,
    color: colors.black.darker,

    '& $menuItemIcon svg': { color: colors.black.darker },
    '& $menuItemIcon svg path': { stroke: colors.black.darker },
    '& $menuItemIcon i': { color: colors.black.darker },
  },
  hovered: {
    backgroundColor: colors.kato.grey[100],
    color: colors.kato.navy[950],

    '& $menuItemIcon svg': { color: 'currentColor' },
    '& $menuItemIcon svg path': { stroke: 'currentColor' },
    '& $menuItemIcon i': { color: 'currentColor' },
  },
  active: {
    backgroundColor: colors.kato.grey[200],
    color: colors.kato.navy[950],

    '& $menuItemIcon svg': { color: 'currentColor' },
    '& $menuItemIcon svg path': { stroke: 'currentColor' },
    '& $menuItemIcon i': { color: 'currentColor' },
  },
  disabled: {
    backgroundColor: colors.white.lightest,
    color: '#9EA6B3',
    cursor: 'not-allowed',
    pointerEvents: 'none',

    '& $menuItemIcon svg': { color: 'currentColor' },
    '& $menuItemIcon svg path': { stroke: 'currentColor' },
    '& $menuItemIcon i': { color: 'currentColor' },
  },
};

export const commonStyles = (theme: Theme) => {
  const coreButtonStyling = getCoreButtonStyling(theme);

  return getStyles({
    root: {
      '& fieldset': {
        border: 'none',
      },
      '& svg.MuiSelect-icon': {
        strokeWidth: 0, // Using only for down arrow icon to reduce icon stroke width
      },
      '& .MuiInputBase-root fieldset': {
        border: 'none',
      },

      '& .MuiInputBase-root': {
        border: '1px solid transparent',
        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.07)',
        transition: `all ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeInOut}`, //Using transition object to be consistent with transition that used in material ui for button by default

        '&.Mui-error': {
          borderColor: '#EA3418 !important', //TODO replace after new palette creating
        },
      },

      // Colour - Primary
      ['&:not($selectDisabled)$' + SelectColour.primary + ' .MuiInputBase-root']: {
        ...coreButtonStyling[ButtonColour.primary].default,
        ...currentColorStyling,
      },
      ['&:not($selectDisabled)$' + SelectColour.primary + ':hover .MuiInputBase-root']: {
        ...coreButtonStyling[ButtonColour.primary].hovered,
        ...currentColorStyling,
      },
      ['&:not($selectDisabled)$' + SelectColour.primary + '$selectHovered .MuiInputBase-root']: {
        ...coreButtonStyling[ButtonColour.primary].hovered,
        ...currentColorStyling,
      },
      ['&:not($selectDisabled)$' + SelectColour.primary + ':active .MuiInputBase-root']: {
        ...coreButtonStyling[ButtonColour.primary].active,
        ...currentColorStyling,
      },
      ['&:not($selectDisabled)$' + SelectColour.primary + '$selectActive .MuiInputBase-root']: {
        ...coreButtonStyling[ButtonColour.primary].active,
        ...currentColorStyling,
      },
      ['&$selectDisabled$' + SelectColour.primary + ' .MuiInputBase-root']: {
        ...coreButtonStyling[ButtonColour.primary].disabled,
        ...currentColorStyling,
      },

      // Colour - Primary Special
      ['&:not($selectDisabled)$' + SelectColour.primarySpecial + ' .MuiInputBase-root']: {
        ...coreButtonStyling[ButtonColour.primarySpecial].default,
        ...currentColorStyling,
      },
      ['&:not($selectDisabled)$' + SelectColour.primarySpecial + ':hover .MuiInputBase-root']: {
        ...coreButtonStyling[ButtonColour.primarySpecial].hovered,
        ...currentColorStyling,
      },
      ['&:not($selectDisabled)$' + SelectColour.primarySpecial + '$selectHovered .MuiInputBase-root']: {
        ...coreButtonStyling[ButtonColour.primarySpecial].hovered,
        ...currentColorStyling,
      },
      ['&:not($selectDisabled)$' + SelectColour.primarySpecial + ':active .MuiInputBase-root']: {
        ...coreButtonStyling[ButtonColour.primarySpecial].active,
        ...currentColorStyling,
      },
      ['&:not($selectDisabled)$' + SelectColour.primarySpecial + '$selectActive .MuiInputBase-root']: {
        ...coreButtonStyling[ButtonColour.primarySpecial].active,
        ...currentColorStyling,
      },
      ['&$selectDisabled$' + SelectColour.primarySpecial + ' .MuiInputBase-root']: {
        ...coreButtonStyling[ButtonColour.primarySpecial].disabled,
        ...currentColorStyling,
      },

      // Colour - Secondary
      ['&:not($selectDisabled)$' + SelectColour.secondary + ' .MuiInputBase-root']: {
        ...coreButtonStyling[ButtonColour.secondary].default,
        '& i': { color: '#7E8899' },
        '& svg': { color: '#7E8899' },
        '& svg path': { stroke: '#7E8899' },
        '& svg.MuiSelect-icon': { color: '#7E8899' },
        '& svg.MuiSelect-icon path': { stroke: '#7E8899' },
      },
      ['&:not($selectDisabled)$' + SelectColour.secondary + ':hover .MuiInputBase-root']: {
        ...coreButtonStyling[ButtonColour.secondary].hovered,
        ...currentColorStyling,
      },
      ['&:not($selectDisabled)$' + SelectColour.secondary + '$selectHovered .MuiInputBase-root']: {
        ...coreButtonStyling[ButtonColour.secondary].hovered,
        ...currentColorStyling,
      },
      ['&:not($selectDisabled)$' + SelectColour.secondary + ':active .MuiInputBase-root']: {
        ...coreButtonStyling[ButtonColour.secondary].active,
        ...currentColorStyling,
      },
      ['&:not($selectDisabled)$' + SelectColour.secondary + '$selectActive .MuiInputBase-root']: {
        ...coreButtonStyling[ButtonColour.secondary].active,
        ...currentColorStyling,
      },
      ['&$selectDisabled$' + SelectColour.secondary + ' .MuiInputBase-root']: {
        ...coreButtonStyling[ButtonColour.secondary].disabled,
        ...currentColorStyling,
      },

      // Colour - Secondary Colour
      ['&:not($selectDisabled)$' + SelectColour.secondaryColour + ' .MuiInputBase-root']: {
        ...coreButtonStyling[ButtonColour.secondaryColour].default,
        '& i': { color: '#7E8899' },
        '& svg': { color: '#7E8899' },
        '& svg path': { stroke: '#7E8899' },
        '& svg.MuiSelect-icon': { color: '#7E8899' },
        '& svg.MuiSelect-icon path': { stroke: '#7E8899' },
      },
      ['&:not($selectDisabled)$' + SelectColour.secondaryColour + ':hover .MuiInputBase-root']: {
        ...coreButtonStyling[ButtonColour.secondaryColour].hovered,
        ...currentColorStyling,
      },
      ['&:not($selectDisabled)$' + SelectColour.secondaryColour + '$selectHovered .MuiInputBase-root']: {
        ...coreButtonStyling[ButtonColour.secondaryColour].hovered,
        ...currentColorStyling,
      },
      ['&:not($selectDisabled)$' + SelectColour.secondaryColour + ':active .MuiInputBase-root']: {
        ...coreButtonStyling[ButtonColour.secondaryColour].active,
        ...currentColorStyling,
      },
      ['&:not($selectDisabled)$' + SelectColour.secondaryColour + '$selectActive .MuiInputBase-root']: {
        ...coreButtonStyling[ButtonColour.secondaryColour].active,
        ...currentColorStyling,
      },
      ['&$selectDisabled$' + SelectColour.secondaryColour + ' .MuiInputBase-root']: {
        ...coreButtonStyling[ButtonColour.secondaryColour].disabled,
        ...currentColorStyling,
      },

      // Colour - Error
      ['&:not($selectDisabled)$' + SelectColour.error + ' .MuiInputBase-root']: {
        ...coreButtonStyling[ButtonColour.error].default,
        ...currentColorStyling,
      },
      ['&:not($selectDisabled)$' + SelectColour.error + ':hover .MuiInputBase-root']: {
        ...coreButtonStyling[ButtonColour.error].hovered,
        ...currentColorStyling,
      },
      ['&:not($selectDisabled)$' + SelectColour.error + '$selectHovered .MuiInputBase-root']: {
        ...coreButtonStyling[ButtonColour.error].hovered,
        ...currentColorStyling,
      },
      ['&:not($selectDisabled)$' + SelectColour.error + ':active .MuiInputBase-root']: {
        ...coreButtonStyling[ButtonColour.error].active,
        ...currentColorStyling,
      },
      ['&:not($selectDisabled)$' + SelectColour.error + '$selectActive .MuiInputBase-root']: {
        ...coreButtonStyling[ButtonColour.error].active,
        ...currentColorStyling,
      },
      ['&$selectDisabled$' + SelectColour.error + ' .MuiInputBase-root']: {
        ...coreButtonStyling[ButtonColour.error].disabled,
        ...currentColorStyling,
      },

      // Colour - Teal
      ['&:not($selectDisabled)$' + SelectColour.teal + ' .MuiInputBase-root']: {
        ...coreButtonStyling[ButtonColour.teal].default,
        ...currentColorStyling,
      },
      ['&:not($selectDisabled)$' + SelectColour.teal + ':hover .MuiInputBase-root']: {
        ...coreButtonStyling[ButtonColour.teal].hovered,
        ...currentColorStyling,
      },
      ['&:not($selectDisabled)$' + SelectColour.teal + '$selectHovered .MuiInputBase-root']: {
        ...coreButtonStyling[ButtonColour.teal].hovered,
        ...currentColorStyling,
      },
      ['&:not($selectDisabled)$' + SelectColour.teal + ':active .MuiInputBase-root']: {
        ...coreButtonStyling[ButtonColour.teal].active,
        ...currentColorStyling,
      },
      ['&:not($selectDisabled)$' + SelectColour.teal + '$selectActive .MuiInputBase-root']: {
        ...coreButtonStyling[ButtonColour.teal].active,
        ...currentColorStyling,
      },
      ['&$selectDisabled$' + SelectColour.teal + ' .MuiInputBase-root']: {
        ...coreButtonStyling[ButtonColour.teal].disabled,
        ...currentColorStyling,
      },

      // Colour - Transparent
      ['&:not($selectDisabled)$' + SelectColour.secondaryGhost + ' .MuiInputBase-root']: {
        ...coreButtonStyling[ButtonColour.secondaryGhost].default,
        ...currentColorStyling,
      },
      ['&:not($selectDisabled)$' + SelectColour.secondaryGhost + ':hover .MuiInputBase-root']: {
        ...coreButtonStyling[ButtonColour.secondaryGhost].hovered,
        ...currentColorStyling,
      },
      ['&:not($selectDisabled)$' + SelectColour.secondaryGhost + '$selectHovered .MuiInputBase-root']: {
        ...coreButtonStyling[ButtonColour.secondaryGhost].hovered,
        ...currentColorStyling,
      },
      ['&:not($selectDisabled)$' + SelectColour.secondaryGhost + ':active .MuiInputBase-root']: {
        ...coreButtonStyling[ButtonColour.secondaryGhost].active,
        ...currentColorStyling,
      },
      ['&:not($selectDisabled)$' + SelectColour.secondaryGhost + '$selectActive .MuiInputBase-root']: {
        ...coreButtonStyling[ButtonColour.secondaryGhost].active,
        ...currentColorStyling,
      },
      ['&$selectDisabled$' + SelectColour.secondaryGhost + ' .MuiInputBase-root']: {
        ...coreButtonStyling[ButtonColour.secondaryGhost].disabled,
        ...currentColorStyling,
      },

      // Size
      ['&$' + ButtonSize.extraSmall + ' .MuiInputBase-root']: {
        height: 24,

        '&.MuiOutlinedInput-adornedStart': {
          paddingLeft: theme.spacing(1.5), // NOTE: Refer to size padding-left below
        },

        '& svg.MuiSelect-icon': {
          right: theme.spacing(0.5), // NOTE: Refer to size padding-left below - 4px
        },
      },
      ['&$' + ButtonSize.small + ' .MuiInputBase-root']: {
        height: 32,

        '&.MuiOutlinedInput-adornedStart': {
          paddingLeft: theme.spacing(3), // NOTE: Refer to size padding-left below
        },

        '& svg.MuiSelect-icon': {
          right: theme.spacing(1), // NOTE: Refer to size padding-left below - 8px
        },
      },
      ['&$' + ButtonSize.mediumLegacy + ' .MuiInputBase-root']: {
        height: 36,

        '&.MuiOutlinedInput-adornedStart': {
          paddingLeft: theme.spacing(3), // NOTE: Refer to size padding-left below
        },

        '& svg.MuiSelect-icon': {
          right: theme.spacing(1), // NOTE: Refer to size padding-left below - 8px
        },
      },
      ['&$' + ButtonSize.medium + ' .MuiInputBase-root']: {
        height: 40,

        '&.MuiOutlinedInput-adornedStart': {
          paddingLeft: theme.spacing(4), // NOTE: Refer to size padding-left below
        },

        '& svg.MuiSelect-icon': {
          right: theme.spacing(2), // NOTE: Refer to size padding-left below - 8px
        },
      },
      ['&$' + ButtonSize.large + ' .MuiInputBase-root']: {
        height: 48,

        '&.MuiOutlinedInput-adornedStart': {
          paddingLeft: theme.spacing(4), // NOTE: Refer to size padding-left below
        },

        '& svg.MuiSelect-icon': {
          right: theme.spacing(2), // NOTE: Refer to size padding-left below - 8px
        },
      },
    },
    rootFullWidth: {
      width: '100%',
    },

    select: {
      alignItems: 'center',
      backgroundColor: 'transparent',
      color: 'inherit',
      display: 'flex',
      height: '100%',
      justifyContent: 'flex-start',

      '&.Mui-disabled': {
        color: 'inherit',
      },

      // Size
      ['&$' + ButtonSize.extraSmall]: {
        padding: theme.spacing(0, 8, 0, 1.5),

        '&.MuiOutlinedInput-inputAdornedStart': {
          paddingLeft: theme.spacing(0),
        },
      },
      ['&$' + ButtonSize.small]: {
        padding: theme.spacing(0, 9, 0, 3),

        '&.MuiOutlinedInput-inputAdornedStart': {
          paddingLeft: theme.spacing(0),
        },
      },
      ['&$' + ButtonSize.mediumLegacy]: {
        padding: theme.spacing(0, 10, 0, 3),

        '&.MuiOutlinedInput-inputAdornedStart': {
          paddingLeft: theme.spacing(0),
        },
      },
      ['&$' + ButtonSize.medium]: {
        padding: theme.spacing(0, 10, 0, 4),

        '&.MuiOutlinedInput-inputAdornedStart': {
          paddingLeft: theme.spacing(0),
        },
      },
      ['&$' + ButtonSize.large]: {
        padding: theme.spacing(0, 10, 0, 4),

        '&.MuiOutlinedInput-inputAdornedStart': {
          paddingLeft: theme.spacing(0),
        },
      },
    },

    menu: {
      border: '1px solid #EAECF0',
      borderRadius: 8,
      boxShadow: '0px 12px 24px -4px rgba(16, 24, 40, 0.10), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
      marginTop: theme.spacing(1),
      minWidth: 150,
      width: 'inherit',

      '& ul.MuiList-padding': {
        padding: 0,
      },
    },
    menuItem: {
      alignItems: 'center',
      display: 'flex',
      fontSize: 14,
      padding: theme.spacing(2.5, 3.75),
      ...menuItemStyling.default,

      '& svg': {
        fontSize: 'inherit',
      },
      '& svg path': {},
      '& i': {
        fontSize: 'inherit',
      },

      '&.MuiListItem-root:hover': { ...menuItemStyling.hovered },
      '&.MuiListItem-root$menuItemHovered': { ...menuItemStyling.hovered },
      '&.MuiListItem-root:active': { ...menuItemStyling.active },
      '&.MuiListItem-root$menuItemActive': { ...menuItemStyling.active },
      '&.MuiListItem-root$menuItemDisabled': { ...menuItemStyling.disabled },

      '&.Mui-selected': {
        backgroundColor: colors.white.lightest,
      },
    },
    menuItemIcon: {
      alignItems: 'center',
      display: 'inline-flex',
      fontSize: 16,
      paddingRight: theme.spacing(2.5),
    },
    menuItemText: {},
    autoMenuHeight: {
      maxHeight: 'calc(100vh - 24px)',
      height: 'fit-content',
    },

    // Utility classes - DO NOT STYLE
    // Colours
    [SelectColour.primary]: {},
    [SelectColour.primarySpecial]: {},
    [SelectColour.secondary]: {},
    [SelectColour.secondaryColour]: {},
    [SelectColour.secondaryGhost]: {},
    [SelectColour.error]: {},
    [SelectColour.teal]: {},
    // Sizes
    [ButtonSize.extraSmall]: {},
    [ButtonSize.small]: {},
    [ButtonSize.medium]: {},
    [ButtonSize.mediumLegacy]: {},
    [ButtonSize.large]: {},
    // Icon sizes
    [ButtonSize.iconSmall]: {},
    [ButtonSize.iconLarge]: {},
    [ButtonSize.iconOnly]: {},
    // Other
    menuItemDisabled: {},
    selectDisabled: {},
    selectHovered: {},
    selectActive: {},
    selectError: {},
  });
};

const stylesFn = (theme: Theme) =>
  getStyles({
    ...commonStyles(theme),

    value: {
      display: 'flex',
      fontSize: 14,
      width: '100%',

      // Size
      ['&$' + ButtonSize.extraSmall]: {
        fontSize: 12,
      },
      ['&$' + ButtonSize.small]: {
        fontSize: 14,
      },
      ['&$' + ButtonSize.mediumLegacy]: {
        fontSize: 14,
      },
      ['&$' + ButtonSize.medium]: {
        fontSize: 14,
      },
      ['&$' + ButtonSize.large]: {
        fontSize: 16,
      },
    },
    selectorValueText: {
      fontWeight: 500,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',
    },
    valueIcon: {
      alignItems: 'center',
      display: 'inline-flex',
      fontSize: 16,
      paddingRight: theme.spacing(2),

      '& svg': {
        fontSize: 'inherit',
      },
      '& svg path': {},
      '& i': {
        fontSize: 'inherit',
      },
    },
    valueIconPlaceholder: {},
    valuePlaceholderEllipsis: {
      display: 'inline-flex',
      fontSize: 'inherit',
    },

    labelRoot: {
      // TODO: Make this consistent with TextField styling, when time permits. -Ben
      fontSize: 14,
      marginBottom: theme.spacing(2),
      color: '#1D2433', //TODO replace after new palette creating
    },
    labelError: {
      color: '#C52810 !important', //TODO replace after new palette creating
    },
    helperText: {
      paddingTop: theme.spacing(1),
    },
    // Utility classes - DO NOT STYLE
    menuItemActive: {},
    menuItemHovered: {},
    valueDisabled: {},
    valueMultiple: {},
    valuePlaceholder: {},
  });
const stylesResult = stylesFn(mockTheme);

const makeClasses = makeStyles<keyof typeof stylesResult>('Select', stylesFn);

export const useSelectClasses = getUseClasses<keyof typeof stylesResult>(makeClasses);
