import cx from 'classnames';
import { FC } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';

import { styles } from './ReportingSpaceTableTab.styles';

export interface ReportingSpaceTableTabProps extends WithStyles<typeof styles> {
  active: boolean;
  text: string;
  count: number;
  onClick: () => void;
}

const ReportingSpaceTableTabComponent: FC<ReportingSpaceTableTabProps> = ({
  active,
  classes,
  count,
  text,
  onClick,
}) => {
  return (
    <div className={cx(classes.root, { [classes.rootActive]: active })} onClick={onClick}>
      <span className={classes.text}>{text}</span>
      <div className={classes.count}>{count}</div>
    </div>
  );
};

export const ReportingSpaceTableTab = withStyles(styles)(ReportingSpaceTableTabComponent);
