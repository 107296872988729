import { createStyles } from '@material-ui/core/styles';

const styles = () =>
  createStyles({
    root: {
      fill: 'transparent',
      fontSize: 16,
    },
  });

export default styles;
