import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

export const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    root: {
      color: '#7E8899', //TODO update after palette will be updated,
      display: 'inline-flex',
      alignItems: 'center',
      fontSize: 14,
      fontWeight: 500,
      position: 'relative',
      cursor: 'pointer',
      userSelect: 'none',

      '&:hover': {
        '& $label': {
          color: '#101828', //TODO update after palette will be updated,
        },

        '& $icon svg path': {
          stroke: '#101828', //TODO update after palette will be updated,
        },
      },
    },
    rootDisabled: {
      cursor: 'not-allowed',

      '& $count': {
        opacity: 0.6,
      },

      '&:hover': {
        '& $label': {
          color: colors.grey.main,
        },

        '& $icon svg path': {
          stroke: colors.grey.main,
        },
      },
    },
    underLine: {
      borderBottom: '2px solid transparent',
      bottom: 0,
      left: 0,
      position: 'absolute',
      right: 0,
      top: 0,
    },
    underLineActive: {
      borderBottomColor: palette.primarySpecial.main,
    },
    icon: {
      display: 'inline-flex',
      marginRight: spacing(1),

      '& svg path': {
        transition: 'all 0.4s ease',
        stroke: '#7E8899', //TODO update after palette will be updated,
      },
    },
    iconActive: {
      '& svg path': {
        stroke: '#101828', //TODO update after palette will be updated,
      },
    },
    label: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      margin: spacing(2, 0),
      position: 'relative',
      textAlign: 'center',
      transition: 'all 0.4s ease',
    },
    labelActive: {
      color: '#101828', //TODO update after palette will be updated,
    },
    count: {
      backgroundColor: '#E8EBEF',
      color: '#7E8899', //TODO update after palette will be updated,
      borderRadius: 17,
      fontWeight: 600,
      fontSize: 12,
      marginLeft: spacing(1),
      width: 20,
      height: 20,
    },
    countActive: {
      backgroundColor: '#FFE5E8', //TODO update after palette will be updated,
      color: palette.primarySpecial.dark,
    },
  });
