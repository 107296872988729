import { FC } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';

import { styles } from './ReportingSpaceTableTabs.styles';
import { ReportingSpaceTableTab } from '../reporting-space-table-tab';

export interface InterestScheduleTableTabItem<T> {
  count: number;
  text: string;
  value: T;
}

export interface ReportingSpaceTableTabsProps<T = string> extends WithStyles<typeof styles> {
  activeTab: T;
  tabsLeft: InterestScheduleTableTabItem<T>[];
  tabsRight: InterestScheduleTableTabItem<T>[];
  onChangeTab: (value: T) => void;
}

const ReportingSpaceTableTabsComponent: FC<ReportingSpaceTableTabsProps> = ({
  activeTab,
  classes,
  tabsLeft,
  tabsRight,
  onChangeTab,
}) => {
  return (
    <div className={classes.root}>
      <div className={classes.tabsLeft}>
        {tabsLeft.map((tab, idx) => (
          <ReportingSpaceTableTab
            active={activeTab === tab.value}
            classes={{
              rootActive: classes.activeTabRoot,
              root: classes.tabRoot,
              text: classes.tabText,
              count: classes.tabCount,
            }}
            count={tab.count}
            key={idx}
            text={tab.text}
            onClick={() => onChangeTab(tab.value)}
          />
        ))}
      </div>
      <div className={classes.tabsRight}>
        {tabsRight.map((tab, idx) => (
          <ReportingSpaceTableTab
            active={activeTab === tab.value}
            classes={{
              rootActive: classes.activeTabRoot,
              root: classes.tabRoot,
              text: classes.tabText,
              count: classes.tabCount,
            }}
            count={tab.count}
            key={idx}
            text={tab.text}
            onClick={() => onChangeTab(tab.value)}
          />
        ))}
      </div>
    </div>
  );
};

export const ReportingSpaceTableTabs = withStyles(styles)(ReportingSpaceTableTabsComponent);
