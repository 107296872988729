import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

const sidebarWidth = 56;

export const styles = ({ spacing }: Theme) =>
  createStyles({
    dialogRoot: {},
    root: {
      borderRadius: 0,
      display: 'flex',
      height: '100%',
      margin: 0,
      maxHeight: '100vh',
      padding: '0 !important',
      width: '100%',
    },

    header: {
      borderBottom: '1px solid #EAECF0', //TODO change after creating new palette
      display: 'flex',
      flex: '0 0 auto',
      justifyContent: 'space-between',
      gap: spacing(2),
      padding: spacing(4, 6),
    },
    headerTitle: {
      alignItems: 'center',
      display: 'flex',
      gap: spacing(4),
      justifyContent: 'flex-start',
    },
    headerActions: {
      alignItems: 'center',
      display: 'flex',
      gap: spacing(4),
      justifyContent: 'flex-end',
    },

    content: {
      height: 0,
      display: 'flex',
      flex: '1 1 auto',
      flexDirection: 'row',
      gap: spacing(4),
      padding: spacing(6),
    },
    sidebar: {
      backgroundColor: colors.white.lightest,
      border: '1px solid #EAECF0', //TODO change after creating new palette
      borderRadius: 12,
      boxShadow: '0px 1px 6px 0px #1018280F',
      display: 'flex',
      flexDirection: 'column',
      gap: spacing(2),
      height: 'fit-content',
      maxHeight: '100%',
      overflow: 'auto',
      padding: spacing(2),
      minWidth: sidebarWidth,
      width: sidebarWidth,
    },
    spaceButton: {
      minWidth: 40,
      width: 40,
    },

    body: {
      flex: '1 1 auto',
      maxWidth: 'calc(100% - 70px)',
    },
  });
