import cx from 'classnames';
import { FC, useMemo } from 'react';

import { t } from '@core/i18n';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { PartialDisposalInterestIndexItem } from '@shared/apis/disposals';
import { Dropdown, IDropdownItem } from '@shared/components/dropdown-new';
import { useInterestScheduleConfig } from '@shared/hooks/interestSchedule.hooks';
import { AlertCircleIcon } from '@shared/icons/alert-circle';
import { CloseIcon } from '@shared/icons/close';
import { ButtonSize } from '@shared/types/common/button';
import { InterestScheduleStatuses } from '@shared/types/common/match';
import { ScheduleStatusTheme } from '@shared/types/common/match';
import { getInterestScheduleStatusOptions } from '@shared/utils/match';

import { styles } from './InterestScheduleStatusDropdown.styles';

export enum InterestScheduleStatusDropdownTheme {
  Amber = 'amber',
  Green = 'green',
  Red = 'red',
  Neutral = 'neutral',
}

export interface InterestScheduleStatusDropdownProps extends WithStyles<typeof styles> {
  disabled?: boolean;
  fullWidth?: boolean;
  interest: PartialDisposalInterestIndexItem;
  onChangeStatus: (option: IDropdownItem) => void;
}

const InterestScheduleStatusDropdownComponent: FC<InterestScheduleStatusDropdownProps> = ({
  classes,
  disabled = false,
  fullWidth = true,
  interest,
  onChangeStatus,
}) => {
  const { scheduleConfig } = useInterestScheduleConfig(interest.is_investment);

  // const isDiscounted = useMemo(() => !!interest.discounted_reason, [interest]); //TODO uncomment when discounted_reason is back
  const isDiscounted = useMemo(() => false, [interest]);

  const statusOptions = useMemo(
    () =>
      getInterestScheduleStatusOptions(scheduleConfig, { interest }).map((option) => ({
        ...option,
        onClick: () => {
          if (option.active) {
            return;
          }

          if (option?.value) {
            onChangeStatus(option);
          }
        },
      })),
    [interest]
  );

  const dropdownTheme = useMemo(() => {
    if (isDiscounted) {
      return ScheduleStatusTheme.Red;
    }

    return scheduleConfig.statusThemesMap[interest.interest_schedule_status];
  }, [isDiscounted, interest, scheduleConfig]);

  const statusInfo = useMemo(() => {
    let label = t('unknown');
    let icon = undefined;
    let tooltip = '';
    const isNoLongerActive = !!interest.requirement_no_longer_active;

    statusOptions.forEach((option) => {
      if (option.value == interest.interest_schedule_status) {
        label = option.displayedText || option.text;
      }
    });

    if (interest.interest_schedule_status === InterestScheduleStatuses.SentItem) {
      label = t('match_requested');
    }

    if (isNoLongerActive) {
      icon = <AlertCircleIcon classes={{ root: classes.icon }} />;
      tooltip = t('requirement_no_longer_active');
    } else if (isDiscounted) {
      icon = <CloseIcon classes={{ root: classes.icon }} />;
      tooltip = t('match_discounted_due_to_quote_xreasonx_quote', {
        reason: 'interest.discounted_reason_string', //TODO uncomment when discounted_reason_string is back
      });
    }

    return {
      label,
      icon,
      tooltip,
    };
  }, [classes, isDiscounted, interest, statusOptions]);

  return (
    <Dropdown
      classes={{ root: cx(classes.root, classes[dropdownTheme]) }}
      disabled={disabled}
      fullWidth={fullWidth}
      icon={statusInfo.icon}
      items={statusOptions}
      mainBtnText={statusInfo.label}
      size={ButtonSize.small}
      tooltip={statusInfo.tooltip}
      tooltipPlacement="top"
    />
  );
};

export const InterestScheduleStatusDropdown = withStyles(styles)(InterestScheduleStatusDropdownComponent);
