import cx from 'classnames';
import { FC, MouseEvent, ReactNode, useMemo } from 'react';

import { withStyles, WithStyles } from '@core/theme/utils/with-styles';
import { Flex } from '@shared/components/flex';
import { Tooltip } from '@shared/components/tooltip';

import { styles } from './Tab.styles';

export interface TabConfigItem<T = any> {
  count?: number;
  divider?: boolean;
  icon?: ReactNode;
  label?: string;
  value?: T;
}

export interface TabProps<T = any> extends WithStyles<typeof styles> {
  active?: boolean;
  count?: number;
  disabled?: boolean;
  icon?: ReactNode;
  label: string;
  tooltip?: string;
  url?: string;
  value: T;
  onClick: (value: T, event: MouseEvent<HTMLElement>) => void;
}

const TabComponent: FC<TabProps> = ({
  classes,
  label,
  value,
  active,
  icon,
  disabled,
  tooltip,
  count,
  onClick,
  url,
}) => {
  const handlePreventClick = (event: MouseEvent<HTMLElement>) => {
    // If url is passed tab can act as a link with cmd + click / ctrl + click
    if (url) {
      // Do nothing if the user is trying to cmd + click / ctrl + click
      if (event.ctrlKey || event.metaKey) {
        return;
      }
      event.preventDefault();
    }

    if (!active && !disabled) onClick(value, event);
  };

  const content = useMemo(() => {
    return (
      <a href={url} className={cx(classes.root, { [classes.rootDisabled]: disabled })} onClick={handlePreventClick}>
        <div className={cx(classes.underLine, { [classes.underLineActive]: active })} />
        {!!icon && <div className={cx(classes.icon, { [classes.iconActive]: active })}>{icon}</div>}
        <div className={cx(classes.label, { [classes.labelActive]: active })}>{label}</div>
        {typeof count === 'number' && count >= 0 ? (
          <Flex
            alignItems="center"
            justifyContent="center"
            autoWidth={false}
            classes={{ root: cx(classes.count, { [classes.countActive]: count > 0 }) }}
          >
            {count}
          </Flex>
        ) : (
          ''
        )}
      </a>
    );
  }, [classes, handlePreventClick, label, disabled]);

  if (tooltip) {
    return (
      <Tooltip title={tooltip} placement="top">
        {content}
      </Tooltip>
    );
  }

  return content;
};

export const Tab = withStyles(styles)(TabComponent);
