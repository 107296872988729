import React from 'react';

import { t } from '@core/i18n';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Button } from '@shared/components/button-new';
import { Checkbox } from '@shared/components/checkbox';
import { Dropdown, IDropdownItem } from '@shared/components/dropdown-new';
import { Flex } from '@shared/components/flex';
import { ButtonSize, SelectColour } from '@shared/types/common/button';
import { ButtonColour } from '@shared/types/common/button';

import { styles } from './BulkActionsBar.styles';

export interface BulkActionsBarProps extends WithStyles<typeof styles> {
  actions: IDropdownItem[];
  actionBtnText?: string;
  allSelected?: boolean;
  selectedCount: number;
  size?: ButtonSize;
  onClear?: () => void;
  onSelectAll?: (value: boolean) => void;
}

const BulkActionsBarComponent: React.FC<BulkActionsBarProps> = ({
  actions,
  actionBtnText,
  allSelected,
  classes,
  selectedCount,
  size = ButtonSize.medium,
  onClear,
  onSelectAll,
}) => {
  return (
    <Flex alignItems="center" autoWidth={false} className={classes.root} justifyContent="space-between">
      {onSelectAll && (
        <Checkbox
          classes={{
            iconChecked: classes.checkboxIconChecked,
            label: classes.checkboxLabel,
          }}
          checked={allSelected}
          label={t('select_all')}
          onChange={onSelectAll}
        />
      )}

      <span className={classes.matchLabel}>{t('xcountx_selected', { count: selectedCount })}</span>
      {onClear && (
        <>
          <span className={classes.clearAction} onClick={onClear}>
            {t('clear')}
          </span>
          <div className={classes.divider} />
        </>
      )}
      {actions.length === 1 && actions[0] && (
        <Button
          colour={ButtonColour.primary}
          size={size}
          startIcon={actions[0].icon}
          text={actions[0].text}
          onClick={actions[0].onClick}
        />
      )}
      {actions.length > 1 && actionBtnText && (
        <Dropdown colour={SelectColour.primary} items={actions} mainBtnText={actionBtnText} size={size} />
      )}
    </Flex>
  );
};

export const BulkActionsBar = withStyles(styles)(BulkActionsBarComponent);
