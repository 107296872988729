import { FC, useState } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Dialog } from '@shared/components/dialog';
import { SingleSelect } from '@shared/components/select-new/single-select';
import { TextField } from '@shared/components/text-field';
import { useCoreOptions, useEmptyOption } from '@shared/contexts/OptionsContext';
import { t } from '@shared/i18n';
import { ButtonSize } from '@shared/types/common/button';

import { styles } from './InterestScheduleBulkDiscountModal.styles';

export type InterestScheduleBulkDiscountModalProps = WithStyles<typeof styles> & {
  loading?: boolean;
  onClose: () => void;
  onSave: (discountReason: Id, comment: string) => void;
};

const InterestScheduleBulkDiscountModalComponent: FC<InterestScheduleBulkDiscountModalProps> = ({
  classes,
  loading,
  onClose,
  onSave,
}) => {
  const { discountReasonOptions } = useCoreOptions();
  const [discountReason, setDiscountReason] = useState<Id>('');
  const [comment, setComment] = useState<string>('');

  const discountReasonOptionsWithNull = useEmptyOption(discountReasonOptions, {
    id: '',
    label: t('please_select'),
  });

  const handleSubmit = async () => {
    onSave(discountReason, comment);
  };

  return (
    <Dialog
      buttonProps={{
        cancel: {
          disabled: loading,
          onClick: onClose,
        },
        confirm: {
          disabled: !discountReason,
          loading,
          text: t('discount'),
          onClick: handleSubmit,
        },
      }}
      classes={{ actionButtons: classes.actionButtons }}
      headingProps={{
        heading: t('discount_this_requirement'),
        withCloseButton: true,
      }}
      open
      withTransition
      onClose={onClose}
    >
      <SingleSelect
        label={t('discount_reason')}
        options={discountReasonOptionsWithNull}
        placeholder={t('please_select')}
        required
        size={ButtonSize.medium}
        value={discountReason}
        onChange={(value: Id) => setDiscountReason(value)}
      />
      <TextField
        fullWidth
        label={t('comment')}
        minRows={3}
        multiline
        placeholder={t('xtextx_ellipsis', { text: t('add_a_comment_you_can_add_others_with_at_to_notify_them') })}
        value={comment}
        onChange={(e) => setComment(e.target.value)}
      />
    </Dialog>
  );
};

export const InterestScheduleBulkDiscountModal = withStyles(styles)(InterestScheduleBulkDiscountModalComponent);
