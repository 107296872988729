import { createStyles, Theme } from '@material-ui/core/styles';

export default function styles({ shape }: Theme) {
  return createStyles({
    root: {},
    container: {
      height: '100%',
      borderWidth: '0 1px 0 1px',
      borderStyle: 'solid',
      borderColor: 'transparent',
      borderRadius: shape.borderRadius,
      boxShadow: '0 2px 3px 0 rgb(0 18 52 / 10%)',
    },
    wrapper: {
      tableLayout: 'fixed',
    },
    cell: {},
    headerCell: {},
    headerCellText: {},
    noDataCell: {},
    bodyCell: {},
    bodyRow: {},
    menuItem: {
      '&[aria-selected=true]': {
        fontWeight: 500,
      },
    },
  });
}
