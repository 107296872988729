import { SvgIcon } from '@material-ui/core';
import { FC } from 'react';

import { withStyles, WithStyles } from '@core/theme/utils/with-styles';

import { styles } from './Drag.styles';

export type DragIconProps = WithStyles<typeof styles>;

const DragIconComponent: FC<DragIconProps> = ({ classes }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="16"
      viewBox="0 0 9 16"
      fill="none"
      classes={{ root: classes.root }}
    >
      <circle cx="1.5" cy="2" r="1.5" fill="#828D9E" />
      <circle cx="7.5" cy="2" r="1.5" fill="#828D9E" />
      <circle cx="1.5" cy="8" r="1.5" fill="#828D9E" />
      <circle cx="7.5" cy="8" r="1.5" fill="#828D9E" />
      <circle cx="1.5" cy="14" r="1.5" fill="#828D9E" />
      <circle cx="7.5" cy="14" r="1.5" fill="#828D9E" />
    </SvgIcon>
  );
};

export const DragIcon = withStyles(styles)(DragIconComponent);
