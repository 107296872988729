import { PropsWithChildren, useEffect, useMemo, useState } from 'react';

import { ClassesProp } from '@core/theme/utils/with-styles';
import { Tooltip } from '@shared/components/tooltip';

import { useMultiSelectClasses } from './MultiSelect.styles';
import { generateOptionsHash, MultiSelectOnChange, Select, SelectProps } from '../Select';

export interface MultiSelectProps<TValue extends Id = Id>
  extends Omit<SelectProps<TValue>, 'classes' | 'defaultValue' | 'multiple' | 'onChange' | 'optionsHash' | 'value'>,
    PropsWithChildren<{
      classes?: ClassesProp<typeof useMultiSelectClasses>;
      defaultValue?: TValue[];
      saveOnClose?: boolean;
      value: TValue[];
      onChange?: MultiSelectOnChange<TValue>;
    }> {}

export const MultiSelect = <TValue extends Id = Id>({
  classes: propClasses,
  saveOnClose,
  value,
  onChange,
  ...selectProps
}: MultiSelectProps<TValue>) => {
  const classes = useMultiSelectClasses(propClasses);

  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const optionsHash = useMemo(() => generateOptionsHash<TValue>(selectProps.options), [selectProps.options]);
  const [selectedOptions, setSelectedOptions] = useState<TValue[]>(value);

  useEffect(() => {
    if (saveOnClose) {
      setSelectedOptions(value);
    }
  }, [saveOnClose, value]);

  const selectedTooltipOptions = useMemo(
    () =>
      selectedOptions.length > 0
        ? selectedOptions.map((item) => selectProps.options.find((option) => option.id === item)?.label).join(`, \n`)
        : '',
    [selectedOptions, selectProps.options]
  );

  const hasChanges = useMemo(() => {
    const selectedOptionsSet = new Set(selectedOptions);
    const valueSet = new Set(value);

    return selectedOptions.some((item) => !valueSet.has(item)) || value.some((item) => !selectedOptionsSet.has(item));
  }, [selectedOptions, value]);

  return (
    <Tooltip placement="top" title={menuOpen ? '' : selectedTooltipOptions} classes={{ container: classes.tooltip }}>
      <Select<TValue>
        {...selectProps}
        classes={classes}
        multiple={true}
        optionsHash={optionsHash}
        value={selectedOptions}
        onChange={(value) => {
          setSelectedOptions(value as TValue[]);
          if (!saveOnClose) {
            if (onChange) {
              onChange(value as TValue[]);
            }
          }
        }}
        onClose={() => {
          setMenuOpen(false);
          if (saveOnClose && hasChanges) {
            if (onChange) onChange(selectedOptions as TValue[]);
          }
        }}
        onOpen={() => setMenuOpen(true)}
      />
    </Tooltip>
  );
};
