import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {},
    header: {
      display: 'flex',
      width: '100%',
      padding: spacing(2),
      borderBottom: `1px solid ${colors.grey.fainter}`,
    },
    body: {},
    iframe: {
      height: '100%',
      border: '1px solid #EAECF0', //TODO change after creating new palette
      borderRadius: 12,
    },
  });
