import { TableCellProps } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

import { SortingType } from '@shared/types/services';

export interface TableColumn<T = Item> extends Column, TableCellProps {
  className?: string;
  headerCellClassName?: string;
  bodyCellClassName?: string;
  bodyCellFontStyle?: CSSProperties['fontStyle'];
  bodyCellFontWeight?: CSSProperties['fontWeight'];
  disableSort?: boolean;
  renderBodyCell?: (item: T) => React.ReactNode;
}

export interface TableConfig {
  defaultOrderField: string;
  defaultOrderType?: SortingType;
  disableAutoInit?: boolean;
  getData?: (...args: any[]) => Promise<any>;
}

export interface NoDataType {
  content?: React.ReactNode;
  icon?: React.ReactNode;
  heading?: string;
  text?: string;
}

// TODO: Why do we need this? Seems redundant. -Ben
export enum View {
  grid,
  table,
}
