import { FeeShowItem } from '@shared/apis/feesApi.types';
import { IDisposalListItem } from '@shared/models/disposal/disposal';
import { queryClient, UpdateCacheCallback, updateQueryCache } from '@shared/utils/http-client';

import { disposalsQueryKeys } from './disposalsApi.queries';
import {
  DisposalDataRoomAccessListPayload,
  DisposalDataRoomAccessResponse,
  DisposalDataRoomShowItem,
  DisposalShowItem,
  DisposalsListPayload,
  DisposalsResponse,
} from './disposalsApi.types';

// ---- Disposal, List - Utils ----

export const updateDisposalsListCache =
  (payload: DisposalsListPayload) => (ids: number[], update: Partial<IDisposalListItem>) => {
    queryClient.setQueryData<DisposalsResponse>(disposalsQueryKeys.list(payload).queryKey, (response) => {
      if (!response) return response;

      const data = response.data.map((disposal) => {
        if (ids.includes(disposal.id)) {
          return { ...disposal, ...update };
        }

        return disposal;
      });

      return { ...response, data };
    });
  };

// ---- Disposal - Utils ----

export const updateDisposalFeesCache = (id: string, callback: UpdateCacheCallback<FeeShowItem>) =>
  updateQueryCache<FeeShowItem>(disposalsQueryKeys.fees(id).queryKey, callback);

export const updateDisposalShowCache = (id: string, callback: UpdateCacheCallback<DisposalShowItem>) =>
  updateQueryCache<DisposalShowItem>(disposalsQueryKeys.show(id).queryKey, callback);

// TODO: Update this to use updateQueryCache, vs. all the ifs below
export const updateDisposalShowCacheLegacy = (disposalId: string, update: Partial<DisposalShowItem>) => {
  queryClient.setQueryData<DisposalShowItem>(disposalsQueryKeys.show(disposalId).queryKey, (item) => {
    if (!item) return item;

    const disposal = { ...item };

    if ('architect_companies' in update) {
      disposal.architect_companies = update.architect_companies ? update.architect_companies.slice() : [];
    }

    if ('comments' in update) {
      disposal.comments = update.comments ? update.comments.slice() : [];
    }

    if ('developer_companies' in update) {
      disposal.developer_companies = update.developer_companies ? update.developer_companies.slice() : [];
    }

    if ('development_pipeline_comments' in update) {
      disposal.development_pipeline_comments = update.development_pipeline_comments
        ? update.development_pipeline_comments.slice()
        : [];
    }

    if ('primary_address' in update) {
      disposal.primary_address = update.primary_address || disposal.primary_address;
    }

    if ('property' in update) {
      disposal.property = update.property || disposal.property;
    }

    if ('address' in update) {
      disposal.address = update.address || disposal.address;
    }

    if ('address_string' in update) {
      disposal.address_string = update.address_string || disposal.address_string;
    }

    if ('secondary_address' in update) {
      disposal.secondary_address = update.secondary_address || disposal.secondary_address;
    }

    if ('primary_address' in update) {
      disposal.primary_address = update.primary_address || disposal.primary_address;
    }

    if ('tasks' in update) {
      disposal.tasks = update.tasks ? update.tasks.slice() : [];
    }

    if ('needs_approval' in update) {
      disposal.needs_approval = !!update.needs_approval;
    }
    if ('society_listing_needs_approval' in update) {
      disposal.society_listing_needs_approval = !!update.society_listing_needs_approval;
    }
    if ('society_listing_validated' in update) {
      disposal.society_listing_validated = !!update.society_listing_validated;
    }

    return disposal;
  });
};

// ---- Disposal, Data Room - Utils ----

export const updateDisposalDataRoomCache = (id: string, callback: UpdateCacheCallback<DisposalDataRoomShowItem>) =>
  updateQueryCache<DisposalDataRoomShowItem>(disposalsQueryKeys.dataRoom(id).queryKey, callback);

export const updateDisposalDataRoomAccessCache = (
  payload: DisposalDataRoomAccessListPayload,
  callback: UpdateCacheCallback<DisposalDataRoomAccessResponse>
) =>
  updateQueryCache<DisposalDataRoomAccessResponse>(disposalsQueryKeys.dataRoomAccessList(payload).queryKey, callback);
