import { FC, useMemo } from 'react';

import {
  PresentSpaceView,
  UrlState,
} from '@agency/modules/disposals/pages/disposal-manage/pages/disposal-reporting/DisposalReporting.context';
import { t } from '@core/i18n';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { useDisposalPresentInsightsIndexQuery, useDisposalPresentViewingsIndexQuery } from '@shared/apis/disposals';
import { Button } from '@shared/components/button-new';
import { SpacesOverlay } from '@shared/components/spaces-overlay';
import { useReactRouterUrlState } from '@shared/hooks/reactRouterUrlState';
import { BackIcon } from '@shared/icons/back';
import { CalendarIcon } from '@shared/icons/calendar';
import { ChartBreakoutSquareIcon } from '@shared/icons/chart-breakout-square';
import { KatoLogoShortIcon } from '@shared/icons/kato-logo-short';
import { PieChartIcon } from '@shared/icons/pie-chart';
import { ShareIcon } from '@shared/icons/share-2';
import { ButtonColour, ButtonSize } from '@shared/types/common/button';
import { InterestScheduleData } from '@shared/types/common/match';
import { getQueries } from '@shared/utils/common';

import { DisposalReportingPresentInsightsSpace } from './disposal-reporting-present-insights-space/DisposalReportingPresentInsightsSpace';
import { DisposalReportingPresentScheduleSpace } from './disposal-reporting-present-schedule-space';
import { DisposalReportingPresentViewingsSpace } from './disposal-reporting-present-viewings-space';
import { styles } from './DisposalReportingPresentOverlay.styles';

export interface DisposalReportingPresentOverlayProps extends WithStyles<typeof styles> {
  id: string;
  scheduleData: InterestScheduleData;
  title: string;
  onClose: () => void;
}

const DisposalReportingPresentOverlayComponent: FC<DisposalReportingPresentOverlayProps> = ({
  classes,
  id,
  scheduleData,
  title,
  onClose,
}) => {
  const { urlState, setUrlState } = useReactRouterUrlState<UrlState>();

  const insightsData = useDisposalPresentInsightsIndexQuery(id, { keepPreviousData: true });
  const insightsUrl = insightsData.data?.meta?.config?.upsell_url ?? '';

  const viewingsData = useDisposalPresentViewingsIndexQuery(id, { keepPreviousData: true });
  const viewingsUrl = viewingsData.data?.meta?.config?.upsell_url ?? '';

  const spaces = useMemo(
    () => [
      { id: PresentSpaceView.Schedule, icon: <ChartBreakoutSquareIcon />, name: t('schedule') },
      { id: PresentSpaceView.Viewings, icon: <CalendarIcon />, name: t('viewings') },
      { id: PresentSpaceView.Insights, icon: <PieChartIcon />, name: t('insights') },
    ],
    []
  );

  const activeSpaceContent = (() => {
    switch (urlState.present_space) {
      case PresentSpaceView.Insights:
        return <DisposalReportingPresentInsightsSpace iframeUrl={insightsUrl} />;
      case PresentSpaceView.Schedule:
        return <DisposalReportingPresentScheduleSpace id={id} scheduleData={scheduleData} />;
      case PresentSpaceView.Viewings:
        return <DisposalReportingPresentViewingsSpace iframeUrl={viewingsUrl} />;
      default:
        return null;
    }
  })();

  return (
    <SpacesOverlay
      activeSpace={urlState.present_space || PresentSpaceView.Schedule}
      classes={{ header: classes.spacesOverlayHeader }}
      open
      headerActions={
        <>
          <Button
            colour={ButtonColour.primary}
            size={ButtonSize.small}
            startIcon={<BackIcon />}
            text={t('back')}
            onClick={onClose}
          />
          <Button
            colour={ButtonColour.primary}
            size={ButtonSize.small}
            startIcon={<ShareIcon color="#FFFFFF" />}
            text={t('share')}
            onClick={() => console.log('share')}
          />
        </>
      }
      headerTitle={
        <>
          <KatoLogoShortIcon classes={{ root: classes.spacesOverlayHeaderLogo }} />
          <span className={classes.spacesOverlayHeaderTitle}>{title}</span>
        </>
      }
      spaces={spaces}
      onClose={onClose}
      onSelectSpace={(space) => {
        const query = { ...getQueries(), present_space: space as PresentSpaceView };
        setUrlState(query);
      }}
    >
      {activeSpaceContent}
    </SpacesOverlay>
  );
};

export const DisposalReportingPresentOverlay = withStyles(styles)(DisposalReportingPresentOverlayComponent);
