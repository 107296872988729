import { DisposalMarketing } from '@agency/apis/disposals';
import { IAgent } from '@shared/models/agent';
import { AvailableSpace } from '@shared/models/space';
import { Languages } from '@shared/types/common/region';

export enum DisposalCreateType {
  default,
  schemeListing,
  devPipeline,
  investment,
}

export enum DisposalModuleKeys {
  all = 'all',
  dev_pipeline = 'dev_pipeline',
  our_instructions = 'instruction_availability',
  investment = 'investment',
  pitching = 'pitching',
  tracked_availability = 'market_availability',
}

export enum DisposalCompanyKeys {
  all = 'companies',
  architect = 'architect_companies',
  developer = 'developer_companies',
  landlord = 'landlord_companies',
  vendor = 'vendor_companies',
}

export enum DisposalContactKeys {
  all = 'contacts',
  landlord = 'landlord_contacts',
  vendor = 'vendor_contacts',
}

export enum DisposalInvestmentMarketStatus {
  OnMarket = 1,
  OffMarket = 2,
  PotentialUnlock = 3,
}

export type DisposalIndexFeatureFlags = {
  interest_schedule: boolean;
};

export type DisposalShowFeatureFlags = {
  archive_disposal: boolean;
  availability_action: boolean;
  campaigns_email_an_update: boolean;
  campaigns_email_marketing_list: boolean;
  campaigns_email_matching_applicants: boolean;
  campaigns_reports: boolean;
  can_manage_requirement: boolean;
  can_set_assignees: boolean;
  can_set_confidentiality: boolean;
  confidential_locking: boolean;
  duplicate_disposal: boolean;
  edit_location: boolean;
  find_matches: boolean;
  granular_publish_actions: boolean;
  insights: boolean;
  interest_schedule: boolean;
  publish_to_2nd_pdf: boolean | null;
  publish_to_microsite: boolean | null;
  publish_to_nxt: boolean;
  publish_to_nxt_unpublish: boolean;
  publish_to_pdf: boolean | null;
  publish_to_portals: boolean | null;
  publish_to_powerpoint: boolean;
  publish_to_radius: boolean | null;
  publish_to_society: boolean;
  publish_to_twitter: boolean | null;
  publish_to_website: boolean | null;
  publish_to_any: boolean;
  record_investment_sale: boolean;
  record_lease: boolean;
  record_sale: boolean;
};

export interface MarketingKeyPoint {
  name: string;
}

export interface MarketingOtherText {
  content: string;
  heading: string;
  key: string;
}

export interface MarketingEntryData {
  availability_text: string | null;
  description: string | null;
  key_points: MarketingKeyPoint[];
  location: string | null;
  other_text: MarketingOtherText[];
  promo: string | null;
  specifications: string | null;
  summary: string | null;
  terms: string | null;
  viewings: string | null;
}

export type MarketingEntries = {
  [key in Languages]: MarketingEntryData;
};

// Marketing entries payload - generate type based on MarketingEntryData / Languages
// E.g. { marketing_entry_en_availability_text: ...; marketing_entry_pt_summary: ...; ... }
export type MarketingEntryPayload = {
  [P in keyof MarketingEntryData as `marketing_entry_${Languages}_${P}`]?: MarketingEntryData[P];
};

export interface DisposalPublished {
  published_to_bayut: number | boolean | null;
  published_to_bpiexpresso: number | boolean | null;
  published_to_completely_retail: number | boolean | null;
  published_to_dubizzle: number | boolean | null;
  published_to_belbex: number | boolean | null;
  published_to_business_for_sale: number | boolean | null;
  published_to_daft: number | boolean | null;
  published_to_daltons: number | boolean | null;
  published_to_data_room: number | boolean | null;
  published_to_each: number | boolean | null;
  published_to_eg_property: number | boolean | null;
  published_to_egorealestate: number | boolean | null;
  published_to_idealista: number | boolean | null;
  published_to_imovirtual: number | boolean | null;
  published_to_microsite: number | boolean | null;
  published_to_misoficinas: number | boolean | null;
  published_to_novaloca: number | boolean | null;
  published_to_nxt: number | boolean | null;
  published_to_on_the_market: number | boolean | null;
  published_to_property_pal: number | boolean | null;
  published_to_property_news: number | boolean | null;
  published_to_pdf: number | boolean | null;
  published_to_property_finder: number | boolean | null;
  published_to_radius: number | boolean | null;
  published_to_realla: number | boolean | null;
  published_to_rightmove: number | boolean | null;
  published_to_website: number | boolean | null;
  published_to_zoopla: number | boolean | null;
  published_to_zoopla_rtf: number | boolean | null;
}

export interface IDisposalFullObject {
  address: AddressManual;
  address_string: string;
  api_locked: NumberBoolean;
  approved_at: string;
  breakdown: null;
  building_types: string[];
  building_types_society: string[];
  content: any;
  created_at: string;
  external_ref_1: null;
  external_refs: any[];
  for_sale: NumberBoolean;
  hash: Id;
  id: Id;
  is_investment_sale: NumberBoolean;
  joint_agent_schedule_candidate: boolean;
  letting_transaction_id: null;
  marketing: DisposalMarketing;
  marketing_entries: {
    [key in Languages]: MarketingEntryData;
  };
  marketing_languages_enabled: Languages[];
  name: string;
  needs_approval: boolean;
  needs_approval_remaining_days: number;
  organisation_id: Id;
  our_instruction: NumberBoolean;
  position: {
    map: {
      lat: number;
      lng: number;
      zoom: number;
    };
    street_view: {
      heading: number;
      lat: number;
      lng: number;
      pano: string;
      pitch: number;
      zoom: number;
    };
  };
  publish_options: any;
  quality_score: number;
  sale_transaction_id: Id | null;
  society_listing_needs_approval: boolean;
  society_listing_remaining_days: number | null;
  society_listing_validated: boolean | null;
  status: number;
  status_string: string;
  to_let: NumberBoolean;
  competition_schedule_id: null;
  transaction_id: Id | null;
  updated_at: string;
}

export interface IDisposalFindMatches {
  can_be_matched: boolean;
  existing_match: boolean;
  icon: string;
  label: string;
  theme: string;
  tooltip: string;
}

export interface IDisposalSizeFields {
  from: number | null;
  from_sqft: number | null;
  measure: string | null;
  metric: Metric;
  string: string | null;
  to: number | null;
  to_sqft: number | null;
}

// TODO: Move to disposalsApi.types.ts and rename to DisposalListItem
export interface IDisposalListItem extends DisposalPublished {
  address: string;
  agents: IAgent[];
  available_spaces: AvailableSpace[];
  building_types: string[];
  can_be_matched_against_society: boolean;
  collaborator_disposals_count: number;
  created_at: string;
  details_confirmed: boolean;
  existing_match_accepted: boolean;
  existing_match_pending: boolean;
  existing_match_rejected: boolean;
  existing_match: boolean;
  existing_proposal_match: boolean;
  feature_flags: DisposalIndexFeatureFlags;
  find_matches: IDisposalFindMatches;
  for_sale: NumberBoolean;
  hasImage?: boolean;
  id: number;
  image: string;
  images: string[];
  investment_market_status: DisposalInvestmentMarketStatus | null;
  investment_market_status_string: string;
  is_investment_sale: NumberBoolean;
  is_portfolio: boolean;
  lat: number | null;
  listed_by: string | null;
  lng: number | null;
  lot_size: string;
  lot_size_sortable: number | null;
  match_count: number;
  match_new: number;
  name: string;
  organisation_id: Id;
  pending_proposals: number;
  postcode_partial: string;
  postcode: string;
  price: string;
  primary_address: string;
  rent: string;
  size_fields: IDisposalSizeFields;
  size: string;
  society_adverts: number;
  society_listing_needs_approval: boolean;
  society_listing_remaining_days: number | null;
  status_readable: string;
  status: number;
  submarkets: string;
  to_let: NumberBoolean;
  updated_at: string;
  summary: {
    //Non investment
    total_enquiries?: { change: number; value: number };
    total_signed?: { change: number; value: number };
    total_under_offer?: { change: number; value: number };
    total_viewing?: { change: number; value: number };
    //Ivestment
    total_teaser_sent?: { change: number; value: number };
    total_details_shared?: { change: number; value: number };
    total_completed?: { change: number; value: number };
    //Common
    total_negotiating?: { change: number; value: number };
  };
}

export interface IDisposalListTableItem extends DisposalPublished {
  address: string;
  building_types: string[];
  can_be_matched_against_society: boolean;
  collaborator_disposals_count: number;
  created_at: string;
  details_confirmed: boolean;
  existing_match_accepted: boolean;
  existing_match_pending: boolean;
  existing_match_rejected: boolean;
  existing_match: boolean;
  existing_proposal_match: boolean;
  find_matches: IDisposalFindMatches;
  for_sale: NumberBoolean;
  id: Id;
  image: string;
  images: string[];
  is_portfolio: boolean;
  lat: number;
  lng: number;
  match_count: number;
  match_new: number;
  name: string;
  organisation_id: Id;
  pending_proposals: number;
  postcode_partial: string;
  postcode: string;
  price: string;
  rent: string;
  size_fields: IDisposalSizeFields;
  size: string;
  society_adverts: number;
  society_listing_needs_approval: boolean;
  society_listing_remaining_days: number | null;
  status_readable: string;
  status: number;
  submarkets: string;
  to_let: NumberBoolean;
  updated_at: string;
}
