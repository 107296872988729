import { createStyles, Theme } from '@material-ui/core/styles';

import { commonStyles } from '@shared/components/select-new/Select.styles';
import { colors } from '@shared/theme/colours';
import { ButtonSize } from '@shared/types/common/button';

export function styles(theme: Theme) {
  return createStyles({
    ...commonStyles(theme),

    divider: {
      borderTop: `1px solid ${colors.grey.lightest}`,
      margin: theme.spacing(1.5, 0),
    },
    label: {
      display: 'flex',
      fontSize: 14,
      fontWeight: 500,
      width: '100%',
      // Size
      ['&$' + ButtonSize.extraSmall]: {
        fontSize: 12,
      },
      ['&$' + ButtonSize.small]: {
        fontSize: 14,
      },
      ['&$' + ButtonSize.mediumLegacy]: {
        fontSize: 14,
      },
      ['&$' + ButtonSize.medium]: {
        fontSize: 14,
      },
      ['&$' + ButtonSize.large]: {
        fontSize: 16,
      },
    },
    labelIcon: {
      alignItems: 'center',
      display: 'inline-flex',
      fontSize: 16,
      paddingRight: theme.spacing(2),
      '& svg': {
        color: 'currentColor',
        fontSize: 'inherit',
      },
      '& svg path': {
        stroke: 'currentColor',
      },
      '& i': {
        color: 'currentColor',
        fontSize: 'inherit',
      },
    },

    // Utility classes - DO NOT STYLE
    menuItemActive: {},
    menuItemHovered: {},
  });
}
