import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

const threadsPanelWidth = 480;
const messagePanelWidth = 700;

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {},
    body: {
      display: 'flex',
      flexGrow: 1,
      overflow: 'auto',
      width: '100%',
      border: '1px solid #EAECF0', //TODO change after creating new palette
      borderRadius: 12,
    },
    // ----------------- Threads Panel -----------------
    threadsPanel: {
      overflow: 'auto',
      width: threadsPanelWidth,
    },
    searchFieldWrapper: {
      padding: spacing(4, 4, 2, 4),
    },
    searchField: {
      width: '100%',
      maxHeight: 40,
      backgroundColor: colors.white.lightest,
      border: '1px solid #EAECF0', //TODO change after creating new palette
    },
    tabs: {
      borderBottom: '1px solid #EAECF0', //TODO change after creating new palette
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'nowrap',
      width: '100%',
      padding: spacing(0, 4, 0, 4),
      overflow: 'auto',
      gap: spacing(8),
    },
    tab: {
      height: '100%',
    },
    count: {
      width: 16,
      height: 16,
      fontSize: 10,
    },
    countActive: {
      backgroundColor: '#E8EBEF',
      color: '#7E8899', //TODO update after palette will be updated,
    },
    archiveIcon: {
      fontSize: 14,
    },
    noDataContent: {
      paddingTop: spacing(25),
    },
    noData: {},
    noDataText: {
      fontWeight: 500,
    },
    threads: {
      padding: spacing(4),
    },
    threadItem: {},
    // ----------------- Message Panel -----------------
    messagePanel: {
      padding: spacing(4),
      borderLeft: '1px solid #EAECF0', //TODO change after creating new palette
      display: 'flex',
      flexDirection: 'column',
      width: messagePanelWidth,
    },
    contentPaddingNoSubheading: {
      // padding: spacing(6, 6, 6, 10),
    },
  });
