import { createStyles, Theme } from '@material-ui/core/styles';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      width: 480,
    },
    body: {},
    actionsButtons: {
      alignItems: 'center',
      justifyContent: 'space-between !important',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      gap: spacing(6),
    },
    label: {
      color: '#101828',
      fontSize: 14,
      fontWeight: 500,
      marginBottom: spacing(2),
    },
    multiSelect: {
      width: '100%',
    },
  });
