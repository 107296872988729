import { createStyles, Theme } from '@material-ui/core/styles';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      gap: spacing(4),
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      flex: 1,
      gap: spacing(1),
    },
    messageContainer: {
      background: '#F2F4F7', //TODO replace after palette will be change
      borderRadius: 12,
      gap: spacing(1),
      padding: spacing(3),
    },

    message: {
      color: '#101828', //TODO replace after palette will be change
      fontSize: 14,
    },
    messageDate: {
      color: '#7E8899', //TODO replace after palette will be change
      fontSize: 12,
      fontWeight: 500,
      userSelect: 'none',
    },
    userName: {
      color: '#515D70', //TODO replace after palette will be change
      fontSize: 12,
      fontWeight: 500,
    },
    userAvatar: {
      height: 32,
      width: 32,
    },
    userAvatarNoInitials: {
      fontSize: 12,
    },
    emptySpace: {
      width: 32,
    },
  });
