import { Checkbox, TableCell, TableHead, TableRow } from '@material-ui/core';
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import cx from 'classnames';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Ellipsis } from '@shared/components/ellipsis';
import { Flex } from '@shared/components/flex';
import { SortingType } from '@shared/types/services';

import styles from './Head.styles';
import { TableColumn, TableConfig } from '../../../../Table.types';

export enum CheckedState {
  checked = 'checked',
  indeterminate = 'indeterminate',
}

export interface HeadProps extends WithStyles<typeof styles> {
  columns: Array<TableColumn>;
  checkedState?: CheckedState;
  currentOrderField?: TableConfig['defaultOrderField'];
  currentOrderSortingType?: SortingType;
  config?: TableConfig;
  additionalHeader?: React.ReactNode;
  withBulkActions?: boolean;
  onSelectedItemsChange?: (checked: boolean) => any;
  onColumnClick: (field: string) => any;
}

const HeadComponent: React.FC<HeadProps> = ({
  classes,
  columns,
  currentOrderField,
  currentOrderSortingType,
  withBulkActions,
  additionalHeader,
  checkedState,
  onSelectedItemsChange,
  onColumnClick,
}) => {
  const handleColumnClick = (field: string) => () => {
    onColumnClick(field);
  };

  const handleSelectedItemsTogle = (e: any, checked: boolean) => {
    if (onSelectedItemsChange) {
      onSelectedItemsChange(checked);
    }
  };

  const handleSelectedItemsClear = () => {
    if (onSelectedItemsChange) {
      onSelectedItemsChange(false);
    }
  };

  // const handleSelectedItemsChange = (checked: boolean) => {
  //   if (onSelectedItemsChange) {
  //     onSelectedItemsChange(checked);
  //   }
  // }

  return (
    <TableHead classes={{ root: classes.root }}>
      {additionalHeader && <TableRow>{additionalHeader}</TableRow>}
      <TableRow>
        {withBulkActions && (
          <TableCell style={{ width: 70, minWidth: 50 }} className={classes.headerCell}>
            <Flex wrap="nowrap" alignItems="center">
              <Checkbox
                classes={{
                  root: classes.bulkCheckbox,
                }}
                checked={checkedState === CheckedState.checked}
                indeterminate={checkedState === CheckedState.indeterminate}
                onChange={handleSelectedItemsTogle}
              />
              {Boolean(checkedState) && (
                <DeleteIcon className={classes.deleteIcon} onClick={handleSelectedItemsClear} />
              )}
            </Flex>
          </TableCell>
        )}
        {columns.map((column, i) => {
          const {
            align,
            className,
            disableSort,
            key: field,
            headerCellClassName,
            maxWidth,
            minWidth,
            name,
            width,
          } = column;

          const isCurrentOrderField = field === currentOrderField;

          return (
            <TableCell
              key={i}
              align={align}
              style={{ width, maxWidth, minWidth }}
              sortDirection="desc"
              className={cx(classes.headerCell, className, headerCellClassName, {
                [classes.headerCellDisabled]: disableSort,
                [classes.headerCellActive]: isCurrentOrderField,
              })}
              onClick={disableSort ? undefined : handleColumnClick(field)}
            >
              <Flex wrap="nowrap" alignItems="center" className={classes.headerCellTextWrapper}>
                <Ellipsis
                  withTooltip={false}
                  text={name}
                  tooltipPlacement="top"
                  classes={{ root: classes.headerCellText }}
                />
                {!disableSort && (
                  <Flex container direction="column" className={classes.sortIcons}>
                    <ArrowDropUpIcon
                      className={cx(classes.sortIcon, {
                        [classes.sortActiveIcon]: isCurrentOrderField && currentOrderSortingType === SortingType.asc,
                      })}
                    />
                    <ArrowDropDownIcon
                      className={cx(classes.sortIcon, {
                        [classes.sortActiveIcon]: isCurrentOrderField && currentOrderSortingType === SortingType.desc,
                      })}
                    />
                  </Flex>
                )}
              </Flex>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export const Head = withStyles(styles)(HeadComponent);
