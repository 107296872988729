import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

const tabsHeight = 56;
const presentModeTabsHeight = 72;
const bulkActionsHeight = 48;

export const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    tabs: {
      height: tabsHeight,
    },
    tabsPresentMode: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: presentModeTabsHeight,
    },
    tabsLeft: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
    tabsRight: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    table: {
      height: '100%',
    },
    tableContainerRoot: {
      borderRadius: 0,
      border: 'none',
    },
    tableWithBulkActions: {
      height: `calc(100% - ${bulkActionsHeight / 2}px)`,
    },
    tablePresentMode: {
      height: `calc(100% - ${presentModeTabsHeight}px)`,
    },
    // tableRoot: {
    //   borderTop: 'none',
    //   borderTopLeftRadius: 0,
    //   borderTopRightRadius: 0,
    //   height: 600,

    //   '& .MuiDataGrid-renderingZone': {
    //     maxHeight: `559px !important`, //TODO
    //   },
    // },

    tableBody: {
      border: 'none',
      borderRadius: 0,
    },
    tableCell: {
      verticalAlign: 'top',
    },
    tableRowFirstLine: {
      display: 'flex',
      alignItems: 'center',
      color: '#101828', //TODO change after creating new palette,
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '20px',
      gap: spacing(2),
    },
    tableRowSecondLine: {
      color: '#7E8899', //TODO change after creating new palette,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '20px',
    },
    tooltipContainer: {
      display: 'block',
      width: '100%',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    leaveComment: {
      color: palette.primarySpecial.main,
      cursor: 'pointer',
      fontSize: 12,
      fontWeight: 500,
      lineHeight: 'normal',
    },
    commentsTooltip: {
      maxWidth: 350,
      textAlign: 'left',
    },
    hideSwitchLabel: {
      fontSize: 12,
    },
    userAvatar: {
      width: 24,
      height: 24,
    },
    userInitials: {
      fontSize: 12,
    },
    sharedByText: {
      color: colors.grey.main,
      fontSize: 10,
      fontStyle: 'italic',
    },
    lockIconTooltip: {
      display: 'inline-flex',
    },
    columnIcon: {
      fontSize: 16,
    },
    emptyItem: {
      width: 20,
    },
    noResultsIcon: {
      fontSize: 32,

      '& path': {
        stroke: colors.black.darker,
      },
    },
    divider: {
      background: '#EAECF0', //TODO change after creating new palette,
      height: 32,
      width: 1,
    },
    presentTab: {
      borderRadius: 6,
      cursor: 'pointer',
      padding: spacing(2.5, 4),
      gap: 8,
      height: 40,
      transition: 'all .3s',

      '& > *': {
        color: '#7E8899', //TODO change after creating new palette,

        fontWeight: 500,
        lineHeight: '20px',
      },
    },
    presentTabActive: {
      background: palette.primarySpecial.main,

      '& > *': {
        color: colors.white.lightest,
      },
    },
    tableFooter: {
      display: 'flex',
      flex: '0 0 auto',
      height: bulkActionsHeight,
      justifyContent: 'center',
      marginTop: -spacing(7),
      zIndex: 2,
    },
    bulkActionsBar: {
      width: 'auto',
      zIndex: 2,
    },
    tenantContainer: {
      display: 'flex',
      alignItems: 'flex-start',
      flexWrap: 'nowrap',
      gap: spacing(1),
    },
    tenantBody: {
      display: 'flex',
      flexDirection: 'column',
      gap: spacing(1),
    },
    tenantIcon: {
      display: 'inline-flex',
      fontSize: 12,
    },
    popover: {},
    popoverContent: {
      width: 380,
      padding: spacing(4),
      border: `1px solid ${colors.grey.light}`,
      borderRadius: 12,
    },
    popoverButtonContainer: {
      display: 'flex',
      gap: spacing(4),
      marginTop: spacing(4),
    },
    popoverButton: {
      flex: 1,
    },
  });
