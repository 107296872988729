import { SvgIcon } from '@material-ui/core';
import { FC } from 'react';

import { withStyles, WithStyles } from '@core/theme/utils/with-styles';

import { styles } from './Star.styles';

interface Props extends WithStyles<typeof styles> {
  active: boolean;
}

const StarIconComponent: FC<Props> = ({ classes, active }) => {
  return active ? (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      classes={{ root: classes.root }}
    >
      <path
        d="M7.52181 1.54912C7.67547 1.23783 7.75229 1.08218 7.85659 1.03245C7.94734 0.989183 8.05276 0.989183 8.14351 1.03245C8.24781 1.08218 8.32464 1.23783 8.4783 1.54912L9.93608 4.50243C9.98144 4.59433 10.0041 4.64028 10.0373 4.67596C10.0666 4.70755 10.1018 4.73314 10.1409 4.75132C10.1851 4.77186 10.2358 4.77927 10.3372 4.79409L13.598 5.27071C13.9414 5.3209 14.1131 5.346 14.1926 5.42986C14.2617 5.50284 14.2942 5.60311 14.281 5.70276C14.2659 5.81729 14.1416 5.93836 13.893 6.18049L11.5343 8.47785C11.4608 8.54947 11.424 8.58528 11.4003 8.62788C11.3793 8.6656 11.3658 8.70705 11.3606 8.74991C11.3548 8.79832 11.3635 8.8489 11.3808 8.95007L11.9373 12.195C11.996 12.5372 12.0254 12.7084 11.9702 12.8099C11.9222 12.8983 11.8369 12.9602 11.7381 12.9786C11.6245 12.9996 11.4708 12.9188 11.1634 12.7572L8.24829 11.2241C8.15746 11.1764 8.11205 11.1525 8.0642 11.1431C8.02184 11.1348 7.97827 11.1348 7.9359 11.1431C7.88806 11.1525 7.84264 11.1764 7.75181 11.2241L4.83666 12.7572C4.52932 12.9188 4.37565 12.9996 4.26202 12.9786C4.16316 12.9602 4.07786 12.8983 4.02987 12.8099C3.97471 12.7084 4.00406 12.5372 4.06276 12.195L4.6193 8.95007C4.63665 8.8489 4.64532 8.79832 4.63945 8.74991C4.63426 8.70705 4.62078 8.6656 4.59978 8.62788C4.57606 8.58528 4.53929 8.54947 4.46576 8.47785L2.10708 6.18049C1.85848 5.93836 1.73418 5.81729 1.71906 5.70276C1.7059 5.60311 1.73841 5.50284 1.80754 5.42986C1.887 5.346 2.05869 5.3209 2.40207 5.27071L5.66291 4.79409C5.76432 4.77927 5.81503 4.77186 5.85919 4.75132C5.89828 4.73314 5.93348 4.70755 5.96283 4.67596C5.99598 4.64028 6.01866 4.59433 6.06402 4.50243L7.52181 1.54912Z"
        fill="#FEB872"
        stroke="#FEB872"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </SvgIcon>
  ) : (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      classes={{ root: classes.root }}
    >
      <rect width="16" height="16" fill="none" />
      <path
        d="M7.52181 2.30217C7.67547 1.99088 7.75229 1.83523 7.85659 1.7855C7.94734 1.74224 8.05277 1.74224 8.14351 1.7855C8.24781 1.83523 8.32464 1.99088 8.4783 2.30217L9.93608 5.25548C9.98144 5.34738 10.0041 5.39333 10.0373 5.42901C10.0666 5.4606 10.1018 5.48619 10.1409 5.50437C10.1851 5.52491 10.2358 5.53232 10.3372 5.54714L13.598 6.02376C13.9414 6.07395 14.1131 6.09905 14.1926 6.18292C14.2617 6.25589 14.2942 6.35616 14.281 6.45581C14.2659 6.57035 14.1416 6.69141 13.893 6.93355L11.5343 9.23091C11.4608 9.30252 11.424 9.33833 11.4003 9.38093C11.3793 9.41866 11.3658 9.4601 11.3607 9.50296C11.3548 9.55137 11.3635 9.60196 11.3808 9.70312L11.9373 12.948C11.996 13.2903 12.0254 13.4614 11.9702 13.563C11.9222 13.6513 11.8369 13.7133 11.7381 13.7316C11.6245 13.7527 11.4708 13.6719 11.1634 13.5102L8.24829 11.9772C8.15746 11.9294 8.11205 11.9055 8.0642 11.8961C8.02184 11.8878 7.97827 11.8878 7.9359 11.8961C7.88806 11.9055 7.84264 11.9294 7.75181 11.9772L4.83666 13.5102C4.52932 13.6719 4.37565 13.7527 4.26202 13.7316C4.16316 13.7133 4.07786 13.6513 4.02987 13.563C3.97471 13.4614 4.00406 13.2903 4.06276 12.948L4.6193 9.70312C4.63665 9.60196 4.64532 9.55137 4.63945 9.50296C4.63426 9.4601 4.62078 9.41866 4.59978 9.38093C4.57606 9.33833 4.53929 9.30252 4.46576 9.2309L2.10708 6.93355C1.85848 6.69141 1.73418 6.57035 1.71906 6.45581C1.7059 6.35616 1.73841 6.25589 1.80754 6.18292C1.887 6.09905 2.05869 6.07395 2.40207 6.02376L5.66291 5.54714C5.76432 5.53232 5.81503 5.52491 5.85919 5.50437C5.89828 5.48619 5.93348 5.4606 5.96283 5.42901C5.99598 5.39333 6.01866 5.34738 6.06402 5.25548L7.52181 2.30217Z"
        stroke="#9EA6B3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export const StarIcon = withStyles(styles)(StarIconComponent);
