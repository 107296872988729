import { useHistory, useLocation } from 'react-router-dom';

const parseSearchParams = (search: string) => {
  const searchParams = new URLSearchParams(search);
  const params: Record<string, string> = {};
  searchParams.forEach((value, key) => {
    params[key] = value;
  });
  return params;
};

interface UseReactRouterUrlState<T> {
  urlState: T;
  setUrlState: (newState: Partial<T>) => void;
}

export const useReactRouterUrlState = <T>(): UseReactRouterUrlState<T> => {
  const location = useLocation();
  const history = useHistory();

  const urlState = parseSearchParams(location.search) as unknown as T;

  const setUrlState = (newState: Partial<T> = {}) => {
    const searchParams = new URLSearchParams(location.search);

    Object.entries(newState).forEach(([key, value]) => {
      if (value === undefined || value === null) {
        searchParams.delete(key);
      } else {
        searchParams.set(key, value as string);
      }
    });

    history.replace({
      ...location,
      search: searchParams.toString(),
    });
  };

  return { urlState, setUrlState };
};
