import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

const headerHeight = 32;
const tabsHeight = 55;
const footerHeight = 71;

export const styles: (theme: Theme) => any = ({ spacing }: Theme) =>
  createStyles({
    root: {},
    tablePresent: {
      border: 'none',
    },
    tabs: {
      height: tabsHeight,
    },
    tableBody: {
      border: 'none',
    },
    header: {
      display: 'flex',
      alignContent: 'center',
      width: '100%',
      justifyContent: 'space-between',
      height: headerHeight,
    },
    headerControls: {
      display: 'flex',
      gap: spacing(2),
    },
    searchBtn: {
      minWidth: 32,
      width: 32,
      height: 32,
    },
    searchField: {
      minWidth: 340,
      background: colors.white.lightest,
    },
    content: {
      border: '1px solid #EAECF0', //TODO change after creating new palette
      borderRadius: 12,
      boxShadow: '0px 1px 6px 0px #1018280F',
      maxHeight: `calc(100% - ${headerHeight + spacing(4)}px)`,
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
    },
    body: {
      flexGrow: 1,
      maxHeight: `calc(100% - ${tabsHeight}px)`,
      overflow: 'auto',
      width: '100%',
    },
    bodyTableView: {
      maxHeight: `calc(100% - ${tabsHeight + footerHeight}px)`,
    },
    bodyTableNoItems: {
      minHeight: 300,
    },
    tableFooter: {
      height: footerHeight,
      padding: spacing(4),
    },
    addInterestBtn: {
      width: '100%',
    },
    divider: {
      height: '100%',
      width: 1,
      backgroundColor: colors.grey.fainter,
    },
    boardBody: {},
    hideSwitchLabel: {
      display: 'none',
    },
  });
