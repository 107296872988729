import { PopoverOrigin } from '@material-ui/core';

export type MenuPlacement = 'left' | 'right';

interface CreateMenuPlacementReturnValue {
  anchorOrigin: PopoverOrigin;
  transformOrigin: PopoverOrigin;
}

export const createMenuPlacementObject = (placement: MenuPlacement): CreateMenuPlacementReturnValue => {
  if (placement === 'left') {
    return {
      anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      transformOrigin: { vertical: 'top', horizontal: 'left' },
    };
  }
  return {
    anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
    transformOrigin: { vertical: 'top', horizontal: 'right' },
  };
};
