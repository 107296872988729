import { useMemo } from 'react';

import { InterestHash } from '@agency/modules/disposals/pages/disposal-manage/pages/disposal-reporting/DisposalReporting.context';
import { DisposalInterestIndexItem } from '@shared/apis/disposals';
import { useConfig } from '@shared/contexts/ConfigContext';
import {
  ALL_SCHEDULE_STATUS_GROUP,
  ScheduleConfig,
  ScheduleStatuses,
  ScheduleStatusGroups,
  ScheduleStatusGroupsWithAll,
} from '@shared/types/common/match';
import { reduceNumberRecordToObject } from '@shared/utils/common';
import {
  filterMatchByStatusGroup,
  GroupdByFilter as GroupedByFilter,
  OrderByConfig,
} from '@shared/utils/interest-schedule';

export const useInterestScheduleConfig = (investment: boolean) => {
  const { interestScheduleConfig } = useConfig();

  return useMemo(
    () => ({
      scheduleConfig: investment ? interestScheduleConfig.investment : interestScheduleConfig.lease,
    }),
    [interestScheduleConfig, investment]
  );
};

type GroupedItems = Record<ScheduleStatusGroups, DisposalInterestIndexItem[]>;
type IdsByStatus = Record<ScheduleStatuses, number[]>;
type InterestCounts = Record<ScheduleStatusGroupsWithAll, number>;

interface UseInterestScheduleMatchesData {
  includeDiscounted: boolean;
  interestFilter: (interestId: number) => boolean;
  interestHash: InterestHash;
  interestIds: number[];
  interestSearchFilter: (interestId: number) => boolean;
  matchesGroupBy: GroupedByFilter;
  orderBy: OrderByConfig;
  scheduleConfig: ScheduleConfig;
  search: string;
  tab: ScheduleStatusGroupsWithAll;
}

export const useInterestScheduleMatches = ({
  includeDiscounted,
  interestFilter,
  interestHash,
  interestIds,
  interestSearchFilter,
  matchesGroupBy,
  orderBy,
  scheduleConfig,
  search,
  tab,
}: UseInterestScheduleMatchesData) => {
  const idsByStatus: IdsByStatus = useMemo(() => {
    // console.log('idsByStatus memo');
    const initialResult = scheduleConfig.statuses.reduce((result, key) => {
      result[key] = [];

      return result;
    }, {} as IdsByStatus);

    const result = interestIds.reduce<IdsByStatus>((reduceResult, interestId) => {
      const interest = interestHash[interestId];

      if (interest && interest.interest_schedule_status in reduceResult) {
        reduceResult[interest.interest_schedule_status].push(interestId);
      }

      return reduceResult;
    }, initialResult);

    return reduceNumberRecordToObject(result, (reduceResult, value, key) => {
      reduceResult[key] = value
        .sort((a, b) => sortByOrder(interestHash, a, b, orderBy))
        .sort((a, b) => sortByFav(interestHash, a, b, matchesGroupBy))
        .sort((a, b) => sortByDiscounted(interestHash, a, b, includeDiscounted));

      return reduceResult;
    });
  }, [includeDiscounted, interestHash, interestIds, matchesGroupBy, orderBy, scheduleConfig.statuses]);

  const allTableIds: number[] = useMemo(() => {
    // console.log('allTableIds memo');
    if (matchesGroupBy === GroupedByFilter.Status) {
      const ids: number[] = [];

      scheduleConfig.statuses.forEach((status) => {
        if (status in idsByStatus) {
          idsByStatus[status].forEach((interestId) => ids.push(interestId));
        }
      });

      return ids.sort((a, b) => sortByDiscounted(interestHash, a, b, includeDiscounted));
    }

    return interestIds
      .sort((a, b) => sortByOrder(interestHash, a, b, orderBy))
      .sort((a, b) => sortByFav(interestHash, a, b, matchesGroupBy))
      .sort((a, b) => sortByDiscounted(interestHash, a, b, includeDiscounted));
  }, [includeDiscounted, idsByStatus, interestHash, interestIds, matchesGroupBy, orderBy, scheduleConfig.statuses]);

  const filteredIds: number[] = useMemo(() => {
    // console.log('filteredIds memo', allTableIds.length, search);
    if (!allTableIds.length) {
      return [];
    }
    // Do not filter if we are searching
    if (search) {
      return allTableIds;
    }

    return allTableIds.filter(interestFilter);
  }, [interestFilter, search, allTableIds]);

  const searchingIds: number[] = useMemo(() => {
    // console.log('searchingIds memo');

    if (!filteredIds.length) {
      return [];
    }
    // Do nothing if we aren't searching
    if (!search) {
      return filteredIds;
    }
    // console.log('searchingMatches. searching: ', search);
    return filteredIds.filter(interestSearchFilter);
  }, [filteredIds, interestSearchFilter, search]);

  const allTabItems: DisposalInterestIndexItem[] = useMemo(() => {
    // console.log('allTabItems memo');
    if (!searchingIds.length) {
      return [];
    }

    const allTabIds = searchingIds.filter((interestId) => {
      const interest = interestHash[interestId];

      if (interest) {
        const groupKey = scheduleConfig.statusGroupsMap[interest.interest_schedule_status];

        return scheduleConfig.scheduleGroupsActive.includes(groupKey);
      }

      return false;
    });

    return mapIdsToItems(interestHash, allTabIds);
  }, [interestHash, searchingIds, scheduleConfig.scheduleGroupsActive, scheduleConfig.statusGroupsMap]);

  // Table items
  const interestItems: DisposalInterestIndexItem[] = useMemo(() => {
    // console.log('interestItems memo');
    if (!searchingIds.length) {
      return [];
    }

    if (tab === ALL_SCHEDULE_STATUS_GROUP) {
      return allTabItems;
    }

    return searchingIds.reduce((result, interestId) => {
      const interest = interestHash[interestId];

      if (interest && filterMatchByStatusGroup(interest, tab, scheduleConfig)) {
        result.push(interest);
      }

      return result;
    }, [] as DisposalInterestIndexItem[]);
  }, [allTabItems, interestHash, searchingIds, scheduleConfig, tab]);

  const interestItemIds: number[] = useMemo(() => interestItems.map((interest) => interest.id), [interestItems]);
  // console.log('interestItemIds memo');

  // Board items
  const interestGrouped: GroupedItems = useMemo(() => {
    // console.log('interestGrouped memo');
    const initialResult = Object.values(ScheduleStatusGroups).reduce((result, key) => {
      result[key] = [];

      return result;
    }, {} as GroupedItems);

    return searchingIds.reduce<GroupedItems>((result, interestId) => {
      const interest = interestHash[interestId];

      if (interest && scheduleConfig.statusGroupsMap[interest.interest_schedule_status]) {
        result[scheduleConfig.statusGroupsMap[interest.interest_schedule_status]].push(interest);
      }

      return result;
    }, initialResult);
  }, [interestHash, searchingIds, scheduleConfig.statusGroupsMap]);

  const interestCounts: InterestCounts = useMemo(() => {
    // console.log('interestCounts memo');
    const initialResult = {
      ...Object.values(ScheduleStatusGroups).reduce((result, key) => {
        result[key] = 0;

        return result;
      }, {} as InterestCounts),
      [ALL_SCHEDULE_STATUS_GROUP]: 0,
    };

    return searchingIds.reduce((result, interestId) => {
      const interest = interestHash[interestId];

      if (interest) {
        const groupKey = scheduleConfig.statusGroupsMap[interest.interest_schedule_status];

        // Set the group tab count
        if (scheduleConfig.scheduleGroups.includes(groupKey)) {
          result[groupKey]++;
        }

        // Set the "All" tab count
        if (scheduleConfig.scheduleGroupsActive.includes(groupKey) || typeof groupKey === 'undefined') {
          result[ALL_SCHEDULE_STATUS_GROUP]++;
        }

        // Set the "Discounted" tab count
        if (interest.interest_schedule_discounted_reason) {
          result[ScheduleStatusGroups.Discounted]++;
        }
      }

      return result;
    }, initialResult);
  }, [scheduleConfig, searchingIds]);

  return {
    interestCounts: interestCounts,
    interestGrouped: interestGrouped,
    interestItems: interestItems,
    interestItemIds: interestItemIds,
  };
};

// ---- Utils ----

const mapIdsToItems = (interestHash: InterestHash, ids: number[]): DisposalInterestIndexItem[] => {
  return ids.reduce((result, interestId) => {
    const interest = interestHash[interestId];

    if (interest) {
      result.push(interest);
    }

    return result;
  }, [] as DisposalInterestIndexItem[]);
};

const sortByDiscounted = (interestHash: InterestHash, idA: number, idB: number, includeDiscounted: boolean): number => {
  const interestA = interestHash[idA];
  const interestB = interestHash[idB];

  if (!interestA || !interestB) {
    return 0;
  }

  if (includeDiscounted) {
    if (
      interestA.interest_schedule_discounted_reason === null &&
      interestB.interest_schedule_discounted_reason !== null
    ) {
      return -1;
    }
    if (
      interestA.interest_schedule_discounted_reason !== null &&
      interestB.interest_schedule_discounted_reason === null
    ) {
      return 1;
    }
  }

  return 0;
};

const sortByFav = (interestHash: InterestHash, idA: number, idB: number, matchesGroupBy: GroupedByFilter): number => {
  const interestA = interestHash[idA];
  const interestB = interestHash[idB];

  if (!interestA || !interestB) {
    return 0;
  }

  if (matchesGroupBy === GroupedByFilter.Starred) {
    if (interestA.fav && !interestB.fav) {
      return -1;
    }
    if (!interestA.fav && interestB.fav) {
      return 1;
    }
  }

  return 0;
};

const sortByOrder = (interestHash: InterestHash, idA: number, idB: number, orderBy: OrderByConfig): number => {
  const interestA = interestHash[idA];
  const interestB = interestHash[idB];

  if (!interestA || !interestB) {
    return 0;
  }
  if (interestA[orderBy.key] === interestB[orderBy.key]) {
    return 0;
  }

  const comparison = interestA[orderBy.key] > interestB[orderBy.key] ? 1 : -1;

  return orderBy.reverse ? -comparison : comparison;
};
