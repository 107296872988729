import { Theme } from '@material-ui/core/styles';

import { getStyles, getUseClasses, makeStyles, mockTheme } from '@core/theme/utils/with-styles';

const stylesFn = (_: Theme) =>
  getStyles({
    root: {},
    rootFullWidth: {},

    labelRoot: {},
    labelError: {},

    select: {},
    selectActive: {},
    selectDisabled: {},
    selectError: {},
    selectHovered: {},

    value: {},
    valueIcon: {},
    valueDisabled: {},
    valueMultiple: {},
    valuePlaceholder: {},
    valuePlaceholderEllipsis: {},
    selectorValueText: {},

    menu: {},
    menuItem: {},
    menuItemDisabled: {},
    menuItemIcon: {},
    menuItemText: {},
    autoMenuHeight: {},

    helperText: {},
  });
const stylesResult = stylesFn(mockTheme);

const makeClasses = makeStyles<keyof typeof stylesResult>('SingleSelect', stylesFn);

export const useSingleSelectClasses = getUseClasses<keyof typeof stylesResult>(makeClasses);
