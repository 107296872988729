import cx from 'classnames';
import React, { MouseEventHandler, ReactNode, useMemo } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { FilterCounter } from '@shared/components/filter-counter';
import { Tooltip } from '@shared/components/tooltip';

import { styles } from './StatusBadge.styles';
import { BadgeSize } from '../badge-new';

export enum StatusBadgeTheme {
  danger = 'danger',
  green = 'green',
  grey = 'grey',
  orange = 'orange', // TODO: Change after palette updated
  warning = 'warning',
}

export interface StatusBadgeProps extends WithStyles<typeof styles> {
  content: ReactNode;
  count?: number;
  size?: BadgeSize;
  theme?: StatusBadgeTheme;
  tooltip?: string;
  onClick?: MouseEventHandler;
}

const StatusBadgeComponent: React.FC<StatusBadgeProps> = ({
  classes,
  content,
  count,
  size = BadgeSize.small,
  theme = StatusBadgeTheme.grey,
  tooltip,
  onClick,
}) => {
  const badgeContent = useMemo(() => {
    return (
      <>
        {content}
        {typeof count === 'number' && (
          <FilterCounter
            classes={{
              root: classes.counterRoot,
              rootZero: classes.counterRootZero,
              count: classes.counterCount,
              countZero: classes.counterCountZero,
            }}
            count={count}
          />
        )}
      </>
    );
  }, [classes, content, count]);

  if (tooltip) {
    return (
      <Tooltip
        classes={{ container: cx(classes.badge, classes[size], classes[theme]), root: classes.root }}
        placement="top"
        title={tooltip}
        onClick={onClick}
      >
        {badgeContent}
      </Tooltip>
    );
  }

  return (
    <div className={cx(classes.root, classes.badge, classes[size], classes[theme])} onClick={onClick}>
      {badgeContent}
    </div>
  );
};

export const StatusBadge = withStyles(styles)(StatusBadgeComponent);
