import cx from 'classnames';
import moment from 'moment';
import { FC, useMemo, useState } from 'react';

import { t } from '@core/i18n';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { DisposalInterestShowItem } from '@shared/apis/disposals';
import { TaskShowItem } from '@shared/apis/tasksApi.types';
import { DatePicker, MaterialDate } from '@shared/components/date-picker';
import { Flex } from '@shared/components/flex';
import { ContactsLookupWithDraggableList } from '@shared/components/lookup-with-draggable-list/components/contacts-lookup-with-draggable-list/ContactsLookupWithDraggableList';
import { UsersLookupWithDraggableList } from '@shared/components/lookup-with-draggable-list/components/users-lookup-with-draggable-list/UsersLookupWithDraggableList';
import { RadioGroup, RadioGroupSerializerType } from '@shared/components/radio-group';
import { SingleSelect } from '@shared/components/select-new/single-select';
import { TimeSelect } from '@shared/components/time-select';
import { useCoreOptions } from '@shared/contexts/OptionsContext';
import { ViewingOutcome } from '@shared/models/viewing/viewings';
import { ButtonSize } from '@shared/types/common/button';
import { LookupCommonItem, LookupUserItem } from '@shared/types/common/common';
import { InputSize } from '@shared/types/common/input';
import { formatDate } from '@shared/utils/date';
import { listContactToLookupContact, listUserToLookupUser } from '@shared/utils/lookup';

import { styles } from './CreateUpdateViewingForm.styles';

export const getViewingFormErrors = (state: CreateUpdateViewingFormState): CreateUpdateViewingFormErrors => {
  const errors: CreateUpdateViewingFormErrors = {};

  const viewingInPast = isViewingInPast(state);

  if (!state.date) {
    errors.date = true;
  }

  if (!state.contacts.length) {
    errors.contacts = true;
  }

  if (viewingInPast && !state.feedback) {
    errors.feedback = true;
  }

  if (!state.users.length) {
    errors.users = true;
  }

  return errors;
};

export const getViewingFormStateFromInterest = (interest?: DisposalInterestShowItem): CreateUpdateViewingFormState => ({
  companies: interest?.interest?.companies ?? [],
  contacts: interest?.interest?.contacts ?? [],
  date: null,
  discountReason: '',
  feedback: '',
  time: '09:00',
  users: interest?.interest?.users?.slice() ?? [],
});

export const getViewingFormStateFromTask = (task?: TaskShowItem): CreateUpdateViewingFormState => ({
  companies: [],
  contacts: task?.contacts ? task.contacts.map(listContactToLookupContact) : [],
  date: moment.utc(task?.from),
  discountReason: '',
  feedback: '',
  time: task?.from ? `${formatDate(task.from, 'HH')}:${formatDate(task.from, 'mm')}` : '09:00',
  users: task?.users ? task.users.map(listUserToLookupUser) : [],
});

export const isViewingInPast = (state: CreateUpdateViewingFormState): boolean => {
  if (!state.date) {
    return false;
  }
  const currentDate = moment.utc();

  const selectedDateTime = moment(state.date)
    .clone()
    .set({
      hour: moment.utc(state.time, 'HH:mm').hours(),
      minute: moment.utc(state.time, 'HH:mm').minutes(),
    });

  return selectedDateTime.isBefore(currentDate);
};

export interface CreateUpdateViewingFormState {
  companies: LookupCommonItem[];
  contacts: LookupCommonItem[];
  date: MaterialDate | null;
  discountReason: string;
  feedback: ViewingOutcome | '';
  time: string;
  users: LookupUserItem[];
}

export type CreateUpdateViewingFormFields = keyof CreateUpdateViewingFormState;

export type CreateUpdateViewingFormErrors = Partial<Record<CreateUpdateViewingFormFields, boolean>>;

export interface CreateUpdateViewingFormProps extends WithStyles<typeof styles> {
  disableReason?: boolean; // TODO remove when storing api will support discounted_reason
  errors: CreateUpdateViewingFormErrors;
  lookupDropUp?: boolean;
  required: boolean;
  submitAttempted: boolean;
  state: CreateUpdateViewingFormState;
  onChangeState: <K extends CreateUpdateViewingFormFields>(key: K, value: CreateUpdateViewingFormState[K]) => void;
}

const CreateUpdateViewingFormComponent: FC<CreateUpdateViewingFormProps> = ({
  classes,
  disableReason,
  errors,
  lookupDropUp = true,
  required,
  submitAttempted,
  state,
  onChangeState,
}) => {
  const { discountReasonOptions, viewingFeedbackRadioOptions } = useCoreOptions();

  const [searchUser, setSearchUser] = useState<string>('');
  const [searchContact, setSearchContact] = useState<string>('');

  const viewingInPast = useMemo(() => isViewingInPast(state), [state]);

  return (
    <Flex autoWidth={false} direction="column" wrap="nowrap" classes={{ root: classes.root }}>
      <Flex autoWidth={false} alignItems="flex-start" wrap="nowrap" classes={{ root: classes.calendarInfoWrapper }}>
        <DatePicker
          classes={{
            root: classes.datePickerRoot,
            label: classes.label,
          }}
          error={submitAttempted && errors.date}
          errorText={t('please_select_a_date')}
          label={t(required ? 'viewing_date_asterisk' : 'viewing_date')}
          placeholder="DD/MM/YYYY"
          size={InputSize.medium}
          value={state.date}
          onChange={(date) => onChangeState('date', date)}
        />
        <Flex direction="column" classes={{ root: classes.timeSelectContainer }}>
          <span className={classes.label}>
            {required ? t('xtextx_asterisk_1', { text: t('viewing_time') }) : t('viewing_time')}
          </span>
          <TimeSelect
            classes={{ root: classes.timeSelect }}
            size={ButtonSize.medium}
            time={state.time}
            onTimeChange={(time) => onChangeState('time', time)}
          />
        </Flex>
      </Flex>
      <UsersLookupWithDraggableList
        classes={{
          root: classes.usersLookup,
          avatarPlaceholder: classes.usersAvatarPlaceholder,
          draggableList: classes.usersDraggableList,
          draggableOption: classes.usersDraggableOption,
          label: classes.label,
          selectedOptionAvatar: classes.usersSelectedOptionAvatar,
          selectedOptionAvatarInitials: classes.usersSelectedOptionAvatarInitials,
          closeIconContainer: classes.usersCloseIconContainer,
        }}
        inputProps={{
          errorText: t('at_least_one_agent_must_be_added_to_the_viewing'),
          placeholder: t('xtextx_ellipsis', { text: t('add_additional_agents') }),
        }}
        dropUp={lookupDropUp}
        error={submitAttempted && errors.users}
        items={state.users}
        label={t('xtextx_asterisk_1', { text: t('which_agents_are_showing_the_property_question') })}
        searchValue={searchUser}
        onDragEnd={(list) => onChangeState('users', list)}
        onMatchedDelete={(id) =>
          onChangeState(
            'users',
            state.users.filter((user) => user.id !== id)
          )
        }
        onSearchValueChange={setSearchUser}
        onSelect={(userItem) => {
          setSearchUser('');

          if (userItem && !state.users.some((item) => item.id === userItem.id)) {
            onChangeState('users', [userItem, ...state.users]);
          }
        }}
      />

      <ContactsLookupWithDraggableList
        classes={{
          root: classes.usersLookup,
          draggableList: classes.usersDraggableList,
          label: classes.label,
          closeIconContainer: classes.usersCloseIconContainer,
        }}
        error={submitAttempted && errors.contacts}
        inputProps={{
          errorText: t('at_least_one_inviting_contact_must_be_added_to_the_viewing'),
          InputProps: {
            startAdornment: undefined,
          },
        }}
        items={state.contacts}
        searchValue={searchContact}
        label={t('xtextx_asterisk_1', { text: t('who_are_you_inviting_to_the_viewing_question') })}
        onDragEnd={(list) => onChangeState('contacts', list)}
        onRemoveItem={(id) =>
          onChangeState(
            'contacts',
            state.contacts.filter((user) => user.id !== id)
          )
        }
        onSearchValueChange={setSearchContact}
        onSelect={(contactItem) => {
          setSearchContact('');

          if (contactItem && !state.contacts.some((item) => item.id === contactItem.id)) {
            onChangeState('contacts', [contactItem, ...state.contacts]);
          }
        }}
      />

      {viewingInPast && (
        <RadioGroup
          classes={{ label: cx(classes.label, classes.radioGroupLabel) }}
          direction="row"
          error={submitAttempted && errors.feedback}
          errorText={t('please_select_an_outcome')}
          label={t('xtextx_asterisk_1', { text: t('how_did_they_react_to_the_space_question') })}
          options={viewingFeedbackRadioOptions}
          serializerType={RadioGroupSerializerType.string}
          value={state.feedback}
          onChange={(value) => onChangeState('feedback', value as ViewingOutcome)}
        />
      )}
      {viewingInPast && state.feedback === ViewingOutcome.Negative && (
        <SingleSelect
          classes={{ labelRoot: classes.label }}
          disabled={disableReason}
          label={t('discounted_reason')}
          options={discountReasonOptions}
          value={state.discountReason}
          onChange={(value) => onChangeState('discountReason', value)}
        />
      )}
    </Flex>
  );
};

export const CreateUpdateViewingForm = withStyles(styles)(CreateUpdateViewingFormComponent);
