import GridOffOutlinedIcon from '@material-ui/icons/GridOffOutlined';
import cx from 'classnames';
import { FC, ReactNode, useMemo, useState } from 'react';

import { t } from '@core/i18n';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Flex } from '@shared/components/flex';
import { Loading } from '@shared/components/loading';
import { Sorting, SortingType } from '@shared/types/services';

import TableView, { SelectedItemsIdType } from './components/table-view';
import styles from './Table.styles';
import { NoDataType, TableColumn, TableConfig } from './Table.types';

export type SelectedItem = number | string;

export enum TableTheme {
  Page = 'page',
}

export interface TableProps extends WithStyles<typeof styles> {
  additionalHeader?: ReactNode;
  columns: Array<TableColumn<any>>;
  config?: TableConfig;
  data: any[];
  hover?: boolean;
  loading?: boolean;
  loadingAbsolute?: boolean;
  noDataProps?: NoDataType;
  selectedItem?: SelectedItem;
  selectedItemsIdType?: SelectedItemsIdType;
  stickyHeader?: boolean;
  theme?: TableTheme;
  withBulkActions?: boolean;
  onColumnClick?: (sort: Sorting) => void;
  onRowClick?: (item: any) => void;
  onSelectedItemsChange?: (selectedItems: Id[]) => any;
  onSortData?: (data: any[], field: string, sorting: SortingType) => any[];
}

const TableComponent: FC<TableProps> = ({
  additionalHeader,
  classes,
  config,
  columns,
  data,
  hover,
  loading = false,
  noDataProps,
  selectedItem,
  selectedItemsIdType,
  stickyHeader,
  theme,
  withBulkActions,
  onColumnClick,
  onRowClick,
  onSelectedItemsChange,
  onSortData,
}) => {
  const defaultSortBy = useMemo(
    () => ({
      field: config?.defaultOrderField || undefined,
      type: config?.defaultOrderType || SortingType.asc,
    }),
    []
  );

  const [sortBy, setSortBy] = useState({ ...defaultSortBy });

  const handleColumnClick = (field: string) => {
    const _sortBy: Sorting = { ...sortBy, field };

    if (sortBy.field == field) {
      // Toggle type
      _sortBy.type = _sortBy.type === SortingType.asc ? SortingType.desc : SortingType.asc;
    } else {
      // Sort by asc, as it is a new sort
      _sortBy.type = SortingType.asc;
    }

    setSortBy(_sortBy);

    if (onColumnClick) {
      onColumnClick(_sortBy);
    }
  };

  const sortedData = useMemo(
    () => (onSortData && sortBy.field ? onSortData(data, sortBy.field, sortBy.type) : data),
    [data, onSortData, sortBy]
  );

  const noDataContent = useMemo(() => {
    if (sortedData.length || !noDataProps) {
      return undefined;
    }

    if (noDataProps.content) {
      return noDataProps.content;
    }

    return (
      <Flex direction="column" alignItems="center" justifyContent="center" classes={{ root: classes.noData }}>
        <Flex
          autoWidth={false}
          alignItems="center"
          justifyContent="center"
          classes={{ root: classes.noDataIconContainer }}
        >
          <span className={classes.noDataIcon}>{noDataProps?.icon || <GridOffOutlinedIcon />}</span>
        </Flex>
        <span className={classes.noDataHeader}>{noDataProps?.heading || t('could_not_find_any_data')}</span>
        {noDataProps?.text && <span className={classes.noDataText}>{noDataProps?.text}</span>}
      </Flex>
    );
  }, [classes, noDataProps, sortedData]);

  if (loading) {
    return <Loading absolute classes={{ root: classes.loading }} />;
  }

  return (
    <div
      className={cx(classes.root, theme ? classes[theme] : '', {
        [classes.rootFullHeight]: data.length > 8 && theme === TableTheme.Page,
      })}
    >
      <TableView
        additionalHeader={additionalHeader}
        classes={{
          wrapper: classes.tableViewWrapper,
          container: classes.tableViewContainer,
          headerCell: classes.headerCell,
          headerCellText: classes.headerCellText,
          bodyCell: classes.bodyCell,
          bodyRow: classes.bodyRow,
          noDataCell: classes.noDataCell,
        }}
        columns={columns}
        config={config}
        currentOrderField={sortBy.field}
        currentOrderSortingType={sortBy.type}
        data={sortedData}
        hover={hover}
        loading={loading}
        selectedItem={selectedItem}
        selectedItemsIdType={selectedItemsIdType}
        stickyHeader={stickyHeader}
        withBulkActions={withBulkActions}
        onColumnClick={handleColumnClick}
        onRowClick={onRowClick}
        onSelectedItemsChange={onSelectedItemsChange}
      />
      {noDataContent}
    </div>
  );
};

export const Table = withStyles(styles)(TableComponent);
