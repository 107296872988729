import { SvgIcon } from '@material-ui/core';
import { FC } from 'react';

import { withStyles, WithStyles } from '@core/theme/utils/with-styles';

import { styles } from './Announcement.styles';

interface AnnouncementIconProps extends WithStyles<typeof styles> {
  color?: string;
}

const AnnouncementIconComponent: FC<AnnouncementIconProps> = ({ classes, color = '#344154' }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      classes={{ root: classes.root }}
    >
      <path
        d="M14.6666 5.33328V7.99994M6.83325 3.66661H4.53325C3.41315 3.66661 2.85309 3.66661 2.42527 3.88459C2.04895 4.07634 1.74298 4.3823 1.55124 4.75863C1.33325 5.18645 1.33325 5.7465 1.33325 6.86661L1.33325 7.66661C1.33325 8.28786 1.33325 8.59849 1.43475 8.84352C1.57007 9.17022 1.82964 9.42979 2.15634 9.56511C2.40137 9.66661 2.712 9.66661 3.33325 9.66661V12.4999C3.33325 12.6547 3.33325 12.7321 3.33967 12.7973C3.40202 13.4303 3.90286 13.9312 4.5359 13.9935C4.60108 13.9999 4.67847 13.9999 4.83325 13.9999C4.98803 13.9999 5.06542 13.9999 5.13061 13.9935C5.76365 13.9312 6.26448 13.4303 6.32683 12.7973C6.33325 12.7321 6.33325 12.6547 6.33325 12.4999V9.66661H6.83325C8.01087 9.66661 9.45142 10.2979 10.5628 10.9037C11.2111 11.2571 11.5353 11.4339 11.7476 11.4079C11.9445 11.3837 12.0934 11.2953 12.2088 11.134C12.3333 10.96 12.3333 10.6119 12.3333 9.91575V3.41746C12.3333 2.72127 12.3333 2.37317 12.2088 2.1992C12.0934 2.03788 11.9445 1.94947 11.7476 1.92536C11.5353 1.89936 11.2111 2.07607 10.5628 2.42951C9.45141 3.03535 8.01087 3.66661 6.83325 3.66661Z"
        stroke={color}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export const AnnouncementIcon = withStyles(styles)(AnnouncementIconComponent);
