import { Segment } from '@shared/types/common/segment';

export interface ListResponsePagination {
  count: number;
  current_page: number;
  from: number;
  // links: any[];
  per_page: number;
  to: number;
  total: number;
  total_pages: number;
  last_page?: number;
  links:
    | {
        next: string;
        last: string;
      }
    | any[];
}

export interface IndexMeta {
  pagination: ListResponsePagination;
  segments?: Segment[];
}
export interface IndexResponse<TD = unknown, TM = IndexMeta> {
  data: TD[];
  meta: TM;
}
export interface IndexNoMetaResponse<TD = unknown> {
  data: TD[];
}

export interface Sorting {
  field: string;
  type: SortingType;
}

export enum SortingType {
  asc = 'asc',
  desc = 'desc',
}
