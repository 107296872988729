import { TextFieldProps as MuiTextFieldProps } from '@material-ui/core/TextField/TextField';
import { CancelOutlined as CancelIcon } from '@material-ui/icons';
import { debounce } from 'lodash';
import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';

import { withStyles, WithStyles } from '@core/theme/utils/with-styles';
import { TextField } from '@shared/components/text-field';

import { styles } from './SearchField.styles';

export interface SearchFieldProps extends WithStyles<typeof styles>, Omit<MuiTextFieldProps, 'classes' | 'onChange'> {
  disabled?: boolean;
  placeholder: string;
  search: string;
  onChange: (search: string) => void;
}

const SearchFieldComponent: FC<SearchFieldProps> = ({
  autoFocus = false,
  classes,
  disabled = false,
  fullWidth,
  InputProps,
  inputProps,
  placeholder,
  search,
  onBlur,
  onChange,
}) => {
  const [localSearch, setLocalSearch] = useState<string>(search);

  const changeLocalSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setLocalSearch(event.target.value);
    debouncedSearch(event.target.value);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (search !== localSearch) {
        setLocalSearch(search);
      }
    }, 400);

    return () => clearTimeout(timeout);
  }, [search]);

  const debouncedSearch = useCallback(
    debounce((value) => onChange(value), 350),
    [onChange]
  );

  const handleClearField = () => {
    setLocalSearch('');
    onChange('');
  };

  const endAdornment = useMemo(() => {
    if (localSearch) {
      return <CancelIcon onClick={handleClearField} classes={{ root: classes.clearIcon }} />;
    }
  }, [handleClearField, localSearch]);

  return (
    <TextField
      autoFocus={autoFocus}
      classes={{
        root: classes.root,
        input: classes.inputClass,
      }}
      disabled={disabled}
      fullWidth={fullWidth}
      inputProps={{ ...inputProps }}
      InputProps={{
        endAdornment: endAdornment,
        ...InputProps,
      }}
      placeholder={placeholder}
      value={localSearch}
      withSearchIcon
      onChange={changeLocalSearch}
      onBlur={onBlur}
    />
  );
};

export const SearchField = withStyles(styles)(SearchFieldComponent);
