import { FC, ReactNode, useMemo } from 'react';
import { useIntercom } from 'react-use-intercom';

import { t } from '@core/i18n';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Button } from '@shared/components/button';
import { EmptyStateContent } from '@shared/components/empty-state-content';
import { ErrorStateContent } from '@shared/components/error-state-content';
import { Loading } from '@shared/components/loading';
import { FilterLinesIcon } from '@shared/icons/filter-lines';
import { SearchIcon } from '@shared/icons/search';
import { SearchNoDataIcon } from '@shared/icons/search-no-data';

import { styles } from './ListingPageStates.styles';

const defaultIntercomMessage = t(
  'there_is_an_error_on_the_page_i_cant_seem_to_load_any_data_do_you_know_how_long_until_the_issue_will_be_resolved_question'
);

export enum ListingPageStatesKey {
  loading,
  error,
  noData,
  noResults,
  noResultsAll,
  content,
}

export interface ErrorConfig {
  header?: string;
  intercomMessage?: string;
  text?: ReactNode;
}

export interface NoDataConfig {
  header?: string;
  icon?: ReactNode;
  text?: ReactNode;
  onCreate?: () => void;
}

export interface NoResultsAllConfig {
  header?: string;
  text?: ReactNode;
  onOpenFilters?: () => void;
}

export interface NoResultsConfig extends NoResultsAllConfig {
  search: string;
  onSearchEverywhere?: () => void;
}

export interface ListingPageStatesProps extends WithStyles<typeof styles> {
  errorConfig: ErrorConfig;
  noDataConfig: NoDataConfig;
  noResultsConfig: NoResultsConfig;
  noResultsAllConfig: NoResultsAllConfig;
  stateKey: ListingPageStatesKey;
}

const ListingPageStatesComponent: FC<ListingPageStatesProps> = ({
  classes,
  errorConfig,
  noDataConfig,
  noResultsConfig,
  noResultsAllConfig,
  stateKey = ListingPageStatesKey.content,
}) => {
  const { showNewMessages } = useIntercom();

  const loadingContent = useMemo(() => {
    return <Loading classes={{ root: classes.loading }} size={66} />;
  }, [classes]);

  const errorContent = useMemo(
    () => (
      <ErrorStateContent
        classes={{ root: classes.error, iconContainer: classes.iconContainer }}
        header={errorConfig.header || t('oh_no_it_looks_like_we_had_an_issue_loading_your_data')}
        icon={<SearchIcon color="#324DFC" classes={{ root: classes.icon }} strokeWidth={1} />}
        text={
          <>
            {errorConfig.text || t('sorry_were_having_trouble_with_your_search')}
            <span>{t('try_again_or')}</span>&nbsp;
            <a href="#" onClick={() => showNewMessages(errorConfig.intercomMessage || defaultIntercomMessage)}>
              {t('contact_support_for_help')}
            </a>
            <span>.</span>
          </>
        }
      />
    ),
    [classes, errorConfig, showNewMessages]
  );

  const noDataContent = useMemo(() => {
    return (
      <EmptyStateContent
        classes={{ root: classes.noData }}
        actions={
          noDataConfig?.onCreate && <Button text={t('create_new')} color="primary" onClick={noDataConfig.onCreate} />
        }
        header={noDataConfig.header || t('create_your_first_data')}
        icon={noDataConfig?.icon ? noDataConfig.icon : <SearchNoDataIcon classes={{ root: classes.icon }} />}
        text={noDataConfig.text || t('start_managing_your_data_now_by_creating_something_below')}
      />
    );
  }, [classes, noDataConfig]);

  const noResultsContent = useMemo(() => {
    return (
      <EmptyStateContent
        actions={
          <>
            {!!noResultsConfig.search && !!noResultsConfig.onSearchEverywhere && (
              <Button
                color="default"
                variant="outlined"
                text={t('search_everywhere')}
                onClick={noResultsConfig.onSearchEverywhere}
              />
            )}
            {!!noResultsConfig.onOpenFilters && (
              <Button
                text={t('open_filters_1')}
                color="primary"
                startIcon={<FilterLinesIcon classes={{ root: classes.openFilterIcon }} />}
                onClick={noResultsConfig.onOpenFilters}
              />
            )}
          </>
        }
        classes={{ root: classes.noResults }}
        header={noResultsConfig.header || t('no_data_found')}
        icon={<SearchIcon color="#324DFC" classes={{ root: classes.icon }} strokeWidth={1} />}
        text={noResultsConfig.text || t('try_changing_your_search_criteria')}
      />
    );
  }, [classes, noResultsConfig]);

  const noResultsAllContent = useMemo(() => {
    return (
      <EmptyStateContent
        actions={
          !!noResultsAllConfig.onOpenFilters && (
            <Button text={t('open_filters_1')} color="primary" onClick={noResultsAllConfig.onOpenFilters} />
          )
        }
        classes={{ root: classes.noResultsAll }}
        header={noResultsAllConfig.header || t('no_data_found')}
        icon={<SearchIcon color="#324DFC" classes={{ root: classes.icon }} strokeWidth={1} />}
        text={noResultsConfig.text || t('try_changing_your_search_criteria')}
      />
    );
  }, [classes, noResultsConfig]);

  switch (stateKey) {
    case ListingPageStatesKey.loading:
      return loadingContent;
    case ListingPageStatesKey.error:
      return errorContent;
    case ListingPageStatesKey.noData:
      return noDataContent;
    case ListingPageStatesKey.noResults:
      return noResultsContent;
    case ListingPageStatesKey.noResultsAll:
      return noResultsAllContent;
    default:
      return null;
  }
};

export const ListingPageStates = withStyles(styles)(ListingPageStatesComponent);
