import { FormLabel } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { FC, ReactNode, useState } from 'react';

import { styles } from './TextFieldWithActions.styles';
import { FieldHelperText } from '../field-helper-text';
import { TextField, TextFieldProps } from '../text-field';

export interface TextFieldWithActionsProps extends WithStyles<typeof styles> {
  fieldProps: Omit<TextFieldProps, 'classes'>;
  actions?: ReactNode;
}

const TextFieldWithActionsComponent: FC<TextFieldWithActionsProps> = ({ actions, classes, fieldProps }) => {
  const { label, error, errorText, infoText, warning, warningText, ...otherFieldProps } = fieldProps;

  const [focused, setFocused] = useState(false);
  return (
    <div
      className={cx(classes.root, {
        [classes.error]: error,
        [classes.inputActive]: focused,
      })}
    >
      {label && (
        <FormLabel classes={{ root: classes.labelRoot, error: classes.labelError }} component="legend" error={error}>
          {label}
        </FormLabel>
      )}
      <div className={classes.inputContainer}>
        <TextField
          classes={{ root: classes.textFieldRoot, input: classes.textFieldInput }}
          fullWidth
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          {...otherFieldProps}
        />
        {actions && <div className={classes.actionsContainer}>{actions}</div>}
      </div>

      <FieldHelperText
        classes={{ root: classes.helperText }}
        error={error}
        errorText={errorText}
        infoText={infoText}
        warning={warning}
        warningText={warningText}
      />
    </div>
  );
};

export const TextFieldWithActions = withStyles(styles)(TextFieldWithActionsComponent);
