import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      wiidth: 560,
    },
    content: {},
    actionLabel: {
      fontSize: 14,
      fontWeight: 500,
      color: colors.black.darker,
    },
    checkboxes: {
      gap: 16,
    },
    radiosContainer: {},
    radiosLabel: {
      fontWeight: 600,
      color: colors.black.darker,
    },
    radios: {
      gap: 16,
      marginTop: spacing(2),
    },
  });
