import { FC, useState } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Dialog } from '@shared/components/dialog';
import { TextField } from '@shared/components/text-field';
import { t } from '@shared/i18n';

import { styles } from './AddCommentModal.styles';

export type AddCommentModalProps = WithStyles<typeof styles> & {
  loading?: boolean;
  onClose: () => void;
  onSave: (comment: string) => void;
};

const AddCommentModalComponent: FC<AddCommentModalProps> = ({ classes, loading, onClose, onSave }) => {
  const [comment, setComment] = useState<string>('');

  const handleSubmit = () => {
    onSave(comment);
  };

  return (
    <Dialog
      buttonProps={{
        cancel: {
          onClick: onClose,
        },
        confirm: {
          disabled: !comment,
          loading: loading,
          text: t('save'),
          onClick: handleSubmit,
        },
      }}
      classes={{
        body: classes.content,
        actionButtons: classes.actionButtons,
      }}
      headingProps={{
        heading: t('add_a_comment'),
        withCloseButton: true,
      }}
      open
      withTransition
      onClose={onClose}
    >
      <TextField
        fullWidth
        label={t('comment')}
        minRows={3}
        multiline
        placeholder={t('xtextx_ellipsis', { text: t('add_a_comment_you_can_add_others_with_at_to_notify_them') })}
        value={comment}
        onChange={(e) => setComment(e.target.value)}
      />
    </Dialog>
  );
};

export const AddCommentModal = withStyles(styles)(AddCommentModalComponent);
