export enum SavedViewModes {
  dashboard = 'dashboard',
  view = 'view',
}

export enum SavedViewsRequestSegments {
  advisory = 'advisories',
  crm_companies = 'crm-companies',
  crm_contacts = 'crm-contacts',
  disposals = 'disposals',
  disposals_dev_pipeline = 'disposals/dev_pipeline',
  disposals_insights = 'disposals-insights',
  disposals_instruction_availability = 'disposals/instruction_availability',
  disposals_investment = 'disposals/investment',
  disposals_market_availability = 'disposals/market_availability',
  disposals_pitching = 'disposals/pitching',
  email_campaigns = 'email-campaigns',
  fees = 'fees',
  landlord_disposals = 'landlord-disposals',
  letting_transactions = 'letting-transactions',
  letting_transactions_insights = 'letting-transactions-insights',
  properties = 'properties',
  radius_lettings_transactions = 'radius-lettings-transactions',
  radius_sales_transactions = 'radius-sales-transactions',
  requirements = 'requirements',
  requirements_insights = 'requirements-insights',
  sale_transactions = 'sale-transactions',
  sale_transactions_occupational = 'sale-transactions/occupational',
  sale_transactions_investment = 'sale-transactions/investment',
  sale_transactions_insights = 'sale-transactions-insights',
  society_acquisitions = 'society-acquisitions',
  society_acquisitions_insights = 'society-acquisitions-insights',
  society_disposal_view_insights = 'society-disposal-view-insights',
  society_disposals = 'society-disposals',
  society_disposals_insights = 'society-disposals-insights',
  viewings_insights = 'viewings-insights',
}

export enum PinnedViewKeys {
  advisory = 'pinned_views_advisory',
  crm_companies = 'pinned_views_crm_companies',
  crm_contacts = 'pinned_views_crm_contacts',
  disposal_insights = 'pinned_views_disposal_insights',
  disposals = 'pinned_views_disposals',
  disposals_dev_pipeline = 'pinned_views_disposals_dev_pipeline',
  disposals_instruction_availability = 'pinned_views_disposals_instruction_availability',
  disposals_investment = 'pinned_views_disposals_investment',
  disposals_market_availability = 'pinned_views_disposals_market_availability',
  disposals_pitching = 'pinned_views_disposals_pitching',
  eg_rankings = 'pinned_views_eg_rankings',
  email_campaigns = 'pinned_views_email_campaigns',
  fees = 'pinned_views_wip_fees',
  landlord_disposals = 'pinned_views_landlord_disposals',
  letting_transaction_insights = 'pinned_views_letting_transaction_insights',
  letting_transactions = 'pinned_views_letting_transactions',
  properties = 'pinned_views_properties',
  radius_lettings_transactions = 'pinned_views_radius_lettings_transactions',
  radius_sales_transactions = 'pinned_views_radius_sales_transactions',
  requirement_insights = 'pinned_views_requirement_insights',
  requirements = 'pinned_views_requirements',
  sale_transaction_insights = 'pinned_views_sale_transaction_insights',
  sale_transactions = 'pinned_views_sale_transactions',
  sale_transactions_investment = 'pinned_views_sale_transactions_investment',
  sale_transactions_occupational = 'pinned_views_sale_transactions_occupational',
  society_acquisition_insights = 'pinned_views_society_acquisition_insights',
  society_disposal_insights = 'pinned_views_society_disposal_insights',
  society_disposals = 'pinned_views_society_disposals',
  viewing_insights = 'pinned_views_viewing_insights',
}

export interface SavedViewModeConfig {
  allSavedViews: string;
  deleteView: string;
  deleteViewConfirmCheckbox: string;
  deleteViewSharedNote: string;
  editView: string;
  manageSavedViews: string;
  moreAboutLink: string;
  moreAboutText: string;
  myViewsEmpty: string;
  myViewsInfo: string;
  pinnedViews: string;
  readOnlyView: string;
  saveAsNewView: string;
  saveChangesToView: string;
  savedViews: string;
  saveView: string;
  sharedViewsEmpty: string;
  sharedViewsInfo: string;
  standardViewMessage: string;
  standardViews: string;
  standardViewsEmpty: string;
  standardViewsInfo: string;
}

export enum SavedViewType {
  board = 'board',
  bulkTable = 'bulkTable',
  grid = 'grid',
  iframe = 'iframe',
  list = 'list',
  map = 'map',
  table = 'table',
}

export interface ISavedView<T = number> {
  created_at: string;
  created_at_timestamp: string;
  data: {
    columns: string | null;
    filters: string | null;
    order_by: string;
    tooltip: string;
    reports: Id[] | null;
    view_type: SavedViewType;
  };
  id: T;
  name: string;
  organisation_id: Id;
  pinned: number;
  shared: number;
  user_id: Id;
}
