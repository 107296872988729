import cx from 'classnames';
import { FC, useMemo } from 'react';

import { contactsQueryKeys } from '@apis/contactsApi';
import { t } from '@core/i18n';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { AutocompleteWithQuery } from '@shared/components/autocomplete-with-query/AutocompleteWithQuery';
import { DraggableList } from '@shared/components/draggable-list';
import { Ellipsis } from '@shared/components/ellipsis';
import { Flex } from '@shared/components/flex';
import { TextFieldProps } from '@shared/components/text-field';
import { CloseIcon } from '@shared/icons/close';
import { ContactIcon } from '@shared/icons/contact';
import { DragIcon } from '@shared/icons/drag';
import { SearchIcon } from '@shared/icons/search';
import { Contact } from '@shared/models/contact/contact';
import { LookupCommonItem } from '@shared/types/common/common';
import { listContactToLookupContact } from '@shared/utils/lookup';

import { styles } from './ContactsLookupWithDraggableList.styles';

export interface ContactsLookupWithDraggableListProps extends WithStyles<typeof styles> {
  disabled?: boolean;
  error?: boolean;
  inputProps?: Omit<TextFieldProps, 'classes'>;
  items: LookupCommonItem[];
  label?: string;
  searchDisabled?: boolean;
  searchValue?: string;
  showLookup?: boolean;
  onClick?: (id: number) => void;
  onDragEnd: (list: LookupCommonItem[]) => void;
  onRemoveItem?: (id: number) => void;
  onSearchValueChange?: (value: string) => void;
  onSelect?: (option: LookupCommonItem) => void;
}

const ContactsLookupWithDraggableListComponent: FC<ContactsLookupWithDraggableListProps> = ({
  classes,
  disabled = false,
  error = false,
  inputProps,
  items,
  label = '',
  searchValue = '',
  showLookup = true,
  onClick,
  onDragEnd,
  onRemoveItem,
  onSelect,
  onSearchValueChange,
}) => {
  const isDraggable = useMemo(() => items.length > 1, [items]);

  const renderAutocompleteOption = (option: Contact) => {
    return (
      <Flex autoWidth={false} direction="column">
        <Ellipsis classes={{ root: classes.autocompleteOptionName }} text={option.name} />
        <Ellipsis classes={{ root: classes.autocompleteOptionEmail }} text={option.email} />
      </Flex>
    );
  };

  const renderDraggableItem = (option: LookupCommonItem) => {
    return (
      <div className={classes.dragOption}>
        {isDraggable && <DragIcon classes={{ root: classes.dragIcon }} />}
        <Flex
          direction="row"
          wrap="nowrap"
          classes={{ root: classes.contactContentWrapper }}
          onClick={onClick ? () => onClick(option.id) : undefined}
        >
          <Flex alignItems="center" autoWidth={false} className={classes.contactIconContainer} justifyContent="center">
            <ContactIcon />
          </Flex>
          <Flex autoWidth={false} direction="column" wrap="nowrap" classes={{ root: classes.contactTextWrapper }}>
            <div className={classes.optionTitle}>{option.name}</div>
            <Ellipsis classes={{ root: classes.optionSubtitle }} text={option.subtext} />
          </Flex>
        </Flex>
        {!disabled && (
          <Flex
            className={classes.closeIconContainer}
            onClick={onRemoveItem ? () => onRemoveItem(option.id) : undefined}
          >
            <CloseIcon />
          </Flex>
        )}
      </div>
    );
  };

  return (
    <Flex autoWidth={false} classes={{ root: classes.root }}>
      {showLookup && (
        <AutocompleteWithQuery
          classes={{
            root: cx({ [classes.searchProperty]: items.length > 0 }),
            label: classes.label,
            listItem: classes.autocompleteListItem,
          }}
          error={error}
          inputProps={{
            InputProps: {
              startAdornment: <SearchIcon classes={{ root: classes.searchIcon }} />,
              disabled: disabled,
            },
            placeholder: t('xtextx_ellipsis', { text: t('search_contacts') }),
            ...inputProps,
          }}
          label={label}
          queryFactory={contactsQueryKeys.list}
          searchValue={searchValue}
          withClearIcon
          onSearchValueChange={(search) => {
            if (onSearchValueChange) {
              onSearchValueChange(search);
            }
          }}
          onSelect={(contact) => {
            if (onSelect) {
              onSelect(listContactToLookupContact(contact));
            }
          }}
          renderOption={renderAutocompleteOption}
        />
      )}
      {items.length > 0 && (
        <DraggableList
          classes={{
            disabledItem: classes.draggableListItem,
            root: classes.draggableList,
            item: classes.draggableItem,
          }}
          disabled={disabled}
          items={items}
          onDragEnd={onDragEnd}
          renderItem={renderDraggableItem}
        />
      )}
    </Flex>
  );
};
export const ContactsLookupWithDraggableList = withStyles(styles)(ContactsLookupWithDraggableListComponent);
