import { FC, useMemo } from 'react';

import { t } from '@core/i18n';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Option } from '@shared/components/select-new/Select';
import { SingleSelect } from '@shared/components/select-new/single-select';
import { GridIcon } from '@shared/icons/grid';
import { KanbanIcon } from '@shared/icons/kanban';
import { ListIcon } from '@shared/icons/list-new';
import { MapIcon } from '@shared/icons/map';
import { TableIcon } from '@shared/icons/table';
import { WheelIcon } from '@shared/icons/wheel';
import { SavedViewType } from '@shared/models/saved_view/savedView';
import { ButtonSize } from '@shared/types/common/button';

import { styles } from './ViewTypeSelect.styles';

export interface SupportedViewType {
  disabled?: boolean;
  type: SavedViewType;
}

const getViewTypeOption = (item: SupportedViewType): Option<SavedViewType> | undefined => {
  switch (item.type) {
    case SavedViewType.board:
      return {
        icon: <KanbanIcon />,
        label: t('kanban'),
        id: item.type,
        disabled: item.disabled,
        tooltip: !item.disabled ? '' : t('upgrade_to_pro_for_access'),
      };

    case SavedViewType.grid:
      return {
        icon: <GridIcon />,
        label: t('grid'),
        id: item.type,
        disabled: item.disabled,
        tooltip: !item.disabled ? '' : t('upgrade_to_pro_for_access'),
      };

    case SavedViewType.list:
      return {
        icon: <ListIcon />,
        label: t('list'),
        id: item.type,
        disabled: item.disabled,
        tooltip: !item.disabled ? '' : t('upgrade_to_pro_for_access'),
      };

    case SavedViewType.map:
      return {
        icon: <MapIcon />,
        label: t('map'),
        id: item.type,
        disabled: item.disabled,
        tooltip: !item.disabled ? '' : t('upgrade_to_pro_for_access'),
      };

    case SavedViewType.table:
      return {
        icon: <TableIcon />,
        label: t('table'),
        id: item.type,
        disabled: item.disabled,
        tooltip: !item.disabled ? '' : t('upgrade_to_pro_for_access'),
      };

    case SavedViewType.bulkTable:
    case SavedViewType.iframe:
      return {
        icon: <WheelIcon />,
        label: `DEBUG - ${item.type}`,
        id: item.type,
        disabled: item.disabled,
        tooltip: !item.disabled ? '' : t('upgrade_to_pro_for_access'),
      };
  }
};

export interface ViewTypeSelectProps extends WithStyles<typeof styles> {
  disabled?: boolean;
  supportedViewTypes: SupportedViewType[];
  viewType?: SavedViewType;
  onChange?: () => void;
  setViewType: UseStateSetter<SavedViewType | undefined>;
  size?: ButtonSize;
}

const ViewTypeSelectComponent: FC<ViewTypeSelectProps> = ({
  classes,
  disabled,
  supportedViewTypes,
  viewType,
  onChange,
  setViewType,
  size = ButtonSize.small,
}) => {
  const selectOptions = useMemo(
    () =>
      supportedViewTypes.reduce((result, item) => {
        const option = getViewTypeOption(item);

        if (option) {
          result.push(option);
        }

        return result;
      }, [] as Option<SavedViewType>[]),
    [supportedViewTypes]
  );

  const activeViewIcon = useMemo(() => {
    if (!viewType) {
      return null;
    }

    switch (viewType) {
      case SavedViewType.board:
        return <KanbanIcon />;
      case SavedViewType.grid:
        return <GridIcon />;
      case SavedViewType.list:
        return <ListIcon />;
      case SavedViewType.map:
        return <MapIcon />;
      case SavedViewType.table:
        return <TableIcon />;

      case SavedViewType.bulkTable:
      case SavedViewType.iframe:
        return <WheelIcon />;
    }
  }, [viewType]);

  return (
    <SingleSelect
      classes={{ root: classes.root }}
      disabled={disabled}
      icon={activeViewIcon}
      options={selectOptions}
      placeholder={t('select')}
      size={size}
      value={viewType}
      onChange={(value) => {
        setViewType(value as SavedViewType);

        if (onChange) {
          onChange();
        }
      }}
    />
  );
};

export const ViewTypeSelect = withStyles(styles)(ViewTypeSelectComponent);
