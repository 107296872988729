import { SvgIcon } from '@material-ui/core';
import { FC } from 'react';

import { withStyles, WithStyles } from '@core/theme/utils/with-styles';
import { colors } from '@shared/theme/colours';

import { styles } from './UploadCloud.styles';

interface UploadCloudIconProps extends WithStyles<typeof styles> {
  color?: string;
}

const UploadCloudIconComponent: FC<UploadCloudIconProps> = ({ classes, color = colors.white.lightest }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      classes={{ root: classes.root }}
    >
      <path
        d="M2.66634 10.8282C1.86235 10.29 1.33301 9.37347 1.33301 8.33333C1.33301 6.77095 2.52735 5.48753 4.05284 5.34625C4.36488 3.44809 6.01317 2 7.99967 2C9.98618 2 11.6345 3.44809 11.9465 5.34625C13.472 5.48753 14.6663 6.77095 14.6663 8.33333C14.6663 9.37347 14.137 10.29 13.333 10.8282M5.33301 10.6667L7.99967 8M7.99967 8L10.6663 10.6667M7.99967 8V14"
        stroke={color}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export const UploadCloudIcon = withStyles(styles)(UploadCloudIconComponent);
