import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      background: colors.white.lightest,
      border: `1px solid ${colors.white.lightest}`,
      borderRadius: 6,
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'nowrap',
      gap: 8,
      height: 30,
      padding: spacing(1.5, 3),
      transition: 'all .3s',
      width: 'auto',

      '&:hover': {
        backgroundColor: colors.kato.grey[100],
        borderColor: colors.kato.grey[100],
      },
    },
    rootActive: {
      pointerEvents: 'none',
      border: `1px solid ${colors.kato.navy[950]}`,

      '& $text': {
        color: colors.kato.navy[950],
      },

      '& $count': {
        backgroundColor: colors.kato.navy[950],
        color: colors.white.lightest,
      },
    },
    text: {
      color: '#515D70', //TODO replace after palette will be change
      userSelect: 'none',
      whiteSpace: 'nowrap',
    },
    count: {
      alignItems: 'center',
      backgroundColor: colors.kato.grey[200],
      borderRadius: 15,
      color: colors.kato.navy[950],
      display: 'flex',
      fontSize: 10,
      fontWeight: 600,
      height: 18,
      justifyContent: 'center',
      minWidth: 18,
      padding: spacing(1),
      transition: 'all .3s',
    },
  });
