import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

const headerHeight = 32;
const tabsHeight = 55;

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      boxShadow: '0px 1px 6px 0px #1018280F',
      height: '100%',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      gap: spacing(4),
    },
    header: {
      height: headerHeight,
      display: 'flex',
      alignContent: 'center',
      width: '100%',
      justifyContent: 'space-between',
    },
    headerControls: {
      display: 'flex',
      gap: spacing(2),
    },
    tableBody: {
      border: 'none',
    },
    body: {
      flexGrow: 1,
      height: `calc(100% - ${tabsHeight}px)`,
      overflow: 'auto',
      width: '100%',
    },
    content: {
      border: '1px solid #EAECF0', //TODO change after creating new palette
      borderRadius: 12,
      boxShadow: '0px 1px 6px 0px #1018280F',
      height: `calc(100% - ${headerHeight + spacing(4)}px)`,
      overflow: 'hidden',
    },
    searchBtn: {
      minWidth: 32,
      width: 32,
      height: 32,
    },
    searchField: {
      minWidth: 340,
      background: colors.white.lightest,
    },
    tabs: {
      height: tabsHeight,
    },
    agentColumn: {
      display: 'flex',
      flexDirection: 'column',
      gap: spacing(1),
    },
    tableRowFirstLine: {
      color: '#101828', //TODO change after creating new palette,
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '20px',
    },
    tableRowSecondLine: {
      color: '#7E8899', //TODO change after creating new palette,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '20px',
    },
    badges: {
      display: 'flex',
      flexWrap: 'nowrap',
    },
    badge: {
      borderRadius: 4,
      width: 'fit-content',
      padding: spacing(2),
    },
  });
